import { createSlice } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';
import serverRoutes from '@app/utils/server-routes';
import {
  LiveReport,
  LiveReportRequest,
  LiveReportResponse,
  ReportResponse,
  ReportStatus,
} from '@app/interfaces/report.type';
import { StatusVariant } from '@app/components/status-label/status-label';
import { Filter, FilterRange } from '@app/interfaces/filter';

export type CreateReportData = {
  project_id: string;
  name: string;
  description: string;
  filter: unknown;
  updated: boolean;
  update_period?: number;
};
export type LiveReportSettingsRequestData = {
  id: string;
};
export type LiveReportsRequestData = {
  project_id?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortDesc?: boolean;
};
export type ReportRequestData = { projectId?: string };
export type RemoveLiveReportData = { id: string };

export type ReportsState = {
  report: unknown;
  reports: Array<ReportResponse>;
  liveReport: LiveReport | null;
  liveReports: Array<LiveReport>;
  isPending: boolean;
  error: string | null;
  statusIsPending: boolean;
  statusError: string | null;
  liveReportTotal: number;
};

export type ChangeStatusRequest = { id: string; new_status: StatusVariant };

const initialState: ReportsState = {
  report: null,
  reports: [],
  liveReports: [],
  liveReport: null,
  isPending: false,
  error: null,
  statusIsPending: false,
  liveReportTotal: 0,
  statusError: null,
};

const reportsSlice = createSlice({
  name: 'reportSlice',
  initialState,
  reducers: {
    createLiveReportResponse: (state, { payload }: { payload: LiveReport }) => {
      state.liveReports = [...state.liveReports, payload];
    },
    createReportResponse: (state, { payload }: { payload: ReportResponse }) => {
      state.reports = [...state.reports, payload];
    },
    responseReport: (state, { payload }) => {
      state.report = payload;
    },
    liveReportResponse: (state, { payload }) => {
      state.liveReport = payload;
    },
    responseReports: (state, { payload }) => {
      state.reports = payload;
    },
    removeReportsResponse: (state, { payload }) => {
      const { status, requestData } = payload;
      const { id } = requestData;
      if (status === 204) {
        state.reports = state.reports.filter((report) => report.report_id !== id);
      }
    },
    removeLiveReportsResponse: (state, { payload }) => {
      const { status, requestData } = payload;
      const { id } = requestData;
      if (status === 204) {
        state.liveReports = state.liveReports.filter((report) => report.live_report_id !== id);
      }
    },
    responseIsPending: (state, { payload }) => {
      state.isPending = payload;
    },
    responseError: (state, { payload }) => {
      state.error = payload;
    },
    liveReportsResponse: (state, { payload }: { payload: LiveReportResponse }) => {
      const { live_reports, total } = payload;
      state.liveReports = live_reports;
      state.liveReportTotal = total;
    },
    liveReportEditResponse: (state, { payload }) => {
      state.liveReports = state.liveReports.map((report) => {
        if (report.live_report_id === payload.live_report_id) {
          report.name = payload.name;
        }
        return report;
      });
    },
    responseChangeStatus: (state, { payload }: { payload: ReportStatus }) => {
      // TODO: CHANGE TO REAL
      const { status, dt_status, report_id, dt_next_update } = payload;
      state.reports = state.reports.map((report) =>
        report.report_id === report_id
          ? {
              ...report,
              status: status,
              dt_status: dt_status,
              dt_next_update: dt_next_update,
            }
          : report,
      ) as Array<ReportResponse>;
    },
    responseStatusPending: (state, { payload }) => {
      state.statusIsPending = payload;
    },
    responseStatusError: (state, { payload }) => {
      state.statusError = payload;
    },
  },
});

export const {
  responseReports,
  responseIsPending,
  responseError,
  createReportResponse,
  responseChangeStatus,
  removeReportsResponse,
  liveReportsResponse,
  createLiveReportResponse,
  liveReportResponse,
  removeLiveReportsResponse,
  liveReportEditResponse,
} = reportsSlice.actions;

export default reportsSlice.reducer;

export const createLiveReportRequest = (data: LiveReportRequest) =>
  httpRequest({
    url: serverRoutes.live_report,
    method: 'POST',
    data,
    onSuccess: createLiveReportResponse.type,
    onLoading: responseIsPending.type,
    onError: responseError.type,
  });

export const changeStatusRequest = (data: ChangeStatusRequest) =>
  httpRequest({
    url: serverRoutes.changeStatus,
    method: 'PUT',
    params: data,
    data,
    onSuccess: responseChangeStatus.type,
    onError: responseError.type,
    onLoading: responseIsPending.type,
  });

export const reportRequest = ({ report_id }: { report_id: string }) =>
  httpRequest({
    url: serverRoutes.report,
    method: 'GET',
    params: { id: report_id },
    onSuccess: responseReports.type,
    onLoading: responseIsPending.type,
    onError: responseError.type,
  });

export const reportsRequest = ({ projectId }: ReportRequestData) => {
  const params = projectId ? { params: { project_id: projectId } } : {};
  return httpRequest({
    url: serverRoutes.reports,
    method: 'GET',
    onSuccess: responseReports.type,
    onLoading: responseIsPending.type,
    onError: responseError.type,
    ...params,
  });
};

export const liveReportSettingsRequest = (params: LiveReportSettingsRequestData) =>
  httpRequest({
    url: serverRoutes.live_report,
    method: 'GET',
    params,
    onSuccess: liveReportResponse.type,
    onLoading: responseIsPending.type,
    onError: responseError.type,
  });

export const liveReportsRequest = (data: LiveReportsRequestData) => {
  return httpRequest({
    url: serverRoutes.live_reports,
    method: 'POST',
    onSuccess: liveReportsResponse.type,
    onLoading: responseIsPending.type,
    onError: responseError.type,
    data,
  });
};

export const createReportRequest = (data: CreateReportData) =>
  httpRequest({
    url: serverRoutes.report,
    method: 'POST',
    data,
    onSuccess: createReportResponse.type,
    onLoading: responseIsPending.type,
    onError: responseError.type,
  });

export const removeReportsRequest = (params: { id: string }) =>
  httpRequest({
    url: serverRoutes.report,
    method: 'DELETE',
    params,
    onStatus: removeReportsResponse.type,
    onLoading: responseIsPending.type,
    onError: responseError.type,
  });

export const removeLiveReportRequest = (params: RemoveLiveReportData) =>
  httpRequest({
    url: serverRoutes.live_report,
    method: 'DELETE',
    params,
    onStatus: removeLiveReportsResponse.type,
    onLoading: responseIsPending.type,
    onError: responseError.type,
  });

export type EditLiveReportRequest = { id: string; name: string; filter?: Filter & FilterRange };

export const editLiveReportRequest = (data: EditLiveReportRequest) =>
  httpRequest({
    url: serverRoutes.live_report,
    params: { id: data.id },
    method: 'PUT',
    data: { name: data.name, filter: data.filter, description: data.name },
    onSuccess: liveReportEditResponse.type,
    onError: responseError.type,
  });
