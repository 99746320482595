module.exports = {
  status: 'Статус',
  empty: 'В отчете нет графиков',
  new_chart: 'Новый график',
  loading: 'Поиск графиков',
  popover: {
    add_diagram: 'Добавить диаграмму',
    gist: 'Гистограмма',
  },
  drop_menu: {
    intervals: 'Задать временной период для всех диаграмм',
    edit: 'Редактировать',
    remove: 'Удалить',
    to_report: 'Перейти в отчет',
  },
  empty_charts: 'У отчета еще нет графиков',
  remove_chart_title: 'Удалить график',
  remove_chart_body: 'Вы точно хотите удалить?',
  select: {
    date_placeholder: 'Выберите дату',
    day: '1 день',
    weekdays: '7 дней',
    monthDays: '30 дней',
    today: 'Текущий день',
    week: 'Текущая неделя',
    month: 'Текущий месяц',
    all_data: 'Все данные',
    custom_date: 'Диапазон',
    aggregate_choices: 'Выбрать',
    metric_choices: 'Выбрать',
    color_choices: 'Изменить',
  },
  labels: {
    customizing_data_to_display_on_a_histogram_y1:
      'Настройка данных для отображения на гистограмме (вертикальная ось Y1)',
    customizing_data_to_display_on_a_histogram_y2:
      'Настройка данных для отображения на гистограмме (вертикальная ось Y2)',
    additional_vertical_axis: 'Дополнительная вертикальная ось',
    metric_axis_y1: 'Выбор метрики (ось Y)',
    metric_axis_y2: 'Выбор метрики (ось Y2)',
    aggregate_data: 'Агрегация данных',
    label_color: 'Цвет колонки',
  },
  button: {
    remove_chart: 'Удалить график',
    color_edit: 'Изменить',
  },
  interval_error: 'Интервал не указан или неверный интервал',
  modal_remove_chart_title: 'Подтвердите удаление',
  modal_interval_title: 'Задать период для всех графиков',
  modal_interval_success_button: 'Применить',
  modal_interval_before: 'от',
  modal_interval_after: 'до',
  modal_interval_select_placeholder: 'Период: Указать период',
  modal_interval_or: 'или',
  aggregate: {
    mid: 'среднее',
    sum: 'сумма',
    count: 'количество',
    min: 'min значение',
    max: 'max значение',
    prop_sum: 'доля от суммы по столбцу',
    prop_num: 'доля от заданного значения',
  },
  date_select: {
    h: 'по часам',
    d: 'по дням',
    w: 'по неделям',
    m: 'по месяцам',
  },
  add_chart: 'Добавить график',
  settings: 'Общие настройки',
  chart_name: 'Название графика',
  not_filled: 'Поле не заполнено',
  diagram_type: 'Тип диаграммы',
  default: 'Выбор периода по умолчанию',
  display_values: 'Отобразить значения на диаграмме',
  display_legend: 'Отобразить легенду',
  data_hor_x: 'Выбор данных для группировки (настройка горизонтальной оси X)',
  data_x: 'Данные для группировки (ось X)',
  choose: 'Выбрать',
  date_call: 'Дата и время звонка',
  metric: 'Метрика',
  analytic_project: 'Аналитический проект',
  ap: 'АП',
  chart_toolbar:{
    download: 'Скачать',
    selection: 'Выбрать',
    selectionZoom: 'Увеличить выбранное',
    zoomIn: 'Увеличить',
    zoomOut: 'Уменьшить',
    pan: 'Переместить',
    reset: 'Сбросить настройки',
  }
};
