import React from 'react';

import { CircleButton, Modal, TooltipWrapper } from '@app/components/ui';

import { TableItem } from '@app/components/ui/table/table.type';
import { METAdatetime } from '@app/utils/constants';
import { getFrontendDate } from '@app/utils/helpers';

import Icon from '@app/components/ui/icons';
import {
  FilterItemNotOnlyArray,
  FilterRangeParametersItemForLType,
  FilterRangeParametersItemForRType,
} from '@app/interfaces/filter';

import { useNavigate } from 'react-router';
import { navigationRoutes } from '@app/utils/navigation-routes';

import {
  PresetsCreateDataType,
  PresetsDataType,
} from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';

import RubberTable from '@app/components/ui/rubber-table';

import useTranslation from '@app/hooks/use-translation';
import { MenuSmallIcon } from '@app/components/ui/icons/icons-list';
import { v4 } from 'uuid';
import { useGetAliasesListQuery } from '@app/store/reducers/alias-settings-page.api';
import {
  useCreateSinglePresetMutation,
  useDeletePresetTemplateMutation,
} from '@app/store/api/preset-template.api';
import { Filter, Range } from '@app/components/ui/easy-filter/types';

type PropsType = {
  presetGroup: PresetsDataType[];
};

export const TableChildren: React.FC<PropsType> = ({ presetGroup }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('pages.allPagesHintTitles');
  const { t: ti } = useTranslation('pages.presets');
  // const dispatch = useAppDispatch();
  const [presetId, setPresetId] = React.useState('');
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  // const { aliases } = useAppSelector((state) => state.presets);
  //api
  const { data: aliases } = useGetAliasesListQuery();
  const [deletePreset] = useDeletePresetTemplateMutation();
  const [createPreset] = useCreateSinglePresetMutation();

  const columns = [
    {
      title: ti('table.name'),
      index: 'name',
    },
    {
      title: ti('table.time'),
      index: 'time',
    },
    {
      title: ti('table.desc'),
      index: 'filter',
    },
    {
      title: ti('table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];

  function copyPreset(preset: PresetsDataType) {
    const newPreset: PresetsCreateDataType = {
      name: preset?.name + ` ${ti('copy')}`,
      description: '',
      preset_group_id: preset.preset_group_id,
      shared: false,
      rules: preset.rules as Filter & Range,
    };
    createPreset(newPreset).then((data) => {
      const { data: presetData } = data as unknown as { data: PresetsDataType };
      navigate(`/${navigationRoutes.presets}/${presetData.preset_id}`);
    });
  }

  const deleteSinglePreset = () => {
    deletePreset({ id: presetId }).then(() => {
      setDeleteModal(false);
    });
  };
  const clickCircleDelete = (id: string) => {
    setPresetId(id);
    setDeleteModal(true);
  };
  const getAliasesNameById = (id: string) => {
    return aliases?.aliases.find((alias) => alias.alias_id === id)?.name || 'N/A';
  };
  const getAliasesTypeById = (id: string) => {
    return aliases?.aliases.find((alias) => alias.alias_id === id)?.type_value || id;
  };
  const getRangeNameTypeId = (type: string) => {
    return METAdatetime.find((item) => item.id === type)?.name;
  };
  const getFilterValues = (filter: FilterItemNotOnlyArray) => {
    if (Array.isArray(filter.value)) {
      return filter?.value.map((val, index) => {
        return (
          <span className="mr-[6px] last:mr-[0px]" key={index}>
            {val}
          </span>
        );
      });
    } else if (getAliasesTypeById(filter.id) === 'datetime') {
      return getFrontendDate(filter.value as string, 'dd.MM.yyyy HH:mm');
    } else {
      return filter.value;
    }
  };

  const filteredArr = () => {
    return presetGroup?.map((dataItem) => ({
      ...dataItem,
      utils: (
        <div className="flex pl-[10px] w-[140px] gap-[10px] items-center border-l-[1px] border-[#F1F2F3]">
          <CircleButton
            hintTitle={t('templates.settings_template_icon')}
            icon="SettingIcon"
            size={18}
            className={`text-3color hover:text-action`}
            onClick={() => navigate(`/${navigationRoutes.presets}/${dataItem.preset_id}`)}
          />
          <CircleButton
            hintTitle={t('templates.copy_template_icon')}
            icon="CopyIcon"
            size={18}
            className={`text-3color hover:text-action`}
            onClick={() => copyPreset(dataItem)}
          />
          <CircleButton
            hintTitle={t('templates.delete_template_icon')}
            icon="TrashIcon"
            size={18}
            onClick={() => clickCircleDelete(dataItem.preset_id)}
            className={`text-basic_red`}
          />
        </div>
      ),
      name: (
        <div className="text-0color flex items-center font-[600] tracking-tight text-[14px] leading-[110%]">
          <div className="min-h-[28px] min-w-[28px] max-h-[28px] max-w-[28px] flex items-center justify-center ml-[16px] mr-[12px] bg-blue_pal rounded-[6px]">
            <MenuSmallIcon size={13} className="text-action" />
          </div>
          <TooltipWrapper content={dataItem.name} id={dataItem.name + v4()}>
            <div className="truncate">{dataItem.name}</div>
          </TooltipWrapper>
        </div>
      ),
      time: (
        <div className="flex items-center w-full text-0color font-[400] tracking-tight text-[14px] leading-[110%]">
          <Icon name={'CalendarIcon'} className="mr-[10px]" />
          {dataItem.rules.range.parameters.map((param, index) => {
            if (dataItem.rules.range.type === 'r') {
              const dateAndTime = (param as FilterRangeParametersItemForRType).value.split(' ');
              return (
                <span key={`date-${index}`}>
                  {index === 1 && <span className="inline-block mr-[5px] ml-[5px]">-</span>}
                  <span className="inline-block">
                    {index === 0 ? `${ti('from')} ` : `${ti('to')} `}
                    {getFrontendDate(dateAndTime[0], 'dd.MM.yyyy')}
                  </span>
                  <span className="inline-block">{dateAndTime[1]}</span>
                </span>
              );
            }
            if (dataItem.rules.range.type === 'l') {
              return (
                <span key={`date-${index}`} className="inline-block">
                  {(param as FilterRangeParametersItemForLType).num + ` ${ti('last_days')}`}
                </span>
              );
            }
            return <></>;
          })}
          {dataItem.rules.range.type !== 'r' ? (
            dataItem.rules.range.type !== 'l' ? (
              <span>{getRangeNameTypeId(dataItem.rules.range.type)}</span>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      ),
      filter: (
        <div className="max-h-[31px]">
          <div className="text-0color font-[400] tracking-tight text-[14px] leading-[110%]">
            {dataItem.rules.filter.map((filter, index) => {
              return (
                <span
                  key={filter.id + index}
                  className="mr-[16px] py-2 last:mr-[0px] block w-full truncate"
                >
                  <span>{getAliasesNameById(filter.id)}</span>
                  <span className="mr-[6px] ml-[6px] w-[19px] h-full border-[1px] border-solid border-[#A6ABBA] rounded-[50%] p-[0px_4px_1px_5px]">
                    {filter.condition}
                  </span>

                  {filter.value[0] !== '' && (
                    <span className="bg-basic_app_bg p-[2px_8px_3px] rounded-[4px] text-2color w-full">
                      {getFilterValues(filter)}
                    </span>
                  )}
                </span>
              );
            })}
          </div>
        </div>
      ),
    })) as unknown as Array<TableItem>;
  };
  return (
    <>
      <Modal
        id={'deleteDictionary'}
        variant={'removal'}
        value={deleteModal}
        setValue={setDeleteModal}
        title={ti('warning')}
        size={'xs'}
        onApply={() => deleteSinglePreset()}
      >
        <div className="px-[50px] ">{ti('confirm_group_delete')}</div>
      </Modal>
      <div className="">
        <RubberTable
          onFilter={(e) => console.log(e)}
          columns={columns}
          withoutCols
          dataSource={presetGroup !== null ? filteredArr() : []}
        />
      </div>
    </>
  );
};
