import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import useTranslation from '@app/hooks/use-translation';
import { GridIcon } from '@icons';
import { Checkbox, EasyModal } from '@ui';

import {
  useChangeCustomAliasesListMutation,
  useGetCustomAliasesListQuery,
} from '@app/store/api/custom-aliases.api';
import { useLazyGetRecordsQuery } from '@app/store/api/records.api';
import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';

export interface ChangeColumnDisplayModalProps {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  recordsParams: GetFilterParamsNew;
}
export const ChangeColumnDisplayModalBlock: FC<ChangeColumnDisplayModalProps> = ({
  value,
  setValue,
  recordsParams,
}) => {
  const [selectedAliases, setSelectedAliases] = useState<string[]>([]);
  const { t } = useTranslation('pages.records');
  //api
  const { data } = useGetCustomAliasesListQuery();
  const [changeCustomAliasesList] = useChangeCustomAliasesListMutation();
  const [refetchRecordsData] = useLazyGetRecordsQuery();
  // useEffect
  useEffect(() => {
    if (!data?.show_aliases) return;
    setSelectedAliases(
      data?.show_aliases.filter((item) => item.checked).map((item) => item.alias_id),
    );
  }, [data?.show_aliases]);

  const saveCustomAliases = () => {
    changeCustomAliasesList({ aliases: selectedAliases }).then(() => {
      refetchRecordsData(recordsParams);
    });
  };

  function handleOnClose() {
    setValue(false);
  }
  return (
    <EasyModal
      show={value}
      onClose={handleOnClose}
      onSuccess={saveCustomAliases.bind(null)}
      label={t('display_cols')}
    >
      <>
        <p className="pb-6 pt-4">{t('choose_cols')}</p>
        <div className="h-full mb-1">
          <table className="relative w-full bg-defaultBg rounded-defaultR border-separate border-spacing-y-1 px-1">
            <thead>
              <tr>
                <td className="bg-defaultBg uppercase text-xm font-semibold p-4">
                  {t('description')}
                </td>
              </tr>
            </thead>
            <tbody>
              {data?.show_aliases.map((alias) => (
                <tr key={alias.alias_id}>
                  <td className="bg-white p-3.5 rounded-lg">
                    <div className="flex items-center gap-[10px]">
                      <div className="flex items-center mr-[10.06px]">
                        <GridIcon size={8} />
                      </div>
                      <Checkbox
                        checked={selectedAliases.includes(alias.alias_id)}
                        onChange={() =>
                          setSelectedAliases((prev) =>
                            prev.includes(alias.alias_id)
                              ? prev.filter((item) => item !== alias.alias_id)
                              : [...prev, alias.alias_id],
                          )
                        }
                      />
                      {alias.name}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </EasyModal>
  );
};
