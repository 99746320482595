module.exports = {
  title: 'Отчеты',
  choose_project: 'Выберите проект',
  empty: 'Отчетов не найдено',
  changed_status: 'Статус изменен',
  filter_title: 'Фильтровать записи отчета',
  filter_settings: 'Условия отбора записей в отчет',
  select: {
    all_projects: 'Все аналитические проекты',
  },
  settings: 'Настройки',
  not_found: 'Отчет не найден',
  button_settings: {
    save: 'Сохранить',
    remove: 'Удалить',
    cancel: 'Отмена',
  },
  status: {
    created: 'Новый',
    life: 'LIVE',
    updating: 'Обновление',
    done: 'Готов',
    archive: 'Архив',
    pause: 'Пауза',
    deleting: 'Удаление',
    error: 'Ошибка',
  },
  table: {
    report_name: 'Название отчета',
    analytic_project: 'Аналитический проект',
    status: 'Статус',
    date_created: 'Дата создания',
    date_next_update: 'Дата следующего обновления',
    actions: 'Действия',
    download_report: 'Скачать отчет',
  },
  navigation: {
    charts: 'Графики и диаграммы',
    settings: 'Настройки',
    records: 'Записи отчета',
  },
  modal: {
    remove_report: 'Удалить отчет?',
    confirm_remove_report: 'Все данные и настройки отчёта будут удалены.',
  },
  pagination: {
    calls: '%{call_number} звонков',
  },
};
