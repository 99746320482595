import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from '@app/hooks/use-translation';
import { Button, Input, Select } from '@ui';
import ViewFilter from '@app/components/UniversalFilter/UniversalFilterView';
import { AsrModerType } from '@app/interfaces/asr-moder.type';
import {
  RecognitionRulesSettingsForCreate,
  RecognitionRulesSettingsObj,
} from '@app/interfaces/settings.type';
import useGetUniversalFilter from '@app/hooks/use-universal-filter-values/use-get-universal-filter';
import getArrayFilterValues from '@app/hooks/use-universal-filter-values/getArrayFilterValues';
import { isEqual } from 'lodash';
import cloneFilterWithValues from '@app/hooks/use-universal-filter-values/cloneFilterWithValues';
import { Filter, FilterRange } from '@app/interfaces/filter';
type RecognitionRulesFormPropsType = {
  asr_model: AsrModerType[] | undefined;
  onFormSubmit(data: RecognitionRulesSettingsForCreate): void;
  editMode: boolean;
  recognitionRuleData: RecognitionRulesSettingsObj | undefined;
};
export const RecognitionRulesForm: FC<RecognitionRulesFormPropsType> = (props) => {
  const { asr_model, onFormSubmit, editMode, recognitionRuleData } = props;
  const { t } = useTranslation('pages.settings');
  const getFilter = useGetUniversalFilter();
  const formSetup = editMode
    ? {
        name: recognitionRuleData?.name,
        asr_model_id: recognitionRuleData?.asr_model_id,
        rules: recognitionRuleData?.rules,
      }
    : undefined;
  const { handleSubmit, watch, register, setValue } = useForm<RecognitionRulesSettingsForCreate>({
    defaultValues: formSetup,
  });
  useEffect(() => {
    if (!editMode) return;
    if (!recognitionRuleData) return;
    getFilter({
      filter: getArrayFilterValues(recognitionRuleData?.rules?.filter),
      range: recognitionRuleData?.rules.range,
    });
  }, [editMode, getFilter, recognitionRuleData, recognitionRuleData?.rules]);
  function onSubmit(data) {
    onFormSubmit(data);
  }
  async function saveFilter(newFilter: Filter & FilterRange, oldFilter: Filter & FilterRange) {
    if (!isEqual(oldFilter, newFilter)) {
      const filter = cloneFilterWithValues(newFilter.filter);
      const newFilterValues = { ...newFilter, filter: filter };
      setValue('rules', newFilterValues);
    }
  }
  function getAsrModelOptionsList() {
    return asr_model?.map((model) => ({ title: model.name, value: model.model_id }));
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="flex items-center gap-[101.5px] mb-[20px]">
          <span className="text-1color text-[15px] font-[500] leading-[110%] tracking-tight	">
            {t('recog_rules.form.name')}
          </span>
          <div className={`w-full`}>
            <Input
              {...register('name')}
              placeholder={t('recog_rules.form.placeholder_name')}
              name={'name'}
            />
          </div>
        </div>
        <div className="flex items-center gap-[51px] mb-[20px]">
          <span className="mt-[-25px] whitespace-nowrap text-1color text-[15px] font-[500] leading-[110%] tracking-tight">
            {t('recog_rules.form.filter_rules')}
          </span>
          <div className={'w-full'}>
            <ViewFilter modalMode={true} saveFilter={saveFilter} />
          </div>
        </div>
        <div className="flex items-center gap-[13px] mb-[20px]">
          <span className="whitespace-nowrap text-1color text-[15px] font-[500] leading-[110%] tracking-tight">
            {t('recog_rules.form.recog_model')}
          </span>
          <div className={`w-full`}>
            <Select
              placeholder={t('recog_rules.form.choose_placeholder')}
              options={getAsrModelOptionsList() || []}
              dropWidth={374}
              onChange={(value) => setValue('asr_model_id', value as string)}
              defaultValue={watch().asr_model_id}
            />
          </div>
        </div>
        <div className="w-full flex justify-center flex-col gap-[18px] pl-[132px] pr-[140px]">
          <Button
            type="submit"
            isFull
            className="!px-[60px] !py-[20px] mt-[10px]"
            label={t('alias.form.button_label')}
          />
          {editMode && (
            <div className="flex w-full justify-center items-center ml-[-4px]">
              <Button
                variant="danger"
                fill="linked"
                className="w-full"
                label={t('recog_rules.form.button_label_delete')}
                // onClick={() => aliasData?.alias_id && deleteAliasHandle(aliasData?.alias_id)}
              />
            </div>
          )}
        </div>
      </form>
      {/*<EasyModal variant={'largeW1127'} show={true}>*/}
      {/*  <div className={'min-h-[600px]'}>*/}
      {/*    */}
      {/*  </div>*/}
      {/*</EasyModal>*/}
    </>
  );
};
