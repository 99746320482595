import cn from 'classnames';

export const activeClassName = ({ active }: { active: boolean }) =>
  cn(
    active && 'bg-blue_border translate-x-[8px] transition duration-30',
    'px-[6px]',
    'pt-[7px]',
    'pb-[1px]',
    'flex-1',
    'w-full',
    'rounded',
  );

export const chartMessageIconClassName = (cssBg: string) =>
  cn(
    cssBg,
    'flex',
    'absolute',
    '-left-[16px]',
    'justify-center',
    'items-center',
    'w-[32px]',
    'h-[32px]',
    'rounded-full',
  );
