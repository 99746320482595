import { Loading } from '@app/components/ui';
import { getDictionariesThunks } from '@app/store/reducers/dictionaries-slice';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import React from 'react';
import { useParams } from 'react-router';

import { CreateDictionaries } from './create-dictionaries';
export type PropsType = {
  edit: boolean;
  copy: boolean;
  newDictionary: boolean;
};
export const CreateDictionaryContainer: React.FC<PropsType> = ({
  edit = false,
  copy = false,
  newDictionary = false,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { dictionaries, dictionaryLoading, dictionary } = useAppSelector(
    (state) => state.dictionaris,
  );
  React.useEffect(() => {
    if (id) {
      dispatch(getDictionariesThunks.getDictionaryDataThunk(id));
    }
    dispatch(getDictionariesThunks.getAllDictionariesThunk());
  }, [dispatch, id]);

  return (
    <>
      {dictionaryLoading ? (
        <Loading />
      ) : (
        <CreateDictionaries
          dictionaries={dictionaries}
          dictionary={dictionary}
          edit={edit}
          copy={copy}
          newDictionary={newDictionary}
        />
      )}
    </>
  );
};
