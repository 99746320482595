import React, { FC, useState } from 'react';
import Icon from '@app/components/ui/icons';
import { TrashIcon } from '@icons';
import useTranslation from '@app/hooks/use-translation';
import { DashboardsListType, DashboardsSettingsType } from '@app/interfaces/dashboards.type';
type SelectDashboardsListPropsType = {
  dashboardList: { dashboard: DashboardsListType[] } | undefined;
  currentData: DashboardsSettingsType | undefined;
  deleteDashboard(id: string): void;
  dashboardChange: (id: string) => void;
  createNewDashboard: (data: { name: string; shared: boolean }) => void;
};
export const SelectDashboardsList: FC<SelectDashboardsListPropsType> = (props) => {
  const { dashboardList, currentData, deleteDashboard, dashboardChange, createNewDashboard } =
    props;
  const { t } = useTranslation('pages.dashboard');
  const [hoveredDashboard, setHoveredDashboard] = useState('');
  return (
    <div className="flex gap-[4px] flex-col items-start">
      {dashboardList?.dashboard.map((item) => {
        return (
          <div
            onMouseOver={() => {
              setHoveredDashboard(item.dashboard_id);
            }}
            onMouseOut={() => setHoveredDashboard('')}
            key={item.dashboard_id}
            className={`relative w-full hover:bg-b-light rounded-[10px] ${
              currentData?.dashboard_id === item.dashboard_id && '!bg-pastel_action'
            }`}
          >
            <div
              onClick={() => dashboardChange(item.dashboard_id)}
              className={`flex py-[8px] px-[20px] items-center cursor-pointer`}
            >
              <Icon size={14} className="mr-[9px] text-3color" name={'FolderIcon'} />
              <span
                className={`truncate max-w-[155px] text-[15px] text-1color leading-[110%] tracking-tight ${
                  currentData?.dashboard_id === item.dashboard_id && '!text-action'
                }`}
              >
                {item.name}
              </span>
            </div>
            <div
              onClick={() => {
                deleteDashboard(item.dashboard_id);
              }}
            >
              {hoveredDashboard === item.dashboard_id && (
                <TrashIcon
                  className={`transition absolute cursor-pointer right-[5px] top-[9px] text-[#E92E5D]`}
                  size={14}
                />
              )}
            </div>
          </div>
        );
      })}
      <div
        onClick={() =>
          dashboardList &&
          createNewDashboard({
            name: t('dashboard_create_name', { name: `${dashboardList?.dashboard.length + 1}` }),
            shared: false,
          })
        }
        className="flex h-[32.5px] py-[8px] px-[20px] items-center"
      >
        <Icon size={16} className="mr-[9px] text-3color" name={'FolderPlusIcon'} />
        <span className="cursor-pointer ml-[-2px] text-[15px] leading-[110%] tracking-tight text-action">
          {t('add_dashboard')}
        </span>
      </div>
    </div>
  );
};

export default SelectDashboardsList;
