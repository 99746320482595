import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers, { endpoint }) => {
    const user = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH_KEY') as string);

    if (user && endpoint !== 'refresh') {
      headers.set('Authorization', `Bearer${user.access_token}`);
    }
    return headers;
  },
});
