import React, { FC, useState } from 'react';
import { EasyModal, Input } from '@ui';
import useTranslation from '@app/hooks/use-translation';
type ChangeColumnSizeModalPropsType = {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
  changeColumnSize: React.Dispatch<React.SetStateAction<number | undefined>>;
};
export const ChangeColumnSizeModal: FC<ChangeColumnSizeModalPropsType> = (props) => {
  const { show, onClose, changeColumnSize } = props;
  const [currentSizeValue, changeCurrentSizeValue] = useState<number>();
  const { t } = useTranslation('pages.records');
  return (
    <EasyModal
      onSuccess={() => changeColumnSize(currentSizeValue)}
      show={show}
      onClose={onClose.bind(null, false)}
      variant={'medium484'}
    >
      <div className={'py-6 flex flex-col items-start gap-[10px]'}>
        <div className={'text-3color leading-[110%] tracking-tight text-[15px] '}>
          {t('cols_size_change')}
        </div>
        <Input
          defaultValue={currentSizeValue}
          onChange={(e) => changeCurrentSizeValue(Number(e.target.value))}
        />
      </div>
    </EasyModal>
  );
};
