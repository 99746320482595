module.exports = {
  settings_icon: 'Настроить',
  reload_icon: 'Обновить',
  delete_icon: 'Удалить',
  edit_icon: 'Переименовать',
  recognize_record: 'Распознать записи по новой модели',
  choose_model: 'Выбрать модель распознавания',
  ap_list_count: 'Количество аналитических проектов',
  add_dictionary_from_list: 'Добавить словарь из списка',
  to_dictionary: 'Перейти в словарь',
  choose_color: 'Выбрать цвет',
  recalculate_metric: 'Пересчитать метрики',
  dashboard: {
    dashboard_list: 'Открыть список рабочих столов',
    dashboard_intervals: 'Задать временной период для всех диаграмм',
  },
  records: {
    download_record: 'Скачать аудиозапись', //
    copy_link: 'Копировать ссылку',
    oscilogram_view: 'Выбрать формат отображения осциллограммы', //
    record_play: 'Воспроизвести', //
    playing_speed: 'Скорость воспроизведения', //
    download_transc: 'Скачать транскрипт', //
    metric_view: 'Скрыть/отобразить метрику',
    find_metric: 'Найти метрику в транскрипте',
  },
  reports: {
    to_charts: 'Перейти к диаграммам отчета',
    to_records: 'Перейти к записям отчета',
    download: 'Скачать отчет',
    settings_icon: 'Настройка отчета',
  },
  templates: {
    settings_template_icon: 'Настроить шаблон ',
    delete_template_icon: 'Удалить шаблон',
    copy_template_icon: 'Копировать шаблон',
    templates_list_count: 'Количество шаблонов в группе',
  },
  dictionaries: {
    words_count_icon: 'Количество слов/фраз в словаре',
    childs_count_icon: 'Количество словарей, входящих в данный словарь ',
    parents_count_icon: 'Количество словарей, в которых используется данный словарь',
    copy_icon: 'Копировать словарь и дополнить его',
    settings_icon: 'Редактировать словарь',
  },
  filter: {
    delete_icon: 'Удалить условие',
    plus_icon: 'Добавить новое значение',
    minus_icon: 'Удалить значение',
  },
};
