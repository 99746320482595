const presets = require('./presets');
const projectRecords = require('./projectRecords');
const notFound = require('./notFound');
const authorization = require('./authorization');
const reports = require('./reports');
const chartPage = require('./chartPage');
const settings = require('./settings');
const dashboard = require('./dashboard');
const records = require('./records');
const allPagesHintTitles = require('./allPagesHintTitles');
const metric = require('./metric');
const metricTemplates = require('./metricTemplates');
const dictionaries = require('./dictionaries');
const analytics = require('./analytics');
const wordsCloud = require('./wordsCloud');

module.exports = {
  reports,
  projectRecords,
  notFound,
  authorization,
  chartPage,
  settings,
  dashboard,
  records,
  allPagesHintTitles,
  metric,
  presets,
  metricTemplates,
  dictionaries,
  analytics,
  wordsCloud,
};
