import {
  createGroupAnalytics,
  createProjectAnalytics,
  deleteGroupAnalytics,
  deleteProjectAnalytics,
  editGroupAnalytics,
  editProjectAnalytics,
  editStatusProjectAnalytics,
  getAllGroupsAnalytics,
  getExtAsrModal,
  getGroupAnalytics,
  getListAsrModal,
  getProjectAnalytics,
  getStatusesGroups,
} from '@app/api/analytics.api';
import {
  AnalyticsReducerState,
  GroupsListObjectItem,
  GroupAnalyticsParams,
  ProjectAnalyticsParams,
  GroupsListObject,
  EditStatusProjectAnalyticsParams,
  ProjectItem,
  AllStatusProject,
  GroupsStatuses,
} from '@app/interfaces/analytics';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';
import serverRoutes from '@app/utils/server-routes';
import { getStatusProject } from '@app/api/analytics-api/analytics';
import { cloneDeep } from 'lodash';

const initialState: AnalyticsReducerState = {
  groups: [],
  group_projects: [],
  asrModalsExt: [],
  asrModalsList: [],
  project: null,
  allProjects: [],
  loading: false,
  error: null,
  projectsList: [],
};

export const getAnalyticsThunk = {
  getGroupsThunk: createAsyncThunk<GroupsListObject>(
    'AnalyticsSlice/getGroupsThunk',
    async (_, { rejectWithValue }) => {
      return await getAllGroupsAnalytics()
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  getGroupsToListLastProjects: createAsyncThunk(
    'AnalyticsSlice/getGroupsToListLastProjects',
    async (_, { rejectWithValue }) => {
      return await getGroupAnalytics()
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  getStatusesGroups: createAsyncThunk<GroupsStatuses>(
    'AnalyticsSlice/getStatusesGroups',
    async (_, { rejectWithValue }) => {
      return await getStatusesGroups()
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  getGroupThunk: createAsyncThunk<GroupsListObjectItem, GroupAnalyticsParams>(
    'AnalyticsSlice/getGroupThunk',
    async (data, { rejectWithValue }) => {
      return await getGroupAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  createGroupThunk: createAsyncThunk<GroupsListObjectItem, { name: string }>(
    'AnalyticsSlice/createGroupThunk',
    async (data, { rejectWithValue }) => {
      return await createGroupAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  editGroupThunk: createAsyncThunk<GroupsListObjectItem, GroupsListObjectItem>(
    'AnalyticsSlice/editGroupThunk',
    async (data, { rejectWithValue }) => {
      return await editGroupAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  deleteGroupThunk: createAsyncThunk<GroupsListObjectItem, GroupAnalyticsParams>(
    'AnalyticsSlice/deleteGroupThunk',
    async (data, { rejectWithValue }) => {
      return await deleteGroupAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  getProjectThunk: createAsyncThunk<ProjectItem, Pick<ProjectAnalyticsParams, 'id'>>(
    'AnalyticsSlice/getProjectThunk',
    async (data, { rejectWithValue }) => {
      return await getProjectAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  createProjectThunk: createAsyncThunk<ProjectAnalyticsParams, Omit<ProjectAnalyticsParams, 'id'>>(
    'AnalyticsSlice/createProjectThunk',
    async (data, { rejectWithValue }) => {
      return await createProjectAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  editProjectAnalytics: createAsyncThunk<ProjectAnalyticsParams, ProjectAnalyticsParams>(
    'AnalyticsSlice/editProjectAnalytics',
    async (data, { rejectWithValue }) => {
      return await editProjectAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  editStatusProjectAnalytics: createAsyncThunk(
    'AnalyticsSlice/editStatusProjectAnalytics',
    async (data: EditStatusProjectAnalyticsParams, { rejectWithValue }) => {
      return await editStatusProjectAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  deleteProjectThunk: createAsyncThunk<ProjectAnalyticsParams, Pick<ProjectAnalyticsParams, 'id'>>(
    'AnalyticsSlice/deleteProjectThunk',
    async (data, { rejectWithValue }) => {
      return await deleteProjectAnalytics(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  getAsrExt: createAsyncThunk('AnalyticsSlice/getAsrExt', async (_, { rejectWithValue }) => {
    return await getExtAsrModal()
      .then((response) => response.data)
      .catch((err) => {
        return rejectWithValue(err.response.status + '');
      });
  }),
  getAsrList: createAsyncThunk('AnalyticsSlice/getAsrList', async (_, { rejectWithValue }) => {
    return await getListAsrModal()
      .then((response) => response.data)
      .catch((err) => {
        return rejectWithValue(err.response.status + '');
      });
  }),
  getStatusProject: createAsyncThunk<{ project_status: AllStatusProject }, { id: string }>(
    'AnalyticsSlice/getStatusProject',
    async (data, { rejectWithValue }) => {
      return await getStatusProject(data)
        .then((response) => response.data)
        .catch((error) => rejectWithValue(error.response.status + ''));
    },
  ),
};

const analyticsSlice = createSlice({
  name: 'AnalyticsSlice',
  initialState,
  reducers: {
    setGroups(state, action) {
      state.groups = action.payload;
    },
    setGroupProjects(state, action) {
      state.group_projects = action.payload;
    },
    changeGroupProjects(
      state,
      action: PayloadAction<{ index?: number } & { project: ProjectItem }>,
    ) {
      if (action.payload.index !== undefined && action.payload.index >= 0) {
        const clonePayload = { ...action.payload };
        delete clonePayload.index;
        state.group_projects[action.payload.index] = clonePayload.project;
      }
    },
    changeProjectInGroup(state, action: PayloadAction<ProjectItem>) {
      state.groups = cloneDeep(state.groups).map((group) => {
        group.projects = group.projects.map((project) => {
          if (project.project_id === action.payload.project_id) {
            project = action.payload;
          }
          return project;
        });
        return group;
      });
    },
    changeGroup(state, action) {
      if (action.payload.index !== undefined && action.payload.index >= 0) {
        state.groups[action.payload.index] = action.payload.group;
      }
    },
    allProjectsResponse(state, { payload }: { payload: { projects: Array<ProjectItem> } }) {
      state.allProjects = payload.projects;
    },
    loadingResponse(state, { payload }) {
      state.loading = payload;
    },
    errorResponse(state, { payload }) {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalyticsThunk.getGroupsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAnalyticsThunk.getGroupsThunk.fulfilled, (state, action) => {
      state.groups = action.payload.projects_groups;
      state.loading = false;
    });
    builder.addCase(getAnalyticsThunk.getGroupsThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAnalyticsThunk.getGroupsToListLastProjects.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAnalyticsThunk.getGroupsToListLastProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAnalyticsThunk.getGroupsToListLastProjects.fulfilled, (state, action) => {
      state.projectsList = action.payload.projects;
      state.loading = false;
    });
    builder.addCase(getAnalyticsThunk.getStatusesGroups.fulfilled, (state, { payload }) => {
      // state.groups = state.groups.map((group) => {
      //   group.projects.map((project) => {
      //       const currentStatus = payload.projects_groups.filter((payload_group) => payload_group.project_group_id === group.project_group_id)

      //       return project
      //     })
      //     return group
      //   })
      // })
      state.groups = state.groups.map((group) => {
        group.projects.map((project) => {
          const currentStatus = payload.projects_groups
            .find((payload_group) => payload_group.project_group_id === group.project_group_id)
            ?.projects.find(
              (payload_project) => payload_project.project_id === project.project_id,
            )?.status;

          if (currentStatus) project.status = currentStatus;

          return project;
        });
        return group;
      });
    });
    builder.addCase(getAnalyticsThunk.getProjectThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAnalyticsThunk.getProjectThunk.fulfilled, (state, action) => {
      state.project = action.payload;
      state.loading = false;
    });
    builder.addCase(getAnalyticsThunk.getAsrExt.fulfilled, (state, action) => {
      state.asrModalsExt = action.payload;
      // state.loading = false;
    });
    builder.addCase(getAnalyticsThunk.getAsrList.fulfilled, (state, action) => {
      state.asrModalsList = action.payload;
      // state.loading = false;
    });
    // builder.addCase(getAnalyticsThunk.editStatusProjectAnalytics.fulfilled, (state, action) => {
    //   console.log(action.payload);
    //   // state.loading = false;
    // });
  },
});

export const {
  setGroups,
  setGroupProjects,
  changeGroup,
  changeGroupProjects,
  changeProjectInGroup,
  allProjectsResponse,
  loadingResponse,
  errorResponse,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

export const projectsRequest = () =>
  httpRequest({
    url: serverRoutes.projects,
    method: 'GET',
    onSuccess: allProjectsResponse.type,
    onError: errorResponse.type,
    onLoading: loadingResponse.type,
  });
