import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { DashboardChartSettingsForGraphData } from '@app/interfaces/dashboards.type';

import { ReportChart } from '@app/interfaces/report.type';

import { baseQuery } from '../baseQuery';

/* Данные для графика */
export const chartsGraphDataApi = createApi({
  reducerPath: 'chartsGraphDataApi',
  tagTypes: ['chartsGraphData'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getGraphData: build.query<
      ReportChart,
      { id: string; settings: DashboardChartSettingsForGraphData }
    >({
      query: (body) => ({
        url: serverRoutes.chartData,
        body: { id: body.id, ...body.settings },
        method: 'POST',
      }),
      // transformResponse: ({ dashboard_graph_list }) => {
      //   return dashboard_graph_list;
      // },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ dashboard_graph_id }) => ({
      //           type: 'chartsGraphData' as const,
      //           dashboard_graph_id,
      //         })),
      //         'chartsGraphData',
      //       ]
      //     : ['chartsGraphData'],
    }),
  }),
});

export const { useLazyGetGraphDataQuery, useGetGraphDataQuery } = chartsGraphDataApi;
