import { TooltipWrapper } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import React from 'react';
import { v4 } from 'uuid';

type PropsType = {
  name?: string;
  active?: boolean;
  count?: number;
};
export const AccordionTitle: React.FC<PropsType> = ({ name, active, count }) => {
  const { t } = useTranslation('pages.allPagesHintTitles');

  return (
    <>
      <div className="font-[700] text-[16px] text-2color leading-[110%] tracking-tight truncate max-w-[500px]">
        {name}
      </div>
      {!active && (
        <div className="flex justify-center items-center text-xs w-[17px] h-[17px] rounded-full ml-[13px] text-[11px] bg-defaultBgBtn !text-white">
          <TooltipWrapper
            content={t('templates.templates_list_count')}
            id={t('templates.templates_list_count') + v4()}
            className="text-white"
          >
            {count}
          </TooltipWrapper>
        </div>
      )}
    </>
  );
};
