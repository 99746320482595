import { deleteDictionary, getAllDictionaries, getDictionaryData } from '@app/api/dictionaries.api';
import {
  DictionariesType,
  DictionaryType,
} from '@app/interfaces/slices-types/dictionaries-slice.type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  initDictionaries: DictionariesType[];
  dictionaries: DictionariesType[];
  loading: boolean;
  dictionaryLoading: boolean;
  dictionary: DictionaryType | null;
};

const initialState: initialStateType = {
  initDictionaries: [],
  dictionaries: [],
  loading: false,
  dictionaryLoading: false,
  dictionary: null,
};

export const getDictionariesThunks = {
  getAllDictionariesThunk: createAsyncThunk(
    'Dictionaries/getAllDictionariesThunk',
    async (_, { rejectWithValue }) => {
      try {
        const response = await getAllDictionaries();
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  getDictionaryDataThunk: createAsyncThunk(
    'Dictionaries/getDictionaryDataThunk',
    async (id: string, { rejectWithValue }) => {
      try {
        const response = await getDictionaryData(id);
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  deleteDictionaryThunk: createAsyncThunk(
    'Dictionaries/deleteDictionaryThunk',
    async (id: string, { rejectWithValue }) => {
      try {
        await deleteDictionary(id);
        return id;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
};

const Dictionaries = createSlice({
  name: 'Dictionaries',
  initialState,
  reducers: {
    setFilterDictionary(state, action) {
      if (action.payload !== undefined) {
        if (action.payload[0] === 'nameCol') {
          if (action.payload[1] === -1) {
            state.dictionaries = state.dictionaries.sort((a, b) => -a.name.localeCompare(b.name));
          } else if (action.payload[1] === 1) {
            state.dictionaries = state.dictionaries.sort((a, b) => a.name.localeCompare(b.name));
          }
        } else if (action.payload[0] === 'date') {
          if (action.payload[1] === 1) {
            state.dictionaries = state.dictionaries.sort(
              (a, b) => Date.parse(a.dt_update) - Date.parse(b.dt_update),
            );
          } else if (action.payload[1] === -1) {
            state.dictionaries = state.dictionaries.sort(
              (a, b) => Date.parse(b.dt_update) - Date.parse(a.dt_update),
            );
          }
        }
      } else {
        state.dictionaries = state.initDictionaries;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDictionariesThunks.getAllDictionariesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDictionariesThunks.getAllDictionariesThunk.fulfilled, (state, action) => {
      state.initDictionaries = action.payload.dictionaries;
      state.dictionaries = action.payload.dictionaries;
      state.loading = false;
    });
    builder.addCase(getDictionariesThunks.getDictionaryDataThunk.pending, (state) => {
      state.dictionaryLoading = true;
    });
    builder.addCase(getDictionariesThunks.getDictionaryDataThunk.fulfilled, (state, action) => {
      state.dictionary = action.payload;
      state.dictionaryLoading = false;
    });
    builder.addCase(getDictionariesThunks.deleteDictionaryThunk.fulfilled, (state, action) => {
      state.dictionaries = state.dictionaries.filter((e) => e.dictionary_id !== action.payload);
      state.initDictionaries = state.dictionaries;
    });
  },
});
export const { setFilterDictionary } = Dictionaries.actions;

export default Dictionaries.reducer;
