import UniversalFilterTable from '@app/components/UniversalFilter/UniversalFilterTable';

import {
  FilterAliasItem,
  FilterItem,
  FilterItemNotOnlyArray,
  FilterNotOnlyArray,
  FilterRange,
  FilterRangeParametersItemForLType,
  FilterRangeParametersItemForRType,
} from '@app/interfaces/filter';

import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@app/store/store';

import arrowDown from '@app/imgs/UserInterface/arrowDown.svg';

import { getStringDate, getStringTime } from '@app/utils/helpers';
import { METAdatetime, METAdatetimeCalendar, METAdatetimeCalendarId } from '@app/utils/constants';

import { UniversalFilterTableRef, ViewFilterProps } from '@app/interfaces/universalFilter';

import {
  getFilterSlice,
  setCloneFilter,
  setCloneRange,
  setFilter,
  setFilterLoading,
  setRange,
} from '@app/store/reducers/filterSlice';

import cloneFilterWithValues from '@app/hooks/use-universal-filter-values/cloneFilterWithValues';

import cn from 'classnames';

import getArrayFilterValues from '@app/hooks/use-universal-filter-values/getArrayFilterValues';

import useTranslation from '@app/hooks/use-translation';

import { useIsOverflow } from '@app/hooks/use-overflow';

import { v4 } from 'uuid';

import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';

import useGetUniversalFilter from '@app/hooks/use-universal-filter-values/use-get-universal-filter';

import { getPresetsThunks } from '@app/store/reducers/presets-slice';

import { Button, EasyModal, Modal } from '../ui';
import AccordionItemUI from '../ui/Accordion/AccordionItem';
import AccordionUI from '../ui/Accordion/Aссordion';
import { Edit3Icon } from '../ui/icons/icons-list';

const UniversalFilterView: FC<ViewFilterProps> = (props) => {
  const {
    title,
    footer,
    saveFilter,
    titleModal,
    metricMode = false,
    disabledChangeButton = false,
    getAliases: getAliasesFromProps,
    onCloseFilterTable,
    modalMode = false,
  } = props;
  const dispatch = useAppDispatch();
  // const filterViewRef = useRef<HTMLDivElement>(null);
  const getFilter = useGetUniversalFilter();
  const { groups } = useAppSelector((state) => state.presets);
  const changeVisibilityStaticFiltersBtnRef = useRef<HTMLImageElement>(null);
  const [filterViewRef, setFilterViewRef] = useState<HTMLDivElement | null>(null);
  const [addFromTemplateModalState, setAddFromTemplateModalState] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [heightFilter, setHeightFilter] = useState<'h-[34px]' | 'h-max'>('h-[34px]');
  const [marginValueInFilter, setMarginValueInFilter] = useState<'mt-[2px]' | ''>('');
  const UniversalFilterTableRef = useRef<UniversalFilterTableRef>(null);
  const { filter, range, cloneRange, cloneFilter, aliases, loading } = useAppSelector(
    (state) => state.filter,
  );
  const { metricList } = useAppSelector((state) => state.metric);
  const { t } = useTranslation('components.filter');

  const getAliases = useCallback(() => {
    dispatch(setFilterLoading(true));
    if (getAliasesFromProps) {
      return getAliasesFromProps().finally(() => dispatch(setFilterLoading(false)));
    } else {
      return dispatch(getFilterSlice.getAliasesThunk())
        .unwrap()
        .finally(() => dispatch(setFilterLoading(false)));
    }
  }, [dispatch, getAliasesFromProps]);

  const changeFilterModalState = () => {
    setShowFilter(true);
    getAliases();
    UniversalFilterTableRef.current?.getCloneFilter();
    UniversalFilterTableRef.current?.setCloneRangeToFilter();
    setTimeout(() => UniversalFilterTableRef.current?.cloneFilterInit());
  };

  const getAliasesNameById = (id: string) => {
    if (metricMode) {
      return metricList.find((metric) => metric.metric_id === id)?.name || 'N/A';
    } else {
      return aliases.find((alias) => alias.alias_id === id)?.name || 'N/A';
    }
  };

  const changeStateVisibleMoreFilters = () => {
    filterViewRef?.classList.toggle('truncate');
    setHeightFilter(
      filterViewRef?.classList.contains('truncate') || !filterViewRef ? 'h-[34px]' : 'h-max',
    );
    setMarginValueInFilter(!filterViewRef?.classList.contains('truncate') ? 'mt-[2px]' : '');
    changeVisibilityStaticFiltersBtnRef.current?.classList.toggle('rotate-180');
  };

  const getFilterItemValue = (item: FilterItemNotOnlyArray) => {
    if (item.condition === 'exists') return '';
    return Array.isArray(item.value) ? item.value.filter((item) => item).length : item.value;
  };

  const onApplyFilter = () => {
    setTimeout(() => {
      saveFilter(
        {
          filter: getArrayFilterValues(cloneFilterWithValues(cloneFilter)),
          range: cloneRange,
        },
        {
          filter,
          range,
        },
      )?.then(() => {
        setShowFilter(false);
        dispatch(
          setFilter(
            cloneFilterWithValues(cloneFilter).map((item) => {
              if (!Array.isArray(item.value)) {
                item.value = [item.value];
              }
              return item;
            }) as FilterItem[],
          ),
        );
        dispatch(setRange(cloneRange));
      });
    });
  };

  const onCloseFilter = () => {
    dispatch(setCloneFilter([]));
    dispatch(
      setCloneRange({
        type: 'n',
        parameters: [],
      }),
    );
    onCloseFilterTable?.();
  };

  const isDateAlias = (id: string) => {
    if (metricMode) {
      const metric = metricList.find((metric) => metric.metric_id === id);
      if (metric?.result_value_type === 'datetime') return 'date';
    } else {
      const alias = aliases.find((alias: FilterAliasItem) => alias.alias_id === id);
      if (alias?.type_value === 'datetime') return 'date';
    }
  };
  const changeFilterModalStateOnDoubleClick = (e) => {
    if (e.target.id === 'filter') {
      !disabledChangeButton && changeFilterModalState();
    }
  };
  useIsOverflow(filterViewRef as HTMLElement, (isOverflow) => setOverflow(isOverflow));

  const [activeAccordionItem, setActiveAccordionItem] = React.useState<string | undefined>(
    undefined,
  );
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    if (event.id === activeAccordionItem) {
      if (event.id === '') {
        setActiveAccordionItem(undefined);
      } else {
        setActiveAccordionItem('');
      }
    } else {
      setActiveAccordionItem(event.id);
    }
  };
  function addFilterFromTemplateHandler(filter: FilterNotOnlyArray & FilterRange) {
    getFilter({ ...filter, filter: getArrayFilterValues(filter.filter) });
    setAddFromTemplateModalState(false);
  }
  useEffect(() => {
    dispatch(getPresetsThunks.getPresetsGroupsThunk());
  }, [dispatch]);

  return (
    <div className="rounded-xl">
      <div
        className={
          !modalMode
            ? cn(
                title ? 'pt-[20px]' : 'pt-[21px]',
                'pb-[20px] pr-[25px] pl-[22px] bg-white border-l-[2px] border-l-speech_analitics rounded-xl',
              )
            : ''
        }
      >
        {!modalMode && (
          <>
            {title && <div className="text-[18px] leading-[21px] pb-[16px]">{title}</div>}
            {range.type !== 'n' && (
              <div className="pl-[4.47px] pb-[19px]">
                <span className="inline-block pr-[6px] text-[15px] leading-[18px]">
                  {range.type === 'l' && heightFilter === 'h-[34px]' ? (
                    <>
                      <span>{t('date_time')}: </span>
                      {t('last')}
                      <span className="inline-flex items-center justify-center h-[23px] bg-[#E3E6ED] text-2color rounded mx-[8px] px-2 p-0.5">
                        {(range.parameters[0] as FilterRangeParametersItemForLType).num}
                      </span>
                      {t('days')}
                    </>
                  ) : (
                    METAdatetime.find(
                      (item) => item.id.includes(range.type) || item.id === range.type,
                    )?.name
                  )}
                </span>
                {METAdatetimeCalendarId.includes(range.type) && (
                  <span className="inline-block text-[15px] leading-[18px] bg-bg-4 rounded-[4px] px-[8px] pt-[2px] pb-[3px]">
                    {METAdatetimeCalendar.find((item) => item.id === range.type)?.name}
                  </span>
                )}
                {range.parameters.map((param, index) => {
                  if (range.type === 'r') {
                    return (
                      <span key={`date-${index + v4()}`} className="text-2color">
                        {index === 1 && (
                          <span className="inline-block text-[15px] leading-[18px] w-[7px] h-[18px] mx-[12px]">
                            -
                          </span>
                        )}
                        <span className="inline-block text-[15px] leading-[18px] bg-bg-4 rounded-[4px] px-[8px] pt-[2px] pb-[3px] mr-[8px]">
                          {index === 0 ? 'от ' : 'до '}
                          {getStringDate((param as FilterRangeParametersItemForRType).value, false)}
                        </span>
                        <span className="inline-block text-[15px] leading-[18px] bg-bg-4 rounded-[4px] px-[8px] pt-[2px] pb-[3px]">
                          {getStringTime((param as FilterRangeParametersItemForRType).value)}
                        </span>
                      </span>
                    );
                  }
                  if (range.type === 'l' && heightFilter === 'h-max') {
                    return (
                      <span
                        key={`date-${index + v4()}`}
                        className="inline-flex items-center justify-center h-[23px] text-[15px] bg-[#E3E6ED] text-2color rounded px-2 p-0.5"
                      >
                        {(param as FilterRangeParametersItemForLType).num}
                      </span>
                    );
                  }
                  return <Fragment key={index + v4()}></Fragment>;
                })}
              </div>
            )}
          </>
        )}
        <div className={`flex ${modalMode && 'flex-col'}`}>
          <div
            className={cn(
              `relative grow ${!modalMode && 'mr-6'} ${
                modalMode ? 'pl-[10px]' : 'pl-[19.34px]'
              } pr-[54.66px] py-[4.5px] bg-white border border-b_dark rounded-defaultR`,
              heightFilter,
            )}
            style={{
              width: `${!modalMode ? 'calc(100% - 155px)' : '384px'}`,
            }}
          >
            <div
              onDoubleClick={(e) => changeFilterModalStateOnDoubleClick(e)}
              id="filter"
              ref={(ref) => setFilterViewRef(ref)}
              className={`truncate inline-block w-full px-2`}
            >
              {cloneFilterWithValues(filter).map((item, index) => {
                return (
                  <Fragment key={`${item.id}-${index + v4()}`}>
                    <span className="min-w-max pr-[6px] text-[15px] leading-[18px]">
                      {getAliasesNameById(item.id)}
                    </span>
                    <span className="inline-flex justify-center items-center w-[22px] h-[22px] text-2color text-[15px] leading-[16px] mr-[6px] pb-[2px] border border-b_dark rounded-full">
                      {item.condition === 'exists' ? 'e' : item.condition}
                    </span>
                    {getFilterItemValue(item) && (
                      <span className="pr-[16px] break-all">
                        {Array.isArray(item.value) ? (
                          item.value.map((valueItem, index) => {
                            return (
                              <Fragment key={`filter-preview-value${valueItem}-${index + v4()}`}>
                                {/* // <span key={`filter-preview-value${valueItem}-${index}`}> */}
                                {index === 0 && <span className="inline-block pr-1.5">(</span>}
                                <span
                                  className={cn(
                                    'bg-defaultBg h-[23px] px-[8px] pt-[2px] pb-[3px] rounded-[4px]',
                                    marginValueInFilter,
                                  )}
                                >
                                  {valueItem}
                                </span>
                                {index >= 0 && index < (item.value as []).length - 1 && (
                                  <span className="px-[4px] text-[15px] leading-[18px] mx-1 border rounded-full text-2color">
                                    {t('or')}
                                  </span>
                                )}
                                {index === (item.value as []).length - 1 && (
                                  <span className="inline-block pl-1.5">)</span>
                                )}
                                {/* // </span> */}
                              </Fragment>
                            );
                          })
                        ) : (
                          <span
                            key={index + v4()}
                            className="bg-defaultBg text-[15px] leading-[18px] text-2color px-[8px] pt-[2px] pb-[3px] rounded-[4px]"
                          >
                            {isDateAlias(item.id) ? (
                              <>
                                {getStringDate(item.value as string, false) + ' '}
                                {getStringTime(item.value as string)}
                              </>
                            ) : (
                              item.value
                            )}
                          </span>
                        )}
                      </span>
                    )}
                  </Fragment>
                );
              })}
              {!filter.length && (
                <span className="inline-block text-[15px] leading-[18px]">
                  {t('search_conditions')}
                </span>
              )}
            </div>
            {!modalMode && (overflow || heightFilter === 'h-max') && (
              <img
                ref={changeVisibilityStaticFiltersBtnRef}
                className="absolute right-[8px] bottom-[6px] cursor-pointer"
                src={arrowDown}
                alt=""
                onClick={() => changeStateVisibleMoreFilters()}
              />
            )}
          </div>
          {modalMode ? (
            <div
              onClick={() => changeFilterModalState()}
              className={
                '!text-action cursor-pointer flex items-center gap-[3px] self-end mt-[5px]'
              }
            >
              <Edit3Icon className={'text-action'} size={13} />
              <span className={'text-[13p] font-[500] tracking-tight leading-[110%]'}>
                {t('change')}
              </span>
            </div>
          ) : (
            <Button
              label={t('change')}
              fill="outlined"
              icon="Edit2Icon"
              className="self-start"
              disabled={disabledChangeButton}
              onClick={() => changeFilterModalState()}
            />
          )}
        </div>
        {footer && <div className="pt-[25px]">{footer}</div>}
      </div>
      <Modal
        addFromTemplate={!metricMode}
        title={titleModal || t('edit_filter')}
        value={showFilter}
        setValue={setShowFilter}
        onApply={() => onApplyFilter()}
        onClose={() => onCloseFilter()}
        id={'edit-universal-filter-modal'}
        loading={loading}
        addFromTemplateClick={() => setAddFromTemplateModalState(true)}
      >
        <UniversalFilterTable
          ref={UniversalFilterTableRef}
          loading={loading}
          metricMode={metricMode}
          modalMode={modalMode}
        />
      </Modal>
      <EasyModal
        variant="mediumW650"
        withoutFooter
        show={addFromTemplateModalState}
        onClose={() => setAddFromTemplateModalState(false)}
        label={
          <div className="text-0color text-[24px] font-bold mb-[25px]">
            {t('add_preset_from_template_title')}
          </div>
        }
      >
        <div className="w-full">
          <AccordionUI>
            {groups.map((item) => {
              return (
                <AccordionItemUI
                  // loading={isPending}
                  key={item.preset_group_id}
                  id={item.preset_group_id}
                  title={item.name}
                  clickBtnChild={clickAccordionHeaderBtn}
                  active={activeAccordionItem === item.preset_group_id}
                >
                  {activeAccordionItem === item.preset_group_id && (
                    <ul className="flex w-full rounded-[12px] flex-col border-[1px] border-basic_app_bg">
                      {item.presets.map((item) => {
                        return (
                          <li
                            className="w-full items-center flex justify-between border-b-[1px] border-basic_app_bg text-1color p-4 text-[16px]"
                            key={item.preset_id}
                          >
                            <div
                              onClick={() => addFilterFromTemplateHandler(item.rules)}
                              className="cursor-pointer hover:text-4color"
                            >
                              {item.name}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </AccordionItemUI>
              );
            })}
          </AccordionUI>
        </div>
      </EasyModal>
    </div>
  );
};

export default UniversalFilterView;

UniversalFilterView.displayName = 'UniversalFilterView';
