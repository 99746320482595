import React, { FC, ReactNode } from 'react';
import Icon from '@app/components/ui/icons';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

export type NavigationTabsItem = {
  link?: string;
  icon?: IconNameType;
  label: string;
  index: string | number;
  iconSize?: number;
};

type NavigationTabsProps = {
  items: Array<NavigationTabsItem>;
  onChange?: (NavigationTabsItem) => void;
};

const NavigationTabs: FC<NavigationTabsProps> = (props) => {
  const { items, onChange } = props;
  const tabItemClassName = (isActive: boolean) =>
    cn(
      'inline-flex',
      'gap-[10px] xl:gap-[13px]',
      'select-none',
      'hover:text-1color',
      'items-center',
      'pl-[18px]',
      'pr-[22px]',
      'pt-[12px]',
      'pb-[10px]',
      'text-base',
      'w-full',
      'active:text-primary',
      'truncate',
      isActive ? 'bg-gradient-to-b from-white to-gray text-1color rounded-t-[10px]' : 'text-2color',
    );

  function handlerPushNewPage({ tabItem }: { tabItem: NavigationTabsItem }) {
    onChange?.(tabItem);
  }

  const WrapTabToNavLink = ({
    tabItem,
    children,
  }: {
    tabItem: NavigationTabsItem;
    children: ReactNode;
  }) => {
    if (tabItem?.link) {
      return (
        <NavLink
          to={tabItem.link || '/'}
          className={({ isActive }) => cn(tabItemClassName(isActive))}
          end
        >
          {children}
        </NavLink>
      );
    } else {
      return (
        <div
          className={cn(tabItemClassName(false), 'cursor-pointer')}
          onClick={handlerPushNewPage.bind(null, { tabItem })}
        >
          {children}
        </div>
      );
    }
  };

  const renderNavigationItem = items.map((tabItem) => {
    return (
      <li key={tabItem.index}>
        <WrapTabToNavLink tabItem={tabItem}>
          {tabItem.icon && (
            <Icon className={`text-1color`} size={tabItem.iconSize || 18} name={tabItem.icon} />
          )}
          <span className={cn(items.length > 3 && 'hidden xl:block')}>{tabItem.label}</span>
        </WrapTabToNavLink>
      </li>
    );
  });

  return (
    <div className="border-b border-b_medium">
      <ul className="w-full inline-flex items-center">{renderNavigationItem}</ul>
    </div>
  );
};

export default NavigationTabs;
