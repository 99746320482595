import {
  MetricListArrType,
  MetricObjType,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import apiInstance from '@app/api/common.api';
import { AxiosResponse } from 'axios';

export const getAllTypesOfMetric = async () => {
  return await apiInstance.get('/api/front/metric_types/bulk');
};
export const getMetricList = async (
  project_id: string,
): Promise<AxiosResponse<MetricListArrType[]>> => {
  return await apiInstance.get(`/api/front/project/metric/bulk?project_id=${project_id}`);
};
export const deleteMetric = async (params: { metric_id: string; project_id: string }) => {
  return await apiInstance.delete(`/api/front/project/metric`, { params });
};
export const getSingleMetricInfo = async (project_id: string, metric_id: string) => {
  return await apiInstance.get(
    `/api/front/project/metric?project_id=${project_id}&metric_id=${metric_id}`,
  );
};
export const createMetric = async (id: string, obj: MetricObjType) => {
  return await apiInstance.post(`/api/front/project/metric?project_id=${id}`, obj);
};
export const editMetric = async (id: string, metric_id: string, obj: MetricObjType) => {
  return await apiInstance.put(
    `/api/front/project/metric?project_id=${id}&metric_id=${metric_id}`,
    obj,
  );
};
export const getAllChannels = async () => {
  return await apiInstance.get('/api/front/settings/channel/bulk');
};
export const recalculateMetrics = async (id: string) => {
  return await apiInstance.put(`/api/front/project/recalculate?id=${id}`);
};
