import React, { FC, useEffect, useState } from 'react';
import { ReportChart, ReportChartResponse, ReportTimeRange } from '@app/interfaces/report.type';
import connector, { PropsFromRedux } from '@app/utils/store';
import ChartComponent from '@app/components/chart/chart-component';
import GridLayout from 'react-grid-layout';
import useResizeObserver from '@app/hooks/use-resize-observer';
import ChartDatePickerModalBlock from '@app/pages/reports/blocks/chart-date-picker-modal.block';
import { ChartEditModal } from '@app/pages/dashboards/blocks/chart-edit-modal';
import ModalSettingsChartBlock from '@app/pages/reports/blocks/chart-modal/modal-settings-chart.block';
import { EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useLazyGetGraphDataQuery } from '@app/store/reducers/graph-data.api';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import {
  useDeleteGraphOnDashboardMutation,
  useEditDashboardGraphLayoutMutation,
} from '@app/store/reducers/dashboard-charts.api';
import { FilterItem } from '@app/interfaces/filter';
import { ChartsLayoutType, EditGraphLayoutType } from '@app/interfaces/dashboards.type';
type GridLayoutChartsContainerPropsType = {
  dashboard?: boolean;
  commonRange?: ReportTimeRange | null;
  onEditIntervalWithoutSave?: (data: { data: ReportChart; id: string }) => void;
  //todo
  charts?: any[];
  viewOnly?: boolean;
  filterValue?: FilterItem[];
  currentDashboardOrReportId?: string;
  isDraggable?: boolean;
};
const GridLayoutChartsContainer: FC<GridLayoutChartsContainerPropsType & PropsFromRedux> = (
  props,
) => {
  const { t } = useTranslation('pages.chartPage');
  const {
    dashboard = false,
    viewOnly = false,
    onEditIntervalWithoutSave,
    charts,
    commonRange,
    chartRemoveRequest,
    filterValue,
    currentDashboardOrReportId,
    isDraggable,
  } = props;
  //api
  const [getGraphData] = useLazyGetGraphDataQuery();
  const [deleteChartFromDashboard] = useDeleteGraphOnDashboardMutation();
  const [editDashboardLayoutSettings] = useEditDashboardGraphLayoutMutation();
  //api
  // state
  const { dateToString, intervalTimeBuilder } = useChartDateIntervals();
  const [openCustomDateModal, setOpenedCustomDateModal] = useState<boolean>(false);
  const [openModal, setMobile] = useState<boolean>(false);
  const [isOpenRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [data, setData] = useState<ReportChartResponse['data']>();
  // state
  // functions
  function changeCustomDateInterval({ start, end }: { start: string | null; end: string | null }) {
    const range = intervalTimeBuilder(start, end) as unknown as ReportTimeRange;
    if (!data) return;
    if (dashboard) {
      getGraphData({
        id: data.graph_id,
        settings: {
          width: data.width,
          range,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        onEditIntervalWithoutSave &&
          data.dashboard_graph_id &&
          onEditIntervalWithoutSave({ data: chartData, id: data.dashboard_graph_id });
      });
    } else {
      getGraphData({
        id: data.graph_id,
        settings: {
          width: data.width,
          range,
          filter: filterValue,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        onEditIntervalWithoutSave &&
          data.graph_id &&
          onEditIntervalWithoutSave({ data: chartData, id: data.graph_id });
      });
    }
    setOpenedCustomDateModal(false);
  }
  function handlerRemoveModal() {
    if (!data) return;
    if (data.dashboard_graph_id) {
      deleteChartFromDashboard(data.dashboard_graph_id);
    } else chartRemoveRequest({ id: data?.graph_id });
  }
  // functions
  // grid layout
  const [gridWidth, changeGridWidth] = useState<number>(1055);
  const [layoutWidthRef, changeLayoutWidthRef] = useState<HTMLDivElement>();
  useResizeObserver(layoutWidthRef, changeGridWidth);
  const [layoutSettings, changeLayoutSettings] = useState<ChartsLayoutType[]>([]);
  useEffect(() => {
    if (!charts) return;
    changeLayoutSettings(charts?.map((chart) => chart.layout));
  }, [charts]);
  const onLayoutChange = (data) => {
    const layout: EditGraphLayoutType[] = data.map((item) => ({
      dashboard_graph_id: item.i,
      layout: { x: item.x, y: item.y, w: item.w, h: item.h },
    }));
    layoutSettings.length &&
      isDraggable &&
      currentDashboardOrReportId &&
      editDashboardLayoutSettings({
        body: layout,
        params: { dashboard_id: currentDashboardOrReportId },
      });
  };
  // grid layout
  return (
    <>
      <div ref={(ref) => ref && changeLayoutWidthRef(ref)} className="w-full">
        <GridLayout
          className="layout relative w-fit"
          draggableCancel={'.noDrag'}
          layout={layoutSettings}
          cols={24}
          rowHeight={360}
          width={gridWidth || 1000}
          onLayoutChange={onLayoutChange}
          isDraggable={isDraggable}
          isResizable={isDraggable}
        >
          {charts?.map((graph, index) => (
            <div key={graph.layout.i || `key_${index + 1}`} className={'w-fit'}>
              <ChartComponent
                viewOnly={viewOnly}
                onEditIntervalWithoutSave={onEditIntervalWithoutSave}
                commonRange={commonRange}
                dashboard={dashboard}
                data={dashboard ? graph : graph?.build}
                setData={setData}
                setOpenedCustomDateModal={setOpenedCustomDateModal}
                setMobile={setMobile}
                setOpenRemoveModal={setOpenRemoveModal}
                filterValue={filterValue}
              />
            </div>
          ))}
        </GridLayout>
      </div>
      <ChartDatePickerModalBlock
        show={openCustomDateModal}
        onSuccess={changeCustomDateInterval}
        onClose={setOpenedCustomDateModal.bind(null, false)}
      />
      {dashboard
        ? openModal && (
            <ChartEditModal
              width={data?.width}
              range={dateToString(data?.range)}
              editMode={true}
              dashboard_graph_id={data?.dashboard_graph_id}
              show={openModal}
              live_report_graph_id={data?.graph_id || ''}
              onClose={setMobile.bind(null, false)}
              getChartName={() => data?.name}
            />
          )
        : openModal && (
            <ModalSettingsChartBlock
              isOpen={openModal}
              chartId={data?.graph_id}
              onClose={setMobile.bind(null, false)}
              type={'barchart'}
              onRemove={setOpenRemoveModal}
            />
          )}
      <EasyModal
        show={isOpenRemoveModal}
        variant="removal"
        label={t('modal_remove_chart_title')}
        onClose={setOpenRemoveModal.bind(null, false)}
        onRemove={handlerRemoveModal}
        withoutFooter
      >
        <div className="flex items-center justify-center">{t('button.remove_chart')}</div>
      </EasyModal>
    </>
  );
};

export default connector(GridLayoutChartsContainer);
