import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { NavigationTabs } from '@app/components/ui';
import { navigationData } from '@app/pages/analytics/blocks/navigation.data';
import { MetricSettings } from '@app/pages/analytics/metric/analytics-metric-tab/analytics-metric-tab';
import useTranslation from '@app/hooks/use-translation';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';

const MetricPage = () => {
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const { t } = useTranslation('components.navigateTabs');
  const data: NavigationTabsItem[] = navigationData(project?.project_id).map((item) => ({
    ...item,
    label: t(item.label),
  }));
  return (
    <div>
      <NavigationTabs items={data} />
      <MetricSettings project={project} />
    </div>
  );
};

export default MetricPage;
