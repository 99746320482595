import React, { FC, Fragment } from 'react';
import { Select } from '@ui';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { OptionItem } from '@app/components/ui/select/select.type';

import Icon from '@app/components/ui/icons';

import useTranslation from '@app/hooks/use-translation';

import { KeysForFilteringType } from '@app/components/ui/easy-filter/types';

import { conditions } from '../data';

import NestedField from './nested-field';
type FilterFieldType = {
  keysForFiltering: KeysForFilteringType[];
};
const Field: FC<FilterFieldType> = (props) => {
  const { keysForFiltering } = props;
  const { t } = useTranslation('components.filter');
  const { control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filter',
  });
  function getParamsSelectOptions(): OptionItem[] {
    return keysForFiltering.map((item) => ({ title: item.title, value: item.id }));
  }
  // function getConditionsSelectOptions(): OptionItem[] {}
  function getConditionsSelectOptions(value?: string) {
    const alias = keysForFiltering.find((alias) => alias.id === value);
    if (value && alias) {
      return conditions
        .filter((condition) => condition.type_meta.includes(alias.type_meta))
        .filter((condition) => alias.type_value && condition.values.includes(alias.type_value))
        .map((item) => ({ title: item.name, value: item.id }));
    } else return conditions.map((item) => ({ title: item.name, value: item.id }));
  }
  function onChangeParams(value, index) {
    setValue(`filter[${index}].id`, value);
    setValue(`filter[${index}].condition`, getConditionsSelectOptions(value)[0].value);
  }

  return (
    <ul className={'flex flex-col px-[32px]'}>
      {fields.map((item, index) => (
        <Fragment key={item.id}>
          <div
            className={
              'h-[17px] w-full flex items-center justify-center text-[14px] leading-[17px] font-[400] text-4color'
            }
          >
            {t('and')}
          </div>
          <li className="flex items-center py-4 relative bg-speech_analitics_filter rounded-[12px] border-solid border-l-[2px] border-speech_analitics px-[24px]">
            <div className={'flex items-start justify-center gap-[16px]'}>
              <div className={'w-[360px]'}>
                <Select
                  defaultValue={watch(`filter[${index}].id`)}
                  onChange={(value) => onChangeParams(value, index)}
                  options={getParamsSelectOptions()}
                />
              </div>
              <div className={'w-[120px]'}>
                <Select
                  defaultValue={watch(`filter[${index}].condition`)}
                  onChange={(value) => setValue(`filter[${index}].condition`, value)}
                  options={getConditionsSelectOptions(watch(`filter[${index}].id`))}
                />
              </div>
              <NestedField fieldsLength={fields.length} appendFilter={append} nestIndex={index} />
            </div>
            {fields.length !== index + 1 && (
              <div className={'cursor-pointer absolute right-[-25px]'}>
                <Icon
                  name="TrashIcon"
                  className="!text-basic_red"
                  size={14}
                  onClick={() => remove(index)}
                />
              </div>
            )}
          </li>
        </Fragment>
      ))}
    </ul>
  );
};

export default Field;
