import { createDictionaryType } from '@app/interfaces/slices-types/dictionaries-slice.type';

import apiInstance from './common.api';

export const getAllDictionaries = async () => {
  return await apiInstance.get('/api/front/dictionary/bulk');
};
export const getDictionaryData = async (id: string) => {
  return await apiInstance.get('/api/front/dictionary', { params: { id: id } });
};
export const deleteDictionary = async (id: string) => {
  return await apiInstance.delete('/api/front/dictionary', { params: { id: id } });
};
export const createDictionaries = async (obj: createDictionaryType) => {
  return await apiInstance.post('/api/front/dictionary', obj);
};
export const editDictionary = async (obj: createDictionaryType, id: string) => {
  return await apiInstance.put('/api/front/dictionary', obj, { params: { id: id } });
};
