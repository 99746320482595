import { Filter, Range } from '@app/components/ui/easy-filter/types';

export const onFilterSend = (data: Filter & Range) => {
  return {
    ...data,
    filter: data.filter.map((filter) => {
      return {
        ...filter,
        value: Array.isArray(filter.value)
          ? filter.value.filter((item) => item).length === 1
            ? filter.value[0]
            : filter.value.filter((item) => item)
          : filter.value,
      };
    }),
  };
};
export const onFilterInput = (data: Filter & Range) => {
  const empty = {
    filter: [
      {
        id: '',
        condition: '',
        value: [''],
      },
    ],
  };
  return {
    ...data,
    filter: [...data.filter, ...empty.filter].map((filter) => {
      return {
        ...filter,
        value: Array.isArray(filter.value) ? filter.value : filter.value.split(/\W/),
      };
    }),
  };
};
