import { CircleButton, Modal, TooltipWrapper } from '@app/components/ui';
import Icon from '@app/components/ui/icons';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import { TemplateListIcon } from '@app/components/ui/icons/icons-list';
import RubberTable from '@app/components/ui/rubber-table';
import { TableItem } from '@app/components/ui/table/table.type';
import useTranslation from '@app/hooks/use-translation';
import { MetricTemplatesType } from '@app/interfaces/slices-types/metric-templates.type';
import { getDictionariesThunks } from '@app/store/reducers/dictionaries-slice';
import { getMetricTemplatesThunks } from '@app/store/reducers/metric-templates.slice';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import React, { Dispatch, useEffect, SetStateAction } from 'react';
import { v4 } from 'uuid';

type PropsType = {
  loading?: boolean;
  data: MetricTemplatesType[];
  setActiveModal: Dispatch<SetStateAction<string>>;
  setEditModal: Dispatch<SetStateAction<boolean>>;
  setCopyModal: Dispatch<SetStateAction<boolean>>;
};

export const MetricTemplateTable: React.FC<PropsType> = ({
  data,
  setActiveModal,
  setEditModal,
  loading,
  setCopyModal,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('pages.allPagesHintTitles');
  const { t: ti } = useTranslation('pages.metricTemplates');
  const { metricTypes, allChanels } = useAppSelector((state) => state.metric);
  const { dictionaries } = useAppSelector((state) => state.dictionaris);
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [templateId, setTemplateId] = React.useState('');

  useEffect(() => {
    dispatch(getDictionariesThunks.getAllDictionariesThunk());
  }, [dispatch]);

  const deleteSinglePreset = () => {
    dispatch(getMetricTemplatesThunks.deleteMetricTemplateThunk(templateId));
    setDeleteModal(false);
  };

  const clickCircleDelete = (id: string) => {
    setTemplateId(id);
    setDeleteModal(true);
  };

  const circleButtonClick = (metric_id: string, type: string, mode: string) => {
    if (mode === 'copy') {
      setCopyModal(true);
      setEditModal(true);
    } else {
      setCopyModal(false);
      setEditModal(true);
    }
    dispatch(getMetricTemplatesThunks.getMetricTemplateSettingsThunk(metric_id));
    setTimeout(() => {
      setActiveModal(type);
    }, 0);
  };

  const getChannelNameById = (id: string) => {
    if (id !== 'all') {
      return allChanels.find((item) => item.settings_channels_id === id)?.name;
    } else {
      return ti('all');
    }
  };

  const getTypeName = (type: string) => {
    return metricTypes.find((item) => item.type === type)?.name;
  };

  const getDictionaryNameById = (id: string) => {
    return dictionaries.find((e) => e.dictionary_id === id)?.name;
  };
  const getMetricSetting = (dataItem: MetricTemplatesType) => {
    if (dataItem.type === 'find-words') {
      if (
        dataItem.settings.wordlist?.length !== 0 ||
        dataItem.settings.dictionaries?.length !== 0
      ) {
        return (
          <div className="truncate max-h-[100px] flex flex-wrap items-center gap-[6px] border-solid border-[1px] border-[#DCE0E5] rounded-[10px] p-[6px] w-fit max-w-[400px]">
            {dataItem.settings.dictionaries?.map((e, index) => {
              return (
                <span
                  className="p-[2px_8px_3px] bg-[#FFE2F4] text-0color text-[13px] rounded-[4px]"
                  key={e + index}
                >
                  {getDictionaryNameById(e)}
                </span>
              );
            })}
            {dataItem.settings.wordlist?.map((e, index) => {
              return (
                <span
                  className="p-[2px_8px_3px] bg-basic_app_bg text-0color text-[13px] rounded-[4px]"
                  key={e + index}
                >
                  {e}
                </span>
              );
            })}
          </div>
        );
      } else return ti('none_settings');
    } else {
      return (
        <div className="max-w-[400px] h-full flex flex-wrap items-center gap-[12px] border-solid border-[1px] border-[#DCE0E5] rounded-[10px] p-[6px]">
          <span className="text-[10px] text-0color">
            {ti('first_secs') + ': ' + dataItem.settings.exclude_begin_sec}
          </span>
          <span className="text-[10px] text-0color">
            {ti('last_secs') + ': ' + dataItem.settings.exclude_end_sec}
          </span>
          {dataItem.settings.threshold || dataItem.settings.threshold === 0 ? (
            <span className="text-[10px] text-0color">
              {ti('pause_duration') + ': ' + dataItem.settings.threshold}
            </span>
          ) : null}
        </div>
      );
    }
  };

  const filteredArr = () => {
    return data?.map((dataItem) => ({
      ...dataItem,
      utils: (
        <div className="w-full gap-[10px] pl-[10px] flex items-start border-l-[1px] border-b-light">
          <CircleButton
            hintTitle={t('templates.settings_template_icon')}
            onClick={() => {
              circleButtonClick(dataItem.metric_template_id, dataItem.type, '');
            }}
            icon="SettingIcon"
            size={18}
            className={`text-3color hover:text-action`}
          />
          <CircleButton
            hintTitle={t('templates.copy_template_icon')}
            onClick={() => {
              circleButtonClick(dataItem.metric_template_id, dataItem.type, 'copy');
            }}
            icon="CopyIcon"
            size={18}
            className={`text-3color hover:text-action`}
          />
          <CircleButton
            hintTitle={t('templates.delete_template_icon')}
            icon="TrashIcon"
            size={18}
            onClick={() => clickCircleDelete(dataItem.metric_template_id)}
            className={` text-basic_red`}
          />
        </div>
      ),
      channel: (
        <div className="text-0color font-[400] tracking-tight text-[14px] leading-[110%]">
          <div>{dataItem.settings.channel && getChannelNameById(dataItem.settings.channel)}</div>
        </div>
      ),
      metricSettings: (
        <div className="py-[5px] text-0color font-[400] tracking-tight text-[14px] leading-[110%]">
          {getMetricSetting(dataItem)}
        </div>
      ),
      type: (
        <div className="w-full text-0color font-[400] tracking-tight text-[14px] leading-[110%] flex items-center gap-[10px]">
          <Icon size={28} name={dataItem.type.replace(/(\.|-|\/|\\| )/g, '') as IconNameType} />
          <div className="truncate">{getTypeName(dataItem.type)}</div>
        </div>
      ),
      name: (
        <div className="text-0color font-[600] tracking-tight text-[14px] leading-[110%] flex items-center">
          <div className="min-h-[28px] min-w-[28px] max-h-[28px] max-w-[28px] flex items-center justify-center ml-[16px] mr-[12px] bg-blue_pal rounded-[6px]">
            <TemplateListIcon size={13} className="text-action" />
          </div>
          <TooltipWrapper content={dataItem.name} id={dataItem.name + v4()}>
            <div className="truncate">{dataItem.name}</div>
          </TooltipWrapper>
        </div>
      ),
    })) as unknown as Array<TableItem>;
  };
  const columns = [
    {
      title: ti('table.name'),
      index: 'name',
    },
    {
      title: ti('table.type'),
      index: 'type',
    },
    {
      title: ti('table.channel'),
      index: 'channel',
      maxWidth: 130,
    },
    {
      title: ti('table.settings'),
      index: 'metricSettings',
    },
    {
      title: ti('table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];

  return (
    <>
      <Modal
        id={'deleteDictionary'}
        variant={'removal'}
        value={deleteModal}
        setValue={setDeleteModal}
        title={ti('warning')}
        size={'xs'}
        onApply={() => deleteSinglePreset()}
      >
        <div className="px-4 ">{ti('template_delete')}</div>
      </Modal>

      <RubberTable withoutCols isPending={loading} columns={columns} dataSource={filteredArr()} />
    </>
  );
};
