import React, { FC, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { isEqual } from 'lodash';

import connector from '@app/utils/store';
import ViewFilter from '@app/components/UniversalFilter/UniversalFilterView';
import { Filter, FilterRange } from '@app/interfaces/filter';
import ReportRecordsTableBlock from '@app/pages/reports/blocks/report-records-table.block';
import useTranslation from '@app/hooks/use-translation';
import { ReportOutletContext } from '@app/pages/reports/report.outlet';

import cloneFilterWithValues from '@app/hooks/use-universal-filter-values/cloneFilterWithValues';

import useGetUniversalFilter from '@app/hooks/use-universal-filter-values/use-get-universal-filter';

import ReportHeaderWithStatusBlock from './blocks/report-header-with-status.block';
import ReportNavigatorBlock from './blocks/report-navigator.block';

const ReportRecordsPage: FC = () => {
  const { t } = useTranslation('pages.reports');
  const getFilter = useGetUniversalFilter();
  const {
    report: { live_report_id, filter },
  } = useOutletContext<ReportOutletContext>();
  const [localFilter, setLocalFilter] = useState<Filter & FilterRange>(filter);
  useEffect(() => {
    getFilter();
  }, [getFilter]);
  async function handleFilterLiveReportRecords(
    newFilter: Filter & FilterRange,
    oldFilter: Filter & FilterRange,
  ) {
    if (!isEqual(oldFilter, newFilter)) {
      const filter = cloneFilterWithValues(newFilter.filter);
      const newFilterValues = { ...newFilter, filter: filter };
      setLocalFilter(newFilterValues as Filter & FilterRange);
    }
  }
  return (
    <div>
      <ReportHeaderWithStatusBlock />
      <ReportNavigatorBlock />
      <div>
        <ViewFilter
          title={t('filter_title')}
          saveFilter={handleFilterLiveReportRecords}
          metricMode
        />
      </div>
      <div className="mt-[20px]">
        <ReportRecordsTableBlock reportId={live_report_id} filter={localFilter} />
      </div>
    </div>
  );
};

export default connector(ReportRecordsPage);
