import React, { FC } from 'react';
import { CircleButton, RubberTable } from '@ui';
import { CircleChannelIcon } from '@icons';
import { ChannelSettingObject } from '@app/interfaces/settings.type';
import { TableColumn, TableItem } from '@app/components/ui/rubber-table/table.types';

type ChannelsTablePropsType = {
  tableData: ChannelSettingObject[];
  isLoading?: boolean;
  onEditIconClick: (id: string) => void;
  onDeleteIconClick: (id: string) => void;
};

const ChannelsTableBlock: FC<ChannelsTablePropsType> = (props) => {
  const { tableData, isLoading, onEditIconClick, onDeleteIconClick } = props;
  const tableRecognitionColumns: Array<TableColumn> = [
    { index: 'name', title: 'Название' },
    { index: 'channel', title: 'Номер дорожки' },
    { index: 'actions', title: 'Действия', maxWidth: 110, divider: 'left' },
  ];
  const tableRecognitionValues: Array<TableItem> = tableData.map((item) => ({
    ...item,
    name: (
      <div className="flex items-center text-[14px] h-full font-[700] text-0color">
        <CircleChannelIcon size={22} className="text-action mr-[10px]" />
        {item.name}
      </div>
    ),
    channel: (
      <div className="inline-flex items-center text-[14px] font-[400] text-0color">
        {item.channel}
      </div>
    ),
    actions: (
      <div className="inline-flex items-center">
        <CircleButton
          onClick={() => onEditIconClick(item.settings_channels_id)}
          className="mr-[20px] text-action"
          size={18}
          icon="EditIcon"
        />
        <CircleButton
          onClick={() => onDeleteIconClick(item.settings_channels_id)}
          className="text-basic_red"
          size={18}
          icon="TrashIcon"
        />
      </div>
    ),
  }));
  return (
    <RubberTable
      isPending={isLoading}
      columns={tableRecognitionColumns}
      dataSource={tableRecognitionValues}
    />
  );
};

export default ChannelsTableBlock;
