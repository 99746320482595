import React, { FC } from 'react';
import classNames from 'classnames';

import { IconType } from '../icon.type';

const AvgConflict: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg xmlns="http://www.w3.org/2000/svg" width={size} fill="none" viewBox="0 0 19 19">
        <path
          fill="currentColor"
          d="M9.538 18.12c4.904 0 8.965-4.07 8.965-8.966 0-4.904-4.07-8.965-8.974-8.965C4.634.19.573 4.25.573 9.154c0 4.896 4.07 8.965 8.965 8.965zm0-1.495a7.435 7.435 0 01-7.462-7.47c0-4.15 3.305-7.471 7.453-7.471a7.45 7.45 0 017.48 7.47 7.442 7.442 0 01-7.47 7.471zm-.184-5.651c.439 0 .711-.282.711-.624v-.106c0-.492.282-.808.897-1.213.853-.562 1.459-1.072 1.459-2.127 0-1.459-1.3-2.25-2.795-2.25-1.512 0-2.505.721-2.742 1.53-.044.14-.07.28-.07.43 0 .396.307.607.597.607.299 0 .492-.14.65-.352l.159-.21c.307-.51.764-.81 1.353-.81.8 0 1.319.458 1.319 1.126 0 .597-.37.887-1.134 1.423-.633.44-1.108.906-1.108 1.767v.114c0 .457.255.695.704.695zm-.018 2.619c.51 0 .95-.405.95-.914 0-.51-.432-.914-.95-.914-.519 0-.95.413-.95.914 0 .5.44.914.95.914z"
        ></path>
      </svg>
    </div>
  );
};

export default AvgConflict;
