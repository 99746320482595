/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { Box, Button } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useOutletContext } from 'react-router-dom';
import { ReportOutletContext } from '@app/pages/reports/report.outlet';
import useGetUniversalFilter from '@app/hooks/use-universal-filter-values/use-get-universal-filter';
import ViewFilter from '@app/components/UniversalFilter/UniversalFilterView';
import {
  Filter,
  FilterItemNotOnlyArray,
  FilterRange,
  FilterRangeItem,
} from '@app/interfaces/filter';
import connector, { PropsFromRedux } from '@app/utils/store';

import cloneFilterWithValues from '@app/hooks/use-universal-filter-values/cloneFilterWithValues';

import getArrayFilterValues from '@app/hooks/use-universal-filter-values/getArrayFilterValues';

import { GroupSharedStatus } from '@app/interfaces/analytics';

import { useAppSelector } from '@app/store/store';

import ReportNavigatorBlock from './blocks/report-navigator.block';
import ReportHeaderWithStatusBlock from './blocks/report-header-with-status.block';

const ReportSettingsPage: FC<PropsFromRedux> = (props) => {
  const { editLiveReportRequest, liveReportSettingsRequest } = props;
  const {
    report: { live_report_id, name, filter, project },
  } = useOutletContext<ReportOutletContext>();
  const [newFilterValues, changeNewFilterValues] = useState<{
    filter: FilterItemNotOnlyArray[];
    range: FilterRangeItem;
  }>();
  const [saveButtonDisabledState, setSaveButtonDisabled] = useState(false);
  const getFilter = useGetUniversalFilter();
  const { cloneFilter } = useAppSelector((state) => state.filter);
  const { t } = useTranslation('pages.reports');

  useEffect(() => {
    if (filter) {
      const filterArr = { ...filter, filter: getArrayFilterValues(filter.filter) };
      getFilter(filterArr as Filter & FilterRange);
    }
  }, [filter, getFilter]);

  function getTitleFilter() {
    return <h2 className="text-1color font-bold">{t('filter_settings')}</h2>;
  }

  function handleGetFilter(newFilter: Filter & FilterRange, oldFilter: Filter & FilterRange) {
    if (!isEqual(newFilter, oldFilter)) {
      const filter = cloneFilterWithValues(newFilter.filter);
      changeNewFilterValues({ ...newFilter, filter: filter });
      setSaveButtonDisabled(false);
    }
  }

  function handleSaveFilter() {
    editLiveReportRequest({
      id: live_report_id,
      name,
      filter: newFilterValues
        ? (newFilterValues as Filter & FilterRange)
        : {
            range: {
              type: 'n',
              parameters: [],
            },
            filter: [],
          },
    })
      .then((response) => {
        const filterArr = { ...response.filter, filter: getArrayFilterValues(response.filter) };
        getFilter(filterArr as Filter & FilterRange);
      })
      .finally(() => {
        liveReportSettingsRequest({ id: live_report_id });
      });
    changeNewFilterValues(undefined);
  }
  function saveButtonDisabled() {
    if (!project?.owner && project?.shared === GroupSharedStatus.VIEW) return true;
    if (!newFilterValues?.filter.length && !saveButtonDisabledState) return true;
  }
  function resetButtonDisabled() {
    if (!project?.owner && project?.shared === GroupSharedStatus.VIEW) return true;
    if (!newFilterValues?.filter.length) return true;
  }
  function deleteButtonDisabled() {
    if (!project?.owner && project?.shared === GroupSharedStatus.VIEW) return true;
    if (!filter?.filter.length) return true;
  }
  function handleResetFilter() {
    const filterArr = { ...filter, filter: getArrayFilterValues(filter.filter) };
    getFilter(filterArr as Filter & FilterRange);
    changeNewFilterValues(undefined);
  }
  function handleDeleteFilter() {
    getFilter(undefined);
    changeNewFilterValues(undefined);
    setSaveButtonDisabled(true);
  }

  return (
    <div>
      <ReportHeaderWithStatusBlock />
      <ReportNavigatorBlock />
      <Box className="inline-flex w-full justify-between flex-col">
        <div className="py-[30px] px-[34px]">
          <ViewFilter
            title={getTitleFilter()}
            saveFilter={async (newFilter, oldFilter) => handleGetFilter(newFilter, oldFilter)}
            metricMode={true}
            disabledChangeButton={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
          />
        </div>
        <div className="px-[30px] border-t ">
          <div className="w-full pt-[20px] py-[24px] pb-[23px] inline-flex justify-between">
            <div>
              <Button
                onClick={handleSaveFilter}
                disabled={saveButtonDisabled()}
                label={t('button_settings.save')}
              />
              <Button
                fill="linked"
                variant="primary"
                label={t('button_settings.cancel')}
                onClick={handleResetFilter}
                disabled={resetButtonDisabled()}
              />
            </div>
            <div>
              <Button
                icon="TrashIcon"
                fill="linked"
                variant="danger"
                onClick={handleDeleteFilter}
                label={t('button_settings.remove')}
                disabled={deleteButtonDisabled()}
              />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default connector(ReportSettingsPage);
