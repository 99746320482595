import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { PageHeader, Select, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { OptionItem } from '@app/components/ui/select/select.type';
import { getAnalyticsThunk } from '@app/store/reducers/analyticsSlice';
import { useGetChannelsQuery } from '@app/store/reducers/get-channels-info.api';
import { getDictionariesThunks } from '@app/store/reducers/dictionaries-slice';

import { WordsCloudFilter } from './blocks/words-cloud-filter';
import { WordCloudContext, WordsCloudRequestType } from './types/types';
import WordsCloudSkeleton from './blocks/words-cloud.skeleton';

const WordsCloud = () => {
  const { t } = useTranslation('components.menuBar');
  const { t: ti } = useTranslation('pages.reports');
  const dispatch = useAppDispatch();
  const [wordForSearch, changeWordForSearch] = useState<string[] | undefined>([]);
  const { projectsList, loading } = useAppSelector((state) => state.analytics);
  const [projectId, changeProjectId] = useState<string | undefined>(
    localStorage.getItem('word_cloud_project') || undefined,
  );
  const [dataForCloud, setDataForCloud] = useState<WordsCloudRequestType>();
  const { dictionaries, loading: dictLoading } = useAppSelector((state) => state.dictionaris);
  const { data, isLoading } = useGetChannelsQuery(undefined);
  useEffect(() => {
    if (projectId) return;
    changeProjectId(projectsList[0]?.project_id);
  }, [projectId, projectsList]);

  useEffect(() => {
    dispatch(getAnalyticsThunk.getGroupsToListLastProjects());
    dispatch(getDictionariesThunks.getAllDictionariesThunk());
  }, [dispatch]);

  function handleSelectOnChange(option: string | number) {
    localStorage.setItem('word_cloud_project', option as string);
    changeProjectId(option as string);
  }

  const renderSelectProjectOptions: Array<OptionItem> = projectsList.length
    ? projectsList?.map(({ name, project_id }) => ({
        title: name,
        value: project_id,
      }))
    : [];
  function onFormSubmit(data) {
    if (!projectId) return;
    setDataForCloud({ data: data, project_id: projectId });
  }

  const renderSelectProject = loading ? (
    <Skeleton width={234} height={32} />
  ) : (
    <Select
      size={240}
      onChange={handleSelectOnChange}
      placeholder={ti('select.all_projects')}
      options={renderSelectProjectOptions}
      defaultValue={projectId}
      isEmpty
      dropWidth={238}
    />
  );
  if (loading || dictLoading || isLoading) return <WordsCloudSkeleton />;
  return (
    <>
      <PageHeader label={t('words_cloud')} icon="CloudIcon">
        <div className="inline-flex font-[500] text-[15px] text-1color items-center">
          {ti('choose_project')}:<div className="ml-[11px]">{renderSelectProject}</div>
        </div>
      </PageHeader>
      <div className="flex items-start justify-between gap-[10px] mt-[10px]">
        <Outlet
          context={
            { data: dataForCloud, changeWordForSearch: changeWordForSearch } as WordCloudContext
          }
        />
        <div>
          <WordsCloudFilter
            disableSaveButton={!!projectId}
            onFormSubmit={onFormSubmit}
            dictionaries={dictionaries}
            channels={data}
            wordForSearch={wordForSearch}
          />
        </div>
      </div>
    </>
  );
};

export default WordsCloud;
