import { getAliasRecordPage } from '@app/api/record.api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PresetsDataType } from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';

import {
  InputDataThunksType,
  initialStateType,
  PresetsGroupsType,
} from '../../interfaces/slices-types/presets-slice.type';

import {
  createPreset,
  createPresetGroup,
  deletePreset,
  deletePresetGroups,
  editPreset,
  editPresetGroup,
  getListOfPresetGroups,
  getPresetGroup,
} from '../../api/presets.api';

const initialState: initialStateType = {
  groups: [],
  presetGroup: null,
  aliases: [],
  loading: false,
  presetsCount: 0,
};

export const getPresetsThunks = {
  getPresetsGroupsThunk: createAsyncThunk(
    'PresetsSlice/getPresetsGroupsThunk',
    async (_, { rejectWithValue }) => {
      try {
        const response = await getListOfPresetGroups();
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  getPresetGroupThunk: createAsyncThunk(
    'PresetsSlice/getPresetGroupThunk',
    async (id: string, { rejectWithValue }) => {
      try {
        const response = await getPresetGroup(id);
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  createPreset: createAsyncThunk<PresetsDataType, Omit<PresetsDataType, 'owner_id' | 'preset_id'>>(
    'PresetsSlice/createPreset',
    async (data, { rejectWithValue }) => {
      return await createPreset(data)
        .then((response) => response.data)
        .catch((error) => rejectWithValue(error.response.status + ''));
    },
  ),
  editPresetThunk: createAsyncThunk<PresetsDataType, Omit<PresetsDataType, 'owner_id'>>(
    'PresetsSlice/editPresetThunk',
    async (data, { rejectWithValue }) => {
      return await editPreset(data)
        .then((response) => response.data)
        .catch((error) => rejectWithValue(error.response.status + ''));
    },
  ),
  editPresetNameThunk: createAsyncThunk(
    'PresetsSlice/editPresetNameThunk',
    async ({ preset_group_id, groupName }: InputDataThunksType, thunkApi) => {
      try {
        return await editPresetGroup(preset_group_id, groupName);
      } catch (err) {
        return thunkApi.rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  createPresetGroupThunk: createAsyncThunk<
    PresetsGroupsType,
    Omit<InputDataThunksType, 'preset_group_id'>
  >('PresetsSlice/createPresetGroupThunk', async ({ groupName }, thunkApi) => {
    try {
      return await createPresetGroup(groupName).then((res) => res.data);
    } catch (err) {
      return thunkApi.rejectWithValue(`${err.response.status}`);
    }
  }),
  deletePresetGroupThunk: createAsyncThunk(
    'PresetsSlice/deletePresetGroupThunk',
    async (preset_group_id: string, thunkApi) => {
      try {
        return await deletePresetGroups(preset_group_id).then(() => preset_group_id);
      } catch (err) {
        return thunkApi.rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  deletePresetThunk: createAsyncThunk(
    'PresetsSlice/deletePresetThunk',
    async (preset_id: string, thunkApi) => {
      try {
        return await deletePreset(preset_id).then(() => {
          return { preset_id };
        });
      } catch (err) {
        return thunkApi.rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  getAliasesThunk: createAsyncThunk(
    'PresetsSlice/getAliasesThunk',
    async (_, { rejectWithValue }) => {
      try {
        const response = await getAliasRecordPage();
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
};

const PresetsSlice = createSlice({
  name: 'PresetsSlice',
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPresetsThunks.getPresetsGroupsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPresetsThunks.getPresetsGroupsThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPresetsThunks.getPresetsGroupsThunk.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.loading = false;
    });
    builder.addCase(getPresetsThunks.createPresetGroupThunk.fulfilled, (state, action) => {
      state.groups = state.groups.concat(action.payload);
    });
    builder.addCase(getPresetsThunks.getAliasesThunk.fulfilled, (state, action) => {
      state.aliases = action.payload.aliases;
    });
    builder.addCase(getPresetsThunks.deletePresetGroupThunk.fulfilled, (state, action) => {
      state.groups = state.groups.filter((item) => item.preset_group_id !== action.payload);
    });
    builder.addCase(getPresetsThunks.deletePresetThunk.fulfilled, (state, action) => {
      state.groups = state.groups.map((group) => {
        group.presets.map((preset, index) => {
          if (preset.preset_id === action.payload.preset_id) group.presets.splice(index, 1);
        });
        return group;
      });
    });
    // builder.addCase(getPresetsThunks.getPresetGroupThunk.pending, (state) => {
    //   state.presetsLoading = true;
    // });
    // builder.addCase(getPresetsThunks.getPresetGroupThunk.fulfilled, (state, action) => {
    //   state.presetsCount = action.payload.presets.length;
    //   state.presetGroup = action.payload;
    //   state.presetsLoading = false;
    // });
    builder.addCase(getPresetsThunks.editPresetThunk.fulfilled, (state, action) => {
      state.groups = state.groups.map((group) => {
        group.presets.map((preset) => {
          if (preset.preset_id === action.payload.preset_id) preset = action.payload;
        });
        return group;
      });
    });
  },
});

export const { setLoading: setPresetsLoading } = PresetsSlice.actions;

export default PresetsSlice.reducer;
