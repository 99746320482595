export const userPermissions = {
  display: {
    displayProjectMetricsResult: 'displayProjectMetricsResult',
    displayExternalASRModels: 'displayExternalASRModels',
    displayMetricTemplates: 'displayMetricTemplates',
    displayRecognizeRules: 'displayRecognizeRules',
    displayAPModel: 'displayAPModel',
    displayAuditLogs: 'displayAuditLogs',
    displayDashboards: 'displayDashboards',
    displayLiveReports: 'displayLiveReports',
    displayProjectMetrics: 'displayProjectMetrics',
    displayProjectPreset: 'displayProjectPreset',
    displayProjects: 'displayProjects',
    displayDictionaries: 'displayDictionaries',
    displayPresets: 'displayPresets',
    displayRecords: 'displayRecords',
    displayChannels: 'displayChannels',
    displayAliases: 'displayAliases',
    displaySettings: 'displaySettings',
    displayRepStatusCol: 'displayRepStatusCol',
    dis: 'dis',
  },
  action: {
    actionDeleteRecords: 'actionDeleteRecords',
    actionDeleteRecord: 'actionDeleteRecord',
  },
};
