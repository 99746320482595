module.exports = {
  date_time: 'Дата и время',
  last: 'последние',
  last_from_capital: 'Последние',
  days: 'дней',
  or: 'или',
  search_conditions: 'Условия поиска не заданы',
  change: 'Изменить',
  edit_filter: 'Редактировать фильтр',
  alias: 'Параметр',
  condition: 'Условие',
  values: 'Варианты значений',
  value: 'Значение',
  filter_by_date: 'Фильтр по дате',
  from: 'от',
  to: 'до',
  write_date: 'Укажите дату',
  write_time: 'Укажите время',
  write_value: 'Укажите значение',
  time: 'Время',
  calendar_bind: 'Календарная привязка',
  add_preset_from_template_title: 'Добавить пресет из шаблона',
};
