module.exports = {
  record_not_found: 'Запись не найдена',
  audio: 'Аудиозапись',
  delete: 'Удалить',
  delete_record: 'Удалить запись',
  record_will_deleted: 'Запись будет удалена',
  audoigramm: 'Аудиограмма',
  metric_list: 'Cписок рассчитанных метрик',
  metric: 'Метрика',
  metric_value: 'Значение метрики',
  meta_data: 'Метаданные',
  client: 'Клиет',
  operator: 'Оператор',
  sec: 'сек',
  dialog: 'Диалог',
  link_copied: 'Ссылка на аудио скопирована!',
  translation_download_false: 'Ошибка загрузки',
  channels_meta_name: 'Имя',
  channels_meta_channel: 'Канал',
  channels_meta_gsm: 'Локация',
  end_dialog: 'Конец диалога',
  empty_dialog: 'Запись ещё не распознана',
  metric_show_empty: 'Отображать пустые',
  emotions: 'эмоции',
};
