import React, { FC } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Box, CircleButton, Divide, DropMenu, Select, TooltipWrapper } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';
import connector, { PropsFromRedux } from '@app/utils/store';
import {
  // ChartTypes,
  ReportChart,
  ReportChartResponse,
  ReportTimeRange,
} from '@app/interfaces/report.type';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import { navigationRoutes } from '@app/utils/navigation-routes';

import { useGetDashboardQuery } from '@app/store/reducers/dashboard-page.api';

import { useLazyGetGraphDataQuery } from '@app/store/reducers/graph-data.api';

import { FilterItem } from '@app/interfaces/filter';

import { chartBuildData } from './chart-build.data';

type ChartComponentProps = {
  data: ReportChartResponse['data'];
  chartId?: string;
  dashboard?: boolean;
  commonRange?: ReportTimeRange | null;
  viewOnly?: boolean;
  onEditIntervalWithoutSave?: (data: { data: ReportChart; id: string }) => void;
  setOpenedCustomDateModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setMobile?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenRemoveModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: React.Dispatch<React.SetStateAction<ReportChart | undefined>>;
  filterValue?: FilterItem[];
} & PropsFromRedux;

const ChartBlock: FC<ChartComponentProps> = (props) => {
  const {
    data,
    dashboard,
    viewOnly,
    onEditIntervalWithoutSave,
    setOpenedCustomDateModal,
    setOpenRemoveModal,
    setMobile,
    setData,
    filterValue,
  } = props;
  const { currentData: currentDashboard } = useGetDashboardQuery(
    localStorage.getItem('DASHBOARD_ID') || '',
  );
  const [getGraphData] = useLazyGetGraphDataQuery();
  const navigate = useNavigate();
  const { t } = useTranslation('pages.chartPage');
  const { daysSelectOptions, dateIntervals, dateToString, dateKeys } = useChartDateIntervals();
  const editChartOptions: Array<DropMenuItem> = [
    {
      title: t('drop_menu.edit'),
      icon: 'EditIcon',
      key: 'edit',
      disable: dashboard && !currentDashboard?.owner,
    },
    { title: t('drop_menu.to_report'), icon: 'MetricIcon', key: 'to_report' },
    {
      title: t('drop_menu.remove'),
      color: 'danger',
      icon: 'TrashIcon',
      key: 'remove',
      disable: dashboard && !currentDashboard?.owner,
    },
  ];

  const handlerChangeChartDateSelect = (dateInterval: string | number) => {
    if (dashboard) {
      if (dateKeys.customDate === dateInterval) {
        setData && setData(data);
        setOpenedCustomDateModal && setOpenedCustomDateModal(true);
      } else {
        getGraphData({
          id: data.graph_id,
          settings: {
            width: data.width,
            range: dateIntervals[dateInterval],
          },
        }).then((Cdata) => {
          const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
          onEditIntervalWithoutSave &&
            data.dashboard_graph_id &&
            onEditIntervalWithoutSave({ data: chartData, id: data.dashboard_graph_id });
        });
      }
    } else {
      if (dateKeys.customDate === dateInterval) {
        setData && setData(data);
        setOpenedCustomDateModal && setOpenedCustomDateModal(true);
      } else {
        getGraphData({
          id: data.graph_id,
          settings: {
            width: data.width,
            range: dateIntervals[dateInterval],
            filter: filterValue,
          },
        }).then((Cdata) => {
          const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
          onEditIntervalWithoutSave &&
            data.graph_id &&
            onEditIntervalWithoutSave({ data: chartData, id: data.graph_id });
        });
      }
    }
  };
  // function handlerDateDropTrigger(key: string | number) {
  //   if (dashboard) {
  //     getGraphData({
  //       id: data.graph_id,
  //       settings: {
  //         width: data.width,
  //         range: dateIntervals[key],
  //       },
  //     }).then((Cdata) => {
  //       const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
  //       onEditIntervalWithoutSave &&
  //         data.dashboard_graph_id &&
  //         onEditIntervalWithoutSave({ data: chartData, id: data.dashboard_graph_id });
  //     });
  //   } else {
  //     getGraphData({
  //       id: data.graph_id,
  //       settings: {
  //         width: data.width,
  //         range: dateIntervals[key],
  //         filter: filterValue,
  //       },
  //     }).then((Cdata) => {
  //       const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
  //       onEditIntervalWithoutSave &&
  //         data.graph_id &&
  //         onEditIntervalWithoutSave({ data: chartData, id: data.graph_id });
  //     });
  //   }
  // }

  function handleEditChart({ key }: { key: string }) {
    switch (key) {
      case 'edit': {
        setData && setData(data);
        setMobile && setMobile(true);
        break;
      }
      case 'remove': {
        setData && setData(data);
        setOpenRemoveModal && setOpenRemoveModal(true);
        break;
      }
      case 'to_report': {
        navigate(`/${navigationRoutes.reports}/${data.live_report_id}`);
        break;
      }
    }
  }

  const currentChartBuild =
    data &&
    chartBuildData({
      chart: data,
      translationList: {
        download: t('chart_toolbar.download'),
        selectionZoom: t('chart_toolbar.selectionZoom'),
        zoomIn: t('chart_toolbar.zoomIn'),
        zoomOut: t('chart_toolbar.zoomOut'),
        pan: t('chart_toolbar.pan'),
        reset: t('chart_toolbar.reset'),
      },
      isDashboard: dashboard,
    });

  return (
    <Box
      className={cn(
        //dashboard ? '' : data?.width === 'full' ? 'w-full' : 'w-[calc(50%-5px)]',
        data?.errorStatus && '!bg-basic_red',
        'w-full',
      )}
    >
      <div className="relative pt-[2px] max-w-full">
        <div className="top-[0px] w-full pl-[29px] pr-[13px] py-[18px] inline-flex items-center justify-between">
          <div className="inline-flex items-center w-[calc(100%-310px)] noDrag">
            <TooltipWrapper truncate content={data?.name} id={data?.name + data.graph_id}>
              <h1 className="text-[18px] font-[700] text-1color truncate flex-1 ">{data?.name}</h1>
            </TooltipWrapper>
          </div>
          <div className="inline-flex items-center absolute right-[18px] noDrag">
            {data && (
              <div className={cn(viewOnly ? 'mr-[185px]' : 'mr-[145px]')}>
                <Select
                  placeholder={t('select.date_placeholder')}
                  defaultValue={dateToString(data?.range)}
                  variant="small"
                  size={120}
                  dropWidth={180}
                  onChange={handlerChangeChartDateSelect}
                  options={daysSelectOptions}
                />
              </div>
            )}
            {!viewOnly && (
              <>
                <Divide size={15} span={1} orientation="vertical" className="ml-[16px] mr-[5px]" />
                <DropMenu
                  dropSize={160}
                  onClick={(key) => handleEditChart({ key })}
                  hintTitle={t('menu')}
                  menu={
                    !dashboard
                      ? editChartOptions.filter((item) => item.key !== 'to_report')
                      : editChartOptions
                  }
                >
                  <CircleButton icon="VerticalDotsIcon" size={12} className="text-3color" />
                </DropMenu>
              </>
            )}
          </div>
        </div>
        <div className={'min-w-full'}>
          {data && (
            <Chart
              options={currentChartBuild?.options as ApexOptions}
              series={currentChartBuild?.series}
              height={280}
              width="100%"
              // type={(currentChartBuild?.options?.chart?.type as ChartTypes) || 'bar'}
            />
          )}
        </div>
      </div>
    </Box>
  );
};

export default connector(ChartBlock);
