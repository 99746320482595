import { getCustomAliasRecord, saveCustomAliasRecord } from '@app/api/aliases.api';
import {
  AliasesState,
  ChangeCustomAliasesKey,
  CustomAliasItem,
  TenantAliasesResponse,
} from '@app/interfaces/aliases';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { httpRequest } from '@app/store/middleware/rest';
import serverRoutes from '@app/utils/server-routes';

export const initialState: AliasesState = {
  show_aliases: [],
  tenant_aliases: { aliases: [] },
  isPending: false,
  errors: null,
};

export const getAliasesThunk = {
  getCustomAliasThunk: createAsyncThunk<CustomAliasItem[]>(
    'AliasesSlice/getCustomAliasThunk',
    async (_, { rejectWithValue }) => {
      return await getCustomAliasRecord()
        .then((response) => response.data.show_aliases)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  saveCustomAliasThunk: createAsyncThunk<CustomAliasItem[], string[]>(
    'AliasesSlice/saveCustomAliasThunk',
    async (data, { rejectWithValue }) => {
      return await saveCustomAliasRecord({
        aliases: data,
      })
        .then((response) => response.data.show_aliases)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
};

const aliasesSlice = createSlice({
  name: 'AliasesSlice',
  initialState,
  reducers: {
    tenantAliasesResponse(state, { payload }: { payload: TenantAliasesResponse }) {
      state.tenant_aliases = payload;
    },
    aliasesResponse(state, { payload }) {
      state.show_aliases = payload.show_aliases;
    },
    aliasesPendingResponse(state, { payload }) {
      state.isPending = payload;
    },
    aliasesErrorResponse(state, { payload }) {
      state.errors = payload;
    },
    changeCustomAliasesKey(state, action: PayloadAction<ChangeCustomAliasesKey>) {
      state.show_aliases = cloneDeep(state.show_aliases).map((item) => {
        if (action.payload.id === item.alias_id) {
          (item[action.payload.key] as string | boolean) = action.payload.value;
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAliasesThunk.getCustomAliasThunk.fulfilled, (state, action) => {
      state.show_aliases = action.payload;
    });
  },
});

export const {
  changeCustomAliasesKey,
  aliasesResponse,
  aliasesPendingResponse,
  aliasesErrorResponse,
  tenantAliasesResponse,
} = aliasesSlice.actions;

export default aliasesSlice.reducer;

export const aliasesRequest = () =>
  httpRequest({
    url: serverRoutes.aliases,
    method: 'GET',
    onSuccess: aliasesResponse.type,
    onLoading: aliasesPendingResponse.type,
    onError: aliasesErrorResponse.type,
  });

export const tenantAliasesRequest = () =>
  httpRequest({
    url: serverRoutes.tenantAliases,
    method: 'GET',
    onSuccess: tenantAliasesResponse.type,
    onError: aliasesErrorResponse.type,
    onLoading: aliasesPendingResponse.type,
  });
