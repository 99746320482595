import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import {
  menuBarItemClassName,
  menuBarItemTextClassName,
} from '@app/pages/layout/blocks/menu-bar/menu-bar.styles';
import Icon from '@app/components/ui/icons';
import { MenuBarItem } from '@app/constants/menu-bar-data';
import { usePermissionCheck } from '@app/hooks/use-permission';

type MenuBarItemProps = {
  isCollapsed: boolean;
  itemMenu: MenuBarItem;
  tag?: string;
};

const MenuBarElement: FC<MenuBarItemProps> = (props) => {
  const { isCollapsed, itemMenu, tag } = props;
  const { id, name, icon, route, routeEnd } = itemMenu;
  const isPermission = usePermissionCheck({ tag, permissions: 'display' });
  if (isPermission) return <></>;
  return (
    <li key={id}>
      <NavLink
        to={route}
        className={({ isActive }) => menuBarItemClassName({ isActive, isCollapsed })}
        end={routeEnd}
      >
        <div className="inline-flex items-center overflow-y-hidden overflow-x-hidden">
          <Icon className="text-speech_analitics" name={icon} size={isCollapsed ? 25 : 18} />
          {!isCollapsed && <span className={menuBarItemTextClassName}>{name}</span>}
        </div>
      </NavLink>
    </li>
  );
};

export default MenuBarElement;
