import React, { FC } from 'react';
import { EditText, PageHeader, SharedStatus, TooltipWrapper } from '@ui';
import { useOutletContext, useParams } from 'react-router-dom';
import { ReportOutletContext } from '@app/pages/reports/report.outlet';
import { StatusLabel } from '@app/components';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';
import connector, { PropsFromRedux } from '@app/utils/store';
import { StatusVariant } from '@app/components/status-label/status-label';
import { reportStatusPropertiesData } from '@app/pages/reports/data/report-status-properties.data';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import { useLocation, useNavigate } from 'react-router';

const ReportHeaderWithStatusBlock: FC<PropsFromRedux> = (props) => {
  const {
    changeStatusRequest,
    editLiveReportRequest,
    liveReportSettingsRequest,
    reportsState: { liveReport },
  } = props;
  const status: StatusVariant = 'LIFE';
  const navigate = useNavigate();
  const {
    report: { live_report_id, filter, project },
  } = useOutletContext<ReportOutletContext>();
  const history = useLocation();
  const { t } = useTranslation('pages.chartPage');
  const { id } = useParams();

  function handleEditReportName() {
    // TODO: edit name of report
  }

  function handleChangeStatus(new_status) {
    changeStatusRequest({ id: live_report_id, new_status });
  }

  function handleChangeReportName(name: string) {
    editLiveReportRequest({ id: live_report_id, name, filter }).then(
      () => id && liveReportSettingsRequest({ id: id }),
    );
  }
  return (
    <PageHeader
      label={
        <div className="flex items-center gap-[20px]">
          <EditText
            editIconClassNames="mt-[3px]"
            defaultOpen={history.state !== null}
            showEdit={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
            textStyle="font-[700] text-[24px] text-1color"
            text={liveReport?.name || ''}
            onEdited={handleChangeReportName}
          />

          <div className="flex items-end gap-[10px]">
            {process.env.REACT_APP_THEME !== 'rtk' && (
              <StatusLabel
                status={status as StatusVariant}
                onChangeStatus={handleChangeStatus}
                statusProperties={reportStatusPropertiesData}
              />
            )}
            <SharedStatus status={project?.shared || ''} />
          </div>
        </div>
      }
      popLink={`${
        localStorage.getItem('SELECTED_REPORT_PROJECT_ID') || `/${navigationRoutes.reports}`
      }`}
      afterAction={handleEditReportName}
    >
      <div className="flex items-center font-[500] text-[14px] leading-[17px] text-1color">
        <TooltipWrapper content={t('analytic_project')} id={'AP_project'}>
          <div className="cursor-default">{t('ap')}:</div>
        </TooltipWrapper>
        <div
          onClick={() =>
            navigate(`${`/${navigationRoutes.reports}?project=${project.project_id}`}`)
          }
          className="cursor-pointer"
        >
          &nbsp;{project.project_name}
        </div>
      </div>
    </PageHeader>
  );
};

export default connector(ReportHeaderWithStatusBlock);
