import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import Icon from '@app/components/ui/icons';

import cn from 'classnames';
import useOnClickOutside from '@app/hooks/use-onclick-outside';
import { TriangleBottomIcon } from '@app/components/ui/icons/icons-list';

import { v4 } from 'uuid';

import TooltipWrapper from '../tooltip-wrapper';

import {
  dropContainerClassName,
  dropItemWrapperClassName,
  dropListItemClassName,
  dropTriangleClassName,
} from './drop-menu.styles';

import { DropMenuItem, DropMenuPosition } from './drop-menu.type';
export type DropMenuItemColor = 'primary' | 'danger';

type DropMenuProps = {
  onClick: (key: string) => void;
  children: ReactNode;
  position?: DropMenuPosition;
  menu: Array<DropMenuItem>;
  dropTop?: boolean;
  onChangeState?: (state: boolean) => void;
  dropSize?: string | number;
  hintTitle?: string;
};

const DropMenu: FC<DropMenuProps> = (props) => {
  const {
    children,
    menu,
    onChangeState,
    onClick,
    position = 'end',
    dropTop = false,
    dropSize,
    hintTitle,
  } = props;
  const DEFAULT_DROP_SIZE = 274;
  const [isOpen, changeIsOpen] = useState<boolean>(false);
  const [dropOffset, setDropOffset] = useState<number>();
  const dropMenuRef = useRef(null);
  const dropWrapperRef = useRef<HTMLDivElement>(null);
  const sizeStyle =
    typeof dropSize == 'string'
      ? { width: dropSize }
      : { width: `${dropSize || DEFAULT_DROP_SIZE}px` };
  const paddingForPartOfIcon = 5;
  const dropDownPosition: Record<DropMenuPosition, string> = {
    end: 'justify-end',
    start: 'justify-start',
    center: 'justify-center',
  };
  const menuColors: Record<DropMenuItemColor, string> = {
    primary: 'text-1color',
    danger: 'text-basic_red',
  };

  useEffect(() => {
    if (dropWrapperRef.current && position !== 'center') {
      const currentTriangleMargin = dropWrapperRef.current?.offsetWidth / 2 - paddingForPartOfIcon;
      setDropOffset(currentTriangleMargin);
    }
  }, [position]);

  const renderMenu = menu.map(
    ({ title, content, className, key, icon, color = menuColors.primary, disable = false }) => (
      <li
        key={key}
        onClick={!disable ? onClick.bind(null, key) : undefined}
        className={dropListItemClassName}
      >
        <div
          className={
            className || dropItemWrapperClassName(menuColors[color], color !== 'danger', disable)
          }
        >
          {icon && (
            <div className="mr-[8px]">
              <Icon size={14} name={icon} className={menuColors[color]} />
            </div>
          )}
          {content || title}
        </div>
      </li>
    ),
  );

  function handlerOnChangeOpenState(openState) {
    changeIsOpen(openState);
    onChangeState?.(openState);
  }

  function handleToggleDropMenu() {
    changeIsOpen((prevState) => !prevState);
  }

  useOnClickOutside(dropMenuRef, () => isOpen && handlerOnChangeOpenState(false));

  const renderTriangle = (
    <div
      className={dropTriangleClassName(dropDownPosition[position], dropTop)}
      style={{ paddingLeft: `${dropOffset}px`, paddingRight: `${dropOffset}px` }}
    >
      <TriangleBottomIcon
        size={13}
        className={cn(dropTop ? 'rotate-90 mt-[-10px]' : '-rotate-90', 'text-white shadow-lg')}
      />
    </div>
  );

  return (
    <div className="relative inline-flex items-center justify-center">
      <div className="select-none">
        <div
          ref={dropWrapperRef}
          className={cn('inline-flex', dropDownPosition[position])}
          onClick={handleToggleDropMenu}
        >
          <TooltipWrapper content={hintTitle} id={hintTitle + v4()}>
            {children}
          </TooltipWrapper>
          <div
            ref={dropMenuRef}
            className={dropContainerClassName({
              isOpen,
              dropTop,
            })}
            style={sizeStyle}
          >
            {!dropTop && renderTriangle}
            {renderMenu}
            {dropTop && renderTriangle}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropMenu;
