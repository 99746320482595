import React, { FC } from 'react';
import { optionItemClassName } from '@app/components/ui/select/select.styles';
import { OptionItem } from '@app/components/ui/select/select.type';
import cn from 'classnames';
import Icon from '@app/components/ui/icons';

const SelectItem: FC<{
  onChange: (option: OptionItem) => void;
  option: OptionItem;
  active: boolean;
  isMulti?: boolean;
}> = (props) => {
  const { onChange, option, active, isMulti } = props;

  function handleOnClickOption(option: OptionItem) {
    if (option?.disabled) return;
    onChange?.(option);
  }
  return (
    <li
      className={optionItemClassName({
        nullable: option.nullable || false,
        active,
        disabled: option?.disabled || false,
      })}
      onClick={handleOnClickOption.bind(null, option)}
      title={String(option.title)}
    >
      <div className="truncate w-full">
        <span
          className={cn(
            option.value === '' && 'text-b_dark',
            option.disabled && '!text-3color',
            'text-1color font-[500] tracking-tight leading-[110%]',
            `${active && '!text-action'}`,
          )}
        >
          {option.title}
        </span>
      </div>
      {isMulti && active && <Icon className={'text-action'} size={14} name={'CheckIcon'} />}
    </li>
  );
};

export default SelectItem;
