import React, { FC, useEffect } from 'react';

import { Loading } from '@ui';
import { ReportTimeRange } from '@app/hooks/use-chart-date-intervals';
import connector, { PropsFromRedux } from '@app/utils/store';
import { AggregateSelectForm, SettingsDateStep } from '@app/interfaces/report.type';
import SettingsFormBlock from '@app/pages/reports/blocks/chart-modal/settings-form.block';

type SettingsMetricStep = string;
export type SettingsChartForm = {
  type: string;
  name: string;
  width: 'full' | 'half';
  range: number | string | ReportTimeRange;
  display_legend: boolean;
  colors: Array<string>;
  display_data: boolean;
  group: 'metric' | 'date';
  group2: 'metric' | 'date';
  metric: 'string';
  step?: SettingsDateStep | SettingsMetricStep;
  metric_id: string;
  aggregate: AggregateSelectForm;
  color: string;
  metric_idY2: string;
  aggregateY2: AggregateSelectForm;
  colorY2: string;
  switchY2: boolean;
};

type ModalSettingsChartBlockProps = {
  isOpen: boolean;
  chartId?: string;
  type: 'barchart';
  onClose: (status: boolean) => void;
  onRemove?: (status: boolean) => void;
} & PropsFromRedux;

const ModalSettingsChartBlock: FC<ModalSettingsChartBlockProps> = (props) => {
  const { isOpen, onClose, chartId, chartSettingsRequest, chartSettingsState, onRemove } = props;
  useEffect(() => {
    if (chartId) chartSettingsRequest({ id: chartId });
  }, [chartId, chartSettingsRequest]);
  const { setup, isPending } = chartSettingsState;
  // console.log(setup);
  if (isPending) return <Loading />;
  if (chartId && setup)
    return (
      <SettingsFormBlock
        setup={setup}
        isOpen={isOpen}
        onClose={onClose}
        onRemove={onRemove}
        projectMetrics={[]}
      />
    );
  return <SettingsFormBlock isOpen={isOpen} onClose={onClose} projectMetrics={[]} />;
};

export default connector(ModalSettingsChartBlock);
