import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import { TableTitle } from '@app/components/ui/table/table.type';
const DEFAULT_CELL_SIZE = 180;
const DEFAULT_FILTER_SIZE = 30;
export const TableWrapperForColsAndData: FC<{
  children: ReactNode;
  columns: TableTitle[];
  isScrollArea: boolean;
}> = ({ children, columns, isScrollArea }) => (
  <div
    className={cn('grid  gap-y-[5px] self-baseline')}
    style={{
      gridTemplateColumns: !isScrollArea
        ? columns
            .map(
              (column) =>
                `${
                  (column?.size ? column.size : DEFAULT_CELL_SIZE) +
                  (column.filter ? DEFAULT_FILTER_SIZE : 0)
                }px`,
            )
            .join(' ')
        : `repeat(${columns.length}, 1fr)`,
    }}
  >
    {children}
  </div>
);
