import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { Loading, Paginator, Table } from '@ui';
import { FilterTableData, TableItem } from '@app/components/ui/table/table.type';
import useTranslation from '@app/hooks/use-translation';
import connector, { PropsFromRedux } from '@app/utils/store';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { Filter, FilterRange } from '@app/interfaces/filter';
import { DownloadCloudIcon, Oscilogram } from '@app/components/ui/icons/icons-list';
import {
  getMetricScoreStyles,
  getMetricScoreStyles100,
} from '@app/pages/analytics/project-records/styles/styles';
import { getRecordsThunk } from '@app/store/reducers/recordsSlice';
import { useAppDispatch } from '@app/store/store';

const DEFAULT_REPORT_RECORDS_LIMIT = 10;
const DEFAULT_REPORT_RECORDS_OFFSET = 0;

type ReportRecordsTableBlockProps = {
  filter: Filter & FilterRange;
  reportId: string;
} & PropsFromRedux;

const ReportRecordsTableBlock: FC<ReportRecordsTableBlockProps> = (props) => {
  const {
    recordsLiveReportsListRequest,
    recordsState,
    reportsState: { liveReport },
    filter,
    reportId,
  } = props;
  const { liveReportRecords, isPending } = recordsState;
  const { t } = useTranslation('pages.projectRecords');
  const [reportRecordsLimit, changeReportRecordsLimit] = useState<number>(
    Number(localStorage.getItem('REPORT_RECORDS_LIMIT')) || DEFAULT_REPORT_RECORDS_LIMIT,
  );
  const dispatch = useAppDispatch();
  const [reportRecordsOffset, changeReportRecordsOffset] = useState<number>(
    DEFAULT_REPORT_RECORDS_OFFSET,
  );
  const [reportRecordsOrderBy, changeRecordsReportOrderBy] = useState<FilterTableData>();

  const createFilteredRequest = useCallback(() => {
    recordsLiveReportsListRequest({
      id: reportId,
      offset: reportRecordsOffset,
      limit: reportRecordsLimit,
      filter,
      ...reportRecordsOrderBy,
    });
  }, [
    filter,
    recordsLiveReportsListRequest,
    reportId,
    reportRecordsLimit,
    reportRecordsOffset,
    reportRecordsOrderBy,
  ]);

  useEffect(() => {
    createFilteredRequest();
  }, [createFilteredRequest]);
  useEffect(() => {
    if (liveReportRecords.total <= reportRecordsLimit) {
      changeReportRecordsOffset(0);
    }
  }, [liveReportRecords.total, reportRecordsLimit]);
  const checkingForSorting = (index) => {
    return index !== liveReportRecords.headers.length + 1;
  };

  const recordsLiveReportDataColumns = [
    { index: 'duration', title: t('table.duration'), size: 160, hintTitle: t('to_record_title') },
    ...(liveReportRecords?.headers.map((titleData, index) => ({
      index: titleData.metric_id,
      title: titleData.name,
      size: 190,
      filter: checkingForSorting(index),
    })) || []),
    { index: 'utils', title: t('actions'), size: 110 },
  ];
  function displayItemVisualization(item) {
    if (item.visualization) {
      if (item.visualization === 'score5') {
        if (Number(item.value) > 5 || Number(item.value) === 0) {
          return item.value;
        } else {
          return (
            <div
              className={`${getMetricScoreStyles(
                Number(item.value),
              )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
            >
              {item.value}
            </div>
          );
        }
      } else if (item.visualization === 'score100') {
        if (Number(item.value) > 100 || Number(item.value) === 0) {
          return item.value;
        } else {
          return (
            <div
              style={{ width: `${22 + Number(item.value) * 1.5}px` }}
              className={`${getMetricScoreStyles100(
                Number(item.value),
              )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
            >
              {item.value}
            </div>
          );
        }
      } else return item.value;
    } else return item.value;
  }

  const formattedLiveReportRecordsTableData = liveReportRecords.records?.map((dataItem) => ({
    duration: (
      <div className="inline-flex items-center h-full mt-[8px]">
        <Link
          target="_blank"
          to={`/${navigationRoutes.reports}/${reportId}/${navigationRoutes.reportRecords}/${liveReport?.project.project_id}/${dataItem.record_id}`}
          className="inline-flex items-center"
        >
          <TimeDuration duration={dataItem.duration} />
        </Link>
      </div>
    ),
    ...Object.fromEntries(
      dataItem.data.map((metrics) => [[metrics.metric_id], displayItemVisualization(metrics)]),
    ),
    utils: (
      <div className="flex w-full items-start pl-[19px] pr-[10px]">
        <DownloadCloudIcon
          onClick={() => {
            dispatch(getRecordsThunk.getRecordAudioThunk({ id: dataItem.record_id }));
          }}
          hintTitle={t('download_record')}
          size={18}
          className={`mr-[18px] text-3color hover:text-action transition cursor-pointer`}
        />
        <Oscilogram
          onClick={() =>
            window.open(
              `/${navigationRoutes.reports}/${reportId}/${navigationRoutes.reportRecords}/${liveReport?.project.project_id}/${dataItem.record_id}`,
              '_blank',
            )
          }
          hintTitle={t('to_record_title')}
          size={22}
          className={'text-3color hover:text-action cursor-pointer transition'}
        />
      </div>
    ),
  }));

  function onChangePagination({ offset, limit }: PaginationResponse) {
    changeReportRecordsOffset(offset);
    localStorage.setItem('REPORT_RECORDS_LIMIT', String(limit));
    changeReportRecordsLimit(limit);
  }
  return (
    <div>
      {isPending && <Loading />}
      <Table
        columns={recordsLiveReportDataColumns}
        dataSource={formattedLiveReportRecordsTableData as unknown as Array<TableItem>}
        onFilter={changeRecordsReportOrderBy}
        isEmpty={t('empty_records')}
      />
      <div className="my-[20px]">
        <Paginator
          page={Math.ceil(reportRecordsOffset / reportRecordsLimit) + 1}
          count={liveReportRecords.total}
          limit={reportRecordsLimit}
          onChange={onChangePagination}
        />
      </div>
    </div>
  );
};

export default connector(ReportRecordsTableBlock);
