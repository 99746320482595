import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { BreadcrumbsItem } from '@app/pages/layout/blocks/breadcrumbs/breadcrumbs.type';
import connector, { PropsFromRedux } from '@app/utils/store';
import { useAuthContext } from '@app/utils/auth-provider';

import { useGetUserInfoQuery } from '@app/store/reducers/get-user-info.api';

import { Loading } from '@app/components/ui';

import { Helmet } from 'react-helmet';

import useTranslation from '@app/hooks/use-translation';

import HeaderBlock from './blocks/header.block';
import FooterBlock from './blocks/footer.block';
import LayoutContainerBlock from './blocks/layout-container.block';
import MenuBar from './blocks/menu-bar/menu-bar';

const faviconRtk = require('../../imgs/favicon/faviconRtk.png');
const faviconDefault = require('../../imgs/favicon/faviconDefault.png');
type LayoutWithAuthProps = {
  breadcrumbs?: Array<BreadcrumbsItem>;
};

const LayoutOutlet: FC<LayoutWithAuthProps & PropsFromRedux> = () => {
  const { isAuth } = useAuthContext();
  // const navigate = useNavigate();
  const { isFetching } = useGetUserInfoQuery(undefined, { skip: !isAuth });
  const { t } = useTranslation('common.pageTitle');
  // if (isError) {
  //   navigate('/' + navigationRoutes.error);
  // }
  if (!isAuth)
    return (
      <div className="bg-basic_app_bg">
        <Outlet />
      </div>
    );
  if (isFetching) return <Loading />;
  return (
    <div className="bg-basic_app_bg  border-l-[3px] border-l-speech_analitics">
      <HeaderBlock />
      <div className="flex flex-row">
        <MenuBar />
        <div className="flex flex-col flex-1 relative overflow-hidden">
          <LayoutContainerBlock>
            <Outlet />
          </LayoutContainerBlock>
          <FooterBlock />
          <Helmet>
            <link
              rel="icon"
              type="image/png"
              href={process.env.REACT_APP_THEME === 'rtk' ? faviconRtk : faviconDefault}
              sizes="16x16"
            />
            <title>{t('title')}</title>
          </Helmet>
        </div>
      </div>
    </div>
  );
};

export default connector(LayoutOutlet);
