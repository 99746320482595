import React, { FC } from 'react';
import { useOutletContext } from 'react-router-dom';

import { ReportOutletContext } from '@app/pages/reports/report.outlet';
import ChartListBlock from '@app/pages/reports/blocks/chart-list.block';

import ReportHeaderWithStatusBlock from './blocks/report-header-with-status.block';
import ReportNavigatorBlock from './blocks/report-navigator.block';

const ReportChartPage: FC = () => {
  const {
    report: { live_report_id },
  } = useOutletContext<ReportOutletContext>();
  return (
    <div>
      <ReportHeaderWithStatusBlock />
      <ReportNavigatorBlock />
      <ChartListBlock live_report_id={live_report_id} />
    </div>
  );
};

export default ReportChartPage;
