import { PresetsDataType } from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';

import apiInstance from './common.api';

export const getListOfPresetGroups = () => {
  return apiInstance.get('/api/front/preset_group/bulk');
};

export const getPresetGroup = (id: string) => {
  return apiInstance.get('/api/front/preset_group', {
    params: {
      id: `${id}`,
    },
  });
};

export const createPresetGroup = (name: string) => {
  return apiInstance.post('/api/front/preset_group', { name: name });
};

export const editPresetGroup = (id: string, name: string) => {
  return apiInstance.put(
    '/api/front/preset_group',
    { name: name },
    {
      params: {
        id: `${id}`,
      },
    },
  );
};

export const deletePresetGroups = (id: string) => {
  return apiInstance.delete('/api/front/preset_group', {
    params: {
      id: `${id}`,
    },
  });
};

export const deletePreset = (id: string) => {
  return apiInstance.delete('/api/front/preset', {
    params: {
      id: `${id}`,
    },
  });
};
// // на данный момент нет функционала по тз ,просто для проверки
// export const createPreset = (object: any) => {
//   return apiInstance.post('/api/front/preset', object);
// };

export const editPreset = (data: Omit<PresetsDataType, 'owner_id'>) => {
  return apiInstance.put('/api/front/preset', data, {
    params: {
      id: data.preset_id,
    },
  });
};

export const createPreset = (data: Omit<PresetsDataType, 'owner_id' | 'preset_id'>) => {
  return apiInstance.post('/api/front/preset', data);
};
