import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { RecordsItems } from '@app/interfaces/records';
import serverRoutes from '@app/utils/server-routes';
import { RecordBulk } from '@app/interfaces/record';

import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';

import { baseQuery } from '../baseQuery';

const recordsApi = createApi({
  reducerPath: 'recordsApi',
  tagTypes: ['Records', 'Record'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getRecords: build.query<RecordBulk, GetFilterParamsNew>({
      query: (body) => ({
        url: serverRoutes.recordList,
        body,
        method: 'POST',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.records.map(({ record_id }) => ({
                type: 'Records' as const,
                record_id,
              })),
              'Records',
            ]
          : ['Records'],
    }),
    // TODO change response type
    getRecord: build.query<RecordsItems, { id: string }>({
      query: (params) => ({
        url: serverRoutes.record,
        params,
      }),
    }),
    /* Удаление записей */
    deleteRecords: build.mutation<RecordsItems, { items: Array<string> }>({
      query: (body) => ({
        url: serverRoutes.record,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Records'],
    }),
  }),
});

export const {
  useGetRecordsQuery,
  useLazyGetRecordsQuery,
  useGetRecordQuery,
  useDeleteRecordsMutation,
} = recordsApi;
export default recordsApi;
