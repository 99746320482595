import React, { FC, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import classNames from 'classnames';
import styles from '@app/pages/analytics/project-settings/analytics-main-settings/styles.module.scss';
import Icon from '@app/components/ui/icons';
import {
  Button,
  CircleButton,
  EasyModal,
  Input,
  Loading,
  PermissionAccessWrapper,
  Select,
} from '@ui';
import {
  AllStatusProject,
  CreateProjectAnalyticsParams,
  GroupSharedStatus,
  ProjectItem,
} from '@app/interfaces/analytics';
import { userPermissions } from '@app/utils/user-permissions';
import { Controller, useForm } from 'react-hook-form';
import useTranslation from '@app/hooks/use-translation';
import { AutoFocusHook } from '@app/utils/helpers';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useNavigate } from 'react-router-dom';
import {
  useCreateSingleAnalyticProjectMutation,
  useEditSingleAnalyticProjectMutation,
  useGetAnalyticsProjectsListQuery,
} from '@app/store/reducers/analytics-projects.api';
import { OptionItem } from '@app/components/ui/select/select.type';
import { useGetExtAndUsrModelsQuery } from '@app/store/reducers/asr_model.api';
type MainSettingsPropsType = {
  newProjFlag?: boolean;
  changeStatusFunctions?: {
    REMOVING: () => void;
  };
};
const MainSettings: FC<MainSettingsPropsType> = (props) => {
  const { newProjFlag = false, changeStatusFunctions } = props;
  const { t } = useTranslation('pages.allPagesHintTitles');
  const { t: ta } = useTranslation('pages.analytics');
  const { t: ts } = useTranslation('common.sharedStatus');
  const { t: form } = useTranslation('common.forms');
  const inputFocusRef = AutoFocusHook();
  const navigate = useNavigate();
  const projectData = useOutletContext<AnalyticPageOutlet | undefined>();
  //useState
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  //useState
  //api
  const { data: analyticGroups, isLoading: analyticsGroupsLoading } =
    useGetAnalyticsProjectsListQuery();
  const { data: models, isLoading: modelsLoading } = useGetExtAndUsrModelsQuery();
  const [createAnalyticsProject, { isLoading: createLoading }] =
    useCreateSingleAnalyticProjectMutation();
  const [editAnalyticsProject, { isLoading: editLoading }] = useEditSingleAnalyticProjectMutation();
  //api
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    control,
    clearErrors,
  } = useForm<Omit<CreateProjectAnalyticsParams, 'id'>>({
    defaultValues: {
      name: projectData?.project?.name,
      project_group_id: projectData?.project?.project_group_id,
      shared: projectData?.project?.shared,
      description: ' ',
      asr_model_id: projectData?.project?.asr_model_id,
    },
  });
  //effects
  useEffect(() => {
    if (!newProjFlag) return;
    if (!models) return;
    setValue('asr_model_id', models?.find((item) => item.default)?.model_id || '');
  }, [models, newProjFlag, setValue]);
  //effects

  //handlers
  function onSubmit(data) {
    !newProjFlag
      ? projectData?.project?.project_id &&
        editAnalyticsProject({ body: data, params: { id: projectData?.project?.project_id } }).then(
          () => {
            navigate(`/${navigationRoutes.analytics}`);
          },
        )
      : createAnalyticsProject(data).then((data) => {
          const { data: projectData } = data as { data: ProjectItem };
          navigate(`/${navigationRoutes.analytics}/${projectData.project_id}`);
        });
  }
  const disableStatusForSubmit = () => {
    if (createLoading) return true;
    if (editLoading) return true;
    if (!newProjFlag) {
      if (!projectData?.project?.owner && projectData?.project?.shared === GroupSharedStatus.VIEW)
        return true;
      if (projectData?.project?.status === AllStatusProject.PAUSE) {
        return false;
      }
      return projectData?.project?.status !== AllStatusProject.CREATED;
    }
  };
  const disableStatusForButton = () => {
    if (!newProjFlag) {
      return projectData?.project?.status !== 'PAUSE';
    } else return false;
  };
  const deleteProjectClick = () => {
    if (newProjFlag) {
      navigate(`/${navigationRoutes.analytics}`);
    } else {
      setDeleteModal(true);
    }
  };
  function getSelectOptions() {
    const newArr: OptionItem[] = [];
    const values = Object.values(GroupSharedStatus).filter((i) => isNaN(Number(i)));
    for (let i = 0; i < values.length; i++) {
      newArr.push({
        value: values[i],
        title: ts(values[i]),
      });
    }
    return newArr;
  }
  const getModelNameById = (id: string) => {
    return models?.find((item) => item.model_id === id)?.name || 'N/A';
  };
  function onSuccessModelChange() {
    setModalVisible(false);
  }
  function deleteModalApply() {
    changeStatusFunctions && changeStatusFunctions.REMOVING();
  }
  //handlers
  if (analyticsGroupsLoading || modelsLoading) return <Loading />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ minHeight: '594px', display: 'flex', flexDirection: 'column' }}>
          <div className={classNames(styles.main)}>
            <div className={classNames(styles.folderIcon)}>
              <Icon size={70} className="text-speech_analitics" name={'FolderOpenIcon'} />
            </div>
            <div className={classNames(styles.content)}>
              <h4 className="mb-[27px] text-0color text-[18px] leading-[21px]">
                <b>{ta('settings.main_settings')}</b>
              </h4>
              <div className="flex items-center mb-[23px] w-full">
                <div className="flex items-center gap-[61px]">
                  <span className="w-full">{ta('settings.proj_name')}</span>
                  <div className="min-w-[346px]">
                    <Input
                      {...inputFocusRef}
                      {...register('name', {
                        required: { value: true, message: form('required') },
                      })}
                      disabled={
                        !projectData?.project?.owner &&
                        projectData?.project?.shared === GroupSharedStatus.VIEW
                      }
                      error={errors.name?.message}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '23px' }}>
                <label className={classNames(styles.first, 'text-gray-700')} htmlFor="group">
                  {ta('settings.group_proj')}
                </label>
                <div className="w-[346px]">
                  <Controller
                    render={() => (
                      <Select
                        disable={
                          !projectData?.project?.owner &&
                          projectData?.project?.shared === GroupSharedStatus.VIEW
                        }
                        error={errors.project_group_id?.message}
                        dropHeight={127}
                        dropWidth={342}
                        options={
                          analyticGroups?.map((e) => {
                            return { title: e.name, value: e.project_group_id };
                          }) || []
                        }
                        defaultValue={watch('project_group_id')}
                        onChange={(value) => {
                          setValue('project_group_id', String(value));
                          clearErrors('project_group_id');
                        }}
                        placeholder={newProjFlag ? ta('settings.choose') + '...' : undefined}
                      />
                    )}
                    name={'project_group_id'}
                    control={control}
                    rules={{ validate: (value) => !!value || form('required') }}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label className={classNames(styles.four, 'text-gray-700')} htmlFor="group">
                  {ta('settings.proj_shared')}
                </label>
                <div className="w-[346px]">
                  <Controller
                    render={() => (
                      <Select
                        disable={!newProjFlag ? !projectData?.project?.owner : false}
                        dropHeight={127}
                        dropWidth={342}
                        error={errors.shared?.message}
                        options={getSelectOptions()}
                        defaultValue={watch('shared')}
                        onChange={(item) => {
                          setValue('shared', String(item) as GroupSharedStatus);
                          clearErrors('shared');
                        }}
                        placeholder={newProjFlag ? ta('settings.choose') + '...' : undefined}
                      />
                    )}
                    name={'shared'}
                    control={control}
                    rules={{ validate: (value) => !!value || form('required') }}
                  />
                </div>
              </div>

              <PermissionAccessWrapper
                tag={userPermissions.display.displayAPModel}
                permissions={'display'}
              >
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '23px' }}>
                  <label className={classNames(styles.third, 'text-gray-700')} htmlFor="model">
                    {ta('settings.model')}
                  </label>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <span>{getModelNameById(watch('asr_model_id'))}</span>
                    {!(
                      !projectData?.project?.owner &&
                      projectData?.project?.shared === GroupSharedStatus.VIEW
                    ) && (
                      <div className="flex items-center gap-[10px]">
                        <CircleButton
                          hintTitle={t('choose_model')}
                          size={16}
                          className="text-action w-[30px] mt-[6px]"
                          icon="EditIcon"
                          onClick={() => setModalVisible(true)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </PermissionAccessWrapper>
            </div>
          </div>
          <div className={classNames(styles.footer)}>
            <div className="flex items-center justify-between h-full py-6 px-6">
              <div className="flex gap-[20px]">
                <Button
                  disabled={disableStatusForSubmit()}
                  type="submit"
                  label={newProjFlag ? ta('settings.create') : ta('settings.save')}
                />
              </div>
              <div>
                <Button
                  disabled={disableStatusForButton()}
                  variant="danger"
                  fill="outlined"
                  icon="TrashIcon"
                  onClick={() => deleteProjectClick()}
                  label={ta('settings.delete')}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      {/*modals  */}
      {modalVisible && (
        <EasyModal
          variant={'medium484'}
          label={<div className="m-[0px_auto] text-center">{ta('settings.model_list')}</div>}
          onSuccess={() => onSuccessModelChange()}
          show={modalVisible}
          isAutoClosable={false}
          onClose={() => {
            setModalVisible(false);
            setValue('asr_model_id', projectData?.project?.asr_model_id || '');
          }}
        >
          <div className="w-[340px] m-[0px_auto] pt-[10px] pb-[150px]">
            <Select
              dropHeight={100}
              dropWidth={336}
              options={[
                ...(models?.map((item) => ({ title: item.name, value: item.model_id })) || []),
              ]}
              onChange={(value) => setValue('asr_model_id', String(value))}
              defaultValue={watch('asr_model_id')}
            />
          </div>
        </EasyModal>
      )}
      <EasyModal
        variant={'removal'}
        show={deleteModal}
        onClose={setDeleteModal.bind(null, false)}
        label={`${ta('delete_proj')} "${projectData?.project?.name}"`}
        onRemove={deleteModalApply}
      >
        <p className="px-[15px]">{ta('delete_proj_confirm')}</p>
      </EasyModal>
      {/*modals  */}
    </>
  );
};

export default MainSettings;
