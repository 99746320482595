import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { CalendarIcon } from '@icons';
import { CircleButton, Modal, Paginator, PermissionAccessWrapper, RubberTable } from '@ui';
import { StatusLabel } from '@app/components/';
import { TableItem } from '@app/components/ui/table/table.type';
import useTranslation from '@app/hooks/use-translation';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { StatusVariant } from '@app/components/status-label/status-label';
import { getFrontendDate } from '@app/utils/helpers';
import connector, { PropsFromRedux } from '@app/utils/store';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';

import { useLocation } from 'react-router';

import { GroupSharedStatus, ProjectItem } from '@app/interfaces/analytics';

import { TableColumn } from '@app/components/ui/rubber-table/table.types';

import { downloadCsvReport } from '@app/api/downloadCsvFileReport';

import { userPermissions } from '@app/utils/user-permissions';

import { toast } from 'react-toastify';

import { reportStatusPropertiesData } from '../data/report-status-properties.data';

const DEFAULT_REPORT_OFFSET = 0;
const DEFAULT_REPORT_LIMIT = 10;

const ReportsTableBlock: FC<
  { currentProjectId?: string; projectAp?: ProjectItem | null } & PropsFromRedux
> = (props) => {
  const { reportsState, liveReportsRequest, removeLiveReportRequest, currentProjectId } = props;
  const { liveReports, liveReportTotal, isPending } = reportsState;
  const { t } = useTranslation('pages.reports');
  const { t: ti } = useTranslation('pages.allPagesHintTitles');
  const [reportsTableLimit, changeReportsTableLimit] = useState<number>(
    Number(localStorage.getItem('reportsPageLimit')) || DEFAULT_REPORT_LIMIT,
  );
  const [reportTableOffset, changeReportsTableOffset] = useState<number>(DEFAULT_REPORT_OFFSET);
  const [reportTableOrderBy, changeReportTableOrderBy] = useState<
    { [x: string]: 1 | -1 } | undefined
  >();
  const tableSortKeyList = useMemo(
    () => ({
      dt_add: 'date',
    }),
    [],
  );
  const [selectedIdAndShowRemoveModal, selectIdAndShowRemoveModal] = useState<string | null>(null);
  const project = useSearchParams()[0].get('project');
  const selectedAndSort = useCallback(() => {
    const projectIsSelected = currentProjectId
      ? { project_id: currentProjectId }
      : project
      ? { project_id: project }
      : {};
    const sort =
      reportTableOrderBy && Object.keys(reportTableOrderBy).length
        ? {
            sortBy: tableSortKeyList[Object.keys(reportTableOrderBy)[0]],
            sortDesc: 0 < Object.values(reportTableOrderBy)[0],
          }
        : {};
    return {
      projectIsSelected,
      sort,
    };
  }, [currentProjectId, project, reportTableOrderBy, tableSortKeyList]);
  useEffect(() => {
    const { projectIsSelected, sort } = selectedAndSort();
    liveReportsRequest({
      offset: reportTableOffset,
      limit: reportsTableLimit,
      ...sort,
      ...projectIsSelected,
    });
  }, [
    liveReportsRequest,
    project,
    reportTableOffset,
    reportTableOrderBy,
    reportsTableLimit,
    selectedAndSort,
    tableSortKeyList,
  ]);

  const tableReportColumns: Array<TableColumn> = [
    {
      title: t('table.report_name'),
      index: 'name',
      // size: 300,
      // truncate: true,
      className: 'font-[700] hover:text-active',
    },
    { title: t('table.analytic_project'), index: 'project_name' },
    {
      title: t('table.status'),
      index: 'status',
      maxWidth: 120,
      tag: userPermissions.display.displayRepStatusCol,
    },
    { title: t('table.date_created'), index: 'dt_add', filter: true, maxWidth: 210 },
    //TODO add then need it { title: t('table.date_next_update'), index: 'dt_next_update', size: 181 },
    {
      title: t('table.actions'),
      index: 'actions',
      maxWidth: 100,
    },
    { title: '', index: 'utils', maxWidth: 130, divider: 'left' },
  ];
  const location = useLocation();
  const onCicleButtonLinkClick = () => {
    if (location.pathname.slice(1) !== 'reports') {
      localStorage.setItem('SELECTED_REPORT_PROJECT_ID', location.pathname);
    } else {
      if (!location.search) {
        localStorage.removeItem('SELECTED_REPORT_PROJECT_ID');
      }
    }
  };
  function disableDeleteButton(project) {
    if (project.project.owner) {
      return false;
    } else return project.project.shared !== GroupSharedStatus.EDIT;
  }
  function downloadCsv(live_report_id: string, name: string) {
    downloadCsvReport(live_report_id)
      .then((data) => {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // add here
        const blob = new Blob([bom, data.data], { type: 'octet/stream' }), // add bom
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${name}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => toast.error('downloadError'));
  }
  const currentDataTable = liveReports.map((report) => ({
    ...report,
    name: (
      <Link
        onClick={() => onCicleButtonLinkClick()}
        className="hover:text-action transition duration-75"
        to={`/${navigationRoutes.reports}/${report.live_report_id}`}
      >
        {report.name}
      </Link>
    ),
    project_name: report.project.project_name,
    dt_add: (
      <div className="inline-flex items-center">
        <CalendarIcon size={20} className="text-3color ml-[0px] mr-[9px]" />
        {getFrontendDate(report.dt_add, 'dd.MM.yyyy HH:mm')}
      </div>
    ),
    filter: '',
    status: (
      <PermissionAccessWrapper
        tag={userPermissions.display.displayRepStatusCol}
        permissions={'display'}
      >
        <div className="inline-flex items-center">
          <StatusLabel
            status={'LIFE' as StatusVariant}
            statusProperties={reportStatusPropertiesData}
            isDropLeft
          />
        </div>
      </PermissionAccessWrapper>
    ),
    actions: (
      <div className="inline-flex items-center">
        <CircleButton
          icon="ChartIcon"
          hintTitle={ti('reports.to_charts')}
          size={18}
          onClick={() => onCicleButtonLinkClick()}
          link={`/${navigationRoutes.reports}/${report.live_report_id}`}
          className="text-3color"
        />
        <CircleButton
          icon="TableIcon"
          hintTitle={ti('reports.to_records')}
          onClick={() => onCicleButtonLinkClick()}
          link={`/${navigationRoutes.reports}/${report.live_report_id}/${navigationRoutes.reportRecords}`}
          size={18}
          className="text-3color"
        />
      </div>
    ),
    utils: (
      <div className="inline-flex items-center">
        <CircleButton
          hintTitle={ti('reports.settings_icon')}
          icon="SettingIcon"
          onClick={() => onCicleButtonLinkClick()}
          link={`/${navigationRoutes.reports}/${report.live_report_id}/${navigationRoutes.settings}`}
          size={20}
          className="text-3color"
        />
        <CircleButton
          hintTitle={ti('reports.download')}
          icon="DownloadCloudIcon"
          size={20}
          onClick={() => downloadCsv(report.live_report_id, report.name)}
          className="text-3color"
        />
        <CircleButton
          hintTitle={ti('delete_icon')}
          disable={disableDeleteButton(report)}
          icon="TrashIcon"
          onClick={selectIdAndShowRemoveModal.bind(null, report.live_report_id)}
          size={18}
          className="text-basic_red"
        />
      </div>
    ),
  }));

  function handlePageChange({ offset, limit }: PaginationResponse) {
    if (reportsTableLimit !== limit) {
      changeReportsTableLimit(limit);
      localStorage.setItem('reportsPageLimit', limit.toString());
    } else if (reportTableOffset !== offset) changeReportsTableOffset(offset);
  }

  function handlerRemoveReport() {
    if (selectedIdAndShowRemoveModal) {
      const { projectIsSelected, sort } = selectedAndSort();
      removeLiveReportRequest({ id: selectedIdAndShowRemoveModal });
      selectIdAndShowRemoveModal(null);
      liveReportsRequest({
        offset: reportTableOffset,
        limit: reportsTableLimit,
        ...sort,
        ...projectIsSelected,
      });
    }
  }
  return (
    <div className="w-full">
      <div className={'flex flex-col gap-[20px]'}>
        <RubberTable
          onFilter={changeReportTableOrderBy}
          isEmpty={t('empty')}
          skeletons={currentDataTable.length}
          columns={tableReportColumns}
          dataSource={currentDataTable as unknown as TableItem[]}
          truncate={false}
          isPending={isPending}
        />
        {liveReports?.length ? (
          <Paginator
            page={Math.ceil(reportTableOffset / reportsTableLimit) + 1}
            visiblePages={1}
            count={liveReportTotal}
            limit={reportsTableLimit}
            onChange={handlePageChange}
          />
        ) : null}
      </div>
      <Modal
        id="remove_report"
        title={t('modal.remove_report')}
        value={!!selectedIdAndShowRemoveModal}
        setValue={selectIdAndShowRemoveModal.bind(null, null)}
        onApply={handlerRemoveReport}
        variant="removal"
        size="xs"
      >
        <div className="text-[14px] font-[500]">{t('modal.confirm_remove_report')}</div>
      </Modal>
    </div>
  );
};

export default connector(ReportsTableBlock);
