import { FilterItem, FilterItemNotOnlyArray } from '@app/interfaces/filter';
import { cloneDeep } from 'lodash';

export default function cloneFilterWithValues(cloneFilter: FilterItem[]) {
  const filter: FilterItemNotOnlyArray[] = [];

  cloneFilter.map((item) => {
    if (item.condition && item.id) {
      filter.push(cloneDeep(item));
    }
  });

  return filter
    .map((item) => {
      if (item.condition === 'exists') return item;
      if (Array.isArray(item.value)) {
        const filteredValue = item.value.filter((value) => value || value === 0);
        item.value =
          filteredValue.length === 0
            ? ''
            : filteredValue.length === 1
            ? filteredValue[0]
            : filteredValue;
      }
      return item;
    })
    .filter((item) => item.value || item.value === 0);
}
