import apiInstance from '@app/api/common.api';
import serverRoutes from '@app/utils/server-routes';
import { AxiosResponse } from 'axios';
import {
  GraphChartFullData,
  GraphSettingsDate,
  ReportChart,
  ReportChartResponse,
  ReportListChart,
  ReportTimeRange,
} from '@app/interfaces/report.type';
import { toast } from 'react-toastify';
import { FilterItem } from '@app/interfaces/filter';

// Запрос на редактирование настроек и подгрузку обновления визуализации
export const chartEditSettingsApi = async ({
  params,
  settings,
}: {
  params: { id };
  settings: { name: string; settings: GraphSettingsDate };
}) => {
  const { data } = await apiInstance.put(`${serverRoutes.chart}?id=${params.id}`, settings);
  return data;
};

// Запрос на смену range одного графика
export const chartUpdateRangeApi = async (id, range): Promise<ReportChart> => {
  const setup = await chartLoadSettings(id);
  await apiInstance.put(`${serverRoutes.chart}?id=${id}`, {
    name: setup.name,
    settings: { ...setup.settings, range },
  });
  return await chartLoadBuild(id);
};

// Запрос на смену range для всех графиков
export const chartCommonRangeUpdateApi = async ({
  liveReportId,
  ids,
  range,
}: {
  liveReportId: string;
  ids: Array<string>;
  range: ReportTimeRange;
}): Promise<Array<GraphChartFullData>> => {
  if (ids.length > 0) {
    for (let i = 0; i < ids.length; i++) {
      await chartUpdateRangeApi(ids[i], range);
    }
  }
  return await chartsLoadApi(liveReportId);
};

// Запрос на общие данные и визуализацию
export const chartsLoadApi = async (
  id,
  filter?: FilterItem[],
): Promise<Array<GraphChartFullData>> => {
  const resultOfRequestChart = await chartCommonInfoRequest(id);
  const buildCharts: Array<GraphChartFullData> = [];
  if (resultOfRequestChart.length < 1) return buildCharts;
  for (let i = 0; i < resultOfRequestChart.length; i++) {
    try {
      const resultOfBuild = await chartLoadBuild(resultOfRequestChart[i].graph_id, filter);
      buildCharts.push({
        ...resultOfRequestChart[i],
        live_report_id: id,
        build: { ...resultOfBuild, type: resultOfBuild.type || 'barchart' },
      });
    } catch (error) {
      toast.dismiss();
      buildCharts.push({
        ...resultOfRequestChart[i],
        live_report_id: id,
        build: { ...error.response.data.data, errorStatus: true },
      });
    }
  }
  return buildCharts;
};
// Запрос на выгрузку массива данных по графикам
export const chartCommonInfoRequest = async (live_report_id: string) => {
  return await apiInstance
    .get(`${serverRoutes.charts}?live_report_id=${live_report_id}`)
    .then((chartsCommonInfo: AxiosResponse<{ live_report_graph_list: Array<ReportListChart> }>) => {
      //TODO: remove mock layout object
      //return chartsCommonInfo.data.live_report_graph_list;
      return chartsCommonInfo.data.live_report_graph_list.map((graph) => {
        return { ...graph, layout: { x: 1, y: 1, w: 1, h: 1, minW: 1, maxW: 3, static: false } };
      });
    });
};

// Запрос на данные визуализации графика
export const chartLoadBuild = async (graphId, filter?: FilterItem[]) => {
  const filterItem = filter?.length && { filter: filter };
  return await apiInstance
    .post(serverRoutes.chartData, { id: graphId, ...filterItem })
    .then((chartInfo: AxiosResponse<ReportChartResponse>) => {
      return chartInfo.data.data;
    });
};
export const getChartLoadBuildData = async (
  graphId,
  range?: ReportTimeRange,
  filter?: FilterItem[],
) => {
  const filterItem = filter?.length && { filter: filter };
  return await apiInstance
    .post(serverRoutes.chartData, { id: graphId, range, ...filterItem })
    .then((chartInfo: AxiosResponse<ReportChartResponse>) => {
      return chartInfo.data.data;
    });
};

// Запрос на выгрузку настроек графика
export const chartLoadSettings = async (graphId) => {
  return await apiInstance
    .get(`${serverRoutes.chart}?id=${graphId}`)
    .then((chartInfo: AxiosResponse<GraphSettingsDate>) => {
      return chartInfo.data;
    });
};
