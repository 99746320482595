import React, { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { LiveReport } from '@app/interfaces/report.type';
import { NotFoundedPage } from '@app/pages';
import useTranslation from '@app/hooks/use-translation';
import connector, { PropsFromRedux } from '@app/utils/store';
import { SkeletonPageLoading } from '@ui';
import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

export type ReportOutletContext = {
  report: LiveReport;
  chartModalIsOpen: boolean;
  changeIsOpenChartModal: (status: boolean) => void;
  projectMetrics: Array<MetricListArrType>;
};

const ReportOutlet: FC<PropsFromRedux> = (props) => {
  const { reportsState, liveReportSettingsRequest, projectMetricListRequest, metricState } = props;
  const { liveReport, isPending } = reportsState;
  const { metricList } = metricState;
  const { t } = useTranslation('pages.reports');
  const { id } = useParams();
  const [chartModalIsOpen, changeIsOpenChartModal] = useState<boolean>(false);

  useEffect(() => {
    if (id) liveReportSettingsRequest({ id: id });
  }, [id, liveReportSettingsRequest]);

  useEffect(() => {
    liveReport &&
      projectMetricListRequest({
        project_id: liveReport.project.project_id,
      });
  }, [liveReport, projectMetricListRequest]);

  if (isPending) return <SkeletonPageLoading />;
  if (!liveReport && !isPending)
    return <NotFoundedPage text={t('not_found')} icon="BrokenDocIcon" />;

  return (
    <Outlet
      context={
        {
          projectMetrics: metricList,
          report: liveReport,
          chartModalIsOpen,
          changeIsOpenChartModal,
        } as unknown as ReportOutletContext
      }
    />
  );
};

export default connector(ReportOutlet);
