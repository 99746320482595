type ChildrenExpression = { name: string; value: string; children: unknown };
type BlockList = Array<{ name: string; value: string; children: Array<ChildrenExpression> }>;

export const replaceValues = {
  FORMULA: 'root',
  OP: 'operator',
  A: 'element_left',
  B: 'element_right',
  STATEMENT: 'value',
  VALUE_IF_TRUE: 'then',
  VALUE_IF_FALSE: 'else',
  METRIC_ID: 'metric_id',
  RIGHT: 'element_right',
  LEFT: 'element_left',
  EXPRESSION: 'if',
  OPERATOR: 'operator',
  formula_and_or: 'logic',
  NAME: 'operator',
  OPERATOR_INPUT_FIELD_1: 'operator',
  EXPRESSION_1: 'element_left',
  EXPRESSION_2: 'element_right',
};
export const replaceJsonKeys = {
  EXPRESSION_1: 'element_left',
  EXPRESSION_2: 'element_right',
  math_arithmetic: 'math',
  OP: 'operator',
  A: 'left',
  B: 'right',
  RIGHT: 'element_right',
  LEFT: 'element_left',
  formula_expression: 'const',
  formula_if: 'condition',
  formula_metric: 'metric',
  formula_relops: 'comparison',
  formula_mathops: 'math',
  formula_not: '',
  formula_and_or: 'logic',
  NAME: 'operator',
};
export const replaceMathValue = {
  AND: 'and',
  OR: 'or',
  ADD: '+',
  DIVIDE: '/',
  MULTIPLY: '*',
  POWER: '^',
  MINUS: '-',
  '&lt;=': '<=',
  '&gt;=': '>=',
  '&lt;': '<',
  '&gt;': '>',
};

export const replaceMathValueReverse = {
  and: 'AND',
  or: 'OR',
  '+': 'ADD',
  '/': 'DIVIDE',
  '*': 'MULTIPLY',
  '^': 'POWER',
  '-': 'MINUS',
  '<=': '&lt;=',
  '>=': '&gt;=',
  '<': '&lt;',
  '>': '&gt;',
};

export function decodeMetricToXml(metricObj: any): Array<string> {
  if (Object.keys(metricObj).length < 2) {
    const xml: Array<string> = [];
    Object.keys(metricObj).forEach((metricCase) => {
      switch (metricCase) {
        case 'logic': {
          return xml.push(`<block type="formula_and_or">
            <field name="NAME">${metricObj[metricCase]['operator'].toUpperCase()}</field>
            <value name="EXPRESSION_1">${decodeMetricToXml(
              metricObj[metricCase]['element_left'],
            )}</value>
            <value name="EXPRESSION_2">${decodeMetricToXml(
              metricObj[metricCase]['element_right'],
            )}</value>
            </block>`);
        }
        case 'math': {
          let mathOperatorIndex = 0;
          let mathElementIndex = -1;
          return xml.push(`
        <block type="formula_mathops">
          <mutation count="${Math.ceil(metricObj[metricCase].length / 2)}"></mutation>
          ${metricObj[metricCase]
            .map((metricMath) => {
              // eslint-disable-next-line no-prototype-builtins
              if (metricMath?.hasOwnProperty('element')) {
                ++mathElementIndex;
                const valueName =
                  mathElementIndex === 0 ? 'LEFT' : 'RIGHT_INPUT_' + mathElementIndex;
                return `<value name="${valueName}">${decodeMetricToXml(
                  metricMath.element,
                )}</value>`;
              }
              // eslint-disable-next-line no-prototype-builtins
              if (metricMath?.hasOwnProperty('operator')) {
                mathOperatorIndex++;
                return `<field name="OPERATOR_INPUT_FIELD_${mathOperatorIndex}">${metricMath.operator}</field>`;
              }
            })
            .join(' ')}
        </block>`);
        }
        case 'const': {
          return xml.push(`
          <block type="formula_expression">
          <field name="STATEMENT">${metricObj[metricCase]?.value || 0}</field>
          </block>`);
        }
        case 'comparison':
          return xml.push(`<block type="formula_relops">
          <field name="OPERATOR">${
            replaceMathValueReverse[metricObj[metricCase]['operator']]
          }</field>
          <value name="LEFT">${decodeMetricToXml(metricObj[metricCase]['element_left'])}</value>
          <value name="RIGHT">${decodeMetricToXml(metricObj[metricCase]['element_right'])}</value>
        </block>`);
        case 'condition':
          return xml.push(`
      <block type="formula_if">
        <value name="EXPRESSION">${decodeMetricToXml(metricObj[metricCase]['if'])}</value>
        <value name="VALUE_IF_TRUE">${decodeMetricToXml(metricObj[metricCase]['then'])}</value>
        <value name="VALUE_IF_FALSE">${decodeMetricToXml(metricObj[metricCase]['else'])}</value>
      </block>`);
        case 'metric':
          return xml.push(`
          <block type="formula_metric">
            <field name="METRIC_ID">${decodeMetricToXml(metricObj[metricCase]['metric_id'])}</field>
          </block>
          `);
      }
    });
    return xml;
  } else {
    return metricObj;
  }
}

const verifyRuleOfConditions: { [x: string]: Array<string> } = {
  root: ['math', 'condition', 'const', 'element'],
  element: ['condition', 'const'],
  element_right: ['metric', 'const', 'condition'],
  element_left: ['metric', 'const', 'condition'],
  comparison: ['metric', 'const', 'math'],
  if: ['comparison', 'logic'],
  then: ['math', 'const', 'condition', 'metric'],
  else: ['math', 'const', 'condition', 'metric'],
};

export function checkErrorOnFormula(formula, verifyBoolean = true): boolean {
  if (typeof Object.values(formula)[0] === 'object') {
    let verifyLocalBoolean = true;
    Object.keys(formula).forEach((keyOfFormulaElement) => {
      if (keyOfFormulaElement in verifyRuleOfConditions) {
        verifyLocalBoolean =
          verifyBoolean &&
          verifyRuleOfConditions[keyOfFormulaElement].includes(
            Object.keys(formula[keyOfFormulaElement])[0],
          ) &&
          checkErrorOnFormula(formula[keyOfFormulaElement], verifyBoolean);
      } else {
        verifyLocalBoolean =
          verifyBoolean && checkErrorOnFormula(formula[keyOfFormulaElement], verifyBoolean);
      }
    });
    return verifyLocalBoolean;
  } else {
    return verifyBoolean;
  }
}

function mathBlockBuild(math: BlockList) {
  const mathOperator: Array<string> = [];
  const mathExpression: Array<ChildrenExpression> = [];
  const mergedLists: Array<{ element: ChildrenExpression } | { operator: string }> = [];
  math.forEach((mathItem) => {
    mathItem.name === 'field' && mathOperator.push(mathItem.value);
    mathItem.name === 'value' &&
      mathExpression.push(buildingJsonFromMetricFormula(mathItem, 'formula_mathops'));
  });
  return mathExpression.reduce<typeof mergedLists>((mergedArray, currentExpression, index) => {
    mergedArray.push({ element: currentExpression });
    if (mathOperator[index]) mergedArray.push({ operator: mathOperator[index] });
    return mergedArray;
  }, []);
}

export const buildingJsonFromMetricFormula = (formula, lastKey): any => {
  // // eslint-disable-next-line no-debugger
  // debugger;
  if (formula) {
    if (formula?.children.length > 0) {
      const childArray: {
        math?: Array<{ element: ChildrenExpression } | { operator: string }> | string;
        value?: { value: string } | string;
        metric_id?: { metric_id: string } | string;
        operator?: { operator: string } | string;
      } = {};
      for (const children of formula.children) {
        if (children.name === 'mutation') continue;
        switch (children.attributes.type) {
          // case 'formula_and_or': {
          //   childArray['logic'] = andOrLoginBuild(children.children as unknown as BlockList);
          //   break;
          // }
          case 'formula_mathops': {
            childArray['math'] = mathBlockBuild(children.children as unknown as BlockList);
            break;
          }
          default:
            childArray[
              replaceJsonKeys[children.attributes.type] || replaceValues[children.attributes.name]
            ] = buildingJsonFromMetricFormula(
              children,
              replaceJsonKeys[children.attributes.type] || lastKey,
            );
        }
      }
      if (childArray?.value) childArray['value'] = childArray['value']['value'];
      if (childArray?.metric_id) childArray['metric_id'] = childArray['metric_id']['metric_id'];
      if (childArray?.operator) childArray['operator'] = childArray['operator']['operator'];
      return childArray;
    } else {
      const childArray = {};
      childArray[
        replaceJsonKeys[formula.attributes.type] || replaceValues[formula.attributes.name]
      ] = String(replaceMathValue[formula.value] || formula.value)
        .replace('&gt;', '>')
        .replace('&lt;', '<');
      return childArray;
    }
  }
};
