import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/pages/layout';
import {
  ReportChartPage,
  ReportsPage,
  ReportOutlet,
  ReportRecordsPage,
  ReportSettingsPage,
  ReportRecordPage,
} from '@app/pages';
import RequiredAuth from '@app/routes/required-auth.route';

export const reportsRoute = (
  <Route
    path={navigationRoutes.reports}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.reports} crumb="reports" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <ReportsPage />
        </RequiredAuth>
      }
    />
    <Route
      path=":id"
      element={<ReportOutlet />}
      handle={{
        crumb: () => <BreadcrumbsItem path={navigationRoutes.reports} crumb="report" />,
      }}
    >
      <Route
        index
        element={
          <RequiredAuth>
            <ReportChartPage />
          </RequiredAuth>
        }
      />
      <Route
        path={navigationRoutes.settings}
        element={<ReportSettingsPage />}
        handle={{
          crumb: () => <BreadcrumbsItem path={navigationRoutes.reports} crumb="report_settings" />,
        }}
      />
      <Route
        path={navigationRoutes.reportRecords}
        handle={{
          crumb: () => (
            <BreadcrumbsItem
              path={`${navigationRoutes.reports}/${navigationRoutes.reportRecords}`}
              crumb="report_list"
            />
          ),
        }}
      >
        <Route
          index
          element={
            <RequiredAuth>
              <ReportRecordsPage />
            </RequiredAuth>
          }
        />
        <Route
          path=":analytics_id/:record_id"
          handle={{
            crumb: () => <BreadcrumbsItem path={navigationRoutes.reports} crumb="recordsId" />,
          }}
          element={
            <RequiredAuth>
              <ReportRecordPage />
            </RequiredAuth>
          }
        />
      </Route>
    </Route>
  </Route>
);
