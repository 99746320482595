import { Button, EasyModal, Loading } from '@app/components/ui';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import AccordionUI from '@app/components/ui/Accordion/Aссordion';
import { ColsWithoutTable } from '@app/components/ui/rubber-table/blocks/cols-without-table';
import React, { FC, useCallback, useState } from 'react';

import {
  useAddAliasMutation,
  useDeleteAliasMutation,
  useEditAliasMutation,
  useGetAliasesListQuery,
  useLazyGetAliasQuery,
} from '@app/store/reducers/alias-settings-page.api';

import { AliasSettingsForCreate, AliasSettingsObj } from '@app/interfaces/settings.type';

import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';

import useTranslation from '@app/hooks/use-translation';

import { toast } from 'react-toastify';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { AliasSettingsTable } from './blocks/alias-settings/alias-settings-table';
import { AccordionTitle } from './blocks/alias-settings/accordion-title';
import { ModalWithFormAliasSettings } from './blocks/alias-settings/modal-with-form';

const AliasSettings: FC = () => {
  const { t } = useTranslation('pages.settings');
  const { data, isLoading } = useGetAliasesListQuery();
  const [addAlias] = useAddAliasMutation();
  const [editAlias] = useEditAliasMutation();
  const [deleteAlias] = useDeleteAliasMutation();
  const [getAlias] = useLazyGetAliasQuery();
  const [aliasData, changeAliasData] = useState<AliasSettingsObj>();
  const [isOpenAliasModal, changeIsOpenAliasModal] = useState(false);
  const [editMode, changeEditMode] = useState(false);
  const [idForDeleteAndEdit, changeIdForDeleteAndEdit] = useState<string>();
  const [isOpenDeleteModal, changeOpenDeleteModal] = useState(false);
  const [activeAccordionItem, setActiveAccordionItem] = useState<Array<string | undefined>>(
    localStorage.getItem('alias_accordion')?.split(',') || [],
  );
  const getAliasesData = useCallback(() => {
    return data?.aliases.reduce((r, a) => {
      r[a.type_meta] = r[a.type_meta] || [];
      r[a.type_meta].push(a);
      return r;
    }, {}) as { [x: string]: AliasSettingsObj[] };
  }, [data]);

  React.useEffect(() => {
    if (!getAliasesData()) return;
    const accordionListFromStorage = localStorage.getItem('alias_accordion');
    if (!accordionListFromStorage) {
      const defaultGroup = Object.entries(getAliasesData())?.[0][0];
      setActiveAccordionItem([defaultGroup]);
      defaultGroup && localStorage.setItem('alias_accordion', defaultGroup);
    }
  }, [getAliasesData]);

  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    setActiveAccordionItem((prev) => {
      if (!prev.includes(event.id)) {
        localStorage.setItem('alias_accordion', [...prev, event.id].join(','));
        return [...prev, event.id];
      } else {
        const accordionListFromStorage = localStorage.getItem('alias_accordion')?.split(',');
        const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
          (item) => item !== event.id,
        ) as string[];
        localStorage.setItem('alias_accordion', filteredAccordionListFromStorage.join(','));

        return prev.filter((item) => item !== event.id);
      }
    });
  };
  function resetModalState() {
    changeIsOpenAliasModal(false);
  }
  function onFormSubmitHandler(data: AliasSettingsForCreate) {
    editMode && idForDeleteAndEdit
      ? editAlias({ body: data, id: idForDeleteAndEdit }).then(resetModalState, resetModalState)
      : addAlias(data).then(resetModalState, resetModalState);
  }
  function onEditAliasHandler(id: string) {
    getAlias(id).then((data) => {
      changeIdForDeleteAndEdit(id);
      changeAliasData(data.data);
      changeEditMode(true);
      changeIsOpenAliasModal(true);
    });
  }
  function onDeleteAliasHandler(id: string) {
    changeIdForDeleteAndEdit(id);
    changeOpenDeleteModal(true);
  }
  function deleteAliasHandle(id: string) {
    deleteAlias(id).then((data) => {
      const { error } = data as { error: FetchBaseQueryError };
      const errorStatus = error.status;
      errorStatus === 403 && toast.error(t('alias.delete_alias_text_alias_is_using'));
    });
    resetModalState();
  }
  const columns = [
    {
      title: 'Название',
      index: 'name',
    },
    {
      title: 'Название метаданного',
      index: 'meta_name',
    },
    {
      title: 'Тип значения',
      index: 'type_value',
    },
    {
      title: 'ТИп метаданного',
      index: 'type_meta',
    },
    {
      title: 'Действия',
      index: 'utils',
      maxWidth: 110,
    },
  ];
  const renderModal = () => {
    if (!isOpenAliasModal) return;
    return (
      <ModalWithFormAliasSettings
        deleteAliasHandle={deleteAliasHandle}
        aliasData={aliasData}
        editMode={editMode}
        changeEditMode={changeEditMode}
        isOpenAliasModal={isOpenAliasModal}
        changeIsOpenAliasModal={changeIsOpenAliasModal}
        onFormSubmitHandler={onFormSubmitHandler}
      />
    );
  };
  if (isLoading) return <Loading />;
  return (
    <div>
      <div className="py-[30px] flex items-center justify-end">
        <Button
          onClick={changeIsOpenAliasModal.bind(null, true)}
          icon="CirclePlusIcon"
          label="Добавить алиас"
        />
      </div>
      <div className="pb-[15px]">
        <ColsWithoutTable columns={columns} />
        <AccordionUI>
          {Object.entries(getAliasesData())?.map((aliasItem) => {
            return (
              <AccordionItemUI
                variant="filled"
                key={aliasItem[0]}
                id={aliasItem[0]}
                title={
                  <AccordionTitle
                    name={aliasItem[0]}
                    active={activeAccordionItem?.includes(aliasItem[0])}
                    count={aliasItem[1].length}
                  />
                }
                clickBtnChild={clickAccordionHeaderBtn}
                active={activeAccordionItem?.includes(aliasItem[0])}
              >
                {activeAccordionItem?.includes(aliasItem[0]) && (
                  <AliasSettingsTable
                    onEditAliasHandler={onEditAliasHandler}
                    onDeleteAliasHandler={onDeleteAliasHandler}
                    data={aliasItem[1]}
                    cols={columns}
                  />
                )}
              </AccordionItemUI>
            );
          })}
        </AccordionUI>
      </div>
      {renderModal()}
      <EasyModal
        withoutFooter
        onClose={changeOpenDeleteModal.bind(null, false)}
        label={t('warning')}
        show={isOpenDeleteModal}
        variant="removal"
        onRemove={() => idForDeleteAndEdit && deleteAliasHandle(idForDeleteAndEdit)}
      >
        <div className="flex items-center justify-center">{t('alias.delete_alias_text')}</div>
      </EasyModal>
    </div>
  );
};
export default AliasSettings;
