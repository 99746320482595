import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { NavigationTabs } from '@ui';
import { navigationData } from '@app/pages/analytics/blocks/navigation.data';

import useTranslation from '@app/hooks/use-translation';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';

import ReportsTableBlock from './blocks/reports-table.block';

const ProjectReportsPage = () => {
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const { t } = useTranslation('components.navigateTabs');
  const data: NavigationTabsItem[] = navigationData(project?.project_id).map((item) => ({
    ...item,
    label: t(item.label),
  }));
  return (
    <div>
      <NavigationTabs items={data} />
      <ReportsTableBlock projectAp={project} currentProjectId={project?.project_id} />
    </div>
  );
};

export default ProjectReportsPage;
