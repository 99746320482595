import {
  createMetricTemplateGroup,
  deleteMetricTemplatesGroups,
  deleteTemplate,
  getAllMetricTemplates,
  getMetricTemplateSettings,
} from '@app/api/metric-templates.api';
import { MetricObjType } from '@app/interfaces/pages-types/anatylics-metric.type';
import { MetricTemplatesGroupType } from '@app/interfaces/slices-types/metric-templates.type';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  metricTemplates: MetricTemplatesGroupType[];
  loading: boolean;
  metricTemplateSettings: MetricObjType | null;
};

const initialState: initialStateType = {
  metricTemplates: [],
  loading: false,
  metricTemplateSettings: null,
};

export const getMetricTemplatesThunks = {
  getAllMetricTemplatesThunk: createAsyncThunk(
    'MetricTemplates/getMetricTemplatesThunk',
    async (_, { rejectWithValue }) => {
      try {
        const response = await getAllMetricTemplates();
        return response.data.metric_templates_groups;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  getMetricTemplateSettingsThunk: createAsyncThunk(
    'MetricTemplates/getMetricTemplateSettingsThunk',
    async (id: string, { rejectWithValue }) => {
      try {
        const response = await getMetricTemplateSettings(id);
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  createMetricTemplateGroupThunk: createAsyncThunk(
    'MetricTemplates/createMetricTemplateGroupThunk',
    async (name: string, { rejectWithValue }) => {
      try {
        const response = await createMetricTemplateGroup(name);
        return response.data;
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  deleteMetricTemplateThunk: createAsyncThunk(
    'MetricTemplates/deleteMetricTemplateThunk',
    async (id: string, { rejectWithValue }) => {
      try {
        return await deleteTemplate(id).then(() => {
          return { id };
        });
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
  deleteMetricTemplateGroupThunk: createAsyncThunk(
    'MetricTemplates/deleteMetricTemplateGroupThunk',
    async (id: string, { rejectWithValue }) => {
      try {
        return await deleteMetricTemplatesGroups(id).then(() => {
          return { id };
        });
      } catch (err) {
        return rejectWithValue(`${err.response.status}`);
      }
    },
  ),
};

const MetricTemplates = createSlice({
  name: 'MetricTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMetricTemplatesThunks.getAllMetricTemplatesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMetricTemplatesThunks.getAllMetricTemplatesThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getMetricTemplatesThunks.getAllMetricTemplatesThunk.fulfilled,
      (state, action) => {
        state.metricTemplates = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(getMetricTemplatesThunks.getMetricTemplateSettingsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMetricTemplatesThunks.getMetricTemplateSettingsThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getMetricTemplatesThunks.getMetricTemplateSettingsThunk.fulfilled,
      (state, action) => {
        state.metricTemplateSettings = action.payload;
        state.loading = false;
      },
    );
    builder.addCase(
      getMetricTemplatesThunks.createMetricTemplateGroupThunk.fulfilled,
      (state, action) => {
        state.metricTemplates = state.metricTemplates.concat(action.payload);
      },
    );
    builder.addCase(
      getMetricTemplatesThunks.deleteMetricTemplateGroupThunk.fulfilled,
      (state, action) => {
        state.metricTemplates = state.metricTemplates.filter(
          (item) => item.metric_templates_group_id !== action.payload.id,
        );
      },
    );
    builder.addCase(
      getMetricTemplatesThunks.deleteMetricTemplateThunk.fulfilled,
      (state, action) => {
        state.metricTemplates = state.metricTemplates.map((group) => {
          group.metric_templates.map((template, index) => {
            if (template.metric_template_id === action.payload.id)
              group.metric_templates.splice(index, 1);
          });
          return group;
        });
      },
    );
  },
});

export default MetricTemplates.reducer;
