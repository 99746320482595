import React, { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { FilterTableData, TableProps } from '@app/components/ui/table/table.type';
import { CircleButton, TooltipWrapper } from '@ui';
import {
  sortActiveClassName,
  tableWhiteDateCellClassName,
} from '@app/components/ui/table/table.style';
import CellWrapper from '@app/components/ui/table/cell-wrapper';
import TitleWrapper from '@app/components/ui/table/title-wrapper';
import { TableWrapperForColsAndData } from '@app/components/ui/table/table-wrapper-for-cols-and-data';

const DEFAULT_CELL_SIZE = 180;
/* Всегда больше длинны все слоев */
const Z_INDEX_TITLE_GROW = 2;
const NO_SCROLL_TABLE_LIMIT = 6;
const Table: FC<TableProps> = (props) => {
  const { onFilter } = props;
  const [filterItem, changeFilterItem] = useState<FilterTableData>();
  const fullScrollRef = useRef<HTMLDivElement | null>(null);
  const scrollHeadTableRef = useRef<HTMLDivElement | null>(null);
  const scrollBodyTableRef = useRef<HTMLDivElement | null>(null);
  const rangeRef = useRef<HTMLInputElement>(null);
  const { columns, dataSource } = props;
  const isScrollArea = columns.length < NO_SCROLL_TABLE_LIMIT;

  const columnsBuilder = columns.map((column, index) => (
    <TitleWrapper
      key={index}
      size={column.size || DEFAULT_CELL_SIZE}
      position={index === 0 ? 'first' : index === columns.length - 1 ? 'last' : undefined}
    >
      <div className="px-[4px] text-clip break-words">{column.title}</div>
      {column.filter && (
        <div className="inline-flex items-center  ml-[3px]">
          <CircleButton
            icon="ArrowUpIcon"
            size={7}
            onClick={changeFilterHandler.bind(null, { sortBy: column.index, sortDesc: false })}
            className={cn(
              sortActiveClassName(filterItem?.sortBy === column.index && !filterItem?.sortDesc),
              'ml-[3px]',
            )}
          />
          <CircleButton
            icon="ArrowDownIcon"
            size={7}
            onClick={changeFilterHandler.bind(null, { sortBy: column.index, sortDesc: true })}
            className={cn(
              sortActiveClassName(filterItem?.sortBy === column.index && filterItem?.sortDesc),
              'ml-[-8px]',
            )}
          />
        </div>
      )}
    </TitleWrapper>
  ));

  const dataBuilder = dataSource?.map((row, index) => {
    return columns.map((column, indexColumn) => {
      return (
        <CellWrapper
          key={`${index}_${indexColumn}`}
          size={column.size || DEFAULT_CELL_SIZE}
          position={
            indexColumn === 0 ? 'first' : indexColumn === columns.length - 1 ? 'last' : undefined
          }
        >
          <TooltipWrapper
            content={
              row[column.index] &&
              (typeof row[column.index] === 'string' || typeof row[column.index] === 'number')
                ? row[column.index]
                : column.hintTitle && String(column.hintTitle)
            }
            id={`${column.index}_${indexColumn}_${index}`}
          >
            <div className={tableWhiteDateCellClassName}>
              <div className={cn(column.truncate && 'truncate', 'px-[10px]')}>
                {row[column.index]}
              </div>
            </div>
          </TooltipWrapper>
        </CellWrapper>
      );
    });
  });
  function changeFilterHandler(params: FilterTableData) {
    changeFilterItem(params);
    onFilter?.(params);
  }
  function changeRangeHandler(value = 0, isScrollAction = false) {
    const newRangeValue = `${value}%`;
    if (
      fullScrollRef?.current &&
      fullScrollRef.current?.style.width !== newRangeValue &&
      scrollBodyTableRef?.current
    ) {
      fullScrollRef.current.style.width = newRangeValue;
      if (!isScrollAction) {
        scrollBodyTableRef.current.scrollLeft =
          ((scrollBodyTableRef.current?.scrollWidth - scrollBodyTableRef.current?.clientWidth) /
            100) *
          value;
      }
    }
  }

  function onEventScroll(scrollLeft: number) {
    if (scrollHeadTableRef.current && scrollBodyTableRef.current && fullScrollRef?.current) {
      switch (true) {
        case scrollLeft !== scrollHeadTableRef.current.scrollLeft:
          scrollHeadTableRef.current.scrollLeft = scrollLeft;
          break;
        case scrollLeft !== scrollBodyTableRef.current.scrollLeft:
          scrollBodyTableRef.current.scrollLeft = scrollLeft;
          break;
      }
      changeRangeHandler(
        Math.ceil(
          (scrollLeft /
            (scrollBodyTableRef.current?.scrollWidth - scrollBodyTableRef.current?.clientWidth)) *
            100,
        ),
        true,
      );
    }
  }
  useEffect(() => {
    const el = scrollBodyTableRef?.current;
    const secondEl = scrollHeadTableRef?.current;
    if (el && secondEl) {
      const onWheel = (e) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        secondEl.scrollTo({
          left: secondEl.scrollLeft + e.deltaY,
          behavior: 'auto',
        });
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'auto',
        });
      };
      el.addEventListener('wheel', onWheel);
      secondEl.addEventListener('wheel', onWheel);
      return () => {
        el.removeEventListener('wheel', onWheel);
        secondEl.removeEventListener('wheel', onWheel);
      };
    }
  }, [scrollBodyTableRef, scrollHeadTableRef]);
  return (
    <div className="relative z-0">
      <div className="sticky top-0 z-[999]">
        <div
          ref={scrollHeadTableRef}
          className="width-[100%] overflow-hidden overflow-x-scroll scrollHidden scroll-auto  top-0"
          onScroll={(event) => onEventScroll(event.currentTarget.scrollLeft)}
          style={{ zIndex: (dataSource?.length || 0) + Z_INDEX_TITLE_GROW }}
        >
          <TableWrapperForColsAndData columns={columns} isScrollArea={isScrollArea}>
            {columnsBuilder}
          </TableWrapperForColsAndData>
        </div>
      </div>
      {!isScrollArea && (
        <div
          className="sticky opacity-50 hover:opacity-100 transition"
          style={{
            top: `${scrollHeadTableRef.current?.clientHeight}px`,
            marginLeft: `${columns[0]?.size || DEFAULT_CELL_SIZE}px`,
            zIndex: (dataSource?.length || 0) + 1,
            width: `calc(100% - ${
              (columns[0]?.size || DEFAULT_CELL_SIZE) +
              (columns[columns.length - 1]?.size || DEFAULT_CELL_SIZE)
            }px)`,
          }}
        >
          <label
            htmlFor="table_scroll"
            className="relative w-full my-[10px] block rounded-full h-[6px] bg-[#CCC]"
          >
            <div
              className="bg-speech_analitics absolute top-[0px] rounded-lg left-0 h-[6px] z-0"
              ref={fullScrollRef}
            />
            <input
              ref={rangeRef}
              id="table_scroll"
              type="range"
              min={0}
              max={100}
              step={0.1}
              onChange={(event) => changeRangeHandler(Number(event.currentTarget.value))}
              className="tableScroll w-full absolute top-[0px] rounded-lg cursor-pointer  z-10"
            />
          </label>
        </div>
      )}
      <div
        ref={scrollBodyTableRef}
        className="width-[100%] overflow-hidden overflow-x-scroll scrollHidden scroll-auto"
        onScroll={(event) => onEventScroll(event.currentTarget.scrollLeft)}
      >
        {/*<div*/}
        {/*  className={cn('grid  gap-y-[5px] self-baseline')}*/}
        {/*  style={{*/}
        {/*    gridTemplateColumns: !isScrollArea*/}
        {/*      ? columns*/}
        {/*          .map(*/}
        {/*            (column) =>*/}
        {/*              `${*/}
        {/*                (column?.size ? column.size : DEFAULT_CELL_SIZE) +*/}
        {/*                (column.filter ? DEFAULT_FILTER_SIZE : 0)*/}
        {/*              }px`,*/}
        {/*          )*/}
        {/*          .join(' ')*/}
        {/*      : `repeat(${columns.length}, 1fr)`,*/}
        {/*  }}*/}
        {/*>*/}
        <TableWrapperForColsAndData columns={columns} isScrollArea={isScrollArea}>
          {dataBuilder}
        </TableWrapperForColsAndData>
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Table;
// import React, { FC, ReactNode, useRef, useState, UIEvent } from 'react';
// import { ArrowDownIcon, ArrowUpIcon } from '@app/components/ui/icons/icons-list';
// import cn from 'classnames';
//
// import TableSkeleton from '@app/components/ui/table/table-skeleton';
// import { Empty, TooltipWrapper } from '@app/components/ui';
//
// import { v4 } from 'uuid';
//
// import { columnTitleClassName, tableTitleFilterWrapperClassName } from './table.style';
// import { TableFilter, TableProps, TableTitle } from './table.type';
//
//const Table: FC<TableProps> = (props) => {
//   const {
//     columns,
//     dataSource,
//     isPending,
//     truncate = false,
//     onFilter,
//     isEmpty,
//     skeletons = 6,
//   } = props;
//   const [filterData, setFilterData] = useState<TableFilter>();
//   const bodyTableRef = useRef<HTMLDivElement>(null);
//   const titleTableRef = useRef<HTMLDivElement>(null);
//   const cellStyles = (size, cellHeight) => ({ minWidth: `${size}px`, height: cellHeight });
//   if (isPending) return <TableSkeleton count={skeletons} />;
//   if (dataSource && dataSource?.length < 1) return <Empty title={isEmpty} />;
//
//   function handlerResetFilters() {
//     setFilterData({});
//     onFilter?.({});
//   }
//   function handleOnFilter(titleIndex: string, isFilter: boolean | undefined) {
//     if (!isFilter) return;
//     if (filterData && filterData[titleIndex] === 1) {
//       handlerResetFilters();
//       return;
//     }
//     const updatedFilter: TableFilter = {
//       [titleIndex]: filterData?.[titleIndex] !== undefined ? 1 : -1,
//     };
//     setFilterData(updatedFilter);
//     onFilter?.(updatedFilter);
//   }
//
//   const table: Record<TableTitle['index'], Array<string | number | ReactNode>> = {};
//   dataSource?.forEach((source) => {
//     columns.forEach((column) => {
//       if (!table[column.index]) table[column.index] = [source[column.index]];
//       else table[column.index].push(source[column.index]);
//     });
//   });
//
//   function onEventScroll(event: UIEvent<HTMLDivElement>, scrollDiv: 'title' | 'body') {
//     if (titleTableRef.current !== null && bodyTableRef.current !== null) {
//       if (scrollDiv === 'body') titleTableRef.current.scrollLeft = event.currentTarget.scrollLeft;
//       if (scrollDiv === 'title') bodyTableRef.current.scrollLeft = event.currentTarget.scrollLeft;
//     }
//   }
//
//   const buildTableTitleItem = ({ filter, title, index, size = 100, right }: TableTitle) => (
//     <div
//       className={columnTitleClassName({
//         truncate,
//         right: false,
//       })}
//       style={{ minWidth: `${size}px` }}
//       onClick={() => handleOnFilter(index, filter)}
//       key={index}
//     >
//       <div className={tableTitleFilterWrapperClassName({ filter, right: right || false })}>
//         {title}
//         {filter && (
//           <div className="inline-flex w-full items-center ml-[5px]">
//             <ArrowUpIcon
//               size={8}
//               className={cn(filterData?.[index] === -1 ? 'text-action' : 'text-3color', 'ml-[3px]')}
//             />
//             <ArrowDownIcon
//               size={8}
//               className={cn(filterData?.[index] === 1 ? 'text-action' : 'text-3color')}
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
//
//   const renderTitles = (
//     <div
//       className="grid w-full mb-[12px]  bg-basic_app_bg"
//       style={{
//         gridTemplateColumns: `${columns[0]?.size || 100}px calc(100% - ${
//           (columns[0]?.size || 100) + (columns[columns.length - 1]?.size || 100)
//         }px) ${columns[columns.length - 1]?.size || 100}px`,
//       }}
//     >
//       <div
//         className="w-full inline-flex items-center"
//         style={{ width: `${columns[0]?.size || 100}px` }}
//       >
//         {columns[0] && buildTableTitleItem(columns[0])}
//       </div>
//       <div
//         ref={titleTableRef}
//         onScroll={(event) => onEventScroll(event, 'title')}
//         className="grid overflow-x-auto mediumScrollBar overflow-y-hidden mb-[-9px]"
//         style={{ gridTemplateColumns: `repeat(${columns.length - 2}, 1fr)` }}
//       >
//         {Object.keys(table)
//           .slice(1, columns.length - 1)
//           .map((columnName, index) => (
//             <div
//               key={index}
//               className={cn(
//                 'h-[80px] w-full inline-flex items-center overflow-hidden',
//                 columns[index + 1].right && 'justify-end',
//               )}
//               style={{ minWidth: `${columns[index + 1]?.size || 100}px` }}
//             >
//               {columns[index + 1] && buildTableTitleItem(columns[index + 1])}
//             </div>
//           ))}
//       </div>
//       <div
//         className="h-[80px] w-full inline-flex sticky top-0 items-center overflow-hidden"
//         style={{ width: `${columns[columns.length - 1]?.size || 100}px` }}
//       >
//         {columns[columns.length - 1] && buildTableTitleItem(columns[columns.length - 1])}
//       </div>
//     </div>
//   );
//
//   function renderTableColumn({
//     column,
//     position,
//     size = 100,
//     isRight = false,
//     divider,
//     className,
//     truncate = false,
//     overflow = true,
//     hintTitle,
//   }: {
//     column: Array<string | number | ReactNode>;
//     position?: 'left' | 'right' | undefined;
//     size: number;
//     isRight?: boolean;
//     divider: 'left' | 'right' | undefined;
//     last?: boolean;
//     className?: string;
//     truncate?: boolean;
//     overflow?: boolean;
//     hintTitle?: string;
//   }) {
//     return (
//       <div
//         className={cn('inline-flex flex-col gap-[2px] w-full', isRight && 'justify-end', className)}
//         style={{ minWidth: `${size}px` }}
//       >
//         {column.map((cell, indexColumn) => {
//           return (
//             <TooltipWrapper key={indexColumn} content={hintTitle} id={hintTitle + v4()}>
//               <div
//                 style={cellStyles(size, columns[0]?.cellHeight || 36)}
//                 className={cn(
//                   'relative',
//                   position === 'left' && 'rounded-l-defaultR',
//                   position === 'right' && 'rounded-r-defaultR',
//                   overflow && 'overflow-y-auto scrollHidden',
//                   'bg-white',
//                   'w-full',
//                   'shadow-sm',
//                   'px-[15px]',
//                 )}
//               >
//                 <div
//                   className={cn('h-full w-full inline-flex items-center', isRight && 'justify-end')}
//                 >
//                   {divider === 'left' && (
//                     <div>
//                       <div className="flex-1 h-[28px] w-[1px] bg-b_light" />
//                     </div>
//                   )}
//                   <div
//                     className={cn(
//                       typeof cell === 'string' && truncate && 'truncate',
//                       'text-[14px] font-400 leading-[30px]',
//                     )}
//                     title={typeof cell === 'string' ? cell : ''}
//                     style={{ maxWidth: `${size}px` }}
//                   >
//                     {cell}
//                   </div>
//                   {divider === 'right' && (
//                     <div>
//                       <div className="flex-1 h-[28px] w-[1px] bg-b_light" />
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </TooltipWrapper>
//           );
//         })}
//       </div>
//     );
//   }
//
//   const renderTableRows = (
//     <div
//       className="grid w-full"
//       style={{
//         gridTemplateColumns: `${columns[0]?.size || 100}px calc(100% - ${
//           (columns[0]?.size || 100) + (columns[columns.length - 1]?.size || 100)
//         }px) ${columns[columns.length - 1]?.size || 100}px`,
//       }}
//     >
//       <div className="w-full" style={{ width: `${columns[0]?.size || 100}px` }}>
//         {columns[0] &&
//           renderTableColumn({
//             last: false,
//             column: table[columns[0].index],
//             position: 'left',
//             size: columns[0].size || 100,
//             isRight: columns[0].right || false,
//             divider: columns[0].divider,
//             className: columns[0].className,
//             overflow: columns[0].overflow,
//             hintTitle: columns[0].hintTitle,
//           })}
//       </div>
//       <div
//         ref={bodyTableRef}
//         className="grid overflow-y-auto scrollHidden"
//         onScroll={(event) => onEventScroll(event, 'body')}
//         style={{ gridTemplateColumns: `repeat(${columns.length - 2}, 2fr)` }}
//       >
//         {Object.keys(table)
//           .slice(1, columns.length - 1)
//           .map((columnName, index) => (
//             <div key={index} style={{ minWidth: `${columns[index + 1].size || 100}px` }}>
//               {renderTableColumn({
//                 last: false,
//                 column: table[columnName],
//                 size: columns[index + 1].size || 100,
//                 isRight: columns[index + 1].right || false,
//                 divider: columns[index + 1].divider,
//                 truncate: columns[index + 1].truncate,
//                 className: columns[index + 1].className,
//                 overflow: columns[index + 1].overflow,
//                 hintTitle: columns[index + 1].hintTitle,
//               })}
//             </div>
//           ))}
//       </div>
//       <div style={{ width: `${columns[columns.length - 1]?.size || 100}px` }}>
//         {columns[columns.length - 1] &&
//           renderTableColumn({
//             last: true,
//             column: table[columns[columns.length - 1].index],
//             position: 'right',
//             size: columns[columns.length - 1].size || 100,
//             isRight: columns[columns.length - 1].right || false,
//             divider: columns[columns.length - 1].divider,
//             className: columns[columns.length - 1].className,
//             hintTitle: columns[columns.length - 1].hintTitle,
//             overflow: false,
//           })}
//       </div>
//     </div>
//   );
//
//   return (
//     <div className="w-full">
//       <div className="sticky bg-bg_default min-h-[80px] z-30 top-0 inline-flex w-full">
//         {renderTitles}
//       </div>
//       <div className="inline-flex w-full">{renderTableRows}</div>
//     </div>
//   );
// };
//
// export default Table;
