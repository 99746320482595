import { langData } from './blockly-lang.data';

export const formulaBlocks = {
  lang: 'ru',
  colors: {
    grey: {
      c1: '#424242',
      c2: '#616161',
      c3: '#757575',
    },
    green: {
      c1: '#1B5E20',
      c2: '#2E7D32',
      c3: '#388E3C',
      c4: '#43A047',
      c5: '#4CAF50',
    },
    blue: {
      c1: '#0D47A1',
      c2: '#1565C0',
      c3: '#1976D2',
      c4: '#1976D2',
      c5: '#2196F3',
    },
    bluegrey: {
      c1: '#263238',
      c2: '#37474F',
      c3: '#455A64',
      c4: '#546E7A',
      c5: '#607D8B',
    },
    orange: {
      c1: '#BF360C',
      c2: '#D84315',
      c3: '#E64A19',
      c4: '#F4511E',
      c5: '#FF5722',
    },
    red: {
      c1: '#B71C1C',
      c2: '#C62828',
      c3: '#D32F2F',
      c4: '#E53935',
      c5: '#F44336',
    },
    teal: {
      c1: '#004D40',
      c2: '#00695C',
      c3: '#00796B',
      c4: '#00897B',
      c5: '#009688',
    },
    pink: {
      c1: '#a83d72',
      c2: '#ab4d7c',
      c3: '#a55b80',
    },
  },
};

export function getBlockLocale(block) {
  return langData.blocks[block][formulaBlocks.lang];
}

export function getFunctionName(block, name) {
  if (name === undefined) {
    return langData.blocks[block][formulaBlocks.lang].name;
  } else {
    for (let i = 0; i < langData.blocks[block][formulaBlocks.lang].name.length; i++) {
      if (langData.blocks[block][formulaBlocks.lang].name[i][1] === name) {
        return langData.blocks[block][formulaBlocks.lang].name[i][0];
      }
    }
  }
}
