import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Modals } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/modals';
import { Button, EditText, PageHeader } from '@app/components/ui';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import AccordionUI from '@app/components/ui/Accordion/Aссordion';
import { ColsWithoutTable } from '@app/components/ui/rubber-table/blocks/cols-without-table';
import useTranslation from '@app/hooks/use-translation';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import { getMetricThunks } from '@app/store/reducers/metric-slice';
import { getMetricTemplatesThunks } from '@app/store/reducers/metric-templates.slice';
import { getPresetsThunks } from '@app/store/reducers/presets-slice';
import { useAppDispatch, useAppSelector } from '@app/store/store';

import { AccordionTitle } from './blocks/accordion-title';
import { MetricTemplateTable } from './blocks/container';
import MetricTemplateSkeleton from './blocks/metric-template.skeleton';

export const MetricTemplates: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('pages.metricTemplates');
  const { metricTemplates, loading } = useAppSelector((state) => state.metricTemplates);
  const [edit, setEdit] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [groupName, setNewGroupName] = useState('');
  const { metricTypes, allChanels } = useAppSelector((state) => state.metric);
  const [activeModal, setActiveModal] = useState<string>('');
  const [editModal, setEditModal] = useState(false);
  const [createMetric, setCreateMetric] = useState(false);
  const onClickCreateModal = () => {
    setEditModal(false);
    setCreateMetric(true);
  };
  React.useEffect(() => {
    dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
    dispatch(getMetricThunks.getAllTypesOfMetricThunk());
    dispatch(getMetricThunks.getAllChannels());
    dispatch(getPresetsThunks.getAliasesThunk());
  }, [dispatch]);

  const createMetricGroup = (e: string) => {
    setNewGroupName(e);
    if (e !== '') {
      dispatch(getMetricTemplatesThunks.createMetricTemplateGroupThunk(e));
      setNewGroupName('');
    } else {
      toast.error(t('cant_create_group'));
    }
    setEdit(false);
  };

  const [activeAccordionItem, setActiveAccordionItem] = React.useState<Array<string | undefined>>(
    localStorage.getItem('metric_accordion')?.split(',') || [],
  );

  React.useEffect(() => {
    const accordionListFromStorage = localStorage.getItem('metric_accordion');
    if (!accordionListFromStorage) {
      const defaultGroup = metricTemplates.find(
        (group) => group.is_default === true,
      )?.metric_templates_group_id;
      setActiveAccordionItem([defaultGroup]);
      defaultGroup && localStorage.setItem('metric_accordion', defaultGroup);
    }
  }, [metricTemplates]);

  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    setActiveAccordionItem((prev) => {
      if (!prev.includes(event.id)) {
        localStorage.setItem('metric_accordion', [...prev, event.id].join(','));
        return [...prev, event.id];
      } else {
        const accordionListFromStorage = localStorage.getItem('metric_accordion')?.split(',');
        const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
          (item) => item !== event.id,
        ) as string[];
        localStorage.setItem('metric_accordion', filteredAccordionListFromStorage.join(','));

        return prev.filter((item) => item !== event.id);
      }
    });
  };
  const columns = [
    {
      title: t('table.name'),
      index: 'name',
    },
    {
      title: t('table.type'),
      index: 'type',
    },
    {
      title: t('table.channel'),
      index: 'channel',
      maxWidth: 130,
    },
    {
      title: t('table.settings'),
      index: 'metricSettings',
    },
    {
      title: t('table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];
  if (loading) return <MetricTemplateSkeleton />;
  return (
    <>
      {metricTypes && allChanels && (
        <Modals
          copyModal={copyModal}
          page={'metricTemplate'}
          metricTypes={metricTypes}
          allChanels={allChanels}
          setEditModal={setEditModal}
          editModal={editModal}
          setFirstModal={setCreateMetric}
          firstModal={createMetric}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
        />
      )}
      <PageHeader icon="TemplateListIcon" label={t('metric_templates')}>
        <Button
          label={t('add_metric_template')}
          icon="CirclePlusIcon"
          onClick={() => onClickCreateModal()}
        />
      </PageHeader>
      <div>
        <ColsWithoutTable columns={columns} />
        <div className={'flex flex-col gap-[20px]'}>
          <AccordionUI>
            {metricTemplates.map((group) => {
              return (
                <AccordionItemUI
                  variant="filled"
                  key={group.metric_templates_group_id}
                  id={group.metric_templates_group_id}
                  title={
                    <AccordionTitle
                      setActiveAccordionItem={setActiveAccordionItem}
                      isDefault={group.is_default}
                      name={group.name}
                      active={activeAccordionItem.includes(group.metric_templates_group_id)}
                      templatesCount={group.metric_templates.length}
                      metric_templates_group_id={group.metric_templates_group_id}
                    />
                  }
                  clickBtnChild={clickAccordionHeaderBtn}
                  active={activeAccordionItem.includes(group.metric_templates_group_id)}
                >
                  {activeAccordionItem.includes(group.metric_templates_group_id) && (
                    <MetricTemplateTable
                      loading={loading}
                      setActiveModal={setActiveModal}
                      data={group.metric_templates}
                      setEditModal={setEditModal}
                      setCopyModal={setCopyModal}
                    />
                  )}
                </AccordionItemUI>
              );
            })}
            {edit && (
              <AccordionItemUI
                title={
                  <div className="max-w-[240px]">
                    <EditText
                      textStyle="font-[700] text-[13px] text-2color leading-[14px]"
                      editIconSize={14}
                      text={groupName}
                      onEdited={createMetricGroup}
                      defaultOpen
                    />
                  </div>
                }
                id={''}
                variant="filled"
              >
                {''}
              </AccordionItemUI>
            )}
          </AccordionUI>
          <div className="">
            <Button icon="CirclePlusIcon" label={t('new_group')} onClick={() => setEdit(!edit)} />
          </div>
        </div>
      </div>
    </>
  );
};
