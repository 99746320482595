import useTranslation from '@app/hooks/use-translation';
import React, { useCallback, useState } from 'react';
import {
  useAddNewDashboardMutation,
  useGetDashboardQuery,
  useGetDashboardsListQuery,
} from '@app/store/reducers/dashboard-page.api';
import { Button } from '@ui';
import Dashboard from '@app/pages/dashboards/blocks/dashboard';
import { DashboardsListType, DashboardsSettingsType } from '@app/interfaces/dashboards.type';
import DashboardPageSkeleton from '@app/pages/dashboards/blocks/dashboard-page.skeleton';

export const DashboardPage = () => {
  const { data } = useGetDashboardsListQuery();
  const [createDashboard] = useAddNewDashboardMutation();
  const { t } = useTranslation('pages.dashboard');
  const [id, setId] = useState<string | undefined>();
  const { currentData, isFetching } = useGetDashboardQuery(id || '', {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const dashboardExists = useCallback((id: string, arr: DashboardsListType[]) => {
    const dashboardsIdList: string[] = [];
    for (const dashboard of arr) {
      dashboardsIdList.push(dashboard.dashboard_id);
    }
    return dashboardsIdList.includes(id);
  }, []);

  React.useEffect(() => {
    const idFromStorage = localStorage.getItem('DASHBOARD_ID');
    if (!idFromStorage && data) {
      localStorage.setItem('DASHBOARD_ID', data?.dashboard?.[0].dashboard_id);
      setId(data?.dashboard?.[0].dashboard_id);
    } else {
      if (idFromStorage && data && dashboardExists(idFromStorage, data?.dashboard)) {
        setId(idFromStorage);
      } else {
        data && localStorage.setItem('DASHBOARD_ID', data?.dashboard?.[0].dashboard_id);
        setId(data?.dashboard?.[0].dashboard_id);
      }
    }
  }, [dashboardExists, data, id]);

  const createNewDashboard = (data: { name: string; shared: boolean }) => {
    createDashboard(data).then((data) => {
      const { data: DData } = data as unknown as { data: DashboardsSettingsType };
      localStorage.setItem('DASHBOARD_ID', DData.dashboard_id);
    });
  };
  const createFirstDashboard = () => {
    localStorage.removeItem('DASHBOARD_ID');
    createNewDashboard({ name: t('dashboard_create_name', { name: '1' }), shared: false });
  };

  if (isFetching) return <DashboardPageSkeleton />;
  return (
    <>
      {data?.dashboard?.length === 0 ? (
        <div className="flex items-center justify-center h-full">
          <Button
            icon="CirclePlusIcon"
            onClick={() => createFirstDashboard()}
            label={t('add_dashboard')}
          />
        </div>
      ) : (
        <Dashboard
          currentDashboard={currentData}
          changeDashboardId={setId}
          createNewDashboard={createNewDashboard}
        />
      )}
    </>
  );
};
