module.exports = {
  cant_delete: 'Нельзя удалить, данный словарь используется в другом словаре',
  warning: 'Предупреждение',
  dict_confirm_delete:
    'Удаление словаря повлияет на работу метрик, в которых он используется. Вы действительно хотите удалить данный словарь?',
  dictionaries: 'Словари',
  dictionary: 'Словарь',
  table: {
    name: 'НАЗВАНИЕ',
    desc: 'ОПИСАНИЕ',
    params: 'Параметры',
    date: 'Дата обновления',
    actions: 'Действия',
  },
  add_dictionaries: 'Добавить словарь',
  copy: 'копия',
  dict_desc: 'Описание словаря',
  input: 'Ввод',
  date_update: 'Дата обновления',
  dict_used: 'Словарь используется',
  save: 'Сохранить',
  cancel: 'Отменить',
  copy_dict: 'Скопировать словарь',
  delete: 'Удалить',
  dictDeleteToastError: 'Для удаления словаря необходимо исключить его из всех метрик аналитических проектов',
  fillAllFields: 'Заполните все поля',
};
