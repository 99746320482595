import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  GraphChartFullData,
  GraphSettingsDate,
  ReportChart,
  ReportChartResponse,
  ReportTimeRange,
} from '@app/interfaces/report.type';
import { httpRequest } from '@app/store/middleware/rest';
import serverRoutes from '@app/utils/server-routes';
import { chartCommonRangeUpdateApi, chartsLoadApi, chartUpdateRangeApi } from '@app/api/chartsApi';
import { FilterItem } from '@app/interfaces/filter';

export type ChartsState = {
  chartList: Array<GraphChartFullData>;
  isPending: boolean;
  error: string | null;
};

const initialState: ChartsState = {
  chartList: [],
  isPending: false,
  error: null,
};

export type ChartCommonRangeUpdateData = {
  liveReportId: string;
  ids: Array<string>;
  range: ReportTimeRange;
};
type ChartAsyncRequestData = { id: string; range: ReportTimeRange };

export const chartCommonRangeUpdate = createAsyncThunk<
  Array<GraphChartFullData>,
  ChartCommonRangeUpdateData
>(
  'chartCommonChangeRange/chartSlice',
  async ({ liveReportId, ids, range }) =>
    await chartCommonRangeUpdateApi({ liveReportId, ids, range }),
);

export const chartAsyncUpdateRequest = createAsyncThunk<ReportChart, ChartAsyncRequestData>(
  'chartUpdateRange/getCharts',
  async ({ id, range }) => {
    return await chartUpdateRangeApi(id, range);
  },
);

export const chartAsyncLoadingRequest = createAsyncThunk<
  Array<GraphChartFullData>,
  { reportId: string; filter?: FilterItem[] }
>('chartSlice/getCharts', async ({ reportId, filter }) => {
  return await chartsLoadApi(reportId, filter);
});

const chartsSlice = createSlice({
  name: 'ChartsSlice',
  initialState,
  reducers: {
    updateChart: (
      state,
      { payload }: { payload: { id: string; name: string; range: ReportTimeRange } },
    ) => {
      state.chartList = state.chartList.map((chart) => {
        if (payload.id === chart.graph_id) {
          chart.build.name = payload.name;
          chart.build.range = payload.range;
        }
        return chart;
      });
    },
    responseDataChart: (state, { payload }: { payload: ReportChartResponse }) => {
      const { data } = payload;
      state.chartList = state.chartList.map((chart) => {
        if (data.graph_id === chart.graph_id) {
          chart.build = data;
        }
        return chart;
      });
    },
    updateChartResponse: (
      state,
      {
        payload,
      }: { payload: { status: number; requestData: { id: string }; data: GraphSettingsDate } },
    ) => {
      const {
        requestData: { id },
        data,
      } = payload;
      state.chartList = state.chartList.map((chart) => {
        if (chart.graph_id === id) {
          chart.name = data.name;
          chart.build = { ...chart.build, name: data.name };
        }
        return chart;
      });
    },
    createChartResponse: (
      state,
      {
        payload,
      }: {
        payload: {
          requestData: { name: string; settings: GraphSettingsDate };
          data: GraphSettingsDate;
        };
      },
    ) => {
      const { data } = payload;
      state.chartList = [
        ...state.chartList,
        {
          name: data.name,
          graph_id: data.graph_id,
          layout: { w: 1, h: 1, x: 1, y: 1, minW: 2, maxW: 2, static: false },
          live_report_id: data.live_report_id,
          build: {
            type: data.settings.type,
            graph_id: data.graph_id,
            name: data.name,
            dataLabels: false,
            bar_width: 1,
            width: 'part',
            series: [{ name: '', data: [], color: '', gradient: '' }],
            categories: [],
            range: { parameters: [{ num: 7 }], type: 'l' },
          },
        },
      ];
    },
    removeChartResponse: (
      state,
      { payload }: { payload: { requestData: { id: string }; status: number } },
    ) => {
      const {
        status,
        requestData: { id },
      } = payload;
      if (status === 204) {
        state.chartList = state.chartList.filter((chart) => chart.graph_id !== id);
      }
    },
    pendingResponse: (state, { payload }) => {
      state.isPending = payload;
    },
    errorResponse: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(chartCommonRangeUpdate.fulfilled, (state, { payload }) => {
      state.chartList = payload;
      state.isPending = false;
    });
    builder.addCase(chartCommonRangeUpdate.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(chartAsyncUpdateRequest.fulfilled, (state, { payload }) => {
      state.chartList = state.chartList.map((chart) => {
        if (chart.graph_id === payload.graph_id) {
          chart.build = payload;
        }
        return chart;
      });
    });
    builder.addCase(chartAsyncLoadingRequest.fulfilled, (state, action) => {
      const { payload } = action;
      state.chartList = [];
      if (payload.length < 1) {
        state.chartList = [];
      } else {
        state.chartList = payload;
      }
      state.isPending = false;
    });
    builder.addCase(chartAsyncLoadingRequest.pending, (state) => {
      state.isPending = true;
    });
  },
});

export default chartsSlice.reducer;
export const {
  pendingResponse,
  errorResponse,
  createChartResponse,
  updateChartResponse,
  removeChartResponse,
  responseDataChart,
  updateChart,
} = chartsSlice.actions;

export const createChartRequest = (data) =>
  httpRequest({
    url: serverRoutes.chart,
    method: 'POST',
    data,
    onStatus: createChartResponse.type,
    onError: errorResponse.type,
  });

export const chartRemoveRequest = (params: { id: string }) =>
  httpRequest({
    url: serverRoutes.chart,
    method: 'DELETE',
    params: params,
    onStatus: removeChartResponse.type,
    onError: errorResponse.type,
    onLoading: pendingResponse.type,
  });

export const requestDataChart = (data: { id: string }) =>
  httpRequest({
    url: serverRoutes.chartData,
    method: 'POST',
    data,
    onSuccess: responseDataChart.type,
    onError: errorResponse.type,
  });
