import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';
import classNames from 'classnames';

const SettingIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={classNames(className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 32 32"
      >
        <path
          fill="currentColor"
          d="M30.168 18.705l-2.038-1.177a12.191 12.191 0 000-3.056l2.038-1.177a1.496 1.496 0 00.69-1.719 15.473 15.473 0 00-3.595-6.224 1.5 1.5 0 00-1.84-.268L23.388 6.26a12.135 12.135 0 00-2.646-1.53v-2.35c0-.697-.479-1.301-1.156-1.461a15.61 15.61 0 00-7.173 0 1.501 1.501 0 00-1.155 1.46V4.73c-.943.396-1.832.91-2.646 1.53L6.577 5.084a1.5 1.5 0 00-1.84.268 15.474 15.474 0 00-3.596 6.224 1.496 1.496 0 00.69 1.72l2.038 1.176a12.198 12.198 0 000 3.056l-2.038 1.177a1.496 1.496 0 00-.69 1.719c.7 2.359 1.948 4.483 3.596 6.224a1.5 1.5 0 001.84.268l2.035-1.175c.814.62 1.703 1.134 2.646 1.53v2.35c0 .697.478 1.301 1.155 1.461 2.353.557 4.82.557 7.173 0a1.5 1.5 0 001.156-1.46V27.27c.943-.396 1.832-.91 2.646-1.53l2.035 1.175a1.5 1.5 0 001.84-.268 15.474 15.474 0 003.596-6.224 1.496 1.496 0 00-.69-1.72zm-4.092 6.279l-2.894-1.672c-1.687 1.442-2.285 1.805-4.44 2.566v3.343a13.57 13.57 0 01-5.484 0v-3.343c-2.101-.742-2.71-1.087-4.44-2.566l-2.894 1.672a13.5 13.5 0 01-2.746-4.748l2.896-1.671c-.409-2.214-.409-2.915 0-5.13l-2.896-1.671a13.51 13.51 0 012.746-4.748l2.894 1.672c1.711-1.464 2.316-1.816 4.44-2.567V2.78a13.55 13.55 0 015.484 0v3.342c2.123.75 2.729 1.103 4.44 2.567l2.894-1.672c1.232 1.381 2.168 3 2.745 4.748l-2.895 1.671c.408 2.214.409 2.915 0 5.13l2.896 1.671a13.5 13.5 0 01-2.746 4.748zM16 10c-3.309 0-6 2.692-6 6s2.691 6 6 6c3.308 0 6-2.692 6-6s-2.692-6-6-6zm0 10c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z"
        ></path>
      </svg>
    </div>
  );
};

export default SettingIcon;
