import cn from 'classnames';

export const dropContainerClassName = ({
  isOpen,
  dropTop = false,
}: {
  isOpen: boolean;
  dropTop: boolean;
}) =>
  cn(
    isOpen ? ' transition visible translate-y-[20px] ease-in-out' : 'invisible -translate-y-[0px]',
    dropTop ? 'mb-[25px] bottom-full' : 'mt-[20px]',
    'absolute',
    'duration-[80]',
    'shadow-popover',
    'bg-white',
    'py-[8px]',
    'w-full',
    'z-[99999]',
    'rounded-defaultR',
  );

export const dropTriangleClassName = (position: string, dropTop) =>
  cn(
    'inline-flex',
    position,
    'w-full',
    'left-0',
    'absolute',
    dropTop ? 'top-full bottom-[-20px]' : 'top-[-20px]',
  );

export const dropListItemClassName = cn(
  'rounded-defaultR',
  'font-[500]',
  'text-[13px]',
  'px-[10px]',
  'w-full',
  'rounded-[10px]',
  'list-none',
);
export const dropItemWrapperClassName = (color: string, isNotDanger: boolean, disable: boolean) =>
  cn(
    disable && 'opacity-[0.5]',
    color,
    'truncate',
    !disable && isNotDanger && ' hover:text-speech_analitics',
    'inline-flex',
    'rounded-defaultR',
    !disable && 'cursor-pointer',
    'px-[12px]',
    'py-[8px]',
    'pb-[7px]',
    'h-full',
    'w-full',
    'items-center',
    !disable && 'hover:bg-defaultBg',
  );
