import Blockly from 'blockly';
import * as Python from 'blockly/python';

import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { formulaBlocks, getBlockLocale } from '../data/blockly-colour.data';

Blockly.Blocks['formula_expression'] = {
  init: function () {
    const locale = getBlockLocale('formula_expression');
    this.setOutput(true);
    this.setInputsInline(false);
    this.appendDummyInput('NEXT').appendField(new Blockly.FieldTextInput(locale.name), 'STATEMENT');
    this.setTooltip(locale.help);
    this.setColour(formulaBlocks.colors.pink.c2);
  },
};
(Blockly as any).Blocks['new_boundary_function'] = {
  init: function () {
    const locale = getBlockLocale('formula_metric');
    this.appendDummyInput()
      .appendField(locale.titles)
      .appendField(
        new (Blockly as any).FieldDropdown([
          ['first', 'first'],
          ['second', 'second'],
        ]),
      );
    this.appendStatementInput('Content').setCheck(null);
    this.setInputsInline(true);
    this.setColour(315);
    this.setTooltip('');
    this.setHelpUrl('');
    this.setOutput(true, null);
  },
};

Python['new_boundary_function'] = function (block) {
  const text_name = block.getFieldValue('Name');
  const statements_content = (Blockly as any).Python.statementToCode(block, 'Content');
  // TODO: Assemble Python into code variable.
  return 'def ' + text_name + '(_object,**kwargs):\n' + statements_content + '\n';
};

Blockly.Blocks['return'] = {
  init: function () {
    this.appendValueInput('NAME').setCheck(null).appendField('return');
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setColour(330);
    this.setTooltip('');
    this.setHelpUrl('');
  },
};

Python['return'] = function (block) {
  const value_name = (Blockly as any).Python.valueToCode(
    block,
    'NAME',
    (Blockly as any).Python.ORDER_ATOMIC,
  );
  // TODO: Assemble Python into code variable.
  return 'return ' + value_name + '\n';
};
export function createCustomMetric(
  metric: Array<MetricListArrType>,
  currentMetric?: MetricListArrType['metric_id'] | undefined,
) {
  Blockly.Blocks['formula_metric'] = {
    init: function () {
      const filteredMetric = currentMetric
        ? metric.filter((actualMetric) => actualMetric.metric_id !== currentMetric)
        : metric;
      const locale = getBlockLocale('formula_metric');
      const options = metric ? filteredMetric.map((metric) => [metric.name, metric.metric_id]) : [];
      this.appendDummyInput().appendField(metric.length ? locale.title : locale.empty);
      if (metric.length && options.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const dropdown = new Blockly.FieldDropdown(options);
        this.appendDummyInput().appendField(dropdown, 'METRIC_ID');
      }
      this.setOutput(true);
      this.setInputsInline(true);
      this.setColour(formulaBlocks.colors.pink.c3);
    },
  };
}
