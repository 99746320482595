import React, { FC, ReactNode, useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Input } from '@ui';
type CustomDatePickerPropsType = {
  intervalPicker?: boolean;
  maxDate?: Date;
  minDate?: Date;
  customInput?: ReactNode;
  disabled?: boolean;
  isFull?: boolean;
  onChange?(date: string): void;
  inputClassName?: string;
  selected?: Date;
};
const CustomDatePicker: FC<CustomDatePickerPropsType> = (props) => {
  const {
    intervalPicker = false,
    maxDate,
    onChange,
    minDate,
    isFull,
    customInput,
    disabled = false,
    inputClassName,
    selected,
  } = props;
  const [startDate, setStartDate] = useState(selected || moment().toDate());
  const [endDate, setEndDate] = useState(null);
  const onChangePicker = (dates) => {
    intervalPicker ? onIntervalChangeHandler(dates) : onOneDateChange(dates);
  };
  function onIntervalChangeHandler(dates) {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }
  function onOneDateChange(dates) {
    setStartDate(dates);
    const date = moment(dates).format('yyyy.MM.DD');
    onChange && onChange(date);
  }
  function getInputDefaultValue() {
    return intervalPicker
      ? `${moment(startDate).format('yyyy.MM.DD')} ${
          endDate ? '- ' + moment(endDate).format('yyyy.MM.DD') : ''
        }`
      : String(moment(startDate).format('yyyy.MM.DD'));
  }
  const rangeDateProps = intervalPicker && {
    startDate: startDate,
    endDate: endDate,
    selectsRange: true,
  };
  const inputProps = {
    disabled: disabled,
    // datePickerMode: !disabled,
  };
  useEffect(() => {
    if (!selected) return;
    setStartDate(selected);
  }, [selected]);
  return (
    <div>
      <DatePicker
        {...rangeDateProps}
        showYearDropdown
        showMonthDropdown
        showDisabledMonthNavigation
        dropdownMode="select"
        onChange={onChangePicker}
        selected={startDate}
        maxDate={maxDate}
        minDate={minDate}
        customInput={
          customInput ? (
            customInput
          ) : (
            <div
              className={`flex items-center ${isFull ? 'w-full' : 'w-[214px]'} ${
                inputClassName || ''
              }`}
            >
              <Input
                {...inputProps}
                name="dateStart"
                icon="CalendarEmptyIcon"
                defaultValue={getInputDefaultValue()}
              />
            </div>
          )
        }
      />
    </div>
  );
};

export default CustomDatePicker;
