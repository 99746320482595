import React from 'react';
import { Outlet } from 'react-router-dom';
import { PageHeader } from '@ui';

import SettingsNavigationBlock from './blocks/settings-navigation.block';

const SettingsOutlet = () => {
  return (
    <div>
      <PageHeader label="Настройки" icon="SettingIcon" />
      <SettingsNavigationBlock />
      <Outlet />
    </div>
  );
};

export default SettingsOutlet;
