import Blockly from 'blockly';

import { getBlockLocale } from '../data/blockly-colour.data';
import { formulaBlocks } from '../data/blockly-colour.data';
Blockly.Blocks['formula_start'] = {
  init: function () {
    const locale = getBlockLocale('formula_start');
    this.setDeletable(false);
    this.setInputsInline(false);
    this.appendValueInput('FORMULA').appendField('=');
    this.setTooltip(locale.help);
    this.setColour(formulaBlocks.colors.grey.c1);
    this.setEditable(false);
  },
};

Blockly.Blocks['formula_mathops'] = {
  init: function () {
    this.operators = [
      ['+', '+'],
      ['-', '-'],
      ['*', '*'],
      ['/', '/'],
      ['^', '^'],
    ];
    this.inputOperatorName = 'OPERATOR_INPUT';
    this.inputOperatorFieldName = 'OPERATOR_INPUT_FIELD';
    this.inputRightName = 'RIGHT_INPUT';
    const locale = getBlockLocale('formula_mathops');
    this.setInputsInline(false);
    this.setOutput(true);
    this.appendValueInput('LEFT');
    this.addInput(1);
    this.setTooltip(locale.help);
    this.setColour(formulaBlocks.colors.green.c2);
  },
  customContextMenu: function (options) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const block = this;

    console.log('options', options);
    const locale = getBlockLocale('formula_mathops');
    const count = this.countInput();
    const optionAdd = {
      enabled: true,
      text: locale.button.add,
      callback: function () {
        block.addInput(count);
      },
    };
    options.push(optionAdd);

    const optionRemove = {
      enabled: count > 2,
      text: locale.button.remove,
      callback: function () {
        block.deleteInput(count - 1);
      },
    };
    options.push(optionRemove);
  },
  mutationToDom: function () {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('count', this.countInput());
    return container;
  },
  domToMutation: function (xmlElement) {
    const count = parseInt(xmlElement.getAttribute('count'));
    if (count > this.countInput()) {
      for (let i = this.countInput(); i < count; i++) {
        this.addInput(i);
      }
    }
  },
  countInput() {
    return (this.inputList.length - 1) / 2 + 1;
  },
  addInput(num) {
    this.appendDummyInput(this.inputOperatorName + '_' + num).appendField(
      new Blockly.FieldDropdown(this.operators),
      this.inputOperatorFieldName + '_' + num,
    );
    this.appendValueInput(this.inputRightName + '_' + num);
    this.save();
  },
  deleteInput(num) {
    this.removeInput(this.inputOperatorName + '_' + num);
    this.removeInput(this.inputRightName + '_' + num);
    this.save();
  },
  save() {
    this.workspace.listeners.forEach((l) => {
      if (l.name === 'bound saveBlockly') {
        l();
      }
    });
  },
};

Blockly.Blocks['formula_relops'] = {
  init: function () {
    const locale = getBlockLocale('formula_relops');
    this.setInputsInline(true);
    this.setOutput(true);
    this.appendValueInput('LEFT');
    this.appendDummyInput().appendField(
      new Blockly.FieldDropdown([
        ['==', '=='],
        ['<', '<'],
        ['>', '>'],
        ['<=', '<='],
        ['>=', '>='],
        ['<>', '<>'],
      ]),
      'OPERATOR',
    );
    this.appendValueInput('RIGHT');
    this.setTooltip(locale.help);
    this.setColour(formulaBlocks.colors.green.c3);
  },
};
