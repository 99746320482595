import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import { NavigationRoute, navigationRoutes, NavigationRoutes } from '@app/utils/navigation-routes';
import { userPermissions } from '@app/utils/user-permissions';

export type MenuBarItem = {
  id: number;
  name: string;
  icon: IconNameType;
  routeEnd?: boolean;
  route: NavigationRoutes[NavigationRoute];
  tag?: string;
};
export type MenuBarList = {
  id: number;
  title?: string;
  menuItems: Array<MenuBarItem>;
};

export type MenuSecondBarList = {
  id: number;
  name: string;
  routeEnd?: boolean;
  route: NavigationRoutes[NavigationRoute];
};

export const menuBarData: Array<MenuBarList> = [
  {
    id: 1,
    title: 'main',
    menuItems: [
      {
        id: 1,
        name: 'dashboard',
        icon: 'HomeIcon',
        route: navigationRoutes.main,
        routeEnd: true,
        tag: userPermissions.display.displayDashboards,
      },
      {
        id: 2,
        name: 'all_records',
        icon: 'PlayCircleIcon',
        route: navigationRoutes.records,
      },
    ],
  },
  {
    id: 2,
    title: 'analytic_title',
    menuItems: [
      {
        id: 3,
        name: 'analytic_proj',
        icon: 'FolderIcon',
        route: navigationRoutes.analytics,
      },
      {
        id: 4,
        name: 'proj_records',
        icon: 'FolderMediaIcon',
        route: navigationRoutes.projectRecords,
      },
      {
        id: 5,
        name: 'reports',
        icon: 'MetricIcon',
        route: navigationRoutes.reports,
      },
      {
        id: 12,
        name: 'words_cloud',
        icon: 'CloudIcon',
        route: navigationRoutes.wordsCloud,
      },
    ],
  },
  {
    id: 3,
    title: 'templates_title',
    menuItems: [
      {
        id: 6,
        name: 'presets_templates',
        icon: 'MenuSmallIcon',
        route: navigationRoutes.presets,
      },
      // {
      //   id: 7,
      //   name: 'Шаблоны проектов',
      //   icon: 'FolderConfigIcon',
      //   route: `${navigationRoutes.templates}/${navigationRoutes.projects}`,
      // },
      {
        id: 8,
        name: 'metrics_templates',
        icon: 'TemplateListIcon',
        route: `${navigationRoutes.templates}/${navigationRoutes.metric}`,
      },
    ],
  },
  {
    id: 4,
    title: 'settings_title',
    menuItems: [
      {
        id: 9,
        name: 'dictionaries',
        icon: 'BookIcon',
        route: navigationRoutes.dictionaries,
      },
      {
        id: 10,
        name: 'system_settings',
        icon: 'SettingIcon',
        route: navigationRoutes.settings,
        tag: userPermissions.display.displaySettings,
      },
      {
        id: 11,
        name: 'audit_logs',
        icon: 'SettingIcon',
        route: navigationRoutes.auditLogs,
        tag: userPermissions.display.displayAuditLogs,
      },
    ],
  },
];

export const secondMenuList: Array<MenuSecondBarList> = [
  {
    id: 1,
    name: 'Speech Analytics',
    routeEnd: true,
    route: navigationRoutes.main,
  },
  {
    id: 2,
    name: 'Text-to-Speech',
    routeEnd: false,
    route: navigationRoutes.reports,
  },
  {
    id: 3,
    name: 'Assist Superviser',
    routeEnd: false,
    route: navigationRoutes.records,
  },
  {
    id: 4,
    name: 'Assist Conf',
    routeEnd: false,
    route: navigationRoutes.presets,
  },
];
