import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useOutletContext, useParams } from 'react-router';
import { v4 } from 'uuid';

import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import { BigChartIcon } from '@icons';
import { Button, CircleButton, DropMenu, Empty, Popover, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';
import connector, { PropsFromRedux } from '@app/utils/store';
import { GraphChartFullData, ReportChart, ReportTimeRange } from '@app/interfaces/report.type';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import { SelectUniversalFilter } from '@app/components/select-universal-filter';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import { useAppSelector } from '@app/store/store';
import { FilterItem } from '@app/interfaces/filter';

import { useLazyGetGraphDataQuery } from '@app/store/reducers/graph-data.api';

import { GridLayoutChartsContainer } from '@app/components/grid-layout-charts-container';

import { ReportOutletContext } from '../report.outlet';

import ChartDatePickerModalBlock from './chart-date-picker-modal.block';
import ModalSettingsChartBlock from './chart-modal/modal-settings-chart.block';

const ChartListBlock: FC<{ live_report_id: string } & PropsFromRedux> = (props) => {
  const { chartsState, chartAsyncLoadingRequest, live_report_id } = props;
  const { isPending, chartList: charts } = chartsState;
  const customDateTrigger = 'customDate';
  const chartCreatedDropKeys = {
    bar: 'bar',
  };
  const {
    report: { project },
  } = useOutletContext<ReportOutletContext>();
  const { metricList } = useAppSelector((state) => state.metric);
  const [getGraphData] = useLazyGetGraphDataQuery();
  const [isShowModelDatePicker, changeShowingModelDatePicker] = useState<boolean>(false);
  // const [filterItem, setFilterItem] = useState<FilterItem[]>([]);
  const { id } = useParams();
  const [showEditChartModal, changeShowOpenChartModel] = useState<boolean>(false);
  const [commonRange, setCommonRange] = useState<ReportTimeRange | null>(null);
  const { t } = useTranslation('pages.chartPage');
  const [chartList, changeChartList] = useState<GraphChartFullData[]>();
  const [filterValue, changeFilterValue] = useState<FilterItem[]>();
  const { daysSelectOptions, dateIntervals, intervalTimeBuilder } = useChartDateIntervals();
  const chartAddListItemClassName = cn(
    'cursor-pointer',
    'hover:bg-speech_analitics_filter',
    'px-[21px]',
    'py-[15px]',
    'rounded-defaultR',
    'transition duration-150',
  );
  useEffect(() => {
    id && chartAsyncLoadingRequest(id);
  }, [chartAsyncLoadingRequest, id]);
  useEffect(() => {
    changeChartList(charts);
  }, [charts]);
  function handleCreateChart(key) {
    if (key === chartCreatedDropKeys.bar) changeShowOpenChartModel(true);
  }

  function handlerDateDropTrigger(key: string | number) {
    if (!chartList) return;
    if (key === customDateTrigger) {
      changeShowingModelDatePicker(true);
      return;
    }
    for (const chart of chartList) {
      getGraphData({
        id: chart.graph_id,
        settings: {
          width: chart.build.width,
          range: dateIntervals[key],
          filter: filterValue,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        onEditIntervalWithoutSave &&
          onEditIntervalWithoutSave({ id: chartData.graph_id, data: chartData });
      });
    }
    setCommonRange(dateIntervals[key]);
  }
  function onEditIntervalWithoutSave({ data, id }: { data: ReportChart; id: string }) {
    console.log(id);
    changeChartList((prev) => {
      return prev?.map((item) => {
        if (item.graph_id === id) {
          item = {
            ...item,
            build: {
              ...item.build,
              categories: data.categories,
              series: data.series,
              range: data.range,
            },
          };
        }
        return item;
      });
    });
  }
  function handlerChangeCustomRangeInterval({
    start,
    end,
  }: {
    start: string | null | Date;
    end: string | null | Date;
  }) {
    if (!chartList) return;
    const range = intervalTimeBuilder(start, end) as unknown as ReportTimeRange;
    for (const chart of chartList) {
      getGraphData({
        id: chart.graph_id,
        settings: {
          width: chart.build.width,
          range,
          filter: filterValue,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        onEditIntervalWithoutSave &&
          onEditIntervalWithoutSave({ id: chartData.graph_id, data: chartData });
      });
    }
  }
  const filteredCharts =
    (chartList && chartList?.filter((graph) => graph.live_report_id === live_report_id)) || [];
  const renderCharts = filteredCharts.length ? (
    <GridLayoutChartsContainer
      onEditIntervalWithoutSave={onEditIntervalWithoutSave}
      viewOnly={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
      commonRange={commonRange}
      charts={filteredCharts}
      filterValue={filterValue}
    />
  ) : (
    <Empty title={t('empty')} />
  );

  const renderLoadingChartSkeletons = (
    <div className="flex gap-[10px]">
      {Array(6).map((_, index) => (
        <div key={index} className="basic-1/2">
          <Skeleton count={1} height={300} />
        </div>
      ))}
    </div>
  );

  function onChangeFilterHandler(filter: FilterItem[]) {
    if (!chartList) return;
    changeFilterValue(filter);
    for (const chart of chartList) {
      getGraphData({
        id: chart.graph_id,
        settings: {
          width: chart.build.width,
          range: chart.build.range,
          filter: filter,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        onEditIntervalWithoutSave &&
          onEditIntervalWithoutSave({ id: chartData.graph_id, data: chartData });
      });
    }
  }

  const renderChartHistogramItem = (
    <div className={chartAddListItemClassName}>
      <div className="inline-flex items-center w-full justify-between">
        <div className="text-[13px] font-[500] text-1color">{t('popover.gist')}</div>
        <div>
          <CircleButton icon="CirclePlusIcon" className="text-action" />
        </div>
      </div>
      <div className="w-full inline-flex mt-[15px] justify-center">
        <BigChartIcon size={149} />
      </div>
    </div>
  );

  const popoverCreateChartMenu = [
    {
      content: renderChartHistogramItem,
      title: t('popover.gist'),
      key: chartCreatedDropKeys.bar,
    },
  ];

  return (
    <div>
      <div>
        <div className="w-full inline-flex justify-between min-h items-center gap-[37px]">
          <SelectUniversalFilter
            onChangeReturnFilter={(filter) => onChangeFilterHandler(filter)}
            isPending={isPending}
            results={metricList.map((item) => ({
              ...item,
              front_id: v4(),
              style: { fontSize: '15px', color: '#000', fontWeight: '400', lineHeight: '18px' },
            }))}
          />
          <div />
          <div className="inline-flex items-center my-[10px]">
            <Popover
              label={t('popover.add_diagram')}
              position="end"
              onClick={handleCreateChart}
              menu={popoverCreateChartMenu}
              disabled={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
            />
            <div className="ml-[13px]">
              <DropMenu
                dropSize={140}
                hintTitle={t('drop_menu.intervals')}
                onClick={(key) => handlerDateDropTrigger(key)}
                menu={daysSelectOptions as unknown as Array<DropMenuItem>}
              >
                <Button icon="CalendarEmptyIcon" />
              </DropMenu>
            </div>
          </div>
        </div>
        <div className="w-full"> {isPending ? renderLoadingChartSkeletons : renderCharts}</div>
      </div>
      {showEditChartModal && (
        <ModalSettingsChartBlock
          isOpen={showEditChartModal}
          onClose={changeShowOpenChartModel.bind(null, false)}
          type={'barchart'}
        />
      )}
      <ChartDatePickerModalBlock
        onSuccess={handlerChangeCustomRangeInterval}
        show={isShowModelDatePicker}
        onClose={changeShowingModelDatePicker.bind(null, false)}
      />
    </div>
  );
};

export default connector(ChartListBlock);
