import cn from 'classnames';

export function getRowClassName(isHeader = false, type: 'range' | 'filter' = 'filter') {
  return cn(
    'flex',
    isHeader
      ? 'pb-[13px]'
      : 'relative min-h py-4 rounded-[12px] border-l-[2px] border-l-speech_analitics',
    !isHeader && type === 'range' && 'bg-speech_analitics_filter',
    !isHeader && type === 'filter' && 'bg-defaultBg',
  );
}

export function getColumnClassName(index: 0 | 1 | 2, isHeader = false) {
  return cn(
    index === 0 && 'w-[308px] ml-[24px]',
    index === 1 && 'w-[102.2px] mx-[16px]',
    index === 2 && 'w-[330.4px] mr-[27px]',
    isHeader && 'uppercase font-semibold',
  );
}

export function getDescriptionToLType(width: number) {
  return cn(`w-[${width}px]`, 'h-[15px] text-[14px] leading-[15px] tracking-tight');
}
