module.exports = {
  title: 'Настройки',
  warning: 'Предупреждение',
  common_setting: {
    delete_channel_text: 'Вы действительно хотите удалить данный канал?',
    form: {
      channel_name: 'Название канала',
      channel_name_placeholder: 'Название',
      button_label: 'Добавить',
      channel_number: 'Номер дорожки',
    },
    title: 'Аудио-каналы',
    button: {
      add_channel: 'Добавить канал',
    },
  },
};
