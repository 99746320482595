import React, { FC } from 'react';

import { CircleButton, TooltipWrapper } from '@ui';
import { KnownMetricsCases } from '@app/interfaces/records';
import cn from 'classnames';

export type MetricItemProps = {
  isActive?: boolean;
  data: KnownMetricsCases[string]['known_metric'];
  onChangeMetricItem?: ({ caseName, value }: { caseName: string; value: string | number }) => void;
};

const MetricItem: FC<MetricItemProps> = (props) => {
  const { data, isActive = false, onChangeMetricItem } = props;
  const { name, value, metric_id, color, type: metricType } = data;
  const renderMetricTypeContent = metricType === 'find-words' && (
    <div className="flex items-center justify-end h-full">
      <div className="mr-[10px] flex pt-[4px] w-[20px] h-[20px] items-center justify-center">
        <CircleButton
          icon="SearchInTextIcon"
          size={19}
          className="text-3color"
          onClick={onChangeMetricItem?.bind(null, {
            caseName: `${metricType}_once`,
            value: metric_id,
          })}
        />
      </div>
      <div
        className={cn(
          isActive && 'bg-speech_analitics_light',
          'rounded-[6px] flex pt-[3px] w-[20px] h-[20px] items-center justify-center',
        )}
      >
        <CircleButton
          onClick={onChangeMetricItem?.bind(null, { caseName: metricType, value: metric_id })}
          icon="SplitedIcon"
          size={15}
          className="text-speech_analitics"
        />
      </div>
    </div>
  );

  const valueTypeRender =
    typeof value === 'string' ? (
      <div className="flex text-[13px] text-2color h-full items-center justify-center truncate">
        {value}
      </div>
    ) : (
      <div className=" w-full flex h-full justify-center items-center">
        <div className="content-end text-right inline-flex w-full justify-end h-full items-center">
          <div className="relative w-[20px]">
            <div
              className="w-[20px] h-[20px] opacity-10 rounded-[3px]"
              style={{ backgroundColor: `#${color}` || '#000' }}
            />
            <div
              className="absolute top-0 left-0 h-[20px] text-[12px] flex justify-center items-center w-[20px] opacity-100"
              style={{ color: `#${color}` || '#000' }}
            >
              {value}
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="grid grid-cols-[2fr_100px_50px] gap-2 px-[26px] selected-none  hover:bg-basic_app_bg rounded-[3px] py-[5px] transition w-full content-center ">
      <div className="text-1color text-[14px] w-[100%]">
        <TooltipWrapper content={String(name)} id={String(name)}>
          {name}
        </TooltipWrapper>
      </div>
      <TooltipWrapper content={String(value)} id={String(value)} className="right">
        {valueTypeRender}
      </TooltipWrapper>
      {renderMetricTypeContent}
    </div>
  );
};
export default MetricItem;
