import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';
import { ProjectAnalyticsParams, ProjectItem } from '@app/interfaces/analytics';
import { set } from 'lodash';

type AnalyticState = {
  analyticProject: ProjectItem | null;
  isPending: boolean;
  error: string | null;
};

const initialState: AnalyticState = {
  analyticProject: null,
  isPending: false,
  error: null,
};

const analyticSlice = createSlice({
  name: 'analyticSlice',
  initialState,
  reducers: {
    responseAnalyticProject: (state, { payload }: PayloadAction<ProjectItem>) => {
      state.analyticProject = payload;
    },
    pendingResponse: (state, { payload }) => {
      state.isPending = payload;
    },
    errorResponse: (state, { payload }) => {
      state.error = payload;
    },
    changeAnalyticProjectByKey(
      state,
      { payload }: PayloadAction<{ key: keyof ProjectItem; value: string }>,
    ) {
      if (!state.analyticProject) return;
      set(state.analyticProject, payload.key, payload.value);
    },
  },
});

export const {
  responseAnalyticProject,
  pendingResponse,
  errorResponse,
  changeAnalyticProjectByKey,
} = analyticSlice.actions;
export default analyticSlice.reducer;

export const requestAnalyticProject = (project_id: ProjectAnalyticsParams['id']) =>
  httpRequest({
    url: '/api/front/project?id=' + project_id,
    method: 'GET',
    data: { project_id },
    onSuccess: responseAnalyticProject.type,
    onLoading: pendingResponse.type,
    onError: errorResponse.type,
  });
