export type WordsListType = {
  name: string;
};
export type VadModalsChildrenType = {
  inputText: { id: string; labelTitle: string; placeholder: string }[];
  radioButtons: { labelTitle: string; radioBut: { id: string; value: string; label: string }[] }[];
  inputNumber: { labelTitle: string; id: string }[];
};
export const colorsArray = [
  { color: 'FFFFFF' },
  { color: '3BA86D' },
  { color: '05C95F' },
  { color: '13DCDC' },
  { color: '2ECCEF' },
  { color: 'A96640' },
  { color: 'DA7B45' },
  { color: 'FF9B04' },
  { color: 'FCCD25' },
  { color: 'FEE613' },
  { color: '972EE9' },
  { color: '2C62B4' },
  { color: 'E061B2' },
  { color: 'C78FF8' },
  { color: '8F9AF8' },
  { color: 'E80000' },
  { color: 'E92E5D' },
  { color: 'F06275' },
  { color: '182320' },
  { color: '9C9C9C' },
];
export const wordsListArray: string[] = [];
