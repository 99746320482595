import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@app/interfaces/common';
import {
  ChangeStatusRequest,
  changeStatusRequest,
  createLiveReportRequest,
  CreateReportData,
  createReportRequest,
  liveReportsRequest,
  LiveReportsRequestData,
  removeReportsRequest,
  reportRequest,
  reportsRequest,
  removeLiveReportRequest,
  RemoveLiveReportData,
  liveReportResponse,
  liveReportSettingsRequest,
  LiveReportSettingsRequestData,
  editLiveReportRequest,
  EditLiveReportRequest,
} from '@app/store/reducers/reports.slice';
import {
  chartAsyncLoadingRequest,
  chartAsyncUpdateRequest,
  chartCommonRangeUpdate,
  ChartCommonRangeUpdateData,
  chartRemoveRequest,
  createChartRequest,
  requestDataChart,
  updateChart,
} from '@app/store/reducers/charts.slice';
import { projectsRequest } from '@app/store/reducers/analyticsSlice';
import { getFilterSlice } from '@app/store/reducers/filterSlice';
import { LiveReportRequest, ReportResponse, ReportTimeRange } from '@app/interfaces/report.type';
import {
  createMetricRequest,
  CreateMetricRequestType,
  editMetricRequest,
  EditMetricRequestData,
  metricListRequest,
  MetricListRequestParams,
  metricSettingsReset,
  projectMetricListRequest,
  recordMetricListRequest,
  RecordMetricListRequestType,
} from '@app/store/reducers/metric-slice';
import {
  audioRecordRequest,
  getRecordsThunk,
  RecordRequestData,
  recordsLiveReportsListRequest,
  recordTranscriptionRequest,
  removeRecordRequest,
  singleRecordRequest,
} from '@app/store/reducers/recordsSlice';
import {
  chartSettingsRequest,
  chartSettingsReset,
  editChartLiveReportRequest,
} from '@app/store/reducers/chart-settings.slice';
import { aliasesRequest, tenantAliasesRequest } from '@app/store/reducers/aliases-slice';
import { RecordMetaRequest, recordMetaRequest } from '@app/store/reducers/meta.slice';
import {
  audioChannelSettingsRequest,
  audioChannelsSettingsRequest,
} from '@app/store/reducers/audio-channels.slice';
import { CompositeRecordParams } from '@app/interfaces/records';
import { FilterItem } from '@app/interfaces/filter';

const mapGlobalStateToProps = (state: RootState) => ({
  filterState: state.filter,
  analyticsState: state.analytics,
  analyticState: state.analytic,
  recognitionModelsState: state.recognitionModels,
  recordsState: state.records,
  reportsState: state.reports,
  presetsState: state.presets,
  metricState: state.metric,
  aliasesState: state.aliases,
  dictionarisState: state.dictionaris,
  chartsState: state.charts,
  chartSettingsState: state.chartSettings,
  metaState: state.meta,
  audioChannelState: state.audioChannels,
});

const mapGlobalDispatchToProps = (dispatch) => ({
  /* channel settings */
  /**
   * Возвращает настройки всех каналов
   */
  audioChannelsSettingsRequest: () => dispatch(audioChannelsSettingsRequest()),
  /**
   * Возвращает настройки всех каналов
   // * @param params
   */
  audioChannelSettingsRequest: () => dispatch(audioChannelSettingsRequest()),
  /* meta */
  recordMetaRequest: (params: RecordMetaRequest) => dispatch(recordMetaRequest(params)),
  /* analytics */
  projectsRequest: () => dispatch(projectsRequest()),
  /* records */
  compositeRecordRequest: (params: CompositeRecordParams) =>
    dispatch(getRecordsThunk.compositeRecordThunk(params)),
  getFileRecordThunk: (params: { recordId: string }) =>
    dispatch(getRecordsThunk.getFileRecordThunk({ recordId: params.recordId })),
  removeRecordRequest: (params: { items: Array<string> }) => dispatch(removeRecordRequest(params)),
  recordTranscriptionRequest: (params: { record_id: string }) =>
    dispatch(recordTranscriptionRequest(params)),
  audioRecordRequest: (params: { record_id: string }) => dispatch(audioRecordRequest(params)),
  singleRecordRequest: (params: { record_id: string }) => dispatch(singleRecordRequest(params)),
  recordsLiveReportsListRequest: (data: RecordRequestData) =>
    dispatch(recordsLiveReportsListRequest(data)),
  /* metric */
  // Сбросить настройки метрики
  metricSettingsReset: () => dispatch(metricSettingsReset()),
  recordMetricListRequest: (params: RecordMetricListRequestType) =>
    dispatch(recordMetricListRequest(params)),
  createMetricRequest: (allParams: CreateMetricRequestType) =>
    dispatch(createMetricRequest(allParams)),
  projectMetricListRequest: (data: MetricListRequestParams) =>
    dispatch(projectMetricListRequest(data)),
  metricListRequest: (data: MetricListRequestParams) => dispatch(metricListRequest(data)),
  /* reports */
  editLiveReportRequest: (data: EditLiveReportRequest) => dispatch(editLiveReportRequest(data)),
  liveReportResponse: (payload: ReportResponse) => dispatch(liveReportResponse(payload)),
  createLiveReportRequest: (data: LiveReportRequest) => dispatch(createLiveReportRequest(data)),
  liveReportSettingsRequest: (data: LiveReportSettingsRequestData) =>
    dispatch(liveReportSettingsRequest(data)),
  liveReportsRequest: (data: LiveReportsRequestData) => dispatch(liveReportsRequest(data)),
  reportsRequest: (data: { projectId?: string }) => dispatch(reportsRequest(data)),
  createReportRequest: (data: CreateReportData) => dispatch(createReportRequest(data)),
  reportRequest: ({ report_id }: { report_id: string }) => dispatch(reportRequest({ report_id })),
  removeLiveReportRequest: (params: RemoveLiveReportData) =>
    dispatch(removeLiveReportRequest(params)),
  removeReportsRequest: ({ id }: { id: string }) => dispatch(removeReportsRequest({ id })),
  /* charts */
  // обновление range всех графиков
  chartCommonRangeUpdate: (params: ChartCommonRangeUpdateData) =>
    dispatch(chartCommonRangeUpdate(params)),
  // Обновление range для графика
  chartAsyncUpdateRequest: ({ id, range }: { id: string; range: ReportTimeRange }) =>
    dispatch(chartAsyncUpdateRequest({ id, range })),
  updateChart: (data: { id: string; name: string; range: ReportTimeRange }) =>
    dispatch(updateChart(data)),
  editChartLiveReportRequest: (data) => dispatch(editChartLiveReportRequest(data)),
  chartSettingsReset: () => dispatch(chartSettingsReset()),
  chartSettingsRequest: (params: { id: string }) => dispatch(chartSettingsRequest(params)),
  requestDataChart: ({ id }: { id: string }) => dispatch(requestDataChart({ id })),
  chartAsyncLoadingRequest: (reportId: string, filter?: FilterItem[]) =>
    dispatch(chartAsyncLoadingRequest({ reportId, filter })),
  chartRemoveRequest: (params: { id: string }) => dispatch(chartRemoveRequest(params)),
  createChartRequest: (data) => dispatch(createChartRequest(data)),
  /* status */
  changeStatusRequest: (data: ChangeStatusRequest) => dispatch(changeStatusRequest(data)),
  // aliases
  tenantAliasesRequest: () => dispatch(tenantAliasesRequest()),
  aliasesRequest: () => dispatch(aliasesRequest()),
  getAliases: () => dispatch(getFilterSlice.getAliasesThunk()),
  editMetricRequest: (data: EditMetricRequestData) => dispatch(editMetricRequest(data)),
});

const connector = connect(mapGlobalStateToProps, mapGlobalDispatchToProps);
export default connector;
export type PropsFromRedux = ConnectedProps<typeof connector>;
