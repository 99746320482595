import React, { useCallback, useEffect, useState, FC } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { chain } from 'lodash';

import { Button, NavigationTabs } from '@app/components/ui';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { navigationData } from '@app/pages/analytics/blocks/navigation.data';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import RecordsTableWithFilter from '@app/components/records-table-with-filter';
import useUniversalFilterValues from '@app/hooks/use-universal-filter-values';
import { setLimit, setOffset, setSort } from '@app/store/reducers/filterSlice';
import { FilterItemNotOnlyArray, FilterRangeItem, GetFilterParams } from '@app/interfaces/filter';
import { getMetricThunks } from '@app/store/reducers/metric-slice';
import connector, { PropsFromRedux } from '@app/utils/store';
import { TableItem, TableTitle } from '@app/components/ui/table/table.type';
import cloneFilterWithValues from '@app/hooks/use-universal-filter-values/cloneFilterWithValues';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { DownloadCloudIcon, Oscilogram } from '@app/components/ui/icons/icons-list';
import classNames from 'classnames';
import { getRecordsThunk } from '@app/store/reducers/recordsSlice';
import { LiveReport } from '@app/interfaces/report.type';

import useTranslation from '@app/hooks/use-translation';

import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';

import { GroupSharedStatus } from '@app/interfaces/analytics';

import { MetricMetricItemData } from '@app/interfaces/metric.type';

import { Loading } from '@ui';

import TimeDuration from './blocks/time-duration';
import { getMetricScoreStyles, getMetricScoreStyles100 } from './styles/styles';

const localStorageLimit = 'ANALYTICS_PROJECT_RECORDS_PAGE_LIMIT';
const DEFAULT_RECORDS_LIMIT = 10;
const ProjectRecordsPage: FC<PropsFromRedux> = (props) => {
  const { createLiveReportRequest } = props;
  const { t } = useTranslation('pages.records');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const getParams = useUniversalFilterValues();
  const [recordLoading] = useState(false);
  const [filterState, changeFilter] = useState<{
    range: FilterRangeItem;
    filter: FilterItemNotOnlyArray[];
  }>({
    range: {
      type: 'n',
      parameters: [],
    },
    filter: [],
  });
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [offset, changeOffset] = useState<number>(0);
  const [sort, changeSortParams] = useState<{ sortBy: string; sortDesc: boolean } | undefined>();
  const { filter, range } = useAppSelector((state) => state.filter);
  const { metricsItems, totalMetricsItems: total } = useAppSelector((state) => state.metric);
  const tableUtilIconsClassName = classNames(
    'text-3color transition hover:text-action cursor-pointer transition cursor-pointer',
  );
  const [reportsTableLimit, changeReportsTableLimit] = useState<number>(
    Number(localStorage.getItem(localStorageLimit)) || DEFAULT_RECORDS_LIMIT,
  );
  const { t: ti } = useTranslation('components.navigateTabs');
  const data: NavigationTabsItem[] = navigationData(project?.project_id).map((item) => ({
    ...item,
    label: ti(item.label),
  }));
  const sortByOrder = (data) => {
    const value = Object.entries(data)[0];
    const params = {
      sortBy: value?.[0] || '',
      sortDesc: value?.[1] === 1,
    };
    dispatch(setSort(params));
    changeSortParams(params);
  };
  const checkingAliasForSorting = (index) => {
    if (index === 0) {
      return false;
    } else if (index === metricsItems.headers.length + 1) {
      return false;
    }
    return true;
  };
  function displayItemVisualization(item: MetricMetricItemData) {
    if (item.visualization) {
      if (item.visualization === 'score5') {
        if (Number(item.value) > 5 || Number(item.value) === 0) {
          return item.value;
        } else {
          return (
            <div
              className={`${getMetricScoreStyles(
                Number(item.value),
              )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
            >
              {item.value}
            </div>
          );
        }
      } else if (item.visualization === 'score100') {
        if (Number(item.value) > 100 || Number(item.value) === 0) {
          return item.value;
        } else {
          return (
            <div
              style={{ width: `${22 + Number(item.value) * 1.5}px` }}
              className={`${getMetricScoreStyles100(
                Number(item.value),
              )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
            >
              {item.value}
            </div>
          );
        }
      } else return item.value;
    } else return item.value;
  }
  const sizeFunc = (index) => {
    if (index === 0) {
      return 160;
    } else if (index === metricsItems.headers.length + 1) {
      return 110;
    } else {
      return 190;
    }
  };
  const tableColumns = metricsItems.headers.length
    ? chain(metricsItems.headers)
        .cloneDeep()
        .unshift({
          metric_id: 'duration_project_records_items',
          name: t('duration'),
        })
        .push({
          metric_id: 'actions_project_records_items',
          name: t('actions'),
        })
        .map(
          (header, index, arr): TableTitle => ({
            title: header.name,
            index: header.metric_id,
            size: sizeFunc(index),
            divider: index === arr.length - 1 ? 'left' : undefined,
            truncate: true,
            filter: checkingAliasForSorting(index),
            hintTitle: index === 0 ? t('to_record_title') : '',
          }),
        )
        .value()
    : [];
  const tableData: TableItem[] = metricsItems.metrics.map((record) => {
    const obj = {};
    chain(record.data)
      .cloneDeep()
      .unshift({
        metric_id: 'duration_project_records_items',
        value: '',
      })
      .push({
        metric_id: 'actions_project_records_items',
        value: '',
      })
      .map((item, index, arr) => {
        // obj[item.metric_id] = item.value || <span>&mdash;</span>;
        obj[item.metric_id] =
          index === 0 ? (
            <div className="flex cursor-pointer">
              <Link
                target="_blank"
                className="inline-flex items-center"
                to={`/${navigationRoutes.projectRecords}/${project?.project_id}/${record.record_id}`}
              >
                <TimeDuration duration={record.duration} />
              </Link>
            </div>
          ) : index === arr.length - 1 ? (
            <div className="flex items-center pl-[19px]">
              <DownloadCloudIcon
                hintTitle={t('download_record')}
                size={18}
                className={`${tableUtilIconsClassName}, mr-[18px]`}
                onClick={() => getRecordsAudio([record.record_id])}
              />
              <Link
                target="_blank"
                to={`/${navigationRoutes.projectRecords}/${project?.project_id}/${record.record_id}`}
              >
                <Oscilogram
                  hintTitle={t('to_record_title')}
                  size={22}
                  className={tableUtilIconsClassName}
                />
              </Link>
            </div>
          ) : (
            displayItemVisualization(item)
          );
      })
      .value();
    return obj;
  });

  const getRecordsAudio = (recordsIds: string[]) => {
    for (const id of recordsIds) {
      dispatch(getRecordsThunk.getRecordAudioThunk({ id }));
    }
  };

  const getRecords = useCallback(
    (params: GetFilterParams) => {
      setLocalLoading(true);
      return dispatch(
        getMetricThunks.getMetricsBulk({
          ...params,
          project_id: project?.project_id || '',
          limit: reportsTableLimit,
        }),
      )
        .unwrap()
        .finally(() => setLocalLoading(false));
    },
    [dispatch, project?.project_id, reportsTableLimit],
  );
  useEffect(() => {
    getRecords(
      getParams({
        filter: filterState,
        preset_mode: false,
        limit: reportsTableLimit,
        offset,
        ...sort,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, getRecords, offset, reportsTableLimit, sort]);
  function changePage({ offset, limit }: PaginationResponse) {
    if (!project) return;
    dispatch(setOffset(offset));
    dispatch(setLimit(limit));
    changeOffset(offset);
  }

  function getAliases() {
    return dispatch(getMetricThunks.getMetricList(project?.project_id || '')).unwrap();
  }

  async function createLiveReport() {
    if (!project) return;
    const result: LiveReport = await createLiveReportRequest({
      name: `${project.name}`,
      description: project.description,
      filter: {
        filter: cloneFilterWithValues(filter),
        range,
      },
      project_id: project.project_id,
    });
    navigate(`/${navigationRoutes.reports}/${result.live_report_id}/${navigationRoutes.settings}`, {
      state: navigationRoutes.reports,
    });
  }

  const getTitleFilter = () => {
    return <h2 className="text-1color font-bold">{t('filter_records')}</h2>;
  };

  const getFooterFilter = () => (
    <Button
      label={process.env.REACT_APP_THEME === 'rtk' ? t('create_report_rtk') : t('create_report')}
      disabled={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
      onClick={() => createLiveReport()}
    />
  );

  return (
    <div>
      <NavigationTabs items={data} />
      {localLoading && <Loading />}
      <RecordsTableWithFilter
        titleFilter={getTitleFilter()}
        footerFilter={getFooterFilter()}
        tableColumns={tableColumns}
        tableData={tableData}
        getRecords={getRecords}
        getParams={getParams}
        getAliases={getAliases}
        metricMode={true}
        changePage={changePage}
        totalItems={total}
        changeSortTable={sortByOrder}
        resetFilterOnRender
        loading={recordLoading}
        changeReportsTableLimit={changeReportsTableLimit}
        reportsTableLimit={reportsTableLimit}
        localStorageLimit={localStorageLimit}
        actionsWhenSavingFilter={(filter) => {
          changeFilter({ ...filter, filter: cloneFilterWithValues(filter.filter) });
        }}
      />
    </div>
  );
};

export default connector(ProjectRecordsPage);
