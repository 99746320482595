import { EasyModal, Loading } from '@app/components/ui';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import AccordionUI from '@app/components/ui/Accordion/Aссordion';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import React, { Dispatch, SetStateAction } from 'react';
import connector, { PropsFromRedux } from '@app/utils/store';
import { BigChartIcon } from '@app/components/ui/icons/icons-list';

import useTranslation from '@app/hooks/use-translation';

import { chartCommonInfoRequest } from '@app/api/chartsApi';

import { ReportListChart } from '@app/interfaces/report.type';

import { ChartEditModal } from './chart-edit-modal';

type PropsType = {
  createModalState: boolean;
  setCreateModalState: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
};

const CreateChartModal: React.FC<PropsType & PropsFromRedux> = (props) => {
  const {
    createModalState,
    setCreateModalState,
    reportsState: { liveReports, isPending },
    liveReportsRequest,
  } = props;
  const [chartList, setChartList] = React.useState<ReportListChart[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [chartSettingsModal, setChartSettingsModal] = React.useState(false);
  const [chartId, setChartId] = React.useState('');
  const { t } = useTranslation('pages.dashboard');
  React.useEffect(() => {
    createModalState && liveReportsRequest({ limit: 100 });
  }, [createModalState, liveReportsRequest]);
  //accordion
  const [activeAccordionItem, setActiveAccordionItem] = React.useState<string | undefined>(
    undefined,
  );
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    if (event.id === activeAccordionItem) {
      if (event.id === '') {
        setActiveAccordionItem(undefined);
      } else {
        setActiveAccordionItem('');
      }
    } else {
      setActiveAccordionItem(event.id);
      setLoading(true);
      chartCommonInfoRequest(event.id)
        .then((res) => setChartList(res))
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    }
  };
  //accordeon
  const getChartName = () => {
    return chartList.find((e) => e.graph_id === chartId)?.name;
  };

  const onChartClick = (id: string) => {
    setChartId(id);
    setCreateModalState(false);
    setChartSettingsModal(true);
  };
  if (isPending) return <Loading />;
  return (
    <>
      <EasyModal
        variant="mediumW650"
        withoutFooter
        show={createModalState}
        onClose={() => setCreateModalState(false)}
        label={
          <div className="text-0color text-[24px] font-bold mb-[25px]">
            {t('add_chart_from_report')}
          </div>
        }
      >
        <div className="w-full">
          <AccordionUI>
            {liveReports.map((item) => {
              return (
                <AccordionItemUI
                  key={item.live_report_id}
                  id={item.live_report_id}
                  title={item.name}
                  clickBtnChild={clickAccordionHeaderBtn}
                  active={activeAccordionItem === item.live_report_id}
                >
                  {activeAccordionItem === item.live_report_id && (
                    <ul className="flex w-full rounded-[12px] flex-col border-[1px] border-basic_app_bg">
                      {loading ? (
                        <Loading />
                      ) : (
                        chartList.map((item) => {
                          return (
                            <li
                              className="w-full items-center flex justify-between border-b-[1px] border-basic_app_bg p-[10px] text-1color text-[16px] min-h-[90px]"
                              key={item.graph_id}
                            >
                              <BigChartIcon
                                className="p-[16px_11px_11px_11px] bg-basic_app_bg"
                                size={67}
                              />
                              <div className="flex">
                                <button onClick={() => onChartClick(item.graph_id)}>
                                  <div className="flex items-start flex-col">
                                    <div className="font-medium max-w-[250px] whitespace-nowrap text-ellipsis overflow-hidden">
                                      {item.name}
                                    </div>
                                  </div>
                                </button>
                              </div>
                              <span className="w-[28px] text-[#1C1C1E] bold">&gt;</span>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  )}
                </AccordionItemUI>
              );
            })}
          </AccordionUI>
        </div>
      </EasyModal>
      {chartSettingsModal && (
        <ChartEditModal
          live_report_graph_id={chartId}
          onClose={setChartSettingsModal}
          show={chartSettingsModal}
          getChartName={getChartName}
        />
      )}
    </>
  );
};

export default connector(CreateChartModal);
