import React, { FC, useState } from 'react';
import cn from 'classnames';

import { Paginator, Skeleton, Table } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useDeleteRecordsMutation, useGetRecordsQuery } from '@app/store/api/records.api';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { FilterTableData, TableItem, TableTitle } from '@app/components/ui/table/table.type';
import { Link } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { DownloadCloudIcon, Oscilogram } from '@icons';
import Modal from '@app/components/ui/Modal';

import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';
import { tableUtilIconsClassName } from '@app/pages/records/blocks/records-table/records-table.styles';
import { getRecordAudio } from '@app/api/record.api';
import fileDownload from 'js-file-download';
import { useGetAliasesListQuery } from '@app/store/reducers/alias-settings-page.api';
import { PRESET_SETTINGS_RECORDS_PAGE_LIMIT } from '@app/utils/constants';

type RecordsTableBlockPropsType = {
  recordsParams: GetFilterParamsNew;
  getRecordsParams: React.Dispatch<React.SetStateAction<GetFilterParamsNew>>;
};

export const SinglePresetSettingsTableBlock: FC<RecordsTableBlockPropsType> = (props) => {
  const { recordsParams, getRecordsParams } = props;
  const { t } = useTranslation('pages.records');
  const pageOptionsData = [
    { title: '10 ' + t('calls'), value: 10 },
    { title: '15 ' + t('calls'), value: 15 },
    { title: '25 ' + t('calls'), value: 25 },
    { title: '50 ' + t('calls'), value: 50 },
  ];

  const [deleteRecordIds, changeDeleteRecordsIds] = useState<Array<string>>([]);
  const { data: recordsList, isLoading } = useGetRecordsQuery(recordsParams);
  const [deleteRecordRequest, { isLoading: isDeleteLoading }] = useDeleteRecordsMutation();
  const { data } = useGetAliasesListQuery();
  const checkingAliasForSorting = (alias_id: string) => {
    const alias = data?.aliases.find((alias) => alias.alias_id === alias_id);
    if (alias) {
      return alias.type_meta !== 'channel';
    }
    return false;
  };
  const tableTitles: TableTitle[] = recordsList?.headers.length
    ? [
        {
          index: 'duration_records_items',
          title: t('duration'),
        },
        ...recordsList.headers.map((column) => ({
          index: column.alias_id,
          title: column.name,
          filter: checkingAliasForSorting(column.alias_id),
        })),
        { index: 'table_record_details', title: t('actions'), size: 120 },
      ]
    : [];
  const tableData: TableItem[] =
    recordsList?.records.reduce<TableItem[]>((dataTable, record) => {
      const cell: TableItem = {};
      cell['duration_records_items'] = (
        <div className="truncate w-full flex items-center">
          <div className="flex cursor-pointer">
            <Link
              target="_blank"
              className="inline-flex items-center"
              to={`/${navigationRoutes.records}/${record.record_id}`}
            >
              <TimeDuration duration={record.duration} />
            </Link>
          </div>
        </div>
      );
      cell['table_record_details'] = (
        <div className="flex items-center w-[110px] gap-[15px] justify-center">
          <DownloadCloudIcon
            hintTitle={t('download_record')}
            size={18}
            className={tableUtilIconsClassName}
            onClick={() => downloadAudioHandler({ record_id: record.record_id })}
          />
          <Oscilogram
            hintTitle={t('to_record_title')}
            size={22}
            className={cn(tableUtilIconsClassName, 'text-bold')}
            onClick={() =>
              window.open(`/${navigationRoutes.records}/${record.record_id}`, '_blank')
            }
          />
        </div>
      );
      for (const tableCell of record.data) {
        cell[tableCell.alias_id] = tableCell.value;
      }
      dataTable.push(cell);
      return dataTable;
    }, []) || [];

  function paginatorChangePageHandler(paginationData: PaginationResponse) {
    getRecordsParams((prevState) => ({ ...prevState, ...paginationData }));
    localStorage.setItem(PRESET_SETTINGS_RECORDS_PAGE_LIMIT, String(paginationData.limit));
  }
  function tableChangeFilterHandler(tableFilter: FilterTableData) {
    getRecordsParams((prevState) => ({ ...prevState, ...tableFilter }));
  }

  async function downloadAudioHandler(params: { record_id: string }) {
    return await getRecordAudio({ id: params.record_id }).then((res) =>
      fileDownload(
        res.data,
        res.headers['content-disposition'].replace(/"/gim, '').replace('attachment; filename=', ''),
      ),
    );
  }

  function removeRecords() {
    changeDeleteRecordsIds((prevState) => {
      deleteRecordRequest({ items: prevState });
      return [];
    });
  }
  const LoadingSkeleton = (
    <div className={'flex flex-col gap-[10px] pb-2'}>
      <Skeleton height={400} className={'mt-6'} />
      <Skeleton height={50} />
    </div>
  );
  if (isLoading) return LoadingSkeleton;

  return (
    <div className="relative">
      <div className="flex flex-col gap-[10px] pb-2">
        <Table
          columns={tableTitles}
          dataSource={tableData}
          isPending={isLoading}
          onFilter={tableChangeFilterHandler}
        />
        <Paginator
          limit={recordsParams.limit}
          page={Number(recordsParams.offset)}
          count={Number(recordsList?.total)}
          onChange={paginatorChangePageHandler}
          pageOptions={pageOptionsData}
        />
      </div>
      <Modal
        id="deleteRecordFormModal"
        value={Boolean(deleteRecordIds.length)}
        setValue={changeDeleteRecordsIds.bind(null, [])}
        title={
          deleteRecordIds.length > 1 ? (
            <>
              {t('popover.delete_selected')}
              <span className="text-basic_red">{changeDeleteRecordsIds.length}</span>
            </>
          ) : (
            <>
              {t('delete_record')} <span className="text-basic_red">{}</span>
            </>
          )
        }
        onApply={removeRecords}
        onClose={changeDeleteRecordsIds.bind(null, [])}
        loading={isDeleteLoading}
        size="xs"
        variant="removal"
      >
        {deleteRecordIds.length > 1 ? t('delete_all_records') : t('record_wb_deleted')}
      </Modal>
    </div>
  );
};
