import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ReportChart } from '@app/interfaces/report.type';

import { ApexOptions } from 'apexcharts';

import { DownloadCloudIcon } from '../ui/icons/icons-list';

const DEFAULT_BAR_WIDTH = 75;
const DEFAULT_BORDER_RADIUS = 2;
const DEFAULT_TRANSLATION_LIST = {
  download: 'Скачать SVG',
  selection: 'Выбрать',
  selectionZoom: 'Увеличить выбранное',
  zoomIn: 'Увеличить',
  zoomOut: 'Уменьшить',
  pan: 'Переместить',
  reset: 'Сбросить настройки',
};
const downloadChartMenu = (title?: string) =>
  ReactDOMServer.renderToString(
    <div title={title} className="mt-[2px] ml-[3px]">
      <DownloadCloudIcon
        className="text-3color active:scale-[90%] z-[0] hover:opacity-80"
        size={22}
      />
    </div>,
  );

const seriesCalculator = (chart: ReportChart) => {
  if (!chart?.series) return [];
  switch (chart.type) {
    case 'piechart':
      return chart?.series.map((seriesItem) => seriesItem.data[0]) || [];
    case 'linechart':
      return chart?.series?.map((seriesItem) => ({
        name: seriesItem.name,
        type: 'line',
        data: seriesItem.data.map((series, index) => ({
          y: series,
          x: typeof chart.categories[index] === 'number' ? chart.categories[index] : index,
        })),
        position: 'top',
      }));
    case 'horizontal_barchart':
      return chart?.series?.map((seriesItem) => ({
        name: seriesItem.name,
        type: 'column',
        data: seriesItem.data,
        position: 'top',
      }));
    default: {
      return chart?.series?.map((seriesItem) => ({
        name: seriesItem.name,
        data: seriesItem.data,
        position: 'top',
      }));
    }
  }
};
// BACK
// Условие для piechart: Возвращать в series список data с единственным элементом
// Условие для linechart: Возвращать categories только number или Date ( он ставит точку по оси y) series.data это список точек по оси x значение тоже number

const selectChartType = (type: ReportChart['type']) => {
  const DEFAULT_CHART: ReportChart['type'] = 'barchart';
  console.log('type', type);
  const chartSettings: Record<
    ReportChart['type'],
    {
      chart: ApexOptions['chart'];
      plotOptions?: ApexOptions['plotOptions'];
    }
  > = {
    piechart: { chart: { type: 'pie', width: 380 } },
    number: { chart: { type: 'line' } },
    linechart: { chart: { type: 'line' } },
    columnchart: { chart: { type: 'bar', stacked: true } },
    horizontal_barchart: { chart: { type: 'bar' }, plotOptions: { bar: { horizontal: true } } },
    barchart: { chart: { type: 'bar' }, plotOptions: { bar: { horizontal: false } } },
  };
  return type ? chartSettings[type] || chartSettings[DEFAULT_CHART] : chartSettings[DEFAULT_CHART];
};
export const chartBuildData = ({
  chart,
  translationList,
  isDashboard = false,
}: {
  chart: ReportChart;
  translationList?: Partial<typeof DEFAULT_TRANSLATION_LIST>;
  isDashboard?: boolean;
}) => ({
  options: {
    labels: chart.series.length ? chart?.series.map((seriesItem) => seriesItem.name) : [],
    grid: {
      strokeDashArray: 3.3,
      position: 'back',
      borderColor: '#E5E8EB',
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    chart: {
      id: `chart_${chart.graph_id}`,
      height: 10,
      type: 'bar',
      toolbar: {
        autoSelected: 'reset',
        offsetX: -55,
        offsetY: -43,
        tools: {
          download: downloadChartMenu((translationList || DEFAULT_TRANSLATION_LIST)['download']),
          pan: !isDashboard,
          zoom: !isDashboard,
          selection: true,
          zoomIn: true,
          zoomOut: true,
          reset: true,
        },
      },
      defaultLocale: 'ru',
      locales: [
        {
          name: 'ru',
          options: {
            toolbar: { ...DEFAULT_TRANSLATION_LIST, ...translationList },
          },
        },
      ],
      ...selectChartType(chart.type).chart,
    },
    dataLabels: {
      enabled: chart.display_data,
      offsetY: -17,
      style: {
        fontSize: '12px',
        colors: ['rgb(114, 114, 114)'],
        zIndex: 3,
      },
      // background: {
      //   enabled: false,
      //   foreColor: 'rgb(114,114,114)',
      // },
    },
    colors: chart?.series?.map((seriesItem) => seriesItem.color) as never,
    plotOptions: {
      bar: {
        borderRadiusApplication: 'end',
        borderRadius: DEFAULT_BORDER_RADIUS,
        columnWidth: `${chart?.bar_width || DEFAULT_BAR_WIDTH}%`,
        dataLabels: {
          position: 'top',
        },
        horizontal: false,
        ...(selectChartType(chart.type)?.plotOptions?.bar || {}),
      },
    },
    xaxis: {
      categories: chart.categories[0] !== null ? chart?.categories : [],
      tickPlacement: 'on',
      labels: {
        rotate: -45,
      },
    },
    legend: {
      show: chart.display_legend,
      showForSingleSeries: chart.display_legend,
      showForNullSeries: chart.display_legend,
      showForZeroSeries: chart.display_legend,
    },
  },
  series: seriesCalculator(chart),
});
