import React, { FC } from 'react';
import { PlayCircleIcon } from '@app/components/ui/icons/icons-list';
import useTimeLengthHumanize from '@app/hooks/use-time-length-humanize';

const TimeDuration: FC<{ duration: number }> = (props) => {
  const { duration } = props;
  const timeFormat = useTimeLengthHumanize('H:M:S');
  return (
    <div className="rounded-full h-[21px] font-[700] text-sm bg-bg_3 inline-flex items-center">
      <PlayCircleIcon size={22} className="text-3color" />
      <div className="text-0color pl-[8px] pr-[10px] leading-1">{timeFormat(duration)}</div>
    </div>
  );
};

export default TimeDuration;
