import { Button, CircleButton, Modal, PageHeader, TooltipWrapper } from '@app/components/ui';
import Icon from '@app/components/ui/icons';
import RubberTable from '@app/components/ui/rubber-table';
import useTranslation from '@app/hooks/use-translation';
import { getDictionariesThunks, setFilterDictionary } from '@app/store/reducers/dictionaries-slice';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { getFrontendDate } from '@app/utils/helpers';
import { navigationRoutes } from '@app/utils/navigation-routes';
import React from 'react';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';
import { Loading } from '@ui';

export const DictionariePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('pages.allPagesHintTitles');
  const { t: ti } = useTranslation('pages.dictionaries');
  const { dictionaries, loading } = useAppSelector((state) => state.dictionaris);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [dictionaryId, setDictionaryId] = React.useState<string>();

  React.useEffect(() => {
    dispatch(getDictionariesThunks.getAllDictionariesThunk());
  }, [dispatch]);
  const filteredArr = () => {
    return dictionaries.map((dataItem) => ({
      ...dataItem,
      nameCol: (
        <div className="flex items-center truncate pr-[5px]">
          <b className="truncate">{dataItem.name} </b>
          {dataItem.is_system && (
            <div className="flex items-center justify ml-[10px]">
              <Icon name={'LockCircle'} className="text-2color ml-[px]" size={19} />
            </div>
          )}
        </div>
      ),
      utils: (
        <div className="flex pl-[10px] w-full gap-[10px] items-center border-l-[1px] border-[#F1F2F3]">
          <CircleButton
            hintTitle={t('dictionaries.settings_icon')}
            link={`/${navigationRoutes.dictionaries}/${dataItem.dictionary_id}/edit`}
            icon="SettingIcon"
            size={19}
            className={`text-3color`}
          />
          <CircleButton
            hintTitle={t('dictionaries.copy_icon')}
            link={`/${navigationRoutes.dictionaries}/${dataItem.dictionary_id}/copy`}
            icon="CopyIcon"
            size={19}
            className={`text-3color`}
          />
          <CircleButton
            hintTitle={t('delete_icon')}
            onClick={() => {
              if (dataItem.num_child === 0) {
                setDictionaryId(dataItem.dictionary_id);
                setTimeout(() => {
                  setDeleteModal(true);
                }, 0);
              } else {
                toast.error(ti('cant_delete'));
              }
            }}
            icon="TrashIcon"
            size={19}
            className={`text-basic_red`}
            disable={dataItem.is_system}
          />
        </div>
      ),
      date: (
        <div className="flex max-w-[140px] gap-[10px] items-center">
          <Icon name={'CalendarIcon'} />
          <div>{getFrontendDate(dataItem.dt_update, 'dd.MM.yyyy')}</div>
        </div>
      ),
      params: (
        <div className="flex items-center gap-[8px]">
          <TooltipWrapper
            content={t('dictionaries.words_count_icon')}
            id={t('dictionaries.words_count_icon') + v4()}
          >
            <span className="flex items-center gap-[8px] bg-blue_pal rounded-[6px] p-[2px_8px_3px]">
              <Icon className="text-3color" name={'CharacterBook'} />
              {dataItem.num_items}
            </span>
          </TooltipWrapper>
          <TooltipWrapper
            content={t('dictionaries.childs_count_icon')}
            id={t('dictionaries.childs_count_icon') + v4()}
          >
            <span className="flex items-center gap-[8px] bg-blue_pal rounded-[6px] p-[2px_8px_3px]">
              <Icon className="text-3color" name={'JoystickTilt'} />
              {dataItem.num_child}
            </span>
          </TooltipWrapper>
          <TooltipWrapper
            content={t('dictionaries.parents_count_icon')}
            id={t('dictionaries.parents_count_icon') + v4()}
          >
            <span className="flex items-center gap-[8px] bg-blue_pal rounded-[6px] p-[2px_8px_3px]">
              <Icon className="text-3color" name={'ParentIcon'} />
              {dataItem.num_parents}
            </span>
          </TooltipWrapper>
        </div>
      ),
      description: (
        <TooltipWrapper content={dataItem.description} id={dataItem.description + v4()}>
          <div className="truncate">{dataItem.description}</div>
        </TooltipWrapper>
      ),
    }));
  };
  const tableSortKeyList = (sort: { [x: string]: 1 | -1 | undefined }) => {
    const value = Object.entries(sort)[0];
    dispatch(setFilterDictionary(value));
  };
  const columns = [
    {
      title: ti('table.name'),
      index: 'nameCol',
      filter: true,
    },
    {
      title: ti('table.desc'),
      index: 'description',
    },
    {
      title: ti('table.params'),
      index: 'params',
      maxWidth: 215,
    },
    {
      title: ti('table.date'),
      index: 'date',
      filter: true,
      maxWidth: 180,
    },
    {
      title: ti('table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      <Modal
        id={'deleteDictionary'}
        variant={'removal'}
        value={deleteModal}
        setValue={setDeleteModal}
        title={ti('warning')}
        size={'xs'}
        onApply={() => {
          dictionaryId &&
            dispatch(getDictionariesThunks.deleteDictionaryThunk(dictionaryId))
              .unwrap()
              .catch((err) => {
                if (Number(err) === 403) {
                  toast.dismiss();
                  toast.info(ti('dictDeleteToastError'));
                }
              });

          setDeleteModal(false);
        }}
      >
        <div className="px-[50px]">{ti('dict_confirm_delete')}</div>
      </Modal>
      <PageHeader icon="BookIcon" label={ti('dictionaries')}>
        <Button
          label={ti('add_dictionaries')}
          icon="CirclePlusIcon"
          link={navigationRoutes.createDictionaries}
        />
      </PageHeader>
      <RubberTable
        isPending={loading}
        onFilter={tableSortKeyList}
        columns={columns}
        dataSource={filteredArr()}
      />
    </>
  );
};
