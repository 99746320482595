import React from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import AccordionUI from '@app/components/ui/Accordion/Aссordion';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import { Button, EditText, PageHeader } from '@app/components/ui';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';
import { ColsWithoutTable } from '@app/components/ui/rubber-table/blocks/cols-without-table';

import {
  useCreatePresetTemplateGroupMutation,
  useGetPresetTemplateGroupsListQuery,
} from '@app/store/api/preset-template.api';

import { AccordionTitle } from './blocks/accordionTitle';
import { Container } from './blocks/container';
import PresetsPageSkeleton from './blocks/presets-page.skeleton';

export const PresetsPage: React.FC = () => {
  const [edit, setEdit] = React.useState(false);
  const [groupName, setNewGroupName] = React.useState('');
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('pages.presets');
  // api
  const { data: groups, isLoading: loading } = useGetPresetTemplateGroupsListQuery();
  const [createPresetGroup] = useCreatePresetTemplateGroupMutation();
  // handlers

  const createNewGroup = (name: string) => {
    setNewGroupName(name);
    if (name !== '') {
      createPresetGroup({ name }).then(() => {
        setNewGroupName('');
        setEdit(false);
      });
    } else if (groupName === '') {
      setEdit(false);
      toast.error(t('cant_create_group'));
    }
  };

  const [activeAccordionItem, setActiveAccordionItem] = React.useState<Array<string | undefined>>(
    localStorage.getItem('preset_accordion')?.split(',') || [],
  );
  React.useEffect(() => {
    const accordionListFromStorage = localStorage.getItem('preset_accordion');
    if (!accordionListFromStorage) {
      const defaultGroup = groups?.find((group) => group.is_default === true)?.preset_group_id;
      setActiveAccordionItem([defaultGroup]);
      defaultGroup && localStorage.setItem('preset_accordion', defaultGroup);
    }
  }, [groups]);

  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    setActiveAccordionItem((prev) => {
      if (!prev.includes(event.id)) {
        localStorage.setItem('preset_accordion', [...prev, event.id].join(','));
        return [...prev, event.id];
      } else {
        const accordionListFromStorage = localStorage.getItem('preset_accordion')?.split(',');
        const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
          (item) => item !== event.id,
        ) as string[];
        localStorage.setItem('preset_accordion', filteredAccordionListFromStorage.join(','));

        return prev.filter((item) => item !== event.id);
      }
    });
  };

  function createNewPreset() {
    navigate(`/${navigationRoutes.presets}/new`);
  }
  const columns = [
    {
      title: t('table.name'),
      index: 'name',
      className: 'ml-[15px]',
    },
    {
      title: t('table.time'),
      index: 'time',
    },
    {
      title: t('table.desc'),
      index: 'filter',
    },
    {
      title: t('table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];
  if (loading) return <PresetsPageSkeleton />;
  return (
    <>
      <PageHeader icon="MenuSmallIcon" label={t('presets_templates')}>
        <Button
          icon="CirclePlusIcon"
          label={t('create_preset_template')}
          onClick={() => createNewPreset()}
        />
      </PageHeader>

      <ColsWithoutTable columns={columns} />
      <div className={'flex flex-col gap-[20px]'}>
        <AccordionUI>
          {groups?.map((group) => {
            return (
              <AccordionItemUI
                variant="filled"
                key={group.preset_group_id}
                id={group.preset_group_id}
                title={
                  <AccordionTitle
                    setActiveAccordionItem={setActiveAccordionItem}
                    name={group.name}
                    isDefault={group.is_default}
                    active={activeAccordionItem?.includes(group.preset_group_id)}
                    presetsCount={group.presets.length}
                    preset_group_id={group.preset_group_id}
                  />
                }
                clickBtnChild={clickAccordionHeaderBtn}
                active={activeAccordionItem?.includes(group.preset_group_id)}
              >
                {activeAccordionItem?.includes(group.preset_group_id) && (
                  <Container group={group.presets} />
                )}
              </AccordionItemUI>
            );
          })}
          {edit && (
            <AccordionItemUI
              variant="filled"
              title={
                <div className="max-w-[240px]">
                  <EditText
                    textStyle="font-[700] text-[13px] text-2color leading-[14px]"
                    editIconSize={14}
                    text={groupName}
                    onEdited={createNewGroup}
                    defaultOpen
                  />
                </div>
              }
              id={''}
            >
              {''}
            </AccordionItemUI>
          )}
        </AccordionUI>

        <div className="">
          <Button label="Новая группа" icon="CirclePlusIcon" onClick={() => setEdit(!edit)} />
        </div>
      </div>
    </>
  );
};
