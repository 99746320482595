import React, { FC } from 'react';
import { IconType } from '@app/components/ui/icons/icon.type';

const CloudIcon: FC<IconType> = (props) => {
  const { size, className } = props;
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 40 32"
      >
        <path
          fill="currentColor"
          d="M35.731 14.925A6.996 6.996 0 0029 6a6.924 6.924 0 00-3 .675A10.989 10.989 0 0017 2C11.1 2 6.269 6.656 6.012 12.512A8.998 8.998 0 000 21c0 4.975 4.025 9 9 9h23c4.419 0 8-3.575 8-8a7.992 7.992 0 00-4.269-7.075zM32 28H9c-3.869 0-7-3.131-7-7a6.996 6.996 0 016.063-6.938A8.583 8.583 0 018 13c0-4.969 4.031-9 9-9a9.003 9.003 0 018.337 5.6 4.999 4.999 0 017.606 6.475A5.994 5.994 0 0138 22c0 3.313-2.688 6-6 6z"
        ></path>
      </svg>
    </div>
  );
};

export default CloudIcon;
