import { getAliasRecordPage } from '@app/api/record.api';
import {
  FilterAliases,
  FilterItem,
  FilterRangeItem,
  FilterReducerState,
} from '@app/interfaces/filter';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const getFilterSlice = {
  getAliasesThunk: createAsyncThunk<FilterAliases>(
    'FilterSlice/getAliasesThunk',
    async (_, { rejectWithValue }) => {
      return await getAliasRecordPage()
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
};

export const initialState: FilterReducerState = {
  aliases: [],
  filter: [],
  cloneFilter: [],
  limit: 10,
  offset: 0,
  range: {
    type: 'n',
    parameters: [],
  },
  cloneRange: {
    type: 'n',
    parameters: [],
  },
  sortBy: '',
  sortDesc: false,
  preset_mode: false,
  loading: false,
};

const filterSlice = createSlice({
  name: 'FilterSlice',
  initialState,
  reducers: {
    setAliases(state, action) {
      state.aliases = action.payload;
    },
    setFilter(state, action: PayloadAction<FilterItem[]>) {
      state.filter = action.payload;
    },
    setCloneFilter(state, action: PayloadAction<FilterItem[]>) {
      state.cloneFilter = action.payload;
    },
    setRange(state, action: PayloadAction<FilterRangeItem>) {
      state.range = action.payload;
    },
    setCloneRange(state, action: PayloadAction<FilterRangeItem>) {
      state.cloneRange = action.payload;
    },
    setFilterLoading(state, action) {
      state.loading = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setOffset(state, action) {
      state.offset = action.payload;
    },
    setSort(state, action: PayloadAction<{ sortBy: string; sortDesc: boolean }>) {
      state.sortBy = action.payload.sortBy;
      state.sortDesc = action.payload.sortDesc;
    },
    setPresetMode(state, action) {
      state.preset_mode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilterSlice.getAliasesThunk.fulfilled, (state, action) => {
      state.aliases = action.payload.aliases;
    });
  },
});

export const {
  setAliases,
  setFilter,
  setCloneFilter,
  setRange,
  setCloneRange,
  setFilterLoading,
  setLimit,
  setOffset,
  setSort,
  setPresetMode,
} = filterSlice.actions;

export default filterSlice.reducer;
