import {
  GroupsListObjectItem,
  ProjectAnalyticsParams,
  GroupAnalyticsParams,
  EditStatusProjectAnalyticsParams,
} from '@app/interfaces/analytics';

import apiInstance from './common.api';

export const getAllGroupsAnalytics = async () => {
  return await apiInstance.get('/api/front/project/group/bulk');
};

export const getStatusesGroups = async () => {
  return await apiInstance.get('/api/front/project/status/bulk');
};

export const createGroupAnalytics = async (data: { name: string }) => {
  return await apiInstance.post('/api/front/project/group', data);
};

export const getGroupAnalytics = async (params?: { id: string }) => {
  return await apiInstance.get('/api/front/project/bulk', {
    params,
  });
};

export const editGroupAnalytics = async (data: GroupsListObjectItem) => {
  return await apiInstance.put('/api/front/project/group', data, {
    params: {
      id: data.project_group_id,
    },
  });
};

export const deleteGroupAnalytics = async (params: GroupAnalyticsParams) => {
  return await apiInstance.delete('/api/front/project/group', {
    params,
  });
};

export const createProjectAnalytics = async (data: Omit<ProjectAnalyticsParams, 'id'>) => {
  return await apiInstance.post('/api/front/project', data);
};

export const getProjectAnalytics = async (params: Pick<ProjectAnalyticsParams, 'id'>) => {
  return await apiInstance.get('/api/front/project', {
    params: {
      id: params.id,
    },
  });
};

export const editProjectAnalytics = async (data: ProjectAnalyticsParams) => {
  return await apiInstance.put('/api/front/project', data, {
    params: {
      id: data.id || data.project_id,
    },
  });
};
export const editStatusProjectAnalytics = async (data: EditStatusProjectAnalyticsParams) => {
  return await apiInstance.put('/api/front/project/status/change', data, {
    params: {
      id: data.id,
    },
  });
};

export const deleteProjectAnalytics = async (params: Pick<ProjectAnalyticsParams, 'id'>) => {
  return await apiInstance.delete('/api/front/project', {
    params: {
      id: params.id,
    },
  });
};

export const getExtAsrModal = async () => {
  return await apiInstance.get('/api/front/ext_asr_model/bulk');
};
export const getListAsrModal = async () => {
  return await apiInstance.get('/api/front/usr/model/bulk');
};
// export const asrModelApi = async (id: string) => {
//   return await apiInstance.get(`/api/front/project/asr_model_in_use?asr_model_id${id}`);
// };
