module.exports = {
  part_of_speech_data: {
    all: 'Все части речи',
    NOUN: 'Существительное',
    VERB: 'Глагол',
    ADJ: 'Прилагательное',
    ADVB: 'Наречие',
    NUMR: 'Числительное',
    NPRO: 'Местоимение',
    CONJ: 'Предлоги/союзы/частицы',
  },
  occurrence: 'Встречаемость',
};
