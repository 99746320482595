import { AccordionItemUIProps } from '@app/interfaces/userInterface';
import React from 'react';

import { ChevronLeftV2Icon } from '../icons/icons-list';

import { Loading } from '../index';

const AccordionItemUI: React.FC<AccordionItemUIProps> = (props) => {
  const { variant = 'default', active } = props;
  return (
    <div
      key={props.id}
      className={`${variant === 'default' ? 'mb-[22px]' : active ? '' : 'mb-[2px]'}`}
    >
      <div
        onClick={() => {
          const params = {
            id: props.id,
          };
          props.clickBtnChild?.(params);
        }}
        className={`flex items-center ${
          variant === 'default' ? 'h-[34px]' : 'h-[37px] bg-bg_1 pl-[14px] rounded-[10px]'
        }`}
      >
        <div
          className={`flex items-center mb-0 cursor-pointer ${
            variant === 'default' ? 'mr-4 ' : ' mr-[15px]'
          }`}
        >
          <div
            className={`flex items-center text-left transition focus:outline-none ${
              variant === 'default'
                ? 'relative justify-center bg-bg-4 h-[24px] w-[24px] rounded-full text-base'
                : ''
            }`}
            id={`IDACCORDIONBTN${props.id}`}
            // onClick={() => {
            //   const params = {
            //     id: props.id,
            //   };
            //   props.clickBtnChild?.(params);
            // }}
          >
            <ChevronLeftV2Icon
              size={6.95}
              className={`${
                variant === 'default' ? 'text-gray-800' : 'text-action'
              } transition-all duration-200 ${
                variant === 'default'
                  ? props.active
                    ? 'rotate-[270deg]'
                    : 'rotate-[180deg]'
                  : props.active
                  ? 'rotate-[90deg]'
                  : 'rotate-[270deg]'
              }`}
            />
          </div>
        </div>
        {props.title}
      </div>
      <div
        id={`ID${props.id}`}
        className={`border-0 transition-all duration-200   ${!props.active && 'max-h-0'} ${
          !props.active && 'hidden'
        }`}
      >
        <div className={`${variant === 'default' ? 'py-4' : 'pb-[2px]'}`}>
          {props.loading ? <Loading /> : props.children}
        </div>
      </div>
    </div>
  );
};

export default AccordionItemUI;
