import useTranslation from '@app/hooks/use-translation';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import classNames from 'classnames';
import React, { FC } from 'react';

import Icon from '../icons';

type PropsType = {
  status: GroupSharedStatus | string;
};

const SharedStatus: FC<PropsType> = (props) => {
  const { t } = useTranslation('common.sharedStatus');
  const { status } = props;
  const borderColors = {
    [GroupSharedStatus.PRIVATE]: '#969696',
    [GroupSharedStatus.EDIT]: '#7CB972',
    [GroupSharedStatus.VIEW]: '#A0A0A0',
  };
  const getStatusStyle = () =>
    classNames(
      'flex',
      'items-center',
      'gap-[5px]',
      'text-[12px]',
      'font-[500]',
      'leading-[110%]',
      'px-[5px]',
      'py-[3px]',
      'rounded-[2px]',
      'border-solid',
      'border-[1px]',
    );
  const iconStyles = (status: GroupSharedStatus) =>
    classNames(
      GroupSharedStatus.EDIT === status
        ? 'text-[#7CB972]'
        : GroupSharedStatus.PRIVATE === status
        ? 'text-[#969696]'
        : 'text-[#A0A0A0]',
    );

  return (
    <div
      style={{ borderColor: borderColors[status], color: borderColors[status] }}
      className={getStatusStyle()}
    >
      <div className="text-[red]">
        <Icon
          className={status && iconStyles(status as GroupSharedStatus)}
          size={9}
          name="EditIcon"
        />
      </div>
      <span className="mt-[-1px]">{t(status)?.toLowerCase()}</span>
    </div>
  );
};
export default SharedStatus;
