import React, { FC, useMemo } from 'react';
import { SettingsChartForm } from '@app/pages/reports/blocks/chart-modal/modal-settings-chart.block';
import {
  AggregateSelectForm,
  GraphSettingsDate,
  SettingsDateStep,
} from '@app/interfaces/report.type';
import useChartDateIntervals, { ReportTimeRange } from '@app/hooks/use-chart-date-intervals';
import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { ReportOutletContext } from '@app/pages/reports/report.outlet';
import useTranslation from '@app/hooks/use-translation';
import { ColorInput, EasyModal, Input, Select, Toggle } from '@ui';
import { ChartIcon } from '@app/components/ui/icons/default';
import connector, { PropsFromRedux } from '@app/utils/store';

type SettingsFormBlockProps = {
  setup?: GraphSettingsDate;
  projectMetrics: Array<MetricListArrType>;
  isOpen: boolean;
  chartId?: string;
  onClose: (status: boolean) => void;
  onRemove?: (status: boolean) => void;
};

type SettingsMetricStep = string;

const SettingsFormBlock: FC<SettingsFormBlockProps & PropsFromRedux> = (props) => {
  const {
    setup,
    editChartLiveReportRequest,
    createChartRequest,
    updateChart,
    onClose,
    chartAsyncLoadingRequest,
    isOpen,
    onRemove,
  } = props;
  const DEFAULT_CHAR_TYPE = 'barchart';
  const {
    projectMetrics,
    report: { live_report_id },
  } = useOutletContext<ReportOutletContext>();
  const { daysSelectOptions, dateIntervals, dateToString } = useChartDateIntervals();
  const { t } = useTranslation('pages.chartPage');
  const DEFAULT_RANGE: ReportTimeRange = { type: 'l', parameters: [{ num: 7 }] };
  const aggregateSelectOptions = useMemo(
    () => [
      { title: t('aggregate.mid'), value: 'avg' },
      {
        title: t('aggregate.sum'),
        value: 'sum',
      },
      {
        title: t('aggregate.count'),
        value: 'count',
      },
      {
        title: t('aggregate.min'),
        value: 'min',
      },
      {
        title: t('aggregate.max'),
        value: 'max',
      },
      {
        title: t('aggregate.prop_sum'),
        value: 'prop_sum',
      },
      {
        title: t('aggregate.prop_num'),
        value: 'prop_num',
      },
    ],
    [t],
  );
  const defaultFormValues: Partial<SettingsChartForm> = useMemo(
    () =>
      setup
        ? {
            type: DEFAULT_CHAR_TYPE,
            width: 'half',
            name: setup.name,
            range: dateToString(setup?.settings?.range) || 'week',
            display_legend: setup?.settings?.display_legend,
            display_data: setup.settings?.display_data,
            colors: [setup?.settings?.data[0]?.color, setup?.settings?.data[1]?.color],
            group: setup?.settings?.group?.type || 'date',
            step: setup?.settings?.group?.step || setup?.settings?.group?.metric_id,
            aggregate: setup?.settings?.data[0]?.aggregate || aggregateSelectOptions[0].value,
            aggregateY2: setup?.settings?.data[1]?.aggregate || aggregateSelectOptions[0].value,
            metric_id: setup?.settings?.data[0]?.metric_id || projectMetrics[0].metric_id,
            metric_idY2: setup?.settings?.data[1]?.metric_id || projectMetrics[0].metric_id,
            switchY2: (setup?.settings?.data?.length || 1) > 1,
          }
        : {
            type: 'barchart',
            width: 'half',
            name: t('new_chart'),
            group: 'date',
          },
    [aggregateSelectOptions, dateToString, projectMetrics, setup, t],
  );
  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SettingsChartForm>({
    defaultValues: { ...defaultFormValues },
  });
  const headerPathClassName = 'text-[18px] font-[700] mb-[38px]';
  const contentSettingsClassName = 'grid grid-cols-2 w-full gap-[27px] mb-[61px]';
  const reportsTimeIntervals = [...daysSelectOptions];

  const dateSelectOptions = [
    { title: t('date_select.h'), value: 'h' },
    { title: t('date_select.d'), value: 'd' },
    { title: t('date_select.w'), value: 'w' },
    { title: t('date_select.m'), value: 'm' },
  ];
  const graphTypes = {
    barchart: t('popover.gist'),
  };
  function handlerSuccess(event, handlerClose) {
    if (!watch().name) {
      event.preventDefault();
      event.stopPropagation();
      setError('name', { type: 'required' });
      return;
    }
    const form = getValues();
    const formGroup =
      form.group === 'date'
        ? { step: form.step || dateSelectOptions[0].value }
        : { metric_id: form.step || projectMetrics[0].metric_id };
    console.log(form);
    const currentData = {
      name: form.name,
      settings: {
        type: DEFAULT_CHAR_TYPE,
        width: 'half',
        display_data: form.display_data || false,
        display_legend: form.display_legend || false,
        range: dateIntervals[String(form.range)] || dateIntervals['week'],
        group: {
          type: form.group || 'date',
          ...formGroup,
        },
        data: [
          {
            metric_id: form?.metric_id || projectMetrics[0]?.metric_id,
            aggregate: form?.aggregate || aggregateSelectOptions[0]?.value,
            color: form.color,
            type: 'column',
          },
        ],
      },
    };
    if (form.switchY2) {
      currentData.settings.data.push({
        metric_id: form.metric_idY2 || projectMetrics[0]?.metric_id,
        aggregate: form.aggregateY2 || aggregateSelectOptions[0]?.value,
        color: form.colorY2,
        type: 'column',
      });
    }
    if (setup) {
      editChartLiveReportRequest({ params: { id: setup.graph_id }, data: currentData });
      updateChart({
        id: setup.graph_id,
        name: form.name,
        range: dateIntervals[String(form.range)],
      });
    } else {
      createChartRequest({
        live_report_id,
        ...currentData,
      });
    }
    handlerClose();
    // waiting build on server
    setTimeout(() => chartAsyncLoadingRequest(live_report_id), 1000);
  }

  function handlerRemove() {
    onRemove?.(true);
  }

  const renderSortDataSelect = {
    metric: (
      <>
        <div>Выбор категории</div>
        <Select
          variant="small"
          size={200}
          defaultValue={
            watch().step || setup?.settings?.group?.metric_id || projectMetrics[0]?.metric_id
          }
          onChange={(value) => setValue('step', value as SettingsMetricStep)}
          options={projectMetrics.map((metric) => ({
            title: metric.name,
            value: metric?.metric_id,
          }))}
        />
      </>
    ),
    date: (
      <>
        <div>Выбор категории</div>
        <Select
          variant="small"
          defaultValue={watch().step || setup?.settings?.group?.step || dateSelectOptions[0].value}
          size={200}
          onChange={(value) => setValue('step', value as SettingsDateStep)}
          options={dateSelectOptions}
        />
      </>
    ),
  };
  function getMetricResultValueType(id: string) {
    if (id) {
      return projectMetrics.find((metric) => metric.metric_id === id)?.result_value_type;
    } else {
      return projectMetrics.find((metric) => metric.metric_id === projectMetrics[0]?.metric_id)
        ?.result_value_type;
    }
  }

  return (
    <EasyModal
      variant="large"
      onRemove={handlerRemove}
      onSuccess={handlerSuccess}
      label={setup?.name || t('add_chart')}
      show={isOpen}
      onClose={onClose.bind(null, false)}
      isAutoClosable={false}
    >
      <form id={`form_graph_${setup?.graph_id || 0}`} onSubmit={handleSubmit(handlerSuccess)}>
        <div className="my-[20px]">
          <div className="grid grid-cols-2">
            <div className="w-full">
              <h3 className={headerPathClassName}>{t('settings')}</h3>
              <div className="grid grid-cols-2 mb-[20px] gap-8">
                <div>{t('chart_name')}</div>
                <div className="w-full mt-[-5px]">
                  <Input
                    {...register('name', { required: true })}
                    error={errors.name?.type === 'required' ? t('not_filled') : undefined}
                  />
                </div>
              </div>
              <div className={contentSettingsClassName}>
                <div className="text-[15px] ">{t('diagram_type')}</div>
                <div>{graphTypes[DEFAULT_CHAR_TYPE]}</div>
                <div>{t('default')}</div>
                <div>
                  <Select
                    placeholder={t('select.date_placeholder')}
                    defaultValue={
                      String(watch().range) || dateToString(setup?.settings?.range || DEFAULT_RANGE)
                    }
                    variant="small"
                    size={120}
                    onChange={(value) => setValue('range', value)}
                    options={[...reportsTimeIntervals.splice(0, 6), daysSelectOptions[6]]}
                  />
                </div>
                <div>{t('display_values')}</div>
                <div>
                  <Toggle
                    checked={watch().display_data || setup?.settings?.display_data}
                    onChecked={(status) => setValue('display_data', status)}
                  />
                </div>
                <div>{t('display_legend')}</div>
                <div>
                  <Toggle
                    checked={watch().display_legend || setup?.settings?.display_legend}
                    onChecked={(status) => setValue('display_legend', status)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-[20px]">
              <div className=" p-[10px] inline-flex items-center justify-center w-full">
                <ChartIcon size={431} />
              </div>
            </div>
          </div>
          <div>
            <h3 className={headerPathClassName}>{t('data_hor_x')}</h3>
            <div className={contentSettingsClassName}>
              <div>{t('data_x')}</div>
              <div>
                <Select
                  variant="small"
                  placeholder={t('choose')}
                  defaultValue={watch().group || 'date'}
                  size={200}
                  onChange={(value) => setValue('group', value as 'metric' | 'date')}
                  options={[
                    { title: t('date_call'), value: 'date' },
                    { title: t('metric'), value: 'metric' },
                  ]}
                />
              </div>
              {watch().group ? renderSortDataSelect[watch().group] : renderSortDataSelect['date']}
            </div>
          </div>
          <div>
            <h3 className={headerPathClassName}>
              {t('labels.customizing_data_to_display_on_a_histogram_y1')}
            </h3>
            <div className={contentSettingsClassName}>
              <div>{t('labels.metric_axis_y1')}</div>
              <div className="border-[#fff] border-[1px]">
                <Select
                  placeholder={t('choose')}
                  variant="small"
                  defaultValue={watch().metric_id || projectMetrics[0]?.metric_id}
                  size={200}
                  onChange={(value) => setValue('metric_id', value as string)}
                  options={projectMetrics.map((metric) => ({
                    title: metric.name,
                    value: metric?.metric_id,
                  }))}
                  tooltip={{
                    place: 'left',
                    content:
                      projectMetrics.find((metric) => metric.metric_id === watch().metric_id)
                        ?.name || projectMetrics[0]?.name,
                    id: 'metric_axis_y1',
                  }}
                />
              </div>
              <div>{t('labels.aggregate_data')}</div>
              <div>
                <Select
                  placeholder={t('select.aggregate_choices')}
                  defaultValue={
                    watch().aggregate ||
                    setup?.settings?.data[0]?.aggregate ||
                    aggregateSelectOptions[0].value
                  }
                  variant="small"
                  size={200}
                  onChange={(value) => setValue('aggregate', value as AggregateSelectForm)}
                  options={
                    getMetricResultValueType(watch().metric_id) === 'str'
                      ? aggregateSelectOptions.filter(
                          (item) => item.value === 'count' || item.value === 'prop_num',
                        )
                      : aggregateSelectOptions
                  }
                />
              </div>
              <div>{t('labels.label_color')}</div>
              <div>
                <div className="inline-flex items-center">
                  <ColorInput
                    {...register('color')}
                    color={watch().color || setup?.settings?.data[0]?.color}
                    label={t('select.color_choices')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h3 className={headerPathClassName}>
              {t('labels.customizing_data_to_display_on_a_histogram_y2')}
            </h3>
            <div className={contentSettingsClassName}>
              <div>{t('labels.additional_vertical_axis')}</div>
              <Toggle
                checked={watch().switchY2 || (setup?.settings?.data?.length || 1) > 1}
                onChecked={(status) => setValue('switchY2', status)}
              />
              <div>{t('labels.metric_axis_y2')}</div>
              <div>
                <Select
                  placeholder={t('select.metric_choices')}
                  variant="small"
                  size={200}
                  defaultValue={watch().metric_idY2 || projectMetrics[0]?.metric_id}
                  onChange={(value) => setValue('metric_idY2', value as string)}
                  options={projectMetrics.map((metric) => ({
                    title: metric.name,
                    value: metric?.metric_id,
                  }))}
                  tooltip={{
                    place: 'left',
                    content:
                      projectMetrics.find((metric) => metric.metric_id === watch().metric_idY2)
                        ?.name || projectMetrics[0]?.name,
                    id: 'metric_axis_y2',
                  }}
                />
              </div>
              <div>{t('labels.aggregate_data')}</div>
              <div>
                <Select
                  placeholder={t('select.aggregate_choices')}
                  defaultValue={watch().aggregateY2 || aggregateSelectOptions[0].value}
                  variant="small"
                  size={200}
                  onChange={(value) => setValue('aggregateY2', value as AggregateSelectForm)}
                  options={
                    getMetricResultValueType(watch().metric_idY2) === 'str'
                      ? aggregateSelectOptions.filter(
                          (item) => item.value === 'count' || item.value === 'prop_num',
                        )
                      : aggregateSelectOptions
                  }
                />
              </div>
              <div>{t('labels.label_color')}</div>
              <div>
                <div className="inline-flex items-center">
                  <ColorInput
                    {...register('colorY2')}
                    color={watch().colorY2 || setup?.settings?.data[1]?.color}
                    label={t('button.color_edit')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </EasyModal>
  );
};

export default connector(SettingsFormBlock);
