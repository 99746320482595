import { FilterItemNotOnlyArray, FilterItem } from '@app/interfaces/filter';
import { cloneDeep } from 'lodash';

export default function (filter: FilterItemNotOnlyArray[]): FilterItem[] {
  return cloneDeep(filter).map((item) => {
    if (!Array.isArray(item.value)) {
      item.value = [item.value];
    }
    return item;
  }) as FilterItem[];
}
