import React, { FC, useState } from 'react';

import { Button, EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';

import {
  useAddChannelMutation,
  useDeleteChannelMutation,
  useEditChannelMutation,
  useGetCommonSettingsQuery,
  useLazyGetChannelQuery,
} from '@app/store/reducers/common-settings-page.api';

import { ChannelSettingObject } from '@app/interfaces/settings.type';

import ChannelsTableBlock from './blocks/common-settings/channels-table.block';
import { ModalWithForm } from './blocks/common-settings/modal-with-form';

const CommonSettingsPage: FC = () => {
  const { t } = useTranslation('pages.settings');
  const { data, isLoading } = useGetCommonSettingsQuery();
  const [createNewChannel] = useAddChannelMutation();
  const [editChannel] = useEditChannelMutation();
  const [getChannel] = useLazyGetChannelQuery();
  const [isOpenAddChannelModal, changeOpenAddChannelModal] = useState<boolean>(false);
  const [isOpenDeleteModal, changeOpenDeleteModal] = useState<boolean>(false);
  const [channelIdForDelete, changeChannelIdForDelete] = useState<string>();
  const [editMode, changeEditMode] = useState<boolean>(false);
  const [channelData, changeChannelData] = useState<ChannelSettingObject | undefined>();
  const [deleteChannel] = useDeleteChannelMutation();
  function deleteHandleClick(id: string) {
    changeChannelIdForDelete(id);
    changeOpenDeleteModal(true);
  }
  function editHandleClick(id: string) {
    getChannel(id).then((data) => {
      changeChannelData(data.data);
      changeEditMode(true);
      changeOpenAddChannelModal(true);
    });
  }
  function onFormSubmitHandler(data: ChannelSettingObject) {
    editMode ? editChannel(data) : createNewChannel(data);
    changeOpenAddChannelModal((prev) => !prev);
  }
  const renderModal = () => {
    if (!isOpenAddChannelModal) return;
    return (
      <ModalWithForm
        channelData={channelData}
        editMode={editMode}
        changeEditMode={changeEditMode}
        isOpenAddChannelModal={isOpenAddChannelModal}
        changeOpenAddChannelModal={changeOpenAddChannelModal}
        onFormSubmitHandler={onFormSubmitHandler}
      />
    );
  };
  return (
    <div>
      <div className="inline-flex items-center w-full justify-between py-[30px]">
        <h1 className="text-[18px] font-[700] ml-[20px]">{t('common_setting.title')}</h1>
        <div>
          <Button
            onClick={() => {
              changeOpenAddChannelModal(true);
            }}
            icon="CirclePlusIcon"
            label={t('common_setting.button.add_channel')}
          />
        </div>
      </div>
      <div>
        <ChannelsTableBlock
          onDeleteIconClick={deleteHandleClick}
          isLoading={isLoading}
          tableData={data || []}
          onEditIconClick={editHandleClick}
        />
      </div>
      {renderModal()}
      <EasyModal
        withoutFooter
        onClose={changeOpenDeleteModal.bind(null, false)}
        label={t('warning')}
        show={isOpenDeleteModal}
        variant="removal"
        onRemove={() => channelIdForDelete && deleteChannel(channelIdForDelete)}
      >
        <div className="flex items-center justify-center">
          {t('common_setting.delete_channel_text')}
        </div>
      </EasyModal>
    </div>
  );
};

export default CommonSettingsPage;
