import React from 'react';
import { Route } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { CommonSettingsPage, SettingsOutlet } from '@app/pages';
import RequiredAuth from '@app/routes/required-auth.route';
import { BreadcrumbsItem } from '@app/pages/layout';
import { AliasSettings, RecognitionRulesSettings } from '@app/pages/settings';

import { userPermissions } from '@app/utils/user-permissions';

import RequiredPermission from './required-permission.route';

export const settingsRoute = (
  <Route
    path={navigationRoutes.settings}
    element={
      <RequiredAuth>
        <RequiredPermission tag={userPermissions.display.displaySettings}>
          <SettingsOutlet />
        </RequiredPermission>
      </RequiredAuth>
    }
  >
    <Route
      index
      element={
        <RequiredAuth>
          <CommonSettingsPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.settingsAlias}
      element={<AliasSettings />}
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
    <Route
      path={navigationRoutes.settingsRecognitionRules}
      element={<RecognitionRulesSettings />}
      handle={{
        crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
      }}
    />
  </Route>
);
