import { ToastContainer } from 'react-toastify';
import { router } from '@app/routes';
import { RouterProvider } from 'react-router-dom';
import React from 'react';
import { FallbackPage } from '@app/pages';
import i18n from '@app/i18n';
import store from '@app/store/store';
import AuthProvider from '@app/utils/auth-provider';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { flatten } from '@app/utils/flatten';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import localTranslations from '@app/translations';

function App() {
  const lang = 'ru';
  i18n.changeLanguage(lang);
  i18n.addResources(lang, 'translations', flatten(localTranslations));

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <RouterProvider router={router} fallbackElement={<FallbackPage />} />
          <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ zIndex: 99999999 }}
          />
        </AuthProvider>
      </I18nextProvider>
    </Provider>
  );
}

export default App;
