import React, { FC } from 'react';

import ReportsTableBlock from './blocks/reports-table.block';
import ReportHeaderBlock from './blocks/report-header.block';

const ReportsPage: FC = () => {
  return (
    <div className={'pb-[35px]'}>
      <ReportHeaderBlock />
      <ReportsTableBlock />
    </div>
  );
};

export default ReportsPage;
