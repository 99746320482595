import { Filter, FilterRange } from '@app/interfaces/filter';

export function getDefaultFilter(): Filter & FilterRange {
  return {
    range: {
      type: 'l',
      parameters: [
        {
          num: 7,
        },
      ],
    },
    filter: [],
  };
}
