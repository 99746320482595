import apiInstance from '@app/api/common.api';
import { MetricObjType } from '@app/interfaces/pages-types/anatylics-metric.type';

export const getAllMetricTemplates = async () => {
  return await apiInstance.get('/api/front/metric_template/group/bulk');
};

export const getMetricTemplateSettings = async (id: string) => {
  return await apiInstance.get('/api/front/metric_template', {
    params: {
      id: `${id}`,
    },
  });
};

export const createMetricTemplateGroup = async (name: string) => {
  return await apiInstance.post('/api/front/metric_template/group', { name: name });
};

export const editMetricTemplatesGroup = (id: string, name: string) => {
  return apiInstance.put(
    '/api/front/metric_template/group',
    { name: name },
    {
      params: {
        id: `${id}`,
      },
    },
  );
};

export const deleteMetricTemplatesGroups = (id: string) => {
  return apiInstance.delete('/api/front/metric_template/group', {
    params: {
      id: `${id}`,
    },
  });
};

export const createMetricTemplate = async (id: string, obj: MetricObjType) => {
  return await apiInstance.post(`/api/front/metric_template?metric_templates_group_id=${id}`, obj);
};

export const editMetricTemplate = async (
  id: string,
  metric_templates_group_id: string,
  obj: MetricObjType,
) => {
  return await apiInstance.put(`/api/front/metric_template`, obj, {
    params: {
      id: `${id}`,
      metric_templates_group_id: `${metric_templates_group_id}`,
    },
  });
};

export const deleteTemplate = (id: string) => {
  return apiInstance.delete('/api/front/metric_template', {
    params: {
      id: `${id}`,
    },
  });
};
