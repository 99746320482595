import { getRecognitionModels } from '@app/api/recognitionModel.api';
import {
  RecognitionModelsReducerState,
  RecognitionModelItem,
} from '@app/interfaces/recognitionModel';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState: RecognitionModelsReducerState = {
  recognition_models: [],
};

export const getRecognitionModelsThunk = {
  getModelsThunk: createAsyncThunk<RecognitionModelItem[]>(
    'RecognitionModels/getModelsThunk',
    async (_, { rejectWithValue }) => {
      return await getRecognitionModels()
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
};

const recognitionModelsSlice = createSlice({
  name: 'RecognitionModels',
  initialState,
  reducers: {
    setRecognitionModels(state, action) {
      state.recognition_models = action.payload;
    },
  },
  extraReducers: () => {
    // builder.addCase(getRecognitionModelsThunk.getModelsThunk.fulfilled, (state, action) => {
    //   state.recognition_models = action.payload;
    // });
  },
});

export const { setRecognitionModels } = recognitionModelsSlice.actions;

export default recognitionModelsSlice.reducer;
