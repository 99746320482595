import { editMetricTemplatesGroup } from '@app/api/metric-templates.api';
import { CircleButton, EditText, Modal, TooltipWrapper } from '@app/components/ui';
import Icon from '@app/components/ui/icons';
import useTranslation from '@app/hooks/use-translation';
import { getMetricTemplatesThunks } from '@app/store/reducers/metric-templates.slice';
import { useAppDispatch } from '@app/store/store';
import React from 'react';
import { v4 } from 'uuid';
import { NameAndDescriptionPattern } from '@app/constants/validation/regexp';

type PropsType = {
  name?: string;
  isDefault?: boolean;
  metric_templates_group_id?: string;
  active?: boolean;
  templatesCount?: number;
  setActiveAccordionItem: React.Dispatch<React.SetStateAction<(string | undefined)[]>>;
};
export const AccordionTitle: React.FC<PropsType> = ({
  name,
  active,
  templatesCount,
  metric_templates_group_id,
  isDefault,
  setActiveAccordionItem,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('pages.allPagesHintTitles');
  const { t: ti } = useTranslation('pages.metricTemplates');
  const [edit, setEdit] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [id, setId] = React.useState('');

  const deleteName = () => {
    if (metric_templates_group_id) {
      setId(metric_templates_group_id);
      setModalDelete(true);
    }
  };
  const deleteMetricTemplatesGroup = () => {
    dispatch(getMetricTemplatesThunks.deleteMetricTemplateGroupThunk(id))
      .unwrap()
      .then(() => {
        const accordionListFromStorage = localStorage.getItem('metric_accordion')?.split(',');
        const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
          (item) => item !== id,
        ) as string[];
        localStorage.setItem('metric_accordion', filteredAccordionListFromStorage.join(','));
        setActiveAccordionItem(filteredAccordionListFromStorage);
      });
    setModalDelete(false);
  };
  const editGroupName = (groupName: string) => {
    if (metric_templates_group_id && groupName) {
      const value = groupName.replace(NameAndDescriptionPattern, '');
      editMetricTemplatesGroup(metric_templates_group_id, value).then(() =>
        dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk()),
      );
      setEdit(false);
    }
  };
  React.useMemo(() => {
    if (!active) {
      setEdit(false);
    }
  }, [active]);

  return (
    <>
      <Modal
        value={modalDelete}
        setValue={setModalDelete}
        onApply={() => deleteMetricTemplatesGroup()}
        id={'deleteTemplatesMetricGroup'}
        title={ti('warning')}
        size="xs"
        variant="removal"
      >
        <span className="px-4">{ti('group_delete')}</span>
      </Modal>

      <div className="flex items-center gap-[15px]">
        <EditText
          visibleInput={isDefault ? false : active}
          editIconSize={14}
          textStyle="font-[700] text-[16px] text-2color leading-[110%] tracking-tight"
          text={name as string}
          onEdited={editGroupName}
        />
        {!isDefault && active && !edit && (
          <CircleButton
            hintTitle={t('delete_icon')}
            size={14}
            onClick={templatesCount === 0 ? () => deleteName() : undefined}
            className={`${templatesCount === 0 ? 'text-dangerBg' : 'text-3color'}`}
            icon={'TrashIcon'}
            stopPropagation
          />
        )}
      </div>

      {!active && (
        <div className="flex justify-center items-center text-xs w-[17px] h-[17px] rounded-full ml-[13px] text-[11px] bg-defaultBgBtn text-white">
          <TooltipWrapper
            content={t('templates.templates_list_count')}
            id={t('templates.templates_list_count') + v4()}
            className="text-white"
          >
            {templatesCount}
          </TooltipWrapper>
        </div>
      )}
      {isDefault && (
        <div className="flex items-center justify ml-[10px]">
          <Icon name={'LockCircle'} className="text-2color ml-[px]" size={15} />
        </div>
      )}
    </>
  );
};
