import Select from '@app/components/ui/select/index';
import {
  FilterAliasItem,
  FilterItem,
  FilterRangeItem,
  FilterRangeParametersItemForLType,
  FilterRangeParametersItemForRType,
  FilterRangeType,
} from '@app/interfaces/filter';
import {
  UniversalFilterTableProps,
  UniversalFilterTableRef,
} from '@app/interfaces/universalFilter';
import React, { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import { setCloneFilter, setCloneRange } from '@app/store/reducers/filterSlice';
import { useAppDispatch, useAppSelector } from '@app/store/store';

import DatePicker from 'react-datepicker';
import { getStringDate, getStringTime } from '@app/utils/helpers';
import { METAdatetime, METAdatetimeCalendar, METAdatetimeCalendarId } from '@app/utils/constants';
import classNames from 'classnames';

import { cloneDeep } from 'lodash';

import cn from 'classnames';

import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

import useTranslation from '@app/hooks/use-translation';

import moment from 'moment';

import { toast } from 'react-toastify';

import { TrashIcon } from '../ui/icons/icons-list';

import { Input, TooltipWrapper } from '../ui';

import { OptionItem } from '../ui/select/select.type';

import styles from './styles.module.scss';

import {
  getColumnClassName,
  getDescriptionToLType,
  getRowClassName,
} from './UniversalFilterTable.style';

export type DateIntervalKeep = {
  start: string | null;
  startTime: string | null;
  endTime: string | null;
  end: string | null;
};

const UniversalFilterTable = forwardRef<UniversalFilterTableRef, UniversalFilterTableProps>(
  ({ loading, metricMode, modalMode }, ref) => {
    const dispatch = useAppDispatch();
    const { aliases, filter, cloneFilter, range, cloneRange } = useAppSelector(
      (state) => state.filter,
    );
    const DEFAULT_TIME_START = '00:00';
    const DEFAULT_TIME_END = '23:59';
    const DEFAULT_DATE = moment().format('YYYY-MM-DD');
    const [rangeState] = useState<FilterRangeParametersItemForRType[]>(
      range.parameters as unknown as FilterRangeParametersItemForRType[],
    );
    const [dateInterval, setDateInterval] = useState<DateIntervalKeep>({
      start: moment(rangeState[0]?.value).format('YYYY-MM-DD') || DEFAULT_DATE,
      end: moment(rangeState[1]?.value).format('YYYY-MM-DD') || DEFAULT_DATE,
      endTime: DEFAULT_TIME_END,
      startTime: DEFAULT_TIME_START,
    });

    const { t } = useTranslation('pages.allPagesHintTitles');
    const { t: ti } = useTranslation('components.filter');
    const { metricList } = useAppSelector((state) => state.metric);
    const conditions = [
      {
        id: '>',
        name: '>',
        values: ['num'],
        type_meta: ['record', 'base', 'system'],
      },
      {
        id: '<',
        name: '<',
        values: ['num'],
        type_meta: ['record', 'base', 'system'],
      },
      {
        id: '>=',
        name: '>=',
        values: ['num'],
        type_meta: ['record', 'base', 'system'],
      },
      {
        id: '<=',
        name: '<=',
        values: ['num'],
        type_meta: ['record', 'base', 'system'],
      },
      {
        id: '=',
        name: '=',
        values: ['num', 'str', 'datetime'],
        type_meta: ['channel', 'record', 'base', 'system'],
      },
      {
        id: '!=',
        name: '≠',
        values: ['num', 'str', 'datetime'],
        type_meta: ['channel', 'record', 'base', 'system'],
      },
      {
        id: 'exists',
        name: 'Существует',
        values: ['num', 'str'],
        type_meta: ['record', 'base'],
      },
    ];

    useImperativeHandle(ref, () => ({
      getCloneFilter,
      setCloneRangeToFilter,
      cloneFilterInit,
    }));

    const getCloneFilter = () => {
      dispatch(setCloneFilter(cloneDeep(filter)));
    };

    const setCloneRangeToFilter = () => {
      dispatch(setCloneRange(cloneDeep(range)));
    };

    const cloneFilterInit = () => {
      const params: FilterItem = {
        id: '',
        value: [''],
        condition: '',
      };
      dispatch(setCloneFilter([...cloneFilter, params]));
    };

    const addNewAliasToFilter = (filter: FilterItem[]) => {
      if (filter.length) {
        for (const key in filter.at(-1)) {
          const value = filter.at(-1)?.[key as keyof FilterItem];
          if (key === 'value' && filter.at(-1)?.condition === 'exists') continue;
          if (Array.isArray(value) ? !value.some((item) => (item === 0 ? true : item)) : !value)
            return;
        }
        const params: FilterItem = {
          id: '',
          value: [''],
          condition: '',
        };
        dispatch(setCloneFilter([...filter, params]));
      }
    };

    const addNewValueFilter = (id: string, value?: string | number) => {
      if (loading) return;
      if (value == undefined) return;
      const items: FilterItem[] = cloneDeep(cloneFilter);
      const currItem = items.find((item) => item.id === id);
      if (!currItem) return;
      if (!currItem.id) return;

      currItem.value.push(value);

      // const alias = aliases.find((alias) => alias.alias_id === id);
      // if (!alias) return;
      if (currItem.value.length > 1) {
        if (currItem.condition !== '=' && currItem.condition !== '!=') {
          currItem.condition = '=';
        }
      }
      dispatch(setCloneFilter(items));
    };

    const deleteValueFilter = (id: string, index: number) => {
      if (loading) return;
      const items: FilterItem[] = cloneDeep(cloneFilter);
      const currItem = items.find((item) => item.id === id);
      if (!currItem) return;
      if (!currItem.id) return;
      currItem.value.splice(index, 1);
      dispatch(setCloneFilter(items));
    };

    const deleteAliasFromFilter = (index: number) => {
      if (loading) return;
      const items: FilterItem[] = cloneDeep(cloneFilter);
      if (!items[index]) return;
      items.splice(index, 1);
      dispatch(setCloneFilter(items));
    };

    const getTypeInput = (id: string) => {
      if (metricMode) {
        const metric = metricList.find((metric) => metric.metric_id === id);
        if (!metric) return 'text';
        if (metric.result_value_type === 'str') return 'text';
        if (metric.result_value_type === 'num') return 'number';
        if (metric.result_value_type === 'datetime') return 'date';
      } else {
        const alias = aliases.find((alias: FilterAliasItem) => alias.alias_id === id);
        if (!alias) return 'text';
        if (alias.type_value === 'str') return 'text';
        if (alias.type_value === 'num') return 'number';
        if (alias.type_value === 'datetime') return 'date';
      }
    };

    // const getValueDateRange = (index: number) => {
    //   const parameters = cloneRange.parameters[index] as FilterRangeParametersItemForRType;
    //   if (parameters.value) {
    //     return new Date(parameters.value);
    //   }
    // };

    function getValueDateFilter(index: number) {
      return new Date(cloneFilter[index].value[0]);
    }

    const getAliasesWithDisabledField = () => {
      const cloneAliases: FilterAliasItem[] = cloneDeep(aliases);
      return cloneAliases.filter((alias) => !alias.forbidden_rules);
    };

    const getDayClassNameDatePicker = (date: Date, index: number) => {
      if (date.getTime() > new Date().getTime()) {
        return classNames(styles['disabled-date-react-datepicker']);
      }
      const parameters = cloneRange.parameters as FilterRangeParametersItemForRType[];
      if (index === 0) {
        return date.getTime() > new Date(getDate(parameters[1].value)).getTime()
          ? classNames(styles['disabled-date-react-datepicker'])
          : '';
      } else if (index === 1) {
        return date.getTime() < new Date(getDate(parameters[0].value)).getTime()
          ? classNames(styles['disabled-date-react-datepicker'])
          : '';
      }
      return '';
    };

    const changeStateFilter = (items: FilterItem[]) => {
      dispatch(setCloneFilter(items));
      addNewAliasToFilter(items);
    };

    const changeFilterAlias = ({ value, index }: { value: string; index: number }) => {
      const items = cloneDeep(cloneFilter);
      const currentItem = items[index];
      currentItem.id = value;
      let alias: FilterAliasItem | undefined;
      let metric: MetricListArrType | undefined;
      let nextConditions;

      if (metricMode) {
        metric = metricList.find((metric) => metric.metric_id === value);
        if (metric) {
          nextConditions = conditions.filter((condition) => {
            return condition.values.includes((metric as MetricListArrType).result_value_type);
          });
        }
      } else {
        alias = aliases.find((alias) => alias.alias_id === value);
        if (alias) {
          nextConditions = conditions.filter((condition) => {
            return (
              condition.values.includes((alias as FilterAliasItem).type_value) &&
              condition.type_meta.includes((alias as FilterAliasItem).type_meta)
            );
          });
        }
      }

      if (alias || metric) {
        if (!nextConditions.find((condition) => condition.id === currentItem.condition)) {
          items[index].condition = nextConditions[0].id;
        }
        if (getTypeInput(value) !== getTypeInput(cloneFilter[index].id)) {
          items[index].value = [''];
        }
        if (getTypeInput(value) === 'date') {
          currentItem.value = [getDate()];
        }
      }
      changeStateFilter(items);
    };

    const changeFilterCondition = ({ value, index }: { value: string; index: number }) => {
      const items = cloneDeep(cloneFilter);
      items[index].condition = value;
      if (value === 'exists') {
        items[index].value = [''];
      }

      changeStateFilter(items);
    };

    const changeFilterValue = ({
      value,
      indexItem,
      indexValue,
    }: {
      value: string | number;
      indexItem: number;
      indexValue: number;
    }) => {
      const items: FilterItem[] = cloneDeep(cloneFilter);
      items[indexItem].value = items[indexItem].value.map((item, index) => {
        if (index === indexValue) item = value;
        return item;
      });
      changeStateFilter(items);
    };

    function changeFilterDateValue({
      value,
      index,
      part = 'date',
    }: {
      value: Date | null;
      index: number;
      part?: 'date' | 'time';
    }) {
      const filter = cloneDeep(cloneFilter);
      if (!value) {
        if (part === 'date') {
          filter[index].value = [getDate(null, 'date')];
        } else if (part === 'time') {
          filter[index].value[0] = `${(filter[index].value[0] as string).split('T')[0]}T${getDate(
            null,
            'time',
          )}`;
        }
      } else {
        filter[index].value[0] = getDate(value);
      }
      dispatch(setCloneFilter(filter));
    }

    const changeRangeType = (value: string | number) => {
      const newValue = value === METAdatetimeCalendarId ? 'cd' : (value as FilterRangeType);
      const range = cloneDeep(cloneRange);
      range.parameters = [];
      range.type = newValue || 'n'; // fix as to [arr: FilterRangeType[]].includes(value) return...
      if (newValue === 'r') {
        range.parameters = [
          {
            value: `${dateInterval.start} ${dateInterval.startTime}:00`,
            condition: '>=',
          },
          {
            value: `${dateInterval.end} ${dateInterval.endTime}:00`,
            condition: '<=',
          },
        ];
      } else if (newValue === 'l') {
        range.parameters = [
          {
            num: 1,
          },
        ];
      }
      dispatch(setCloneRange(range));
    };

    // function changeRangeParametersRType({
    //   value,
    //   index,
    //   part = 'date',
    // }: {
    //   value: Date | null;
    //   index: number;
    //   part?: 'date' | 'time';
    // }) {
    //   if (index !== 0 && index !== 1) return;
    //   const range = cloneDeep(cloneRange);
    //   const parameters = range.parameters as FilterRangeParametersItemForRType[];
    //   // console.log(value?.toISOString());
    //   if (!value) {
    //     const getPartValue = (partIndex: 0 | 1, indexParam: 0 | 1 = index) =>
    //       parameters[indexParam].value.split('T')[partIndex];
    //     if (part === 'date') {
    //       parameters[index].value = index === 0 ? parameters[1].value : getDate(null, 'dateTime');
    //     } else if (part === 'time') {
    //       let timePart = getPartValue(1);
    //       timePart = index === 0 ? getPartValue(1, 1) : getDate(null, 'time');
    //       parameters[index].value = `${getPartValue(0)}T${timePart}`;
    //     }
    //   } else {
    //     // [...parameters, (parameters[index].value = value.toISOString())];
    //     if (index === 0 && value.getTime() > new Date(parameters[1].value).getTime()) {
    //       parameters[index].value = parameters[1].value;
    //     } else {
    //       if (index === 1 && value.getTime() > new Date().getTime()) {
    //         parameters[index].value = getDate();
    //       } else {
    //         parameters[index].value = getDate(value);
    //       }
    //     }
    //   }
    //   console.log(range);
    //   dispatch(setCloneRange(range));
    // }

    function changeRangeParametersLType(value) {
      const range: FilterRangeItem = cloneDeep(cloneRange);
      (range.parameters[0] as FilterRangeParametersItemForLType).num = Number(value)
        ? +value
        : value;
      dispatch(setCloneRange(range));
    }

    function getDate(
      start_date: Date | string | null = null,
      format: 'date' | 'time' | 'dateTime' = 'dateTime',
    ) {
      if (format === 'dateTime') {
        return `${getStringDate(start_date)}T${getStringTime(start_date, false)}`;
      } else if (format === 'date') {
        return getStringDate(start_date);
      } else {
        return getStringTime(start_date, false);
      }
    }

    const getDelimiterColumn = () => (
      <div className="flex text-center h-[17px]">
        <div className={getColumnClassName(0)}></div>
        <div
          className={cn(
            getColumnClassName(1),
            'text-[14px] leading-[17px]',
            'flex justify-center items-center py-[5px] text-4color',
          )}
        >
          и
        </div>
        <div className={getColumnClassName(2)}></div>
      </div>
    );

    const getAliasCondition = (filterItem: FilterItem) => {
      return conditions.filter((condition) => {
        let alias: FilterAliasItem | undefined;
        let metric: MetricListArrType | undefined;
        let getConditionToUse;
        if (metricMode) {
          if (condition.id === 'exists') return;
          metric = metricList.find((metric) => metric.metric_id === filterItem.id);
          getConditionToUse = () => {
            return condition.values.includes((metric as MetricListArrType).result_value_type);
          };
        } else {
          alias = aliases.find((alias: FilterAliasItem) => alias.alias_id === filterItem.id);
          getConditionToUse = () => {
            return (
              condition.values.includes((alias as FilterAliasItem).type_value) &&
              condition.type_meta.includes((alias as FilterAliasItem).type_meta)
            );
          };
        }
        if (alias || metric) {
          if (Array.isArray(filterItem.value) && filterItem.value.length > 1) {
            return getConditionToUse() && (condition.id === '=' || condition.id === '!=');
          }
          return getConditionToUse();
        }
      });
    };

    const getSelectRangeValue = () => {
      if (METAdatetimeCalendarId.includes(cloneRange.type)) return METAdatetimeCalendarId;
      return cloneRange.type;
    };

    const getOptionsForSelectAlias = (): OptionItem[] => {
      if (!metricMode) {
        return getAliasesWithDisabledField().map((item) => ({
          title: item.name,
          value: item.alias_id,
        }));
      }
      return metricList.map((item) => ({
        title: item.name,
        value: item.metric_id,
      }));
    };

    function handlerChangePicker({
      date,
      type,
    }: {
      date: Date | null | string;
      type: 'start' | 'end' | 'startTime' | 'endTime';
    }) {
      const newInterval = {
        ...dateInterval,
        [type]: type === 'end' || type === 'start' ? moment(date).format('YYYY-MM-DD') : date,
      };
      setDateInterval(newInterval);
      if (
        newInterval.startTime?.replace(/\D/g, '')?.length === 4 &&
        newInterval.endTime?.replace(/\D/g, '')?.length
      ) {
        handlerSuccessDateChoose(newInterval);
      }
    }

    function handlerSuccessDateChoose(newInterval: {
      start: string | null;
      startTime: string | null;
      endTime: string | null;
      end: string | null;
    }) {
      if (
        dateInterval.start === null ||
        dateInterval.end === null ||
        dateInterval.endTime === null ||
        dateInterval.startTime === null ||
        Number(moment(dateInterval.start).unix()) > Number(moment(dateInterval.end).unix())
      ) {
        toast.info(t('interval_error'));
        return;
      } else {
        const cloneR: FilterRangeItem = {
          parameters: [
            {
              value: `${newInterval.start} ${newInterval.startTime}:00`,
              condition: '>=',
            },
            {
              value: `${newInterval.end} ${newInterval.endTime}:00`,
              condition: '<=',
            },
          ],
          type: 'r',
        };

        dispatch(setCloneRange(cloneR));
      }
    }

    return (
      <div className="w-full grid">
        <div className={cn('text-[12px] leading-[13px]', getRowClassName(true))}>
          <div className={getColumnClassName(0, true)}>{ti('alias')}</div>
          <div className={getColumnClassName(1, true)}>{ti('condition')}</div>
          <div className={getColumnClassName(2, true)}>{ti('values')}</div>
        </div>
        <div>
          {!modalMode && (
            <div className={getRowClassName(false, 'range')}>
              <div className={getColumnClassName(0)}>
                <Select
                  defaultValue={getSelectRangeValue()}
                  options={METAdatetime.map((item) => ({
                    title: item.name,
                    value: item.id,
                  }))}
                  placeholder={ti('filter_by_date')}
                  onChange={(value) => changeRangeType(value)}
                  isEmpty={true}
                />
              </div>
              <div className={getColumnClassName(1)}>
                {cloneRange.type === 'r' ? (
                  <>
                    <div className="text-[15px] leading-[16px] font-medium text-3color text-center pt-[9px]">
                      {ti('from')}
                    </div>
                    <div className="text-[15px] leading-[16px] font-medium text-3color text-center pt-[34px]">
                      {ti('to')}
                    </div>
                  </>
                ) : METAdatetimeCalendarId.includes(cloneRange.type) ? (
                  <div className="h-full flex justify-center items-center">
                    <span className="inline-block w-[10px] h-[18px] text-[15px] leading-[18px] text-2color">
                      =
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className={getColumnClassName(2)}>
                {cloneRange.type === 'r' ? (
                  <>
                    {['', ''].map((_, index) => {
                      return (
                        <div
                          key={`datepicker-${index}`}
                          className={`flex ${index > 0 ? 'pt-4' : ''} `}
                        >
                          <DatePicker
                            peekNextMonth
                            maxDate={moment().toDate()}
                            minDate={moment().subtract(364, 'days').toDate()}
                            showDisabledMonthNavigation
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selected={moment(
                              index === 0 ? dateInterval.start : dateInterval.end,
                            ).toDate()}
                            onChange={(date) =>
                              handlerChangePicker({ date, type: index === 0 ? 'start' : 'end' })
                            }
                            customInput={
                              <div className="w-[187.6px]">
                                <Input
                                  defaultValue={
                                    index === 0
                                      ? (dateInterval.start as string)
                                          .replace(/-/g, '.')
                                          .split('.')
                                          .reverse()
                                          .join('.')
                                      : (dateInterval.end as string)
                                          .replace(/-/g, '.')
                                          .split('.')
                                          .reverse()
                                          .join('.')
                                  }
                                  type="text"
                                  name={`date-picker-filter-${index}`}
                                  placeholder={ti('write_date')}
                                  disabled={loading || false}
                                  icon={'CalendarEmptyIcon'}
                                />
                              </div>
                            }
                            dateFormat="dd.MM.yyyy"
                            dayClassName={(date) => getDayClassNameDatePicker(date, index)}
                            popperPlacement="bottom"
                            popperModifiers={[
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 12],
                                },
                              },
                            ]}
                          />
                          <div className="ml-[11px] w-[127.4px]">
                            <Input
                              defaultValue={
                                index === 0
                                  ? (dateInterval.startTime as string)
                                  : (dateInterval.endTime as string)
                              }
                              type="text"
                              name={`time-picker-filter-${index}`}
                              placeholder={ti('write_time')}
                              disabled={loading || false}
                              mask="99:99"
                              onChange={(event) =>
                                handlerChangePicker({
                                  date: event.target.value,
                                  type: index === 0 ? 'startTime' : 'endTime',
                                })
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : cloneRange.type === 'l' ? (
                  <>
                    <div className="flex items-center text-3color">
                      <span className={getDescriptionToLType(98)}>{ti('last_from_capital')}</span>
                      <div className="w-[144.2px] mr-3 ml-[14px]">
                        <Input
                          defaultValue={
                            (cloneRange.parameters[0] as FilterRangeParametersItemForLType).num
                          }
                          name="l-type-range"
                          type="number"
                          placeholder={ti('write_value')}
                          disabled={loading || false}
                          min={0}
                          max={90}
                          onChange={(event) => changeRangeParametersLType(event.target.value)}
                        />
                      </div>
                      <span className={getDescriptionToLType(43.4)}>{ti('days')}</span>
                    </div>
                  </>
                ) : METAdatetimeCalendarId.includes(cloneRange.type) ? (
                  <Select
                    defaultValue={cloneRange.type}
                    options={METAdatetimeCalendar.map((item) => ({
                      title: item.name,
                      value: item.id,
                    }))}
                    placeholder={ti('calendar_bind')}
                    onChange={(value) =>
                      dispatch(
                        setCloneRange({
                          ...cloneRange,
                          type: value as 'cd' | 'cw' | 'cm',
                        }),
                      )
                    }
                    isEmpty={true}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          )}
          {cloneFilter.map((item: FilterItem, index: number) => {
            return (
              <React.Fragment key={`${item.id}-${index}`}>
                {index === 0 && !modalMode && getDelimiterColumn()}
                <div ref={(ref) => ref?.scrollTo({ top: -200 })} className={getRowClassName(false)}>
                  <div className={getColumnClassName(0)}>
                    <Select
                      defaultValue={item.id}
                      options={getOptionsForSelectAlias()}
                      placeholder={ti('alias')}
                      onChange={(value) =>
                        changeFilterAlias({
                          value: value as string,
                          index,
                        })
                      }
                      isEmpty={true}
                      // dropTop={index === arr.length - 1}
                      dropWidth={300}
                      dropHeight={130}
                    />
                  </div>
                  <div className={getColumnClassName(1)}>
                    <Select
                      defaultValue={item.condition}
                      options={getAliasCondition(item).map((item) => ({
                        title: item.name,
                        value: item.id,
                      }))}
                      placeholder={ti('condition')}
                      onChange={(value) =>
                        changeFilterCondition({
                          value: value as string,
                          index,
                        })
                      }
                      isEmpty={true}
                      // dropTop={index === arr.length - 1}
                      dropWidth={150}
                    />
                  </div>
                  <div className={getColumnClassName(2)}>
                    {item.condition === 'exists' ? (
                      <div className="text-center">−</div>
                    ) : (
                      item.value.map((itemValue, indexValue) => {
                        return getTypeInput(item.id) === 'date' ? (
                          <div key={`value-${item.id}-${indexValue}`} className="flex">
                            <DatePicker
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              selected={getValueDateFilter(index)}
                              onChange={(value) => changeFilterDateValue({ value, index })}
                              customInput={
                                <div className="w-[187.6px]">
                                  <Input
                                    defaultValue={getStringDate(getValueDateFilter(index), false)}
                                    type="text"
                                    name={`date-picker-filter-${index}`}
                                    placeholder={ti('write_date')}
                                    disabled={loading || false}
                                  />
                                </div>
                              }
                              dateFormat="dd.MM.yyyy"
                              // dayClassName={(date) => getDayClassNameDatePicker(date, index)}
                              popperPlacement="bottom"
                              popperModifiers={[
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, 12],
                                  },
                                },
                              ]}
                            />
                            <DatePicker
                              selected={getValueDateFilter(index)}
                              onChange={(value) =>
                                changeFilterDateValue({ value, index, part: 'time' })
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption={ti('time')}
                              dateFormat="HH:mm"
                              // timeClassName={(date) => getDayClassNameDatePicker(date, index)}
                              customInput={
                                <div className="ml-[11px] w-[127.4px]">
                                  <Input
                                    defaultValue={getDate(getValueDateFilter(index), 'time')}
                                    type="text"
                                    name={`time-picker-filter-${index}`}
                                    placeholder={ti('write_time')}
                                    disabled={loading || false}
                                  />
                                </div>
                              }
                              popperPlacement="bottom"
                              popperModifiers={[
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, 12],
                                  },
                                },
                              ]}
                            />
                          </div>
                        ) : (
                          <div key={`value-${item.id}-${indexValue}`}>
                            <Input
                              defaultValue={itemValue}
                              type={getTypeInput(item.id) as 'text' | 'number'}
                              name={`value-${index}-${indexValue}`}
                              disabled={!item.id || loading || false}
                              placeholder={ti('value')}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value;
                                changeFilterValue({
                                  value: getTypeInput(item.id) === 'text' ? value : +value,
                                  indexItem: index,
                                  indexValue,
                                });
                              }}
                              icon={indexValue === item.value.length - 1 ? 'PlusIcon' : 'MinusIcon'}
                              iconHintTitle={
                                indexValue === item.value.length - 1
                                  ? t('filter.plus_icon')
                                  : t('filter.minus_icon')
                              }
                              iconColor={
                                indexValue === item.value.length - 1 ? 'primary' : 'default'
                              }
                              onClick={() =>
                                indexValue === (item.value as (string | number)[]).length - 1
                                  ? addNewValueFilter(item.id, '')
                                  : deleteValueFilter(item.id, indexValue)
                              }
                            />
                            {indexValue >= 0 && indexValue < (item.value as []).length - 1 && (
                              <div className="px-4 py-1 text-center text-4color">{ti('or')}</div>
                            )}
                          </div>
                        );
                      })
                    )}
                  </div>
                  {cloneFilter.length > 1 && cloneFilter.length - 1 !== index && (
                    <div
                      className="absolute right-[-22.03px] top-[50%] translate-y-[-50%]"
                      onClick={() => deleteAliasFromFilter(index)}
                    >
                      <TooltipWrapper
                        content={t('filter.delete_icon')}
                        id={t('filter.delete_icon')}
                      >
                        <TrashIcon className="cursor-pointer text-basic_red" size={13.07} />
                      </TooltipWrapper>
                    </div>
                  )}
                </div>
                {index >= 0 && index < cloneFilter.length - 1 && getDelimiterColumn()}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  },
);

export default UniversalFilterTable;

UniversalFilterTable.displayName = 'UniversalFilterTable';
