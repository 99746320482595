import { Button, CircleButton, EasyModal, Select } from '@app/components/ui';
import { BigChartIcon } from '@app/components/ui/icons/icons-list';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import useTranslation from '@app/hooks/use-translation';
import { CreateChartOnDashboardType } from '@app/interfaces/dashboards.type';
import React from 'react';
import {
  useAddNewGraphOnDashboardMutation,
  useEditGraphOnDashboardMutation,
  useGetDashboardGraphListQuery,
} from '@app/store/reducers/dashboard-charts.api';

type PropsType = {
  show: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  getChartName: () => string | undefined;
  live_report_graph_id: string;
  editMode?: boolean;
  dashboard_graph_id?: string;
  width?: string;
  range?: string | number;
  onOpenRemoveModal?: () => void;
};

export const ChartEditModal: React.FC<PropsType> = (props) => {
  const {
    show = false,
    editMode = false,
    onClose,
    getChartName,
    dashboard_graph_id,
    live_report_graph_id,
    width = 'half',
    range = 'week',
  } = props;
  const { data: chartsData } = useGetDashboardGraphListQuery(
    localStorage.getItem('DASHBOARD_ID') || '',
    {
      skip: !localStorage.getItem('DASHBOARD_ID'),
    },
  );
  const [createNewChartOnDashboard] = useAddNewGraphOnDashboardMutation();
  const [editChartOnDashboard] = useEditGraphOnDashboardMutation();
  const [chartRange, setChartRange] = React.useState<string | number>(range);
  const [chartWidth, setChartWidth] = React.useState(width);
  const { daysSelectOptions, dateIntervals } = useChartDateIntervals();
  const reportsTimeIntervals = [...daysSelectOptions];
  const { t } = useTranslation('pages.dashboard');
  const resetFields = () => {
    setChartRange('week');
    setChartWidth('half');
  };
  console.log(chartsData);
  const onButtonClick = () => {
    const dashboard = localStorage.getItem('DASHBOARD_ID');
    const range = dateIntervals[String(chartRange)] || dateIntervals['week'];
    if (!dashboard) return;
    const data: CreateChartOnDashboardType = {
      dashboard_id: dashboard,
      live_report_graph_id: live_report_graph_id,
      settings_override: {
        width: chartWidth,
        range,
      },
      layout: {
        w: 12,
        h: 1,
        x: 1,
        y: chartsData?.length ? chartsData[chartsData.length - 1]?.layout.y + 1 : 1,
      },
    };
    if (editMode && dashboard_graph_id && chartsData) {
      editChartOnDashboard({
        body: {
          ...data,
          layout: chartsData[dashboard_graph_id].layout,
        },
        id: dashboard_graph_id,
      });
    } else {
      createNewChartOnDashboard(data);
    }
    onClose(false);
    resetFields();
  };
  const selectData = () => {
    return [...reportsTimeIntervals.splice(0, 6), daysSelectOptions[6]];
  };
  return (
    <EasyModal
      withoutContentPadding
      withoutLabel
      variant="smallW300"
      withoutFooter
      show={show}
      onClose={() => {
        resetFields();
        onClose(false);
      }}
      customLayer={50}
    >
      <div className="border-b-[1px] px-[-20px] flex w-full gap-[12px] pl-[12px] items-center border-[#E5E8EB] py-[18px]">
        <CircleButton
          onClick={() => {
            resetFields();
            onClose(false);
          }}
          size={14}
          className="text-action"
          icon={'ArrowLeftIcon'}
        />
        <div
          title={getChartName()}
          className="max-w-[100px] whitespace-nowrap text-ellipsis overflow-hidden text-1color text-[13px] font-bold"
        >
          {getChartName()}
        </div>
        <BigChartIcon size={50} />
      </div>
      <div className="ml-[20px] mt-[17px]">
        {/* 1 block */}
        <div className="text-[11px] text-3color mb-[12px]">{t('chart_size')}</div>
        <div className="flex flex-col gap-[12px]">
          <div className="flex items-center gap-[9px]">
            <input
              onChange={(e) => setChartWidth(e.target.value)}
              checked={chartWidth === 'half'}
              value="half"
              type="radio"
            />
            <span>{t('half')}</span>
          </div>
          <div className="flex items-center gap-[9px]">
            <input
              checked={chartWidth === 'full'}
              onChange={(e) => setChartWidth(e.target.value)}
              value="full"
              type="radio"
            />
            <span>{t('full')}</span>
          </div>
        </div>
        {/* 1 block */}
        {/* 2 block */}
        <div className="mt-[25px]">
          <div className="text-[11px] mb-[12px] text-3color">{t('choose_period')}</div>
          <div className="w-[157px]">
            <Select
              defaultValue={chartRange}
              dropWidth={180}
              size={184}
              onChange={(value) => setChartRange(value)}
              options={selectData()}
            />
          </div>
        </div>
        {/* 2 block */}
      </div>
      <div className="py-[25px] px-[20px]">
        <Button
          onClick={() => onButtonClick()}
          className="w-full"
          label={editMode ? t('save') : t('add')}
        />
      </div>
    </EasyModal>
  );
};
