import React, { FC, ReactNode, useState } from 'react';
import {
  Filter,
  FilterRangeParametersItemForLType,
  FilterRangeParametersItemForRType,
  FilterRangeType,
  KeysForFilteringType,
  Range,
} from '@app/components/ui/easy-filter/types';

import { OptionItem } from '@app/components/ui/select/select.type';
import {
  MetaDateTime,
  MetaDateTimeCalendar,
  MetaDateTimeCalendarForRangeShow,
  MetaDateTimeCalendarId,
  MetaDateTimeForRangeShow,
} from '@app/components/ui/easy-filter/data';

import { Button, EasyModal } from '@ui';

import { ChevronDownIcon } from '@icons';

import { useIsOverflow } from '@app/hooks/use-overflow';

import useTranslation from '@app/hooks/use-translation';

import { onFilterInput } from '@app/components/ui/easy-filter/helpers';

import moment from 'moment';

import EasyFilter from './easy-filter';
type EasyFilterProps = {
  data?: Filter & Range;
  footerMarkup?: ReactNode;
  titleMarkup?: ReactNode;
  keysForFiltering: KeysForFilteringType[];
  fillFromTemplate?: boolean;
  onChangeHandler(data: Filter & Range): void;
  isLoading?: boolean;
};
const EasyFilterView: FC<EasyFilterProps> = (props) => {
  const {
    data = {
      range: {
        type: getRangeSelectOptions()[0].value as FilterRangeType,
        parameters: [],
      },
      filter: [],
    },
    footerMarkup,
    titleMarkup,
    keysForFiltering,
    fillFromTemplate = false,
    isLoading,
    onChangeHandler,
  } = props;
  const { t } = useTranslation('components.filter');
  const valueClassName = 'bg-defaultBg px-[8px] pt-[2px] pb-[3px] rounded-[4px]';
  const [calendarBindValue, changeCalendarBindValue] = useState(MetaDateTimeCalendar[0].id);
  const [filterData, changeFilterData] = useState(data);
  const [filterOpenState, changeFilterOpenState] = useState(false);
  const [filterFullHeight, changeFilterFullHeightState] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const [filterViewRef, setFilterViewRef] = useState<HTMLDivElement | null>(null);
  const renderRangeValues = {
    n: <></>,
    r: (
      <div className={'flex items-center w-full gap-[10px]'}>
        <div className={`flex gap-[5px] items-center ${valueClassName}`}>
          <div>{t('from')}</div>
          <div>
            {moment(
              (filterData.range.parameters[0] as FilterRangeParametersItemForRType)?.value,
            ).format('YYYY.MM.DD hh:mm')}
          </div>
        </div>
        <div>-</div>
        <div className={`flex items-center gap-[5px] ${valueClassName}`}>
          <div>{t('before')}</div>
          <div>
            {moment(
              (filterData.range.parameters[1] as FilterRangeParametersItemForRType)?.value,
            ).format('YYYY.MM.DD hh:mm')}
          </div>
        </div>
      </div>
    ),
    l: (
      <div className={'flex items-center gap-[5px]'}>
        <div className={valueClassName}>
          {(filterData.range.parameters[0] as FilterRangeParametersItemForLType)?.num}
        </div>
        <div>{t('days')}</div>
      </div>
    ),
    [MetaDateTimeCalendarId]: (
      <div className={valueClassName}>{MetaDateTimeCalendarForRangeShow[calendarBindValue]}</div>
    ),
  };
  function getRangeSelectOptions(): OptionItem[] {
    return MetaDateTime.map((item) => ({ value: item.id, title: item.name }));
  }
  function onChangeFullHeightFilter() {
    changeFilterFullHeightState((prev) => !prev);
  }
  function changeFilterModalState() {
    changeFilterOpenState(true);
  }
  function getNameByValueKey(key) {
    return keysForFiltering.find((item) => item.id === key)?.title || 'N/A';
  }
  function onChangeFilter(data: Filter & Range) {
    onChangeHandler(data);
    changeFilterData(data);
  }
  function onFillFromTemplateHandler(data: Filter & Range) {
    changeFilterData(data);
    // onChangeHandler(data);
  }

  useIsOverflow(filterViewRef as HTMLElement, (isOverflow) => setOverflow(isOverflow));
  return (
    <>
      <div className="pt-[20px] pb-[20px] pr-[25px] pl-[22px] bg-white border-l-[2px] border-l-speech_analitics rounded-xl">
        {titleMarkup && <div className="text-[18px] leading-[21px]">{titleMarkup}</div>}
        <div className={'flex w-full items-center gap-[6px] pb-[20px]'}>
          <div className={'text-[15px] leading-[18px]'}>
            {MetaDateTimeForRangeShow[filterData.range.type]}
          </div>
          <div className={'text-[15px] leading-[18px]'}>
            {renderRangeValues[filterData.range.type]}
          </div>
        </div>
        <div className={'flex w-full'}>
          <div
            className={
              'relative pl-[19.34px] py-[4.5px] w-full bg-white border border-b_dark rounded-defaultR truncate mr-6'
            }
            style={{ height: filterFullHeight ? '100%' : '34px' }}
            ref={(ref) => setFilterViewRef(ref)}
          >
            <div
              style={{ width: 'calc(100% - 50px)' }}
              className={`flex flex-wrap gap-y-[10px] w-full px-2`}
            >
              {filterData?.filter.map((filter, index) => (
                <div key={index} className={'relative flex items-center '}>
                  {filter.id && (
                    <div className="min-w-max pr-[6px] text-[15px] leading-[18px] ">
                      {getNameByValueKey(filter.id)}
                    </div>
                  )}
                  {filter.condition && (
                    <div className="flex justify-center items-center w-[22px] h-[22px]  text-2color text-[15px] leading-[16px] mr-[6px] pb-[2px] border border-b_dark rounded-full">
                      {filter.condition === 'exists' ? 'e' : filter.condition}
                    </div>
                  )}
                  <div className={'pr-[16px] flex items-center gap-[5px]'}>
                    {Array.isArray(filter.value) ? (
                      filter.value.map(
                        (value, indexValue) =>
                          value && (
                            <div className={'flex items-center'} key={indexValue}>
                              {indexValue <= value.length - 1 && indexValue === 0 && (
                                <div className={'pr-1.5'}>(</div>
                              )}
                              <div
                                className={
                                  'bg-defaultBg w-fit text-[15px] leading-[18px] text-2color px-[8px] pt-[2px] pb-[3px] rounded-[4px]'
                                }
                              >
                                {value}
                              </div>
                              {indexValue < filterData.filter[index]?.value.length - 1 && (
                                <div className="px-[4px] text-[15px] leading-[18px] ml-[5px] border rounded-full text-2color">
                                  {t('or')}
                                </div>
                              )}
                              {indexValue === filterData.filter[index]?.value.length - 1 && (
                                <div className={'pl-1.5'}>)</div>
                              )}
                            </div>
                          ),
                      )
                    ) : (
                      <div
                        className={
                          'bg-defaultBg px-[8px] pt-[2px] pb-[3px] rounded-[4px] w-fit text-[15px] leading-[18px] text-2color'
                        }
                      >
                        {filter.value}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            {(overflow || filterFullHeight) && (
              <div
                onClick={onChangeFullHeightFilter}
                className={`absolute right-[8px] bottom-[6px] cursor-pointer p-[5px] bg-defaultBg rounded-[4px] ${
                  filterFullHeight && 'rotate-180'
                }`}
              >
                <ChevronDownIcon size={12} />
              </div>
            )}
          </div>
          <Button
            label={t('change')}
            fill="outlined"
            icon="Edit2Icon"
            className="self-start"
            // disabled={disabledChangeButton}
            onClick={() => changeFilterModalState()}
          />
        </div>
        {footerMarkup && <div className="pt-[25px]">{footerMarkup}</div>}
      </div>
      <EasyModal
        withoutFooter
        label={<div className={'pb-[30px]'}>{t('edit_filter')}</div>}
        withoutContentPadding
        show={filterOpenState}
        onClose={() => {
          changeFilterOpenState(false);
        }}
        variant={'largeW1127'}
      >
        {/*{filterOpenState && (*/}
        <EasyFilter
          isLoading={isLoading}
          calendarBindValue={changeCalendarBindValue}
          data={onFilterInput(filterData)}
          // changeFilterStateHandle={changeFilterData}
          onChange={(data) => onChangeFilter(data)}
          changeFilterOpenState={changeFilterOpenState}
          keysForFiltering={keysForFiltering}
          fillFromTemplate={fillFromTemplate}
          fillFromTemplateHandler={onFillFromTemplateHandler}
        />
        {/*)}*/}
      </EasyModal>
    </>
  );
};

export default EasyFilterView;
