import { createMetric, editMetric } from '@app/api/analytics-api/analytics-metric.api';
import {
  AllChanelsArrType,
  MetricObjType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { getMetricThunks } from '@app/store/reducers/metric-slice';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Button, Input, Loading, Select, Toggle } from '@ui';

import { AutoFocusHook } from '@app/utils/helpers';

import { createMetricTemplate, editMetricTemplate } from '@app/api/metric-templates.api';

import { getMetricTemplatesThunks } from '@app/store/reducers/metric-templates.slice';

import { AllStatusProject } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import { Controller, useForm } from 'react-hook-form';

import { WordsListComponent } from '@app/components/ui/add-words-and-dictionaries-container/wordsListComponent';

import { toast } from 'react-toastify';

import { VadModalsChildrenType } from '../constantsForModals';
import { ColorInput } from '../color-input/color-input';
import { AddToTemplateModal } from '../add-to-template-modal/add-to-template-modal';
type FormType = {
  activeColor: string;
  switchValue: boolean;
  name: string;
  channel: string;
  exclude_begin_sec: number;
  exclude_end_sec: number;
  select: string;
  wordsListArray: string[];
  dictionariesListArray: string[];
};
export const SpeechModals: React.FC<{
  modalsSetState: (type: string) => void;
  copyModal: boolean;
  dataFields: VadModalsChildrenType[];
  metric: MetricTypesArrType;
  allChanels?: AllChanelsArrType[];
  editModal?: boolean;
  metricTemplate?: boolean;
  createFromTemplate?: boolean;
  status?: AllStatusProject | undefined;
  page?: string;
  viewOnly?: boolean;
}> = ({
  dataFields,
  metric,
  allChanels,
  editModal,
  modalsSetState,
  metricTemplate,
  createFromTemplate,
  status,
  page,
  copyModal,
  viewOnly,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { metricTemplates, metricTemplateSettings } = useAppSelector(
    (state) => state.metricTemplates,
  );
  const { t } = useTranslation('pages.metricTemplates');
  const { t: tf } = useTranslation('common.forms');
  const { metricSettings, isPending } = useAppSelector((state) => state.metric);
  const { dictionaries } = useAppSelector((state) => state.dictionaris);
  const [createTemplateModal, setCreateTemplateModal] = React.useState(false);
  // const [wordsListArray, setWordsListArray] = React.useState<string[]>([]);
  // const [dictionariesListArray, setDictionariesListArray] = React.useState<string[]>([]);
  // const [name, setName] = React.useState<string | undefined>(metric.name);
  // const [channel, setChannel] = React.useState<string>();
  // const [switchValue, setSwitchValue] = React.useState<boolean | undefined>(false);
  // const [activeColor, setActiveColor] = React.useState<string>('');
  // const [exclude_begin_sec, setExclude_begin_sec] = React.useState<number | undefined>(0);
  // const [exclude_end_sec, setExclude_end_sec] = React.useState<number | undefined>(0);
  // const [select, setSelect] = React.useState<string | undefined>('default');
  const defaultValue = (): FormType => {
    if (editModal) {
      if (metricTemplate) {
        return {
          wordsListArray: metricTemplateSettings?.settings.wordlist || [],
          dictionariesListArray: metricTemplateSettings?.settings.dictionaries || [],
          activeColor: `#${metricTemplateSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricTemplateSettings?.settings.all_word_forms || false,
          name: metricTemplateSettings?.name || '',
          channel: metricTemplateSettings?.settings.channel || '',
          exclude_begin_sec: metricTemplateSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricTemplateSettings?.settings.exclude_end_sec || 0,
          select: metricTemplateSettings?.metric_templates_group_id || 'default',
        };
      } else {
        return {
          wordsListArray: metricSettings?.settings.wordlist || [],
          dictionariesListArray: metricSettings?.settings.dictionaries || [],
          activeColor: `#${metricSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricSettings?.settings.all_word_forms || false,
          name: metricSettings?.name || '',
          channel: metricSettings?.settings.channel || '',
          exclude_begin_sec: metricSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricSettings?.settings.exclude_end_sec || 0,
          select: '',
        };
      }
    } else {
      if (createFromTemplate) {
        return {
          wordsListArray: metricTemplateSettings?.settings.wordlist || [],
          dictionariesListArray: metricTemplateSettings?.settings.dictionaries || [],
          activeColor: `#${metricTemplateSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricTemplateSettings?.settings.all_word_forms || false,
          name: metricTemplateSettings?.name || '',
          channel: metricTemplateSettings?.settings.channel || '',
          exclude_begin_sec: metricTemplateSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricTemplateSettings?.settings.exclude_end_sec || 0,
          select: metricTemplateSettings?.metric_templates_group_id || 'default',
        };
      } else
        return {
          activeColor: '',
          switchValue: false,
          name: '',
          channel: '',
          exclude_begin_sec: 0,
          exclude_end_sec: 0,
          select: 'default',
          wordsListArray: [],
          dictionariesListArray: [],
        };
    }
  };
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
    control,
  } = useForm<FormType>({ defaultValues: defaultValue() });
  const disableFunc = () => {
    if (status === AllStatusProject.PAUSE) {
      return false;
    }
    return status !== AllStatusProject.CREATED;
  };

  // React.useEffect(() => {
  //   if (editModal) {
  //     metricSettings?.settings.wordlist && setWordsListArray(metricSettings?.settings.wordlist);
  //     metricTemplateSettings?.settings.wordlist &&
  //       setWordsListArray(metricTemplateSettings?.settings.wordlist);
  //
  //     metricSettings?.settings.dictionaries &&
  //       setDictionariesListArray(metricSettings?.settings.dictionaries);
  //     metricTemplateSettings?.settings.dictionaries &&
  //       setDictionariesListArray(metricTemplateSettings?.settings.dictionaries);
  //   } else if (!editModal) {
  //     if (createFromTemplate) {
  //       metricTemplateSettings?.settings.wordlist &&
  //         setWordsListArray(metricTemplateSettings?.settings.wordlist);
  //       metricTemplateSettings?.settings.dictionaries &&
  //         setDictionariesListArray(metricTemplateSettings?.settings.dictionaries);
  //     }
  //   }
  // }, [
  //   createFromTemplate,
  //   editModal,
  //   metricSettings?.settings.dictionaries,
  //   metricSettings?.settings.wordlist,
  //   metricTemplateSettings?.settings.dictionaries,
  //   metricTemplateSettings?.settings.wordlist,
  // ]);

  const resetAsyncForm = () => {
    modalsSetState(metric.type);
  };

  const onSubmit = (data) => {
    const obj: MetricObjType = {
      name: data.name,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        channel: data.channel,
        all_word_forms: data.switchValue,
        color: data.activeColor?.slice(1).toUpperCase(),
        wordlist: data.wordsListArray,
        dictionaries: data.dictionariesListArray,
        exclude_begin_sec: data.exclude_begin_sec,
        exclude_end_sec: data.exclude_end_sec,
      },
    };
    if (!editModal) {
      if (metricTemplate) {
        createMetricTemplate(watch('select'), obj).finally(() => {
          dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
        });
      } else {
        id &&
          createMetric(id, obj).finally(() => {
            dispatch(getMetricThunks.getMetricList(id));
          });
      }
    } else {
      if (metricSettings?.metric_id) {
        id &&
          editMetric(id, metricSettings.metric_id, obj).finally(() => {
            dispatch(getMetricThunks.getMetricList(id));
          });
      } else if (metricTemplate) {
        if (copyModal) {
          createMetricTemplate(watch('select'), obj).finally(() => {
            dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
          });
        } else {
          editMetricTemplate(
            metricTemplateSettings?.metric_template_id as string,
            watch('select'),
            obj,
          ).finally(() => {
            dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
          });
        }
      }
    }
    resetAsyncForm();
  };
  const getDefaultTemplate = () => {
    return metricTemplates.find((e) => e.is_default === true)?.metric_templates_group_id;
  };
  const createMetricTemplateApplyBtn = () => {
    const obj: MetricObjType = {
      name: watch('name') as string,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        channel: watch('channel'),
        all_word_forms: watch('switchValue'),
        color: watch('activeColor').slice(1).toUpperCase(),
        wordlist: watch('wordsListArray'),
        dictionaries: watch('dictionariesListArray'),
        exclude_begin_sec: watch('exclude_begin_sec'),
        exclude_end_sec: watch('exclude_end_sec'),
      },
    };
    createMetricTemplate(getDefaultTemplate() as string, obj)
      .then(() => {
        resetAsyncForm();
        setCreateTemplateModal(false);
      })
      .catch(() => {
        toast.error(t('fill_all'));
      });
  };

  const clickColor = (event) => {
    setValue('activeColor', event.currentTarget.value);
  };
  const inputFocusRef = AutoFocusHook();

  const getSelectItems = () => {
    return metricTemplates.map((e) => {
      return { title: e.name, value: e.metric_templates_group_id };
    });
  };
  function changeDictionariesArray(word: string[]) {
    setValue('dictionariesListArray', word);
  }
  function changeWordsArray(word: string[]) {
    setValue('wordsListArray', word);
  }
  return (
    <div className={`${metric.type === 'find-words' && 'w-full justify-center flex gap-[30px]'}`}>
      {isPending && <Loading />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${metric.type === 'find-words' ? 'w-[500px]' : 'w-[550px]'}`}
      >
        <div className="flex flex-col gap-[20px]">
          <div className="flex items-center justify-between">
            <span className="max-w-[218px] text-0color text-[15px]">{t('metric_group')}</span>
            <span className="mr-[150px]">
              <b>
                {metric.group.toUpperCase()} {t('one_metric')}
              </b>
            </span>
          </div>
          <div>
            {dataFields.map((e) => {
              return e.inputText.map((e) => {
                return (
                  <div className="flex items-center justify-between" key={e.id}>
                    <span className="max-w-[218px] text-0color text-[15px]">{e.labelTitle}</span>
                    <div className="w-[280px]">
                      <Input
                        {...inputFocusRef}
                        {...register('name', {
                          required: { value: true, message: tf('required') },
                        })}
                        name="name"
                        placeholder={e.placeholder}
                        error={errors.name?.message}
                      />
                    </div>
                  </div>
                );
              });
            })}
          </div>
          {metricTemplate && (
            <div className="flex items-center justify-between">
              <span className="text-0color text-[15px]">{t('metric_templates_group')}</span>
              <div className="w-[280px]">
                <Controller
                  render={() => (
                    <Select
                      error={errors.select?.message}
                      defaultValue={watch('select')}
                      options={[
                        ...getSelectItems(),
                        {
                          title: t('choose') + '...',
                          value: 'default',
                          disabled: true,
                          nullable: true,
                        },
                      ]}
                      onChange={(value) => {
                        setValue('select', value as string);
                        clearErrors('select');
                      }}
                    />
                  )}
                  name={'select'}
                  rules={{ validate: (value) => value !== 'default' || tf('required') }}
                  control={control}
                />
              </div>
            </div>
          )}
          <div className="flex items-center justify-between mr-[150px] relative">
            <span className="max-w-[218px] text-0color text-[15px]">{t('channel_search')}</span>
            <Controller
              render={() => (
                <div className="flex flex-col min-w-[130px]">
                  {metric.type !== 'speech-rate-duration' && (
                    <div className="flex items-center gap-[10px]">
                      <input
                        name="channel"
                        onChange={(e) => {
                          setValue('channel', e.target.value);
                          clearErrors('channel');
                        }}
                        type="checkbox"
                        value={'all'}
                        checked={watch('channel') === 'all'}
                        className="focus:ring-offset-0 focus:ring-0 rounded-full"
                      />
                      <label className="text-[14px] text-0color">{t('all')}</label>
                    </div>
                  )}
                  {allChanels?.map((chanel) => {
                    return (
                      <div
                        className="flex items-center gap-[10px]"
                        key={chanel.settings_channels_id}
                      >
                        <input
                          name="channel"
                          onChange={(e) => {
                            setValue('channel', e.target.value);
                            clearErrors('channel');
                          }}
                          type="checkbox"
                          checked={watch('channel') === chanel.settings_channels_id}
                          value={chanel.settings_channels_id}
                          className="focus:ring-offset-0 focus:ring-0 rounded-full"
                        />
                        <label className="text-[14px] text-0color">{chanel.name}</label>
                      </div>
                    );
                  })}
                </div>
              )}
              name={'channel'}
              control={control}
              rules={{ validate: (value) => !!value || tf('required') }}
            />
            {errors.channel?.message && (
              <div
                className={
                  'absolute bottom-[-17px] right-[10px] text-sm text-red-600 dark:text-red-500'
                }
              >
                {errors.channel?.message}
              </div>
            )}
          </div>
          {metric.type === 'find-words' && (
            <>
              <div className="flex items-center justify-between mr-[247px]">
                <span className="max-w-[218px] text-0color text-[15px]">{t('all_words')}</span>
                <Toggle
                  onChecked={(e) => setValue('switchValue', e)}
                  size={'large'}
                  checked={watch('switchValue')}
                />
              </div>
              <div className="flex items-center justify-between relative">
                <span className="max-w-[218px] text-0color text-[15px]">{t('color')}</span>
                <Controller
                  render={() => (
                    <div id="colors" className="flex items-center gap-[6px] mr-[23px]">
                      <ColorInput
                        onClick={(event) => {
                          clickColor(event);
                          clearErrors('activeColor');
                        }}
                        active={watch('activeColor')}
                      />
                    </div>
                  )}
                  name={'activeColor'}
                  control={control}
                  rules={{ validate: (value) => !!value || tf('required') }}
                />
                {errors.activeColor?.message && (
                  <div
                    className={
                      'absolute bottom-[-17px] right-[160px] text-sm text-red-600 dark:text-red-500'
                    }
                  >
                    {errors.activeColor?.message}
                  </div>
                )}
              </div>
            </>
          )}
          {dataFields.map((e) => {
            return e.inputNumber.map((e, index) => {
              return (
                <div key={e.id} className="flex items-center justify-between">
                  <span className="text-0color max-w-[200px] text-[15px]">{e.labelTitle}</span>
                  <div className="bg-transparent w-[91px] mr-[190px]">
                    <Input
                      {...register(e.id as 'exclude_begin_sec' | 'exclude_end_sec')}
                      max={1000000}
                      min={0}
                      name={e.id}
                      key={index}
                      type={'number'}
                      icon="ClockIcon"
                    />
                  </div>
                </div>
              );
            });
          })}
          <div className="sticky bottom-0">
            <div className="bg-[#fff]">
              <div className="flex items-center justify-between gap-[15px] py-4">
                <div className="gap-[15px] flex">
                  <Button
                    disabled={(page !== 'metricTemplate' && disableFunc()) || viewOnly}
                    type="submit"
                    label={t('save')}
                  />
                  <Button onClick={() => resetAsyncForm()} fill="linked" label={t('cancel')} />
                </div>
                <div>
                  {page !== 'metricTemplate' && !createFromTemplate && (
                    <Button
                      onClick={() => setCreateTemplateModal(true)}
                      fill="linked"
                      icon="FolderConfigIcon"
                      label={t('save_as_template')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {metric.type === 'find-words' && (
        <WordsListComponent
          dictionaries={dictionaries}
          className="!w-[503px]"
          wordsListArray={watch('wordsListArray')}
          setWordsListArray={changeWordsArray}
          dictionariesListArray={watch('dictionariesListArray')}
          setDictionariesListArray={changeDictionariesArray}
        />
      )}
      <AddToTemplateModal
        show={createTemplateModal}
        name={watch('name')}
        applyBtn={createMetricTemplateApplyBtn}
        onClose={setCreateTemplateModal}
      />
    </div>
  );
};
