import { ToolboxDefinition } from 'react-blockly';

const toolboxCategories: ToolboxDefinition = {
  kind: 'categoryToolbox',
  contents: [
    // {
    //   name: 'workspace',
    //   kind: 'workspace',
    //   colour: '#5C81A6',
    //   contents: [
    //     {
    //       kind: 'block',
    //       type: 'formula_start',
    //     },
    //   ],
    // },
    {
      kind: 'category',
      name: 'Логические',
      colour: '#5C81A6',
      contents: [
        {
          kind: 'block',
          type: 'formula_if',
        },
        {
          kind: 'block',
          type: 'formula_and_or',
        },
        // {
        //   kind: 'block',
        //   type: 'formula_not',
        // },
      ],
    },
    {
      kind: 'category',
      name: 'Математические',
      colour: '#5CA65C',
      contents: [
        {
          kind: 'block',
          type: 'formula_mathops',
        },
        {
          kind: 'block',
          type: 'formula_relops',
        },
        {
          kind: 'block',
          type: 'formula_expression',
        },
      ],
    },
    {
      kind: 'category',
      name: 'Метрики',
      colour: '#5CA699',
      contents: [
        {
          kind: 'block',
          type: 'formula_metric',
        },
      ],
    },
  ],
};

export default toolboxCategories;
