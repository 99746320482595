module.exports = {
  to_record_title: 'Перейти к просмотру записи',
  download_record: 'Скачать аудиозапись',
  delete_icon: 'Удалить',
  all_record: 'Настроить отображение данных',
  new_preset: 'Новое условие',
  from_template: 'Из шаблона',
  create_preset: 'Создать условие отбора записей',
  none_templates: 'Нет шаблонов',
  choose_preset_template: 'Выбрать шаблон условия отбора записей',
  popover: {
    download_records: 'Выгрузить выбранные записи',
    delete_selected: 'Удалить выбранные',
  },
  duration: 'Длительность',
  actions: 'Действия',
  template_name: 'Введите название шаблона',
  save_as_template: 'Сохранить как шаблон условия отбора записей',
  save_as_preset: 'Сохранить как новое условие отбора записей',
  save: 'Сохранить',
  delete: 'Удалить',
  cancel: 'Отменить',
  records: 'Записи',
  delete_all_records: 'Будут удалены все выбранные записи',
  delete_record: 'Удалить запись',
  record_wb_deleted: 'Запись будет удалена',
  display_cols: 'Отображение колонок',
  choose_cols: 'Выберите отображаемые колонки',
  description: 'Описание',
  rep_live: 'Отчет LIVE',
  not_saved_proj: 'Нет сохраненного аналитического проекта',
  filter_records: 'Фильтровать записи проекта',
  create_report: 'Создать LIVE отчет на основе фильтра',
  create_report_rtk: 'Создать отчет на основе фильтра',
  analytic_proj: 'Аналитический проект',
  write_analytic_proj: 'Укажите аналитический проект',
  copy: 'копия 1',
  filter_condition: 'Настройка выбора записей',
  filter_condition_ap: 'Условия отбора записей в аналитический проект',
  group: 'Группа',
  filter_conditiond_ap: 'Условия отбора записей в аналитический проект',
  choose_proj: 'Выбрать проект',
  edit_preset: 'Редактировать условия отбора',
  remove_preset: 'Удалить условие отбора',
  confirm_remove_preset: 'Подтвердите удаление условия отбора',
  preset: 'Пресет',
  preset_create: 'Вы можете создать новое условие отбора записей или использовать шаблон',
};
