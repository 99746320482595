import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Button, PageHeader, SharedStatus } from '@app/components/ui';
import { AllStatusProject, ProjectItem } from '@app/interfaces/analytics';
import AnalyticsStatus from '@app/components/ui/analytics-status/analytics-status';
import { toast } from 'react-toastify';
import useTranslation from '@app/hooks/use-translation';
import {
  useChangeStatusOfAnalyticProjectMutation,
  useGetStatusOfAnalyticProjectQuery,
} from '@app/store/reducers/analytics-projects.api';
import { useParams } from 'react-router';

type AnalyticsPageHeaderProps = {
  project: ProjectItem | undefined;
  children?: ReactNode;
};

const AnalyticsPageHeader: FC<AnalyticsPageHeaderProps> = (props) => {
  const { project, children } = props;
  const { t } = useTranslation('pages.analytics');
  const { id } = useParams();
  const [pollingSkip, changePollingSkip] = useState(false);
  const [projectStatus, changeProjectStatus] = useState<AllStatusProject>();
  const { data: status } = useGetStatusOfAnalyticProjectQuery(
    { id: id },
    { pollingInterval: pollingSkip ? undefined : 2000 },
  );
  const [changeStatus] = useChangeStatusOfAnalyticProjectMutation();

  useEffect(() => {
    if (!status) return;
    changeProjectStatus(status.project_status);
  }, [status]);

  useEffect(() => {
    if (projectStatus === 'ACTIVE') return changePollingSkip(true);
    if (projectStatus === 'PAUSE') return changePollingSkip(true);
    if (projectStatus === 'CREATED') return changePollingSkip(true);
    return changePollingSkip(false);
  }, [projectStatus]);

  function editProject() {
    if (!id) return;
    if (
      projectStatus !== AllStatusProject.ACTIVE &&
      projectStatus !== AllStatusProject.CREATED &&
      projectStatus !== AllStatusProject.PAUSE
    )
      return;
    const params = {
      params: { id: id },
      body: {
        status:
          projectStatus === AllStatusProject.ACTIVE
            ? AllStatusProject.STOPPING
            : projectStatus === AllStatusProject.CREATED
            ? AllStatusProject.FILLING
            : AllStatusProject.STARTING,
      },
    };
    changeStatus(params).then((data) => {
      const { error } = data as { error: { status: number } };
      console.log(error);
      if (error.status === 403) {
        toast.info(t('preset_fill_error'));
      }
    });
  }
  function disableStatusChangeButton() {
    if (project?.owner) {
      return false;
    } else {
      return project?.shared !== 'EDIT';
    }
  }

  const buttonsRenderFunction = () => {
    if (projectStatus === AllStatusProject.PAUSE || projectStatus === AllStatusProject.CREATED) {
      return (
        <Button
          icon="TriangleRightIcon"
          sizeIcon={15}
          fill="outlined"
          onClick={() => editProject()}
          label="Запустить проект"
          disabled={disableStatusChangeButton()}
        />
      );
    } else if (projectStatus === AllStatusProject.ACTIVE) {
      return (
        <Button
          icon="PauseIcon"
          sizeIcon={15}
          fill="outlined"
          onClick={() => editProject()}
          label="Остановить проект"
          disabled={disableStatusChangeButton()}
        />
      );
    }
  };

  return (
    <PageHeader
      label={
        <div className="flex items-center gap-[10px]">
          <div className="mt-[-2px] font-[600]">{project?.name}</div>
          <AnalyticsStatus status={projectStatus || 'CREATED'} />
          <SharedStatus status={project?.shared || ''} />
        </div>
      }
      popLink={window.location.pathname
        .split('/')
        .splice(0, window.location.pathname.split('/').length - 1)
        .join('/')}
    >
      {buttonsRenderFunction()}
      {children}
    </PageHeader>
  );
};

export default AnalyticsPageHeader;
