import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { Button, Loading, Modal, NavigationTabs } from '@app/components/ui';
import { navigationData } from '@app/pages/analytics/blocks/navigation.data';
import RecordsTableWithFilter from '@app/components/records-table-with-filter';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { TableItem, TableTitle } from '@app/components/ui/table/table.type';
import { getAnalyticPresetThunk } from '@app/store/reducers/analyticsPresetSlice';

import {
  setFilterLoading,
  setPresetMode,
  setOffset,
  setLimit,
  setSort,
} from '@app/store/reducers/filterSlice';

import classnames from 'classnames';
import {
  ChevronDownIcon,
  DownloadCloudIcon,
  FolderOpenIcon,
  FolderV2Icon,
  Oscilogram,
} from '@app/components/ui/icons/icons-list';
import { getRecordsThunk } from '@app/store/reducers/recordsSlice';
import {
  Filter,
  FilterItem,
  FilterItemNotOnlyArray,
  FilterRange,
  FilterRangeItem,
  GetFilterParams,
} from '@app/interfaces/filter';
import useUniversalFilterValues from '@app/hooks/use-universal-filter-values';
import AccordionUI from '@app/components/ui/Accordion/Aссordion';
import { getPresetsThunks } from '@app/store/reducers/presets-slice';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import cn from 'classnames';
import cloneFilterWithValues from '@app/hooks/use-universal-filter-values/cloneFilterWithValues';
import { chain, cloneDeep, isEqual } from 'lodash';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';

import { getDefaultFilter } from '@app/hooks/use-universal-filter-values/getDefaultFilter';

import useInit from '@app/hooks/use-init';
import useGetUniversalFilter from '@app/hooks/use-universal-filter-values/use-get-universal-filter';
import getArrayFilterValues from '@app/hooks/use-universal-filter-values/getArrayFilterValues';

import { navigationRoutes } from '@app/utils/navigation-routes';

import { getEmptyPreset } from '@app/utils/presets';

import { getEmptyFilter } from '@app/hooks/use-universal-filter-values/getEmptyFilter';

import { AllStatusProject, GroupSharedStatus } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import classNames from 'classnames';

import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';

import { v4 } from 'uuid';

import TimeDuration from '../project-records/blocks/time-duration';

import {
  getFirstRunSelectionBlockItemClassName,
  getFirstRunSketchToBlockClassName,
} from './index.style';

const localStorageLimit = 'ANALYTICS_PRESET_RECORDS_PAGE_LIMIT';
const DEFAULT_RECORDS_LIMIT = 10;
const PresetPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('pages.records');
  const { t: ta } = useTranslation('pages.presetAp');
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const { items: recordsItems, total } = useAppSelector((state) => state.records);
  const { filter, range, aliases } = useAppSelector((state) => state.filter);
  const { groups: presetTemplates } = useAppSelector((state) => state.presets);
  const [startPreset, setStartPreset] = useState<(Filter & FilterRange) | null>(null);
  const [filterClone, setFilterClone] = useState<Filter & FilterRange>();
  const [activePresetTemplateItem, setActivePresetTemplateItem] = useState('');
  const [initFilter, setInitFilter] = useState<FilterItem[]>(filter);
  const [valueFromTemplateModal, setValueFromTemplateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [recordLoading, setRecordLoading] = useState(false);
  const [fromTemplateModalLoading, setFromTemplateModalLoading] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [reportsTableLimit, changeReportsTableLimit] = useState<number>(
    Number(localStorage.getItem(localStorageLimit)) || DEFAULT_RECORDS_LIMIT,
  );
  const { t: ti } = useTranslation('components.navigateTabs');
  const data: NavigationTabsItem[] = navigationData(project?.project_id).map((item) => ({
    ...item,
    label: ti(item.label),
  }));

  const [firstRunPreset, setFirstRunPreset] = useState<'new_preset' | 'from_template'>(
    'new_preset',
  );
  const [filterState, changeFilter] = useState<{
    range: FilterRangeItem;
    filter: FilterItemNotOnlyArray[];
  }>({
    range: {
      type: 'n',
      parameters: [],
    },
    filter: [],
  });
  const [offset, changeOffset] = useState<number>(0);
  const [sort, changeSortParams] = useState<{ sortBy: string; sortDesc: boolean } | undefined>();
  const tableUtilIconsClassName = classNames(
    'text-3color transition hover:text-action cursor-pointer transition cursor-pointer',
  );
  const fromTemplateRef = useRef<HTMLInputElement>(null);
  const newPresetRef = useRef<HTMLInputElement>(null);
  const getFilter = useGetUniversalFilter();
  const getParams = useUniversalFilterValues();
  const getRecordsAudio = (recordsIds: string[]) => {
    for (const id of recordsIds) {
      dispatch(getRecordsThunk.getRecordAudioThunk({ id }));
    }
  };
  const sortByOrder = (data) => {
    const value = Object.entries(data)[0];
    const params = {
      sortBy: value?.[0] || '',
      sortDesc: value?.[1] === 1,
    };
    dispatch(setSort(params));
    changeSortParams(params);
  };
  const checkingAliasForSorting = (alias_id: string) => {
    const alias = aliases.find((alias) => alias.alias_id === alias_id);
    if (alias) {
      return alias.type_meta !== 'channel';
    }
    return false;
  };
  const sizeFunc = (index) => {
    if (index === 0) {
      return 160;
    } else if (index === recordsItems.headers.length + 1) {
      return 110;
    } else {
      return 150;
    }
  };
  const tableColumns = recordsItems.headers.length
    ? chain(recordsItems.headers)
        .cloneDeep()
        .unshift({
          alias_id: 'duration_records_items',
          name: t('duration'),
        })
        .push({
          alias_id: 'download_records_items',
          name: t('actions'),
        })
        .map(
          (header, index, arr): TableTitle => ({
            title: header.name,
            index: header.alias_id,
            size: sizeFunc(index),
            divider: index === arr.length - 1 ? 'left' : undefined,
            truncate: true,
            filter: checkingAliasForSorting(header.alias_id),
            hintTitle: index === 0 ? t('to_record_title') : '',
          }),
        )
        .value()
    : [];
  const tableData: TableItem[] = recordsItems.records.map((record) => {
    const obj = {};
    chain(record.data)
      .cloneDeep()
      .unshift({
        alias_id: 'duration_records_items',
        value: '',
      })
      .push({
        alias_id: 'download_records_items',
        value: '',
      })
      .map((item, index, arr) => {
        obj[item.alias_id] =
          index === 0 ? (
            <div className="flex cursor-pointer">
              <Link
                target="_blank"
                className="inline-flex items-center"
                to={`/${navigationRoutes.records}/${record.record_id}`}
              >
                <TimeDuration duration={record.duration} />
              </Link>
            </div>
          ) : index === arr.length - 1 ? (
            <div className="flex items-center pl-[19px]">
              <DownloadCloudIcon
                hintTitle={t('download_record')}
                size={18}
                className={`${tableUtilIconsClassName}, mr-[18px]`}
                onClick={() => getRecordsAudio([record.record_id])}
              />
              <Link target="_blank" to={`/${navigationRoutes.records}/${record.record_id}`}>
                <Oscilogram
                  hintTitle={t('to_record_title')}
                  size={22}
                  className={tableUtilIconsClassName}
                />
              </Link>
            </div>
          ) : (
            item.value
          );
      })
      .value();
    return obj;
  });
  const getRecords = useCallback(
    (params: GetFilterParams) => {
      dispatch(setFilterLoading(true));
      setRecordLoading(true);
      return dispatch(
        getRecordsThunk.getRecordsThunk({
          ...{ ...params },
          preset_mode: true,
        }),
      )
        .unwrap()
        .finally(() => {
          dispatch(setFilterLoading(false));
          setRecordLoading(false);
        });
    },
    [dispatch],
  );
  const getPresetSettings = useCallback(() => {
    if (!project) return;
    dispatch(setFilterLoading(true));
    const params = {
      project_id: project.project_id,
    };
    return dispatch(getAnalyticPresetThunk.getPreset(params))
      .unwrap()
      .then((response: { preset: (FilterRange & Filter) | null }) => {
        const preset = response.preset;
        setStartPreset(preset);
        if (!preset || (!preset.filter.length && preset.range.type === 'n')) {
          setFirstRun(true);
          return;
        }
        if (preset) {
          const filter = getArrayFilterValues(preset.filter);
          changeFilter({
            filter: cloneFilterWithValues(filter),
            range: preset.range,
          });
          setFilterClone({ filter: filter, range: preset.range });
          setFirstRun(false);
          getFilter({
            filter,
            range: preset.range,
          });
          getRecords(
            getParams({
              filter: {
                filter: cloneFilterWithValues(filter),
                range: preset.range,
              },
            }),
          );
        }
        return response;
      })
      .finally(() => dispatch(setFilterLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getFilter, getRecords, project]);

  const getInitPresetPage = useCallback(() => {
    setLoading(true);
    setInitialLoading(true);
    getPresetSettings()?.finally(() => {
      setLoading(false);
      setInitialLoading(false);
    });
  }, [getPresetSettings]);
  useEffect(() => {
    if (initialLoading) {
      setInitFilter(filter);
    }
  }, [filter, initialLoading]);

  const [initPresetPage] = useInit(getInitPresetPage);

  useEffect(() => {
    if (!project) return;
    initPresetPage();
  }, [project, initPresetPage]);

  useEffect(() => {
    dispatch(setPresetMode(true));
    return () => {
      dispatch(setPresetMode(false));
    };
  }, [dispatch]);
  useEffect(() => {
    getRecords(
      getParams({
        filter: filterState,
        limit: reportsTableLimit,
        offset,
        ...sort,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, getRecords, offset, reportsTableLimit, sort]);
  useEffect(() => {
    if (total <= reportsTableLimit) {
      const params = {
        ...getParams({
          offset: 0,
        }),
      };
      getRecords(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecords, offset, total]);
  function saveSettingsPreset() {
    if (project?.project_id) {
      dispatch(setFilterLoading(true));
      const params = {
        project_id: project.project_id,
        rules: getParams().filter,
      };
      dispatch(getAnalyticPresetThunk.savePreset(params))
        .unwrap()
        .then(() => {
          setStartPreset({
            ...params.rules,
            filter,
          });
        })
        .finally(() => dispatch(setFilterLoading(false)));
    }
  }

  function changeFirstRunPreset(value: 'new_preset' | 'from_template') {
    setFirstRunPreset(value);
  }

  const createPreset = () => {
    if (firstRunPreset === 'new_preset') {
      const { filter, range } = getDefaultFilter();
      getFilter({
        filter,
        range,
      });
      getRecords(
        getParams({
          filter: {
            filter,
            range,
          },
        }),
      );
      setTimeout(() => setFirstRun(false));
    } else {
      setFromTemplateModalLoading(true);
      setValueFromTemplateModal(true);
      dispatch(getPresetsThunks.getPresetsGroupsThunk()).finally(() =>
        setFromTemplateModalLoading(false),
      );
    }
  };

  function cancelFilter() {
    getPresetSettings();
  }

  function clearFilter() {
    getFilter();
    getRecords(
      getParams({
        filter: getEmptyFilter(),
      }),
    );
  }

  function clickAccordionPresetItem(event: AccordionItemUIPropsClickChildParams) {
    setActivePresetTemplateItem(event.id === activePresetTemplateItem ? '' : event.id);
  }

  function clickPresetTemplate(params: { preset_id: string; preset_group_id: string }) {
    setValueFromTemplateModal(false);
    const presetTemplate = presetTemplates.find(
      (presetTemplate) => presetTemplate.preset_group_id === params.preset_group_id,
    );
    if (presetTemplate) {
      const preset = presetTemplate.presets.find((preset) => preset.preset_id === params.preset_id);
      if (preset) {
        setFirstRun(false);
        getFilter({
          filter: getArrayFilterValues(preset.rules.filter),
          range: preset.rules.range,
        });
        getRecords(
          getParams({
            filter: preset.rules,
          }),
        );
      }
    }
  }

  const changePage = ({ offset, limit }: PaginationResponse) => {
    dispatch(setOffset(offset));
    dispatch(setLimit(limit));
    changeOffset(offset);
  };

  function saveAsTemplatePreset() {
    dispatch(
      getPresetsThunks.createPreset({
        ...getEmptyPreset(),
        name: t('template_name'),
        preset_group_id:
          presetTemplates.find((group) => group.is_default)?.preset_group_id ||
          presetTemplates[0]?.preset_group_id,
        rules: {
          filter: cloneFilterWithValues(filter),
          range,
        },
      }),
    )
      .unwrap()
      .then((response) => {
        navigate(`/${navigationRoutes.presets}/${response.preset_id}`);
      });
  }

  const getSaveBtnDisabled = () => {
    // if (initialLoading) return true;
    if (filter === initFilter) return true;
    if (project?.status !== AllStatusProject.CREATED && project?.status !== AllStatusProject.PAUSE)
      return true;
    if (!project?.owner && project?.shared === GroupSharedStatus.VIEW) return true;
    const currentFilter = cloneDeep({ filter, range });
    return isEqual(startPreset, currentFilter);
  };

  const getSaveAsTemplateDisabled = () => {
    if (!project?.owner && project?.shared === GroupSharedStatus.VIEW) return true;
    return !filter.length && range.type === 'n';
  };

  const getTitleFilter = () => {
    return <h2 className="text-1color font-bold">{t('filter_condition_ap')}</h2>;
  };

  const getFooterFilter = () => {
    return (
      <div className="flex">
        <div className="grow">
          <Button
            label={t('save')}
            className="mr-[10px]"
            onClick={() => saveSettingsPreset()}
            disabled={getSaveBtnDisabled()}
          />
          <Button
            fill="linked"
            label={t('save_as_template')}
            className="mr-[-22px]"
            onClick={() => saveAsTemplatePreset()}
            disabled={getSaveAsTemplateDisabled()}
          />
          <Button
            fill="linked"
            label={t('cancel')}
            onClick={() => cancelFilter()}
            disabled={getSaveBtnDisabled()}
          />
        </div>
        <div>
          <Button
            fill="linked"
            label={t('delete')}
            variant="danger"
            icon="TrashIcon"
            disabled={
              project?.status === 'ACTIVE' ||
              (!project?.owner && project?.shared === GroupSharedStatus.VIEW)
            }
            onClick={() => clearFilter()}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <NavigationTabs items={data} />
      <div className="relative min-h-[200px]">
        {loading && <Loading />}
        {!initialLoading && (
          <>
            {!firstRun ? (
              <RecordsTableWithFilter
                onCloseFilterTable={() => getFilter(filterClone)}
                titleFilter={getTitleFilter()}
                footerFilter={getFooterFilter()}
                titleModalFilter={t('edit_preset')}
                tableColumns={tableColumns}
                tableData={tableData}
                presetMode={true}
                disabledChangeButton={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
                getRecords={getRecords}
                getParams={getParams}
                changePage={changePage}
                loading={recordLoading}
                setLoading={setRecordLoading}
                totalItems={total}
                changeSortTable={sortByOrder}
                changeReportsTableLimit={changeReportsTableLimit}
                reportsTableLimit={reportsTableLimit}
                localStorageLimit={localStorageLimit}
                actionsWhenSavingFilter={(filter) => {
                  changeFilter({ ...filter, filter: cloneFilterWithValues(filter.filter) });
                }}
              />
            ) : (
              <div className="text-center">
                <h3 className="text-0color text-[18px] leading-[21px] font-bold mb-[11px] mt-[55px]">
                  {ta('preset_records_condition')}
                </h3>
                <p className="text-2color text-[17px] leading-[19px] font-medium tracking-tight mb-[37px]">
                  {t('preset_create')}
                </p>
                <div className="flex justify-center mb-[35px]">
                  {['new_preset', 'from_template'].map((item, index) => {
                    return (
                      <div
                        className={classnames(
                          getFirstRunSelectionBlockItemClassName(firstRunPreset === item),
                          index === 0 && 'mr-[26px]',
                        )}
                        onClick={() =>
                          index === 0
                            ? newPresetRef.current?.click()
                            : fromTemplateRef.current?.click()
                        }
                        key={`${item}-${index + v4()}`}
                      >
                        <div className={getFirstRunSketchToBlockClassName(index === 1)}>
                          {index === 0 && (
                            <FolderOpenIcon size={73} className="text-speech_analitics" />
                          )}
                          {index === 1 && (
                            <>
                              <div>
                                <FolderV2Icon
                                  size={54}
                                  className="text-speech_analitics mr-[19.52px] mb-[15.7px]"
                                />
                                <FolderV2Icon size={54} className="text-3color mr-[19.52px]" />
                              </div>
                              <div>
                                <FolderV2Icon size={54} className="text-3color mb-[15.7px]" />
                                <FolderV2Icon size={54} className="text-3color" />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="h-[62px] flex justify-center items-center">
                          <input
                            ref={index === 0 ? newPresetRef : fromTemplateRef}
                            type="radio"
                            name="preset"
                            checked={firstRunPreset === item}
                            onChange={() =>
                              changeFirstRunPreset(item as 'new_preset' | 'from_template')
                            }
                            className="mr-[7px] text-action"
                          />{' '}
                          {index === 0 ? t('new_preset') : t('from_template')}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Button
                  label={t('create_preset')}
                  disabled={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
                  onClick={() => createPreset()}
                />
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        id="from-template-modal"
        value={valueFromTemplateModal}
        setValue={setValueFromTemplateModal}
        title={t('choose_preset_template')}
        size="md"
        noFooter={true}
        loading={fromTemplateModalLoading}
      >
        <AccordionUI>
          {presetTemplates.map((presetTemplate) => {
            return (
              <AccordionItemUI
                title={presetTemplate.name}
                id={presetTemplate.preset_group_id}
                key={presetTemplate.preset_group_id}
                clickBtnChild={clickAccordionPresetItem}
                active={activePresetTemplateItem === presetTemplate.preset_group_id}
              >
                {presetTemplate.presets.map((preset, index, presets) => (
                  <div
                    key={preset.preset_id}
                    className={cn(
                      'flex pt-[20px] pl-[26px] pr-[17px] pb-[19px] shadow-medium cursor-pointer',
                      (index === 0 || index === presets.length - 1) && 'rounded-t-defaultR',
                    )}
                    onClick={() =>
                      clickPresetTemplate({
                        preset_id: preset.preset_id,
                        preset_group_id: preset.preset_group_id,
                      })
                    }
                  >
                    <p className="grow text-[17px] font-medium leading-[19px] tracking-tight	text-1color">
                      {preset.name}
                    </p>
                    <div className="rotate-[-90deg]">
                      <ChevronDownIcon size={14} /> {/* width 12, height 17 */}
                    </div>
                  </div>
                ))}
                {!presetTemplate.presets.length && <p>{t('none_templates')}</p>}
              </AccordionItemUI>
            );
          })}
        </AccordionUI>
      </Modal>
    </div>
  );
};

export default PresetPage;
