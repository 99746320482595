import React from 'react';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/pages/layout/blocks/breadcrumbs/breadcrumbs';
import { Route } from 'react-router-dom';
import { Box } from '@app/components/ui';
import {
  MetricPage,
  PresetPage,
  ProjectRecordsPage,
  ProjectSettingsPage,
  ProjectReportsPage,
  SingleAnalyticPage,
} from '@app/pages';
import RequiredAuth from '@app/routes/required-auth.route';
import ProjectReportsOutlet from '@app/pages/analytics/project-reports/project-reports.outlet';
import ProjectChartsPage from '@app/pages/analytics/project-reports/project-charts.page';
import { AnalyticsProjects } from '@app/pages/analytics-projects';
import MainSettings from '@app/pages/analytics/project-settings/analytics-main-settings/blocks/main-settings';

export const analyticRoute = (
  <Route
    path={navigationRoutes.analytics}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.analytics} crumb="analytics" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <AnalyticsProjects />
        </RequiredAuth>
      }
    />
    <Route
      path={navigationRoutes.newProject}
      element={
        <RequiredAuth>
          <Box className="mt-[42px]">
            <MainSettings newProjFlag={true} />
          </Box>
        </RequiredAuth>
      }
    />

    <Route
      path=":id"
      element={
        <RequiredAuth>
          <SingleAnalyticPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => (
          <BreadcrumbsItem path={`${navigationRoutes.analytics}/:id`} crumb="analytic_project" />
        ),
      }}
    >
      <Route
        index
        element={
          <RequiredAuth>
            <ProjectSettingsPage />
          </RequiredAuth>
        }
      />
      <Route path={navigationRoutes.metric} element={<MetricPage />} />
      <Route path={navigationRoutes.presets} element={<PresetPage />} />
      <Route path={navigationRoutes.reports}>
        <Route
          index
          element={
            <RequiredAuth>
              <ProjectReportsPage />
            </RequiredAuth>
          }
        />
        <Route path=":report_id" element={<ProjectReportsOutlet />}>
          <Route
            index
            element={
              <RequiredAuth>
                <ProjectChartsPage />
              </RequiredAuth>
            }
          />
        </Route>
      </Route>
      <Route
        path={navigationRoutes.projectRecords}
        element={
          <RequiredAuth>
            <ProjectRecordsPage />
          </RequiredAuth>
        }
        handle={{
          crumb: () => (
            <BreadcrumbsItem path={navigationRoutes.analytics} crumb="project_records" />
          ),
        }}
      />
    </Route>
  </Route>
);
