import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import { GetFilterMetricResultParams } from '@app/interfaces/filter';
import { MetricsBulkItems } from '@app/interfaces/metric.type';

import { baseQuery } from '../baseQuery';

export const metricRecordsApi = createApi({
  reducerPath: 'metricRecordsApi',
  tagTypes: ['metricRecords'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getMetricRecords: build.query<
      MetricsBulkItems & { total: number },
      GetFilterMetricResultParams
    >({
      query: (body) => ({
        url: serverRoutes.metrics,
        body,
        params: { project_id: body.project_id },
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetMetricRecordsQuery } = metricRecordsApi;
