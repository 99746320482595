import React, { FC, useCallback, useEffect, useRef } from 'react';
import Blockly from 'blockly';
import { WorkspaceSvg } from 'react-blockly';
import XMLParser from 'react-xml-parser';
import { javascriptGenerator } from 'blockly/javascript';
import { toast } from 'react-toastify';

import { CompositeMetricData } from '@app/interfaces/metric.type';
import useTranslation from '@app/hooks/use-translation';

import toolbox from '../metric-blocks/data/toolbox-categories.data';

import { buildingJsonFromMetricFormula } from './data/json-rebuild.data';

type BlocklyNativeProps = {
  initialXml: string;
  onChange: ({ formula }: { formula: CompositeMetricData['formula'] }) => void;
  onCode: (code: string) => void;
};

const BlocklyNative: FC<BlocklyNativeProps> = (props) => {
  const { initialXml, onChange, onCode } = props;
  const BLOCK_ELEMENT = 'blocklyDiv';
  const { t } = useTranslation('pages.metric');
  const blocklyRef = useRef<HTMLDivElement>(null);
  const blocklyBlock = useRef<WorkspaceSvg | null>(null);
  const xmlChangeHandler = useCallback(
    (event) => {
      function ignoreParserException(xmlWorkspace: Element): {
        children: Array<NonNullable<unknown>>;
      } {
        function parseXMLString() {
          const xmlString = new XMLSerializer().serializeToString(xmlWorkspace);
          return new XMLParser().parseFromString(xmlString, 'text/xml');
        }
        try {
          return parseXMLString();
        } catch (error) {
          console.log((error as Error).message);
          return parseXMLString();
        }
      }
      const eventListToReload = [
        'finished_loading',
        'create',
        'move',
        'change',
        'delete',
        'block_field_intermediate_change',
      ];
      if (eventListToReload.includes(event.type)) {
        if (blocklyBlock?.current && Blockly?.Xml.workspaceToDom(blocklyBlock.current)) {
          const xmlWorkspace = Blockly?.Xml.workspaceToDom(blocklyBlock.current);
          onChange({
            formula: buildingJsonFromMetricFormula(
              ignoreParserException(xmlWorkspace).children[0],
              'formula',
            ),
          });
          onCode(javascriptGenerator.workspaceToCode(blocklyBlock.current));
        }
      }
    },
    [onChange, onCode],
  );

  useEffect(() => {
    try {
      if (blocklyRef && !blocklyBlock?.current) {
        blocklyBlock.current = Blockly.inject(BLOCK_ELEMENT, {
          toolbox,
          sounds: false,
          zoom: {
            controls: true,
            wheel: true,
            startScale: 1.0,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2,
            pinch: true,
          },
        });
        const parser = new window.DOMParser();
        const doc = parser.parseFromString(initialXml, 'text/xml').documentElement;
        setTimeout(() => {
          if (blocklyBlock.current) {
            Blockly.Xml.appendDomToWorkspace(doc, blocklyBlock.current);
            blocklyBlock.current.addChangeListener(xmlChangeHandler);
          }
        }, 0);
      }
    } catch (error) {
      console.error(error);
      toast.error(t('error_metric_build'));
    }
  }, [initialXml, onCode, t, xmlChangeHandler]);

  return <div id={BLOCK_ELEMENT} className="w-[1050px] h-[400px] z-50" />;
};
export default BlocklyNative;
