module.exports = {
  cant_create_group: 'Невозможно создать группу без имени',
  template_name: 'Название шаблона',
  presets_templates: 'Шаблоны условий отбора записей',
  create_preset_template: 'Создать шаблон условий отбора записей',
  group_name: 'Название группы',
  remove_presets_group_title: 'Предупреждение',
  confirm_remove_presets_group: 'Вы действительно хотите удалить данную группу?',
  table: {
    name: 'НАЗВАНИЕ',
    time: 'Временной интервал',
    desc: 'Описание',
    actions: 'Действия',
  },
  copy: 'копия 1',
  from: 'от',
  to: 'до',
  last_days: 'последних суток',
  warning: 'Предупреждение',
  confirm_group_delete: 'Вы действительно хотите удалить данный шаблон?',
};
