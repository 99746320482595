import { CircleButton, RubberTable } from '@app/components/ui';
import { TableColumn, TableItem } from '@app/components/ui/rubber-table/table.types';
import { AliasSettingsObj } from '@app/interfaces/settings.type';
import React, { FC } from 'react';
import useTranslation from '@app/hooks/use-translation';
import Icon from '@app/components/ui/icons';
import { LetterA } from '@icons';

type AliasSettingsTablePropsType = {
  data: AliasSettingsObj[];
  cols: TableColumn[];
  onEditAliasHandler(id: string): void;
  onDeleteAliasHandler(id: string): void;
};
const iconData = {
  num: 'NumberIcon',
  str: 'TextIcon',
};
export const AliasSettingsTable: FC<AliasSettingsTablePropsType> = (props) => {
  const { data, cols, onDeleteAliasHandler, onEditAliasHandler } = props;
  const { t } = useTranslation('pages.settings');
  const typesTranslateData = {
    num: t('alias.values_translate.num'),
    str: t('alias.values_translate.str'),
    system: t('alias.values_translate.system'),
    base: t('alias.values_translate.base'),
    channel: t('alias.values_translate.channel'),
    record: t('alias.values_translate.record'),
  };
  function getData() {
    return data.map((item) => ({
      ...item,
      utils: (
        <div className="flex pl-[10px] w-[140px] gap-[10px] items-center border-l-[1px] border-[#F1F2F3]">
          <CircleButton
            icon="EditIcon"
            size={18}
            className={`text-3color hover:text-action`}
            onClick={() => onEditAliasHandler(item.alias_id)}
          />
          <CircleButton
            icon="TrashIcon"
            size={18}
            onClick={() => onDeleteAliasHandler(item.alias_id)}
            className={`text-basic_red`}
          />
        </div>
      ),
      type_value: (
        <div className="flex gap-[7px] h-full">
          <Icon
            className="mb-[-6px] text-3color"
            size={17}
            name={iconData[item.type_value] || 'HelpCircleIcon'}
          />
          {typesTranslateData[item.type_value] || item.type_value}
        </div>
      ),
      type_meta: <div>{typesTranslateData[item.type_meta] || item.type_meta}</div>,
      name: (
        <div className="flex items-center gap-[10px]">
          <LetterA className="text-[#1B97F1]" size={14} />
          <div className="font-[700]">{item.name}</div>
        </div>
      ),
    })) as unknown as TableItem[];
  }
  return <RubberTable dataSource={getData()} columns={cols} withoutCols />;
};
