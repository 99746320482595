import React, { FC, useState } from 'react';

import BoxSpoiler from '@app/components/ui/box-spoiler';
import { useTranslation } from '@app/hooks/use-translation';
import { CalculatedRecordMetric } from '@app/interfaces/records';
import { Toggle } from '@ui';

import { tableTitleClassName } from './record-metric.style';
import MetricItem from './metric-item';

type RecordMetricProps = {
  projectMetric: CalculatedRecordMetric;
  onChangeActiveMetric: (metricId: string | undefined, type: 'once' | 'all') => void;
};

const RecordMetric: FC<RecordMetricProps> = (props) => {
  const { projectMetric, onChangeActiveMetric } = props;
  const [activeMetricId, setActiveMetric] = useState<string>();
  const { t } = useTranslation('components.audio');
  const [isShowEmpty, changeShowEmpty] = useState<boolean>(false);
  function handleMetricItemAction({
    caseName,
    value,
  }: {
    caseName: string;
    value: string | number;
  }) {
    if (caseName === 'find-words') {
      const currentMetric = activeMetricId === String(value) ? undefined : String(value);
      setActiveMetric(currentMetric);
      onChangeActiveMetric?.(currentMetric, 'all');
    }
    if (caseName === 'find-words_once') {
      setActiveMetric(value as string);
      onChangeActiveMetric?.(value as string, 'once');
    }
  }
  const renderMetric = Object.entries(projectMetric).map(([metricId, knownCase], index) =>
    ((typeof knownCase.value === 'number' || isShowEmpty) && knownCase.value > 0) ||
    typeof knownCase.value === 'string' ? (
      <MetricItem
        isActive={metricId === activeMetricId}
        key={index}
        data={knownCase}
        onChangeMetricItem={handleMetricItemAction}
      />
    ) : (
      <div key={index}></div>
    ),
  );

  const renderShowHideMetric = (
    <div className="flex items-center">
      <Toggle
        size="small"
        onChecked={changeShowEmpty.bind(null, (prevState) => !prevState)}
        checked={isShowEmpty}
      />
      <div className="ml-[8px] text-[12px] text-2color mb-[-4px]">{t('metric_show_empty')}</div>
    </div>
  );

  return (
    <BoxSpoiler title={t('metric_list')} sideComponent={renderShowHideMetric}>
      <div className="grid grid-cols-[2fr_1fr_50px] gap-2 px-[26px]">
        <div className={tableTitleClassName}>{t('metric')}</div>
        <div className={tableTitleClassName}>{t('metric_value')}</div>
        <div></div>
      </div>
      <div className="py-[15px] pb-[20px]">{renderMetric}</div>
    </BoxSpoiler>
  );
};

export default RecordMetric;
