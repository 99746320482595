export const langData = {
  gui: {
    category_variables: {
      ru: {
        name: 'Переменные',
      },
    },
    category_expressions: {
      ru: {
        name: 'Выражения',
      },
    },
    category_logic: {
      ru: {
        name: 'Логические функции',
      },
    },
  },
  blocks: {
    formula_start: {
      ru: {
        help: 'Это начало формулы. Все, что вы разместите за пределами этого блока, не будет использовано.',
      },
    },
    formula_expression: {
      ru: {
        name: '0',
        help: 'Любое выражение (строка, число, булевое)\nЭтот блок может заменять другие блоки выражения.',
      },
    },
    formula_mathops: {
      ru: {
        help: 'Выполняет выбранную математическую операцию с заданными выражениями.',
        button: {
          add: 'Добавить операцию',
          remove: 'Удалить операцию',
        },
      },
    },
    formula_relops: {
      ru: {
        help: 'Выполняет выбранную реляционную операцию с заданными выражениями.',
      },
    },

    /* Logic */
    formula_if: {
      ru: {
        name: 'Если',
        then: 'Тогда',
        otherwise: 'Иначе',
        help: 'Оценивает данное логическое выражение и возвращает со вторым параметром, если оно истинно, или третьим, если оно ложно.',
      },
    },
    formula_and_or: {
      ru: {
        name: [
          ['и', 'AND'],
          ['или', 'OR'],
        ],
        help: 'Выполняет логическую операцию И или ИЛИ между заданными выражениями.',
        button: {
          add: 'Добавить значение',
          remove: 'Удалить значение',
        },
      },
    },
    formula_not: {
      ru: {
        name: 'нет',
        help: 'Изменяет результат логического выражения с ИСТИНА на ЛОЖЬ и с ЛОЖЬ на ИСТИНА.',
      },
    },
    formula_metric: {
      ru: {
        titles: 'Меирики',
        title: 'Метрика',
        empty: 'Нет метрик',
      },
    },
  },
};
