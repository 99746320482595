import React, { FC, useState } from 'react';

import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

import { EasyModal, Input } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import moment from 'moment';

type ChartDatePickerModalBlockProps = {
  show: boolean;
  onClose: () => void;
  onSuccess: (dates: { start: string | null; end: string | null }) => void;
};

export type DateIntervalKeep = {
  start: string | null;
  startTime: string | null;
  endTime: string | null;
  end: string | null;
};

const ChartDatePickerModalBlock: FC<ChartDatePickerModalBlockProps> = (props) => {
  const { show, onClose, onSuccess } = props;
  const DEFAULT_TIME_START = '00:00';
  const DEFAULT_TIME_END = '23:59';
  const DEFAULT_DATE = moment().format('YYYY-MM-DD');
  const [dateInterval, setDateInterval] = useState<DateIntervalKeep>({
    start: DEFAULT_DATE,
    end: DEFAULT_DATE,
    endTime: DEFAULT_TIME_END,
    startTime: DEFAULT_TIME_START,
  });

  const { t } = useTranslation('pages.chartPage');
  function handlerChangePicker({
    date,
    type,
  }: {
    date: Date | null | string;
    type: 'start' | 'end' | 'startTime' | 'endTime';
  }) {
    setDateInterval((prevState) => ({
      ...prevState,
      [type]: type === 'end' || type === 'start' ? moment(date).format('YYYY-MM-DD') : date,
    }));
  }
  function handlerSuccessDateChoose() {
    if (
      dateInterval.start === null ||
      dateInterval.end === null ||
      dateInterval.endTime === null ||
      dateInterval.startTime === null ||
      Number(moment(dateInterval.start).unix()) > Number(moment(dateInterval.end).unix())
    ) {
      toast.info(t('interval_error'));
      return;
    } else {
      onClose?.();
      onSuccess?.({
        start: `${dateInterval.start} ${dateInterval.startTime}:00`,
        end: `${dateInterval.end} ${dateInterval.endTime}:00`,
      });
    }
  }
  return (
    <EasyModal
      label={t('modal_interval_title')}
      variant="medium"
      show={show}
      onClose={onClose}
      successText={t('modal_interval_success_button')}
      onSuccess={handlerSuccessDateChoose}
      isAutoClosable={false}
      noOverflowContent
    >
      <div className="inline-flex items-center h-[150px]">
        <div className="mr-[10px]"> {t('modal_interval_before')}</div>
        <DatePicker
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          onChange={(date) => handlerChangePicker({ date, type: 'start' })}
          selected={moment(dateInterval.start).toDate()}
          maxDate={moment(dateInterval.end).toDate()}
          minDate={moment(dateInterval.end).subtract(364, 'days').toDate()}
          showDisabledMonthNavigation
          customInput={
            <div className="inline-flex items-center">
              <Input
                name="dateStart"
                icon="CalendarEmptyIcon"
                defaultValue={String(dateInterval.start)}
                disabled
              />
            </div>
          }
        />
        <div className="w-[110px] ml-[11px]">
          <Input
            mask="99:99"
            name="timeStart"
            icon="ClockIcon"
            defaultValue={dateInterval.startTime || DEFAULT_TIME_START}
            onChange={(event) =>
              handlerChangePicker({ date: event.target.value, type: 'startTime' })
            }
          />
        </div>
        <div className="mr-[10px]  ml-[23px]"> {t('modal_interval_after')}</div>
        <DatePicker
          showYearDropdown
          showMonthDropdown
          dropdownMode="select"
          maxDate={moment(dateInterval.start).subtract(-364, 'days').toDate()}
          minDate={moment(dateInterval.start).toDate()}
          onChange={(date) => handlerChangePicker({ date, type: 'end' })}
          selected={moment(dateInterval.end).toDate()}
          customInput={
            <div className="inline-flex items-center">
              <Input
                name="dateEnd"
                icon="CalendarEmptyIcon"
                defaultValue={String(dateInterval.end)}
                disabled
              />
            </div>
          }
        />
        <div className="w-[110px] ml-[11px]">
          <Input
            mask="99:99"
            defaultValue={dateInterval.endTime || DEFAULT_TIME_END}
            name="timeEnd"
            onChange={(event) => handlerChangePicker({ date: event.target.value, type: 'endTime' })}
            icon="ClockIcon"
          />
        </div>
      </div>
    </EasyModal>
  );
};

export default ChartDatePickerModalBlock;
