import { GetFilterParams } from '@app/interfaces/filter';
import { useAppSelector } from '@app/store/store';

import cloneFilterWithValues from './cloneFilterWithValues';

const useUniversalFilterValues = () => {
  const { cloneFilter, cloneRange, limit, offset, sortBy, sortDesc, preset_mode } = useAppSelector(
    (state) => state.filter,
  );

  function getParams(params?: Partial<GetFilterParams>) {
    const totalParams: GetFilterParams = {
      filter: {
        range: cloneRange,
        filter: cloneFilterWithValues(cloneFilter),
      },
      offset,
      limit,
      preset_mode,
      ...params,
    };

    function deleteSortParams() {
      delete totalParams.sortBy;
      delete totalParams.sortDesc;
    }

    if (params?.sortBy != null && params?.sortBy === '') {
      deleteSortParams();
    } else if (params?.sortBy || sortBy) {
      totalParams.sortBy = params?.sortBy || sortBy;
      totalParams.sortDesc = params?.sortDesc || sortDesc;
    } else {
      deleteSortParams();
    }

    return totalParams;
  }

  return getParams;
};

export default useUniversalFilterValues;
