import { CircleButton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import classNames from 'classnames';
import React from 'react';

import { colorsArray } from '../constantsForModals';

import cls from './color-input.module.scss';

export const ColorInput: React.FC<{
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  active: string;
}> = ({ onClick, active }) => {
  return (
    <div>
      <div id="123321" className="flex items-center gap-[6px]">
        {colorsArray.slice(0, 10).map((e) => {
          return (
            <InputForColorInput
              className={`${
                e.color === 'FFFFFF'
                  ? 'border-[1px] border-solid border-[#9D9D9D] rounded-full'
                  : ''
              }`}
              key={e.color}
              onClick={onClick}
              color={e.color}
              active={active.toUpperCase() === '#' + e.color}
              activeColor={active}
            />
          );
        })}
        <ButtonForColorInput active={active} onClick={onClick} colors={colorsArray.slice(10)} />
      </div>
    </div>
  );
};

const ButtonForColorInput = ({ onClick, colors, active }) => {
  const [visible, setVisible] = React.useState(false);
  const { t } = useTranslation('pages.allPagesHintTitles');
  return (
    <div className="max-h-[18px] z-50">
      <CircleButton
        hintTitle={t('choose_color')}
        size={8}
        className="min-w-[18px] min-h-[18px] border-solid border-[1px] border-[#C4C4C4] rounded-[90px] flex justify-center bold"
        onClick={() => setVisible(!visible)}
        icon={'HorizontalDotsIcon'}
      />
      {visible && (
        <div className="absolute border-[#DCE0E5] border-[1px] border-solid min-w-[138px] min-h-[70px] max-w-[140px] mt-[5px] left-[384px] rounded-[12px_2px_12px_12px]">
          <div className="flex items-center justify-start p-[12px] gap-[6px] flex-wrap">
            {colors.map((e) => {
              return (
                <InputForColorInput
                  setVisible={setVisible}
                  key={e.color}
                  onClick={onClick}
                  color={e.color}
                  active={active.toUpperCase() === '#' + e.color}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
const InputForColorInput: React.FC<{
  onClick: (event) => void;
  color: string;
  active: boolean;
  className?: string;
  activeColor?: string;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ onClick, color, active, className, activeColor }) => {
  const [colorValue, setColorValue] = React.useState(color);
  const cn = classNames(cls.label, className);
  const activeClass = classNames(
    'absolute',
    `${activeColor === '#ffffff' ? 'left-[2px]' : 'left-[3px]'}`,
    `${activeColor === '#ffffff' ? 'top-[2px]' : 'top-[3px]'}`,
    'w-[12px]',
    'h-[12px]',
    'border-[2px]',
    'border-solid',
    `${activeColor === '#ffffff' ? 'border-[#000]' : 'border-[#fff]'}`,
    'cursor-inherit',
    'rounded-full',
  );
  return (
    <label className={cn}>
      <input
        onClick={(event) => {
          event.preventDefault();
          onClick(event);
        }}
        className={cls.input}
        type={'color'}
        value={`#${colorValue}`}
        onChange={(e) => setColorValue(e.target.value)}
      />
      {active && <div className={activeClass}></div>}
    </label>
  );
};
