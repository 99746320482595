import { useRef, useEffect, Dispatch, SetStateAction } from 'react';

export default function useResizeObserver<T extends HTMLDivElement = HTMLDivElement>(
  ref: T | undefined,
  setState: Dispatch<SetStateAction<number>>,
) {
  const observer = useRef(
    // eslint-disable-next-line compat/compat
    new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setState(width);
    }),
  );
  useEffect(() => {
    ref && observer.current.observe(ref);
  }, [ref, observer]);
}
