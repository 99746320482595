import { useState, useEffect } from 'react';

const useInit = (callback: any, ...args) => {
  // callback: Function
  const [mounted, setMounted] = useState(false);
  const resetInit = () => setMounted(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      callback(...args);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, callback]);

  return [resetInit];
};

export default useInit;
