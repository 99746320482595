import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

const CellWrapper: FC<{ position?: 'first' | 'last'; children: ReactNode; size: number }> = (
  props,
) => {
  const { children, position, size } = props;
  if (position) {
    const positionClassName = {
      common: 'absolute hover:z-[9999] h-[40px] bg-basic_app_bg',
      first: 'left-0',
      last: 'right-0',
      first_rounded: 'rounded-l-lg',
      last_rounded: 'rounded-r-lg',
    };
    return (
      <div className="w-full bg-white">
        <div
          className={cn(positionClassName.common, positionClassName[position])}
          style={{ width: `${size}px` }}
        >
          <div
            className={cn(
              'bg-white hover:z-[9999] flex items-center justify-center',
              positionClassName[position + '_rounded'],
            )}
          >
            {position === 'last' && (
              <div>
                <div className="w-[1px] h-[20px] bg-bg_3" />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    );
  }

  return <div className="bg-white">{children}</div>;
};

export default CellWrapper;
