import React, { FC, useCallback, useEffect } from 'react';
import { isEqual } from 'lodash';

import ViewFilter from '@app/components/UniversalFilter';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { setOffset } from '@app/store/reducers/filterSlice';
import { Filter, FilterRange } from '@app/interfaces/filter';
import useGetUniversalFilter from '@app/hooks/use-universal-filter-values/use-get-universal-filter';
import useTranslation from '@app/hooks/use-translation';
import { Loading } from '@ui';

import { Table, Paginator } from '../ui';

import { RecordsTableWithFilterProps } from './index.type';

const RecordsTableWithFilter: FC<RecordsTableWithFilterProps> = (props) => {
  const {
    getParams,
    actionsWhenSavingFilter,
    getRecords: getRecordsItems,
    getAliases,
    changePage,
    changeSortTable,
    loading,
    tableColumns,
    tableData,
    titleFilter,
    footerFilter,
    titleModalFilter,
    totalItems: total,
    metricMode = false,
    disabledChangeButton = false,
    addFromTemplate = false,
    onAddFromTemplateClick,
    onCloseFilterTable,
    localStorageLimit,
    changeReportsTableLimit,
    reportsTableLimit,
    // resetFilterOnRender = false,
  } = props;
  const dispatch = useAppDispatch();
  const { offset } = useAppSelector((state) => state.filter);
  const getFilter = useGetUniversalFilter();
  const { t } = useTranslation('components.recordTableWithFilter');
  const getRecords = useCallback(
    (params) => {
      return getRecordsItems?.(params);
    },
    [getRecordsItems],
  );

  useEffect(() => {
    return () => {
      getFilter();
    };
  }, [getFilter]);

  function saveFilter(newFilter: Filter & FilterRange, oldFilter: Filter & FilterRange) {
    const equal = isEqual(newFilter, oldFilter);
    if (!equal) {
      dispatch(setOffset(0));
    }
    actionsWhenSavingFilter?.(newFilter);
    return getRecords(getParams(!equal ? { offset: 0 } : {}));
  }
  return (
    <>
      {loading && <Loading />}
      <div className="relative pb-[65px]">
        <ViewFilter
          addFromTemplate={addFromTemplate}
          title={titleFilter}
          footer={footerFilter}
          saveFilter={async (newFilter, oldFilter) => saveFilter(newFilter, oldFilter)}
          titleModal={titleModalFilter}
          metricMode={metricMode}
          getAliases={getAliases}
          disabledChangeButton={disabledChangeButton}
          onAddFromTemplateClick={() => onAddFromTemplateClick?.()}
          onCloseFilterTable={() => onCloseFilterTable?.()}
        />
        <div className="pt-[30px]">
          <Table
            columns={tableColumns}
            dataSource={tableData}
            // isPending={loading || false}
            onFilter={changeSortTable}
            skeletons={tableData.length}
          />
        </div>
        <div className="absolute bottom-0 left-0 right-0">
          <Paginator
            limit={reportsTableLimit}
            page={offset + 1}
            count={total}
            onChange={(data) => {
              changePage(data);
              localStorageLimit && localStorage.setItem(localStorageLimit, String(data.limit));
              changeReportsTableLimit(data.limit);
            }}
            pageOptions={[
              { title: '10 ' + t('calls'), value: 10 },
              { title: '15 ' + t('calls'), value: 15 },
              { title: '25 ' + t('calls'), value: 25 },
              { title: '50 ' + t('calls'), value: 50 },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default RecordsTableWithFilter;
