import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';
import {
  AllStatusProject,
  CreateProjectAnalyticsParams,
  GroupsListObjectItem,
  ProjectAnalyticsParams,
  ProjectItem,
} from '@app/interfaces/analytics';

import { baseQuery } from '../baseQuery';
export const analyticsProjectApi = createApi({
  reducerPath: 'analyticsProject',
  tagTypes: ['analyticsProject', 'singleProject', 'status'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAnalyticsProjectsList: build.query<GroupsListObjectItem[], void>({
      query: () => ({
        url: serverRoutes.analyticsProjectsGroups,
      }),
      transformResponse(response: { projects_groups: GroupsListObjectItem[] }) {
        return response.projects_groups;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ project_group_id }) => ({
                type: 'analyticsProject' as const,
                project_group_id,
              })),
              'analyticsProject',
            ]
          : ['analyticsProject'],
    }),
    createAnalyticsProjectsGroup: build.mutation<GroupsListObjectItem, { name: string }>({
      query: (body) => ({
        url: serverRoutes.analyticsProjectsGroup,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    deleteAnalyticsProjectsGroup: build.mutation<GroupsListObjectItem, { id: string }>({
      query: (params) => ({
        url: serverRoutes.analyticsProjectsGroup,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    editAnalyticsProjectsGroup: build.mutation<
      GroupsListObjectItem,
      { params: { id: string }; body: { name: string } }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.analyticsProjectsGroup,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    //single project
    getSingleAnalyticProject: build.query<ProjectItem, { id: string }>({
      query: (params) => ({
        url: serverRoutes.analyticsProject,
        params,
      }),
      providesTags: ['singleProject'],
    }),
    createSingleAnalyticProject: build.mutation<ProjectItem, CreateProjectAnalyticsParams>({
      query: (body) => ({
        url: serverRoutes.analyticsProject,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    editSingleAnalyticProject: build.mutation<
      ProjectItem,
      { body: CreateProjectAnalyticsParams; params: { id: string } }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.analyticsProject,
        method: 'PUT',
        body,
        params,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    deleteAnalyticProject: build.mutation<ProjectAnalyticsParams, { id: string }>({
      query: (params) => ({
        url: serverRoutes.analyticsProject,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['analyticsProject'],
    }),
    //status
    changeStatusOfAnalyticProject: build.mutation<
      { project_status: AllStatusProject },
      { params: { id: string }; body: { status: AllStatusProject } }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.analyticsProjectStatusChange,
        method: 'PUT',
        body,
        params,
      }),
      invalidatesTags: ['analyticsProject', 'singleProject', 'status'],
    }),
    getStatusOfAnalyticProject: build.query<
      { project_status: AllStatusProject },
      { id: string | undefined }
    >({
      query: (params) => ({
        url: serverRoutes.analyticsProjectStatus,
        params,
      }),
      providesTags: ['status'],
    }),
    // mass edit requests
    deleteManyProjects: build.mutation<string, ProjectAnalyticsParams[]>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const deleteProject = async (id: string) =>
          await fetchWithBQ({
            url: serverRoutes.analyticsProject,
            params: { id },
            method: 'DELETE',
          });
        for (const project of arg) {
          project.project_id && (await deleteProject(project.project_id));
        }
        return { data: 'all good' };
      },
      invalidatesTags: ['analyticsProject'],
    }),
    editManyProjects: build.mutation<
      string,
      { projects: ProjectAnalyticsParams[]; groupId: string }
    >({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const editProject = async (project: ProjectAnalyticsParams) =>
          await fetchWithBQ({
            url: serverRoutes.analyticsProject,
            params: { id: project.project_id },
            method: 'PUT',
            body: project,
          });
        for (const project of arg.projects) {
          await editProject({ ...project, project_group_id: arg.groupId });
        }
        return { data: 'all good' };
      },
      invalidatesTags: ['analyticsProject'],
    }),
  }),
});

export const {
  useGetAnalyticsProjectsListQuery,
  useCreateAnalyticsProjectsGroupMutation,
  useDeleteAnalyticsProjectsGroupMutation,
  useEditAnalyticsProjectsGroupMutation,
  useDeleteAnalyticProjectMutation,
  useDeleteManyProjectsMutation,
  useEditManyProjectsMutation,
  useGetSingleAnalyticProjectQuery,
  useCreateSingleAnalyticProjectMutation,
  useEditSingleAnalyticProjectMutation,
  useChangeStatusOfAnalyticProjectMutation,
  useGetStatusOfAnalyticProjectQuery,
} = analyticsProjectApi;
