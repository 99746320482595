module.exports = {
  title: ' Список проектов',
  filter_title: 'Записи',
  empty_records: 'Нет записей',
  to_record_title: 'Перейти к просмотру записи',
  download_record: 'Скачать аудиозапись',
  actions: 'Действия',
  button: {
    create_report_by_filter: 'Создать отчет на основе фильтра',
    start_project: 'Запустить проект',
    create_live_report: 'Создать Life отчет',
  },
  table: {
    duration: 'Длительность',
  },
  drop_menu: {
    download_audio: 'Скачать аудиозапись',
    recalculate_metric: 'Пересчитать метрики',
    watch_record: 'Просмотр записи',
  },
};
