import { PermissionsType } from '@app/interfaces/permissions.type';
import { useGetUserInfoQuery } from '@app/store/reducers/get-user-info.api';

export function usePermissionCheck({ tag, permissions }: PermissionsType): boolean | 'disabled' {
  const { data: displayPermissions } = useGetUserInfoQuery();
  if (!tag) return false;
  if (!displayPermissions?.permissions?.[permissions]) return true;
  if (!displayPermissions?.permissions?.[permissions][tag]) return true;
  return !displayPermissions?.permissions?.[permissions][tag];
}
