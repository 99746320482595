import React, { Fragment, useEffect } from 'react';
import { Input } from '@ui';
import { useFieldArray, useFormContext } from 'react-hook-form';
import useTranslation from '@app/hooks/use-translation';

const NestedField = ({ nestIndex, appendFilter, fieldsLength }) => {
  const { t } = useTranslation('components.filter');
  const { control, watch, setValue } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: `filter[${nestIndex}].value`,
  });
  useEffect(() => {
    fields.length === 0 && append('');
  }, [append, fields]);
  function onInputChange(event, index) {
    const value = event.target.value;
    if (!watch(`filter[${nestIndex}].value[${index}]`) && value && fieldsLength === nestIndex + 1) {
      appendFilter('');
    }
    setValue(`filter[${nestIndex}].value[${index}]`, value);
  }

  // console.log(fields);
  return (
    <div className={'flex flex-col items-center '}>
      {fields.map((item, k) => {
        return (
          <Fragment key={item.id}>
            {k !== 0 && (
              <div
                className={
                  'h-[17px] w-full flex items-center justify-center text-[14px] leading-[17px] font-[400] text-4color'
                }
              >
                {t('or')}
              </div>
            )}
            <div className={'w-[430px] transition-all cursor-pointer'}>
              <Input
                defaultValue={watch(`filter[${nestIndex}].value[${k}]`)}
                onChange={(value) => onInputChange(value, k)}
                // disabled={!watch().filter[nestIndex].id}
                onClick={() => (k !== fields.length - 1 ? remove(k) : append(''))}
                iconColor={'primary'}
                icon={k !== fields.length - 1 ? 'MinusIcon' : 'CirclePlusIcon'}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default NestedField;
