import { PresetsDataType } from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';

export function getEmptyPreset(): PresetsDataType {
  return {
    name: '',
    description: '',
    owner_id: '',
    preset_group_id: '',
    preset_id: '',
    rules: {
      filter: [],
      range: {
        type: 'n',
        parameters: [],
      },
    },
    shared: false,
  };
}
