import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  // ChartsListOnDashboard,
  ChartsListOnDashboardResponse,
  CreateChartOnDashboardType,
  DashboardChartSettings,
  dashboardChartsSettings,
  EditGraphLayoutType,
} from '@app/interfaces/dashboards.type';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { ReportChart } from '@app/interfaces/report.type';

import { baseQuery } from '../baseQuery';

export const dashboardChartsApi = createApi({
  reducerPath: 'dashboardChartsApi',
  tagTypes: ['dashboardCharts'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getDashboardGraphList: build.query<(dashboardChartsSettings & ReportChart)[], string>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const graphsArray: (dashboardChartsSettings & ReportChart)[] = [];
        const graphsOnDashboard = (await fetchWithBQ({
          url: serverRoutes.dashboardGraphs,
          params: { dashboard_id: arg },
        })) as unknown as { data: ChartsListOnDashboardResponse };
        const data = graphsOnDashboard?.data?.dashboard_graph_list;
        for (const graph of data) {
          const graphData = (await fetchWithBQ({
            url: serverRoutes.chartData,
            method: 'POST',
            body: { id: graph.live_report_graph_id, ...graph.settings_override },
          })) as unknown as { data: { data: dashboardChartsSettings & ReportChart } };
          graphsArray.push({
            ...graphData.data.data,
            dashboard_graph_id: graph.dashboard_graph_id,
            live_report_id: graph.live_report_id,
            layout: { ...graph.layout, i: graph.dashboard_graph_id },
            width: graph.settings_override.width as 'full' | 'part',
          });
        }
        return graphsArray
          ? { data: graphsArray }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ dashboard_graph_id }) => ({
                type: 'dashboardCharts' as const,
                dashboard_graph_id,
              })),
              'dashboardCharts',
            ]
          : ['dashboardCharts'],
    }),
    addNewGraphOnDashboard: build.mutation<DashboardChartSettings, CreateChartOnDashboardType>({
      query: (body) => ({
        url: serverRoutes.dashboardGraph,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['dashboardCharts'],
    }),
    editGraphOnDashboard: build.mutation<
      DashboardChartSettings,
      { body: CreateChartOnDashboardType; id: string }
    >({
      query: ({ body, id }) => ({
        url: serverRoutes.dashboardGraph,
        method: 'PUT',
        params: { dashboard_graph_id: id },
        body,
      }),
      invalidatesTags: ['dashboardCharts'],
    }),
    deleteGraphOnDashboard: build.mutation({
      query: (body: string) => ({
        url: serverRoutes.dashboardGraph,
        method: 'DELETE',
        params: { dashboard_graph_id: body },
      }),
      invalidatesTags: ['dashboardCharts'],
    }),
    // dashboard layout change
    editDashboardGraphLayout: build.mutation<
      DashboardChartSettings,
      { body: EditGraphLayoutType[]; params: { dashboard_id: string } }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.dashboardLayout,
        method: 'PUT',
        params,
        body: { dashboard_graph_order: body },
      }),
    }),
    // getDashboard: build.query<DashboardsSettingsType, string>({
    //   query: (body) => ({
    //     url: serverRoutes.dashboardGraph,
    //     params: { id: body },
    //   }),
    // }),
  }),
});

export const {
  useGetDashboardGraphListQuery,
  useAddNewGraphOnDashboardMutation,
  useEditGraphOnDashboardMutation,
  useDeleteGraphOnDashboardMutation,
  useEditDashboardGraphLayoutMutation,
} = dashboardChartsApi;
