import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';
import { navigationRoutes } from '@app/utils/navigation-routes';

export const navigationData: (id: string | undefined) => Array<NavigationTabsItem> = (id) => [
  {
    index: 'main settings',
    link: `/${navigationRoutes.analytics}/${id}`,
    icon: 'SettingIcon',
    label: 'main_settings',
  },
  {
    index: 'preset',
    link: `/${navigationRoutes.analytics}/${id}/${navigationRoutes.presets}`,
    icon: 'MenuSmallIcon',
    label: 'preset',
  },
  {
    index: 'metrics',
    link: `/${navigationRoutes.analytics}/${id}/${navigationRoutes.metric}`,
    icon: 'ChartIcon',
    label: 'metrics',
  },
  {
    index: 'projectRecords',
    link: `/${navigationRoutes.analytics}/${id}/${navigationRoutes.projectRecords}`,
    icon: 'PlayCircleIcon',
    label: 'project_records',
  },
  {
    index: 'reports',
    icon: 'MetricIcon',
    link: `/${navigationRoutes.analytics}/${id}/${navigationRoutes.reports}`,
    label: 'reports',
  },
];
