export type NavigationRoute =
  | 'main'
  | 'records'
  | 'analytics'
  | 'reports'
  | 'presets'
  | 'templates'
  | 'settings'
  | 'dictionaries'
  | 'createDictionaries'
  | 'authorization'
  | 'projects'
  | 'metric'
  | 'triggers'
  | 'newProject'
  | 'reportCharts'
  | 'reportRecords'
  | 'projectRecords'
  | 'auditLogs'
  | 'wordsCloud'
  | 'error'
  | 'settingsAlias'
  | 'settingsRecognitionRules';

export const navigationRoutes: Record<NavigationRoute, string> = {
  main: '/',
  records: 'records',
  analytics: 'analytics',
  reports: 'reports',
  presets: 'presets',
  templates: 'templates',
  settings: 'settings',
  settingsAlias: 'alias',
  settingsRecognitionRules: 'recognitionRules',
  dictionaries: 'dictionaries',
  createDictionaries: 'createDictionaries',
  authorization: 'authorization',
  projectRecords: 'project-records',
  projects: 'projects',
  metric: 'metric',
  newProject: 'new_project',
  triggers: 'triggers',
  reportCharts: 'report-charts',
  reportRecords: 'report-records',
  auditLogs: 'audit-logs',
  wordsCloud: 'words-cloud',
  error: 'error',
};

export type NavigationRoutes = typeof navigationRoutes;
