import React from 'react';

import { NavigationTabs } from '@ui';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';
import { navigationRoutes } from '@app/utils/navigation-routes';

const SettingsNavigationBlock = () => {
  const navigationItemsList: NavigationTabsItem[] = [
    {
      link: `/${navigationRoutes.settings}`,
      icon: 'FolderIcon',
      label: 'Общие настройки',
      index: 'commonSettings',
    },
    {
      link: `${navigationRoutes.settingsAlias}`,
      icon: 'LetterA',
      label: 'Алиасы',
      index: 'alias',
      iconSize: 15,
    },
    {
      link: `${navigationRoutes.settingsRecognitionRules}`,
      icon: 'RecordChannelIcon',
      label: 'Правила распознавания',
      index: 'recognitionRules',
    },
  ];
  return <NavigationTabs items={navigationItemsList} />;
};

export default SettingsNavigationBlock;
