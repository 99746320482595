// import { getSingleMetricInfo } from '@app/api/analytics-api/analytics-metric.api';
import React from 'react';
import { useParams } from 'react-router';

import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';

import { getMetricThunks } from '@app/store/reducers/metric-slice';

import { useAppDispatch, useAppSelector } from '@app/store/store';

import { deleteMetric, recalculateMetrics } from '@app/api/analytics-api/analytics-metric.api';

import { getPresetsThunks } from '@app/store/reducers/presets-slice';

import { getMetricTemplatesThunks } from '@app/store/reducers/metric-templates.slice';

import { MetricListArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { AllStatusProject, GroupSharedStatus, ProjectItem } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import { toast } from 'react-toastify';

import { getDictionariesThunks } from '@app/store/reducers/dictionaries-slice';

import { metricIconsData } from '@app/pages/analytics/metric/analytics-metric-tab/blocks/data/data';

import { Button, CircleButton, EasyModal, Modal, TooltipWrapper } from '@ui';

import { TableItem } from '@app/components/ui/table/table.type';

import AccordionItemUI from '../../../../components/ui/Accordion/AccordionItem';

import AccordionUI from '../../../../components/ui/Accordion/Aссordion';

import Icon from '../../../../components/ui/icons';

import RubberTable from '../../../../components/ui/rubber-table';

import { Modals } from './blocks/modals';

export type MetricPropsType = {
  editModal: boolean;
  setCreateMetric: React.Dispatch<React.SetStateAction<boolean>>;
  createMertic: boolean;
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
};
type PropsType = { project?: ProjectItem | null };
export const MetricSettings: React.FC<PropsType> = ({ project }) => {
  const { id } = useParams();
  const { t } = useTranslation('pages.allPagesHintTitles');
  const { t: ti } = useTranslation('pages.metricTemplates');
  const dispatch = useAppDispatch();
  const [activeModal, setActiveModal] = React.useState<string>('');
  const [metricId, setMetricId] = React.useState('');
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [recalculateModal, setRecalculateModal] = React.useState<boolean>(false);
  const [editModal, setEditModal] = React.useState(false);
  const [createMertic, setCreateMetric] = React.useState(false);
  const { metricTypes, metricList, allChanels } = useAppSelector((state) => state.metric);
  const { aliases } = useAppSelector((state) => state.presets);
  const { dictionaries } = useAppSelector((state) => state.dictionaris);

  const onClickCreateModal = () => {
    setEditModal(false);
    setCreateMetric(true);
  };

  React.useEffect(() => {
    dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
    dispatch(getDictionariesThunks.getAllDictionariesThunk());
    dispatch(getPresetsThunks.getAliasesThunk());
    dispatch(getMetricThunks.getAllTypesOfMetricThunk());
    if (id) dispatch(getMetricThunks.getMetricList(id));
    dispatch(getMetricThunks.getAllChannels());
  }, [dispatch, id]);

  const [activeAccordionItem, setActiveAccordionItem] = React.useState<string | undefined>(
    undefined,
  );
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    if (event.id === activeAccordionItem) {
      if (event.id === '') {
        setActiveAccordionItem(undefined);
      } else {
        setActiveAccordionItem('');
      }
    } else {
      setActiveAccordionItem(event.id);
    }
  };
  const circleButtonClick = (metric_id: string, type: string) => {
    if (!id) return;
    dispatch(
      getMetricThunks.getMetricSettings({
        project_id: id,
        metric_id: metric_id,
      }),
    );
    setEditModal(true);
    setActiveModal(type);
  };
  const deleteMetricClick = () => {
    if (id) {
      const params = {
        project_id: id,
        metric_id: metricId,
      };
      deleteMetric(params).finally(() => {
        dispatch(getMetricThunks.getMetricList(id));
        setDeleteModal(false);
      });
    }
  };
  const circleButtonTrashClick = (id: string) => {
    setMetricId(id);
    setTimeout(() => {
      setDeleteModal(true);
    }, 0);
  };
  const getChannelNameById = (id: string) => {
    if (id !== 'all') {
      return allChanels.find((item) => item.settings_channels_id === id)?.name;
    } else {
      return ti('all');
    }
  };
  const getAliasNameById = (id: string) => {
    if (id !== 'all') {
      return aliases.find((item) => item.alias_id === id)?.name;
    } else {
      return ti('all');
    }
  };
  const getTypeName = (type: string) => {
    return metricTypes.find((item) => item.type === type)?.name;
  };

  const getDictionaryNameById = (id: string) => {
    return dictionaries.find((e) => e.dictionary_id === id)?.name;
  };

  const getMetricSetting = (dataItem: MetricListArrType) => {
    if (dataItem.type === 'find-words') {
      if (
        dataItem.settings.wordlist?.length !== 0 ||
        dataItem.settings.dictionaries?.length !== 0
      ) {
        return (
          <div className="truncate max-h-[100px] flex flex-wrap items-center gap-[6px] border-solid border-[1px] border-[#DCE0E5] rounded-[10px] p-[6px] w-fit max-w-[400px]">
            {dataItem.settings.dictionaries?.map((e, index) => {
              return (
                <span
                  className="p-[2px_8px_3px] bg-[#FFE2F4] text-0color text-[13px] rounded-[4px]"
                  key={e + index}
                >
                  {getDictionaryNameById(e)}
                </span>
              );
            })}
            {dataItem.settings.wordlist?.map((e, index) => {
              return (
                <span
                  className="p-[2px_8px_3px] bg-basic_app_bg text-0color text-[13px] rounded-[4px]"
                  key={e + index}
                >
                  {e}
                </span>
              );
            })}
          </div>
        );
      } else return ti('none_settings');
    } else if (dataItem.type === 'meta') {
      return (
        <span>{dataItem.settings.alias_id && getAliasNameById(dataItem.settings.alias_id)}</span>
      );
    } else {
      return (
        <div className="max-w-[400px] h-full flex flex-wrap items-center gap-[12px] border-solid border-[1px] border-[#DCE0E5] rounded-[10px] p-[6px]">
          <span className="text-[10px] text-0color">
            {ti('first_secs') + ': ' + dataItem.settings.exclude_begin_sec}
          </span>
          <span className="text-[10px] text-0color">
            {ti('last_secs') + ': ' + dataItem.settings.exclude_end_sec}
          </span>
          {dataItem.settings.threshold || dataItem.settings.threshold === 0 ? (
            <span className="text-[10px] text-0color">
              {ti('pause_duration') + ': ' + dataItem.settings.threshold}
            </span>
          ) : null}
        </div>
      );
    }
  };

  const getLength = (group: string) => {
    const filtered = metricTypes?.filter((e) => e.group === group);
    const TypesValues = filtered.map((e) => e.type);
    return metricList?.filter(({ type }) => TypesValues.includes(type)).length;
  };
  const filteredArr = (group: string) => {
    const filtered = metricTypes?.filter((e) => e.group === group);
    const TypesValues = filtered.map((e) => e.type);
    return metricList
      ?.filter(({ type }) => TypesValues.includes(type))
      .map((dataItem) => ({
        ...dataItem,
        utils: (
          <div className="w-full gap-[10px] pl-[10px] flex items-start border-l-[1px] border-[#F1F2F3]">
            <CircleButton
              hintTitle={t('settings_icon')}
              onClick={() => {
                if (dataItem.metric_id) circleButtonClick(dataItem.metric_id, dataItem.type);
              }}
              icon="SettingIcon"
              size={19}
              className={`text-3color`}
            />

            <CircleButton
              disable={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
              hintTitle={t('delete_icon')}
              icon="TrashIcon"
              size={17.5}
              onClick={() => {
                if (dataItem.metric_id && !disableFunc())
                  circleButtonTrashClick(dataItem.metric_id);
              }}
              className={`mr-[18px] text-basic_red ${disableFunc() && 'text-4color'}`}
            />
          </div>
        ),
        channel: (
          <div>
            <div>
              {(dataItem.settings.channel && getChannelNameById(dataItem.settings.channel)) ||
                'N/A'}
            </div>
          </div>
        ),
        metricSettings: <div className="py-[5px]">{getMetricSetting(dataItem)}</div>,
        type: (
          <div className="w-full flex items-center gap-[10px]">
            <Icon
              size={28}
              name={metricIconsData[dataItem.type.replace(/([.\-\\/ ])/g, '_')] || 'HelpCircleIcon'}
            />
            {getTypeName(dataItem.type)}
          </div>
        ),
        name: (
          <TooltipWrapper content={dataItem.name} id={dataItem.name + dataItem.metric_id}>
            <div>{dataItem.name}</div>
          </TooltipWrapper>
        ),
      })) as unknown as Array<TableItem>;
  };
  const disableFunc = () => {
    if (!project?.owner && project?.shared === GroupSharedStatus.VIEW) return true;
    if (project?.status === AllStatusProject.PAUSE) {
      return false;
    }
    return project?.status !== AllStatusProject.CREATED;
  };

  const tables = [
    {
      name: ti('meta_metrics'),
      group: 'meta',
    },
    {
      name: ti('vad_metrics'),
      group: 'vad',
    },
    {
      name: ti('speech_metrics'),
      group: 'speech',
    },
    {
      name: ti('other_metrics'),
      group: 'other',
    },
  ];
  const columns = [
    {
      title: ti('table.name'),
      index: 'name',
    },
    {
      title: ti('table.type'),
      index: 'type',
    },
    {
      title: ti('table.channel'),
      index: 'channel',
      maxWidth: 130,
    },
    {
      title: ti('table.settings'),
      index: 'metricSettings',
    },
    {
      title: ti('table.actions'),
      index: 'utils',
      maxWidth: 110,
    },
  ];

  const getTableColumns = (group: string) => {
    if (group === 'meta') {
      return columns.filter((item) => item.index !== 'channel');
    }
    return columns;
  };
  const recalculateMetricsClick = () => {
    id &&
      recalculateMetrics(id)
        .then(() => {
          toast.success(ti('success'));
        })
        .catch(() => {
          toast.success(ti('error'));
        })
        .finally(() => {
          setRecalculateModal(false);
        });
  };

  return (
    <>
      <div className="flex justify-between items-center py-[25px]">
        <h2 className="text-[24px]">{ti('metric')}</h2>
        <div className="flex items-center gap-[10px]">
          <Button
            disabled={disableFunc()}
            icon="CirclePlusIcon"
            label={ti('add_metric')}
            onClick={() => onClickCreateModal()}
          />
          <TooltipWrapper id="recalculate_metric" content={t('recalculate_metric')}>
            <Button onClick={() => setRecalculateModal(true)} fill="outlined" icon="RefreshIcon" />
          </TooltipWrapper>
        </div>
      </div>
      <AccordionUI>
        {tables.map((table) => {
          return (
            <AccordionItemUI
              key={table.group}
              id={table.group}
              title={
                <div className="flex items-center">
                  {table.name}
                  <div className="flex justify-center items-center text-xs w-[20px] h-[20px] rounded-full ml-3 bg-defaultBgBtn text-white">
                    {getLength(table.group)}
                  </div>
                </div>
              }
              clickBtnChild={clickAccordionHeaderBtn}
              active={activeAccordionItem === table.group}
            >
              <RubberTable
                // truncate={true}
                columns={getTableColumns(table.group)}
                dataSource={filteredArr(table.group)}
              />
            </AccordionItemUI>
          );
        })}
      </AccordionUI>
      <Modal
        setValue={setDeleteModal}
        value={deleteModal}
        onApply={() => deleteMetricClick()}
        id={'deleteMetric'}
        size="xs"
        variant="removal"
        title={ti('warning')}
      >
        <div className="px-[50px]">{ti('metric_delete_confirm')}</div>
      </Modal>
      {metricTypes && allChanels && (
        <Modals
          status={project?.status}
          setEditModal={setEditModal}
          setActiveModal={setActiveModal}
          activeModal={activeModal}
          firstModal={createMertic}
          setFirstModal={setCreateMetric}
          editModal={editModal}
          metricTypes={metricTypes}
          allChanels={allChanels}
          viewOnly={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
        />
      )}
      <EasyModal
        onClose={() => setRecalculateModal(false)}
        withoutFooter
        variant="small"
        show={recalculateModal}
      >
        <div className="w-full p-4">
          <div className="flex flex-col items-center justify-center gap-[20px]">
            <div className="">
              <Icon
                size={30}
                className="text-defaultColor p-4 bg-defaultColor bg-opacity-10 rounded-full"
                name={'HelpCircleIcon'}
              />
            </div>
            <div className="block">
              <div className="text-[14px] mx-12 leading-normal text-1color font-medium">
                {ti('recalculate_modal')}
              </div>
            </div>
            <div>
              <Button onClick={() => recalculateMetricsClick()} label="Пересчитать" />
            </div>
          </div>
        </div>
      </EasyModal>
    </>
  );
};
