import React, { FC, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import connector, { PropsFromRedux } from '@app/utils/store';
import useTranslation from '@app/hooks/use-translation';
import { Loading } from '@ui';
import { NotFoundedPage } from '@app/pages';
import { ReportOutletContext } from '@app/pages/reports/report.outlet';

const ProjectReportsOutlet: FC<PropsFromRedux> = (props) => {
  const { reportsState, liveReportSettingsRequest, projectMetricListRequest, metricState } = props;
  const { liveReport, isPending } = reportsState;
  const { metricList } = metricState;
  const { t } = useTranslation('pages.reports');
  const { report_id } = useParams();
  const [chartModalIsOpen, changeIsOpenChartModal] = useState<boolean>(false);
  useEffect(() => {
    if (report_id) liveReportSettingsRequest({ id: report_id });
  }, [report_id, liveReportSettingsRequest]);

  useEffect(() => {
    liveReport &&
      projectMetricListRequest({
        project_id: liveReport.project.project_id,
      });
  }, [liveReport, projectMetricListRequest]);

  if (isPending) return <Loading />;
  if (!liveReport && !isPending)
    return <NotFoundedPage text={t('not_found')} icon="BrokenDocIcon" />;

  return (
    <div>
      <Outlet
        context={
          {
            projectMetrics: metricList,
            report: liveReport,
            chartModalIsOpen,
            changeIsOpenChartModal,
          } as unknown as ReportOutletContext
        }
      />
    </div>
  );
};

export default connector(ProjectReportsOutlet);
