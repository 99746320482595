import useTranslation from '@app/hooks/use-translation';

import connector, { PropsFromRedux } from '@app/utils/store';
import { Button, DropMenu, EasyModal, Empty, PageHeader, Popover, Select } from '@ui';
import Icon from '@app/components/ui/icons';
import React, { useEffect, useState } from 'react';
import CreateChartModal from '@app/pages/dashboards/blocks/create-chart-modal';
import EditText from '@app/components/ui/edit-text';
import { sharedTranslate } from '@app/pages/dashboards/data/data';
import {
  useDeleteDashboardMutation,
  useEditDashboardMutation,
  useGetDashboardsListQuery,
} from '@app/store/reducers/dashboard-page.api';
import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';
import useChartDateIntervals, { ReportTimeRange } from '@app/hooks/use-chart-date-intervals';
import { useGetDashboardGraphListQuery } from '@app/store/reducers/dashboard-charts.api';
import { dashboardChartsSettings, DashboardsSettingsType } from '@app/interfaces/dashboards.type';
import { ReportChart } from '@app/interfaces/report.type';
import SelectDashboardsList from '@app/pages/dashboards/blocks/SelectDashboardsList';
import { GridLayoutChartsContainer } from '@app/components/grid-layout-charts-container';
import ChartDatePickerModalBlock from '@app/pages/reports/blocks/chart-date-picker-modal.block';
import { useLazyGetGraphDataQuery } from '@app/store/reducers/graph-data.api';

import DashboardChartsSkeleton from '@app/pages/dashboards/blocks/dashboard-charts.skeleton';

type PropsType = {
  createNewDashboard: (data: { name: string; shared: boolean }) => void;
  changeDashboardId: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentDashboard: DashboardsSettingsType | undefined;
};

const Dashboard: React.FC<PropsType & PropsFromRedux> = (props) => {
  const { createNewDashboard, changeDashboardId, currentDashboard } = props;
  const { t } = useTranslation('pages.dashboard');
  const { t: ti } = useTranslation('pages.allPagesHintTitles');
  const { t: tModal } = useTranslation('components.easyModal');
  const { daysSelectOptions, dateIntervals, intervalTimeBuilder } = useChartDateIntervals();
  //api dashboard
  // const { currentData: currentDashboard, isFetching: currentDashboardLoading } =
  //   useGetDashboardQuery(dashboard_id || '', {
  //     skip: !dashboard_id,
  //   });
  const [editDashboardSettings] = useEditDashboardMutation();
  const { data: dashboardList } = useGetDashboardsListQuery();
  const [deleteDashboardMutation] = useDeleteDashboardMutation();
  //api dashboard

  //api dashboardGraphs
  const { data: chartsData, isLoading: chartsLoading } = useGetDashboardGraphListQuery(
    currentDashboard?.dashboard_id || '',
    { skip: !currentDashboard?.dashboard_id },
  );
  const [getGraphData] = useLazyGetGraphDataQuery();
  //api dashboardGraphs

  //charts state
  const [charts, changeChartsState] = useState<
    (dashboardChartsSettings & ReportChart)[] | undefined
  >(chartsData || []);
  useEffect(() => {
    changeChartsState(chartsData);
  }, [chartsData]);
  //charts state

  //state
  const customDateTrigger = 'customDate';
  const [isShowModelDatePicker, changeShowingModelDatePicker] = React.useState<boolean>(false);
  const [commonRange, setCommonRange] = React.useState<ReportTimeRange | null>(null);
  const [createModalState, changeCreateModalState] = useState<boolean>(false);
  const [editDashboardState, changeEditDashboardState] = useState<boolean>(false);
  const [sharedStatusValue, changeSharedStatusValue] = useState<boolean | undefined>();
  const [dashboardNameValue, changeDashboardNameValue] = useState<string | undefined>();
  const [dashboardIdForDelete, changeDashboardIdForDelete] = useState<string>();
  const [deleteModalState, changeDeleteModalState] = useState(false);
  //state

  //effects
  useEffect(() => {
    changeDashboardNameValue(currentDashboard?.name);
    changeSharedStatusValue(currentDashboard?.shared);
  }, [currentDashboard]);
  //effects

  //functions
  function handleChangeIntervalWithoutSave({ data, id }: { data: ReportChart; id: string }) {
    changeChartsState((prev) => {
      return prev?.map((item) => {
        if (item.dashboard_graph_id === id) {
          item = { ...item, categories: data.categories, series: data.series, range: data.range };
        }
        return item;
      });
    });
  }
  function deleteModalApplyBtn() {
    if (!dashboardList) return;
    const deletedIndex = dashboardList?.dashboard.findIndex(
      (e) => currentDashboard?.dashboard_id === e.dashboard_id,
    );
    dashboardIdForDelete && deleteDashboardMutation(dashboardIdForDelete);
    if (deletedIndex !== 0 && currentDashboard?.dashboard_id === dashboardIdForDelete) {
      localStorage.removeItem('DASHBOARD_ID');
      dashboardChange(dashboardList?.dashboard[deletedIndex - 1].dashboard_id);
    } else if (
      deletedIndex === 0 &&
      dashboardList?.dashboard.length > 1 &&
      currentDashboard?.dashboard_id === dashboardIdForDelete
    ) {
      localStorage.removeItem('DASHBOARD_ID');
      dashboardChange(dashboardList?.dashboard[deletedIndex + 1].dashboard_id);
    }
    changeDeleteModalState(false);
  }

  const dashboardChange = (id: string) => {
    // setSettings([]);
    localStorage.setItem('DASHBOARD_ID', id);
    changeDashboardId(id);
  };
  function editSharedStatusHandler(shared: boolean) {
    if (currentDashboard) {
      editDashboardSettings({
        id: currentDashboard.dashboard_id,
        body: { name: currentDashboard?.name, shared: shared },
      });
    }
    changeEditDashboardState(false);
  }
  function editDashboardNameHandler(name: string) {
    changeDashboardNameValue(name);
    if (currentDashboard) {
      editDashboardSettings({
        id: currentDashboard.dashboard_id,
        body: { name: name, shared: currentDashboard.shared },
      });
    }
  }
  function deleteDashboard(id: string) {
    changeDashboardIdForDelete(id);
    changeDeleteModalState(true);
  }
  function handlerDateDropTrigger(key: string | number) {
    if (!charts) return;
    if (key === customDateTrigger) {
      changeShowingModelDatePicker(true);
      return;
    } else {
      for (const chart of charts) {
        getGraphData({
          id: chart.graph_id,
          settings: {
            width: chart.width,
            range: dateIntervals[key],
          },
        }).then((Cdata) => {
          const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
          handleChangeIntervalWithoutSave({ data: chartData, id: chart.dashboard_graph_id });
        });
      }
    }
    setCommonRange(dateIntervals[key]);
  }
  function handlerChangeCustomRangeInterval({
    start,
    end,
  }: {
    start: string | null | Date;
    end: string | null | Date;
  }) {
    if (!charts) return;
    const range = intervalTimeBuilder(start, end) as unknown as ReportTimeRange;
    for (const chart of charts) {
      getGraphData({
        id: chart.graph_id,
        settings: {
          width: chart.width,
          range,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        handleChangeIntervalWithoutSave({ data: chartData, id: chart.dashboard_graph_id });
      });
    }
  }
  //functions

  //popover
  const popoverDashboardsMenu = [
    {
      content: (
        <SelectDashboardsList
          dashboardList={dashboardList}
          currentData={currentDashboard}
          deleteDashboard={deleteDashboard}
          dashboardChange={dashboardChange}
          createNewDashboard={createNewDashboard}
        />
      ),
      title: '123',
      key: 'key',
    },
  ];
  //popover
  //charts
  const renderCharts = charts?.length ? (
    <GridLayoutChartsContainer
      dashboard
      currentDashboardOrReportId={currentDashboard?.dashboard_id}
      commonRange={commonRange}
      onEditIntervalWithoutSave={handleChangeIntervalWithoutSave}
      charts={charts}
      isDraggable={editDashboardState}
    />
  ) : (
    <Empty title={t('dashboard_empty')} />
  );

  //charts
  return (
    <>
      <CreateChartModal
        createModalState={createModalState}
        setCreateModalState={changeCreateModalState}
      />
      <ChartDatePickerModalBlock
        onSuccess={handlerChangeCustomRangeInterval}
        show={isShowModelDatePicker}
        onClose={changeShowingModelDatePicker.bind(null, false)}
      />

      <PageHeader
        label={
          <div className="flex items-center gap-[18px]">
            {dashboardNameValue && (
              <EditText
                textStyle="!text-black !text-[24px]"
                text={dashboardNameValue}
                onEdited={editDashboardNameHandler}
                disableEdit={!currentDashboard?.owner}
              />
            )}
          </div>
        }
      >
        <div>
          {currentDashboard?.owner && (
            <div
              className={`flex items-center gap-[10px] font-[500] text-[14px] leading-[17px] text-1color ${
                !editDashboardState && 'opacity-[0.65]'
              }`}
            >
              {editDashboardState ? (
                <>
                  <Select
                    size={254}
                    defaultValue={String(sharedStatusValue)}
                    dropWidth={250}
                    options={Object.entries(sharedTranslate).map((item) => ({
                      value: item[0],
                      title: item[1],
                    }))}
                    onChange={(item) => changeSharedStatusValue(item === 'true')}
                  />
                  <div
                    onClick={() => editSharedStatusHandler(sharedStatusValue as boolean)}
                    className="p-[8.5px_11.5px] bg-white rounded-[10px]"
                  >
                    <Icon size={11} name="CheckIcon" className="cursor-pointer text-basic_green" />
                  </div>
                  <div
                    onClick={() => {
                      changeEditDashboardState(false);
                      changeSharedStatusValue(currentDashboard?.shared);
                    }}
                    className="p-[8.5px_11.5px] bg-white rounded-[10px]"
                  >
                    <Icon
                      size={11}
                      className="cursor-pointer text-basic_red opacity-1"
                      name="XIcon"
                    />
                  </div>
                </>
              ) : (
                <>
                  <span>{sharedTranslate[String(sharedStatusValue)]}</span>
                  <Icon
                    onClick={() => changeEditDashboardState(true)}
                    name="SettingIcon"
                    className="cursor-pointer"
                  />
                </>
              )}
            </div>
          )}
        </div>
      </PageHeader>

      <div className="flex items-center gap-[16px] justify-end py-[24px] border-[#E5E8EB] border-t-[1px]">
        <Button
          disabled={!currentDashboard?.owner}
          onClick={() => changeCreateModalState(true)}
          icon="CirclePlusIcon"
          label={t('add_chart')}
        />
        <Popover
          hintTitle={ti('dashboard.dashboard_list')}
          size={240}
          icon="ColumnDisplayIcon"
          position="end"
          menu={popoverDashboardsMenu}
        />
        <DropMenu
          dropSize={140}
          hintTitle={ti('dashboard.dashboard_intervals')}
          onClick={(key) => handlerDateDropTrigger(key)}
          menu={daysSelectOptions as unknown as Array<DropMenuItem>}
        >
          <Button icon="CalendarEmptyIcon" />
        </DropMenu>
      </div>
      {currentDashboard?.dashboard_id ? (
        chartsLoading ? (
          <DashboardChartsSkeleton />
        ) : (
          <div className="w-full">{renderCharts}</div>
        )
      ) : (
        <div className="flex items-center justify-center h-[calc(100%-300px)]">
          {t('dashboard_not_selected')}
        </div>
      )}
      <EasyModal
        variant={'removal'}
        show={deleteModalState}
        onClose={changeDeleteModalState.bind(null, false)}
        label={tModal('warning')}
        onRemove={() => deleteModalApplyBtn()}
        withoutFooter
      >
        {t('dashboard_delete_modal')}
      </EasyModal>
    </>
  );
};

export default connector(Dashboard);
