import { GetFilterParams } from '@app/interfaces/filter';

import apiInstance from '../common.api';

export const getSettingsAnalyticsPreset = (params: { project_id: string }) => {
  return apiInstance.get('/api/front/project/preset', { params });
};

export const saveSettingsAnalyticsPreset = (
  params: { project_id: string } & { rules: GetFilterParams['filter'] },
) => {
  return apiInstance.put('/api/front/project/preset', params.rules, {
    params: {
      project_id: params.project_id,
    },
  });
};
