const forms = require('./forms');
const humanize = require('./humanize');
const breadcrumbs = require('./breadcrumbs');
const sharedStatus = require('./sharedStatus');
const pageTitle = require('./pageTitle');

module.exports = {
  forms,
  humanize,
  breadcrumbs,
  sharedStatus,
  pageTitle,
};
