import React, { FC, useEffect, useState } from 'react';

import useGetUniversalFilter from '@app/hooks/use-universal-filter-values/use-get-universal-filter';

import { Loading } from '@app/components/ui';

import LastProjectRecordsPage from './last-project-records.page';

export const LastProjectRecordContainer: FC = () => {
  const [loading, setLoading] = useState(true);
  const getFilter = useGetUniversalFilter();
  useEffect(() => {
    getFilter();
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, [getFilter]);
  if (loading) return <Loading />;
  else return <LastProjectRecordsPage />;
};
