import useTranslation from '@app/hooks/use-translation';

export type ChartOptionDate =
  | 'today'
  | 'currentWeek'
  | 'currentMonth'
  | 'day'
  | 'week'
  | 'month'
  | 'customDate'
  | 'all';

export type ReportTimeRange = {
  type: 'l' | 'r' | 'n' | 'cd' | 'cw' | 'cm';
  parameters?: [{ num: number }];
};

/***
 * useChartDateIntervals
 * @return dateIntervals, daysSelectOptions
 */

const useChartDateIntervals = () => {
  const { t } = useTranslation('pages.chartPage');
  const dateKeys: Record<ChartOptionDate, string> = {
    day: 'day',
    week: 'week',
    month: 'month',
    today: 'today',
    currentWeek: 'currentWeek',
    currentMonth: 'currentMonth',
    all: 'all',
    customDate: 'customDate',
  };

  const daysSelectOptions: Array<{ title: string; key: string; value: string }> = [
    { title: t('select.day'), key: dateKeys.day, value: dateKeys.day },
    { title: t('select.weekdays'), key: dateKeys.week, value: dateKeys.week },
    { title: t('select.monthDays'), key: dateKeys.month, value: dateKeys.month },
    { title: t('select.today'), key: dateKeys.today, value: dateKeys.today },
    { title: t('select.week'), key: dateKeys.currentWeek, value: dateKeys.currentWeek },
    { title: t('select.month'), key: dateKeys.currentMonth, value: dateKeys.currentMonth },
    { title: t('select.all_data'), key: dateKeys.all, value: dateKeys.all },
    { title: t('select.custom_date'), key: dateKeys.customDate, value: dateKeys.customDate },
  ];

  const intervalTimeBuilder = (fistDate: string | Date | null, lastDate: string | Date | null) => ({
    type: 'r',
    parameters: [
      {
        value: fistDate,
        condition: '>',
      },
      {
        value: lastDate,
        condition: '<',
      },
    ],
  });

  const dateIntervals: Record<Exclude<'customDate', ChartOptionDate>, ReportTimeRange> = {
    today: { type: 'cd', parameters: [] },
    currentWeek: { type: 'cw', parameters: [] },
    currentMonth: { type: 'cm', parameters: [] },
    day: { type: 'l', parameters: [{ num: 1 }] },
    week: { type: 'l', parameters: [{ num: 7 }] },
    month: { type: 'l', parameters: [{ num: 30 }] },
    all: { type: 'n', parameters: [] },
  };

  const dateToString = (chartOption?: ReportTimeRange) => {
    let currentInterval = dateKeys.customDate;
    if (chartOption?.type === dateKeys.customDate) return dateKeys.customDate;
    if (!chartOption?.type) return 'week';
    Object.keys(dateIntervals).find((key) => {
      if (
        chartOption.type === dateIntervals[key]?.type &&
        chartOption?.parameters?.[0]?.num === dateIntervals[key].parameters[0]?.num
      ) {
        currentInterval = key;
      }
    });
    return currentInterval;
  };

  return {
    dateKeys,
    dateIntervals,
    daysSelectOptions,
    dateToString,
    intervalTimeBuilder,
  };
};

export default useChartDateIntervals;
