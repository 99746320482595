module.exports = {
  titles: {
    dashboard_list: 'Открыть список рабочих столов',
    dashboard_intervals: 'Задать временной период для всех диаграмм',
  },
  add_dashboard: 'Добавить дашборд',
  dashboard_not_selected: 'Дашборд не выбран',
  add_chart: 'Добавить график',
  dashboard_create_name: 'Дашборд %{name}',
  add_chart_from_report: 'Добавить график из отчета',
  dashboard_delete_modal: 'Вы действительно хотите удалить данный дашборд?',
  dashboard_empty: 'На дашборде нет графиков',
  chart_size: 'Размер графика',
  half: 'Половина',
  full: 'Полный',
  choose_period: 'Выбор периода по умолчанию',
  save: 'Сохранить',
  add: 'Добавить',
};
