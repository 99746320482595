import React, { FC, ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';

import { EasyModal, Loading } from '@ui';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import connector, { PropsFromRedux } from '@app/utils/store';
import { CompositeMetricData } from '@app/interfaces/metric.type';
import useTranslation from '@app/hooks/use-translation';

import { AllStatusProject } from '@app/interfaces/analytics';

import BlocklyBlock from './blockly.block';

const CompositeMetricBlock: FC<
  { show: boolean; onClose: () => void; label: ReactNode; editModal: boolean } & PropsFromRedux
> = (props) => {
  const {
    projectMetricListRequest,
    createMetricRequest,
    metricState,
    show,
    label,
    onClose,
    editModal,
    editMetricRequest,
    metricSettingsReset,
  } = props;
  const { metricSettings, metricList, isPending } = metricState;
  const { t } = useTranslation('pages.metric');
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const [calculatedResult, changeCalculatedResult] = useState<CompositeMetricData>({
    formula: { root: undefined },
    metricType: metricSettings?.result_value_type || 'num',
    name: metricSettings?.name || `${project?.name || ''} calculated metric`,
    visualization: metricSettings?.visualization || 'native',
  });

  const DEFAULT_CALCULATED_RESULT: CompositeMetricData = {
    formula: { root: undefined },
    metricType: 'num',
    name: `${project?.name || ''} calculated metric`,
    visualization: 'native',
  };

  const DEFAULT_CALCULATED_METRIC_DATA = {
    name: metricSettings?.name || `${project?.name || ''} calculated metric`,
    description: metricSettings?.name || `${project?.name || ''} calculated metric`,
    type: 'calculated',
    result_value_type: metricSettings?.result_value_type || 'num',
    settings: {
      formula: {
        root: {
          element: calculatedResult?.formula.root,
        },
      },
    },
  };

  useEffect(() => {
    metricSettings &&
      changeCalculatedResult({
        formula: { root: metricSettings?.settings.formula || undefined },
        metricType: metricSettings?.result_value_type || 'num',
        visualization: metricSettings?.visualization || 'native',
        name: metricSettings?.name || `${project?.name || ''} calculated metric`,
      });
  }, [metricSettings, project]);

  function resetCachedInputFromScreen() {
    document?.querySelector('.blocklyHtmlInput') &&
      document?.querySelector('.blocklyHtmlInput')?.remove();
  }

  async function onCloseHandler() {
    await changeCalculatedResult(DEFAULT_CALCULATED_RESULT);
    await metricSettingsReset();
    resetCachedInputFromScreen();
    onClose?.();
  }
  useEffect(() => {
    project?.project_id && projectMetricListRequest({ project_id: project.project_id });
  }, [project, projectMetricListRequest]);

  function metricCalculateDefaultSettings() {
    return {
      metric_settings: {
        ...DEFAULT_CALCULATED_METRIC_DATA,
        name:
          calculatedResult.name ||
          metricSettings?.result_value_type ||
          `${project?.name || ''} calculated metric`,
        order: calculatedResult.visualization === 'native' ? 1000 : 100,
        result_value_type:
          calculatedResult.metricType || metricSettings?.result_value_type || 'num',
        visualization: calculatedResult.visualization || 'native',
        settings: { formula: { root: { element: calculatedResult.formula.root } } },
      },
    };
  }
  function errorFormulaResponse(calculatedData, status: 'new' | 'edit') {
    changeCalculatedResult((previous) => ({
      ...previous,
      metric_settings: calculatedData.metric_settings,
    }));
    toast.success(
      t(status === 'new' ? 'composite_fail_create_metric' : 'composite_fail_edit_metric'),
    );
  }

  function onSubmitMetricFormula() {
    const calculatedData = metricCalculateDefaultSettings();
    if (project?.project_id) {
      if (editModal && metricSettings?.metric_id) {
        editMetricRequest({
          params: { metric_id: metricSettings?.metric_id, project_id: project.project_id },
          data: calculatedData.metric_settings,
        })
          .then(() => {
            toast.success(t('composite_success_edit_metric'));
            projectMetricListRequest({ project_id: project.project_id });
            onClose();
          })
          .catch(() => {
            errorFormulaResponse(calculatedData, 'edit');
          });
      } else {
        createMetricRequest({
          params: { project_id: project?.project_id },
          data: { ...calculatedData },
        })
          .then(() => {
            toast.success(t('success_create_metric'));
            resetCachedInputFromScreen();
            onClose();
          })
          .catch(() => {
            errorFormulaResponse(calculatedData, 'new');
          });
      }
    }
  }
  const intermediateStatusesDisable = (project) => {
    if (project?.status === AllStatusProject.FILLING) return true;
    if (project?.status === AllStatusProject.STARTING) return true;
    if (project?.status === AllStatusProject.STOPPING) return true;
    return project?.status === AllStatusProject.ACTIVE;
  };

  if ((isPending || !metricSettings) && editModal) return <Loading />;

  return (
    <EasyModal
      variant="largeW1127"
      show={show}
      onClose={onCloseHandler}
      label={label}
      onSuccess={onSubmitMetricFormula}
      customLayer={40}
      isAutoClosable={false}
      isNotContentClosable
      disabled={intermediateStatusesDisable(project)}
    >
      {(metricSettings || !editModal) && (
        <BlocklyBlock
          previousState={calculatedResult}
          onChange={changeCalculatedResult}
          height="300"
          isEditModal={editModal}
          minWidth="1000"
          metric={metricList}
          metricSettings={
            editModal ? metricSettings : metricCalculateDefaultSettings().metric_settings
          }
        />
      )}
    </EasyModal>
  );
};

export default connector(CompositeMetricBlock);
