import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { HelpCircleIcon, LogOutIcon } from '@icons';
import Avatar from '@app/components/ui/avatar';
import { AuthUserData } from '@app/interfaces/auth';
import { useAuthContext } from '@app/utils/auth-provider';
import { useGetUserInfoQuery } from '@app/store/reducers/get-user-info.api';
import { TooltipWrapper } from '@app/components/ui';

type AuthMenuProps = {
  auth: AuthUserData;
};

const AuthMenuBlock: FC<AuthMenuProps> = (props) => {
  const { auth } = props;
  const { logout } = useAuthContext();
  const { avatar } = auth;
  const { data } = useGetUserInfoQuery();
  function handlerLogout() {
    logout();
  }

  return (
    <ul className="inline-flex divide-x">
      <li className="px-[22px] items-center inline-flex">
        <NavLink
          target={'_blank'}
          replace
          to="https://ansp.cloudpbx.rt.ru/support/Speech_analytics_manual_2_0.pdf"
          className="inline-flex hover:opacity-80 "
        >
          <HelpCircleIcon size={20} className="mr-[4px]" /> Помощь
        </NavLink>
      </li>
      <li className="px-[22px] inline-flex items-center">
        <Avatar
          url={avatar}
          alt={data?.userinfo.name}
          size={30}
          classname="mr-[8px] text-speech-analitics"
        />
        <div className="block max-w-[150px] overflow-hidden truncate">
          <NavLink to="/" className="mr-[8px] text-sm flex self-center">
            <TooltipWrapper truncate content={data?.userinfo?.name} id={'username'}>
              {data?.userinfo.name}
            </TooltipWrapper>
          </NavLink>
        </div>
        <NavLink to="/authorization" className="hover:opacity-80" onClick={handlerLogout}>
          <LogOutIcon size={16} />
        </NavLink>
      </li>
    </ul>
  );
};

export default AuthMenuBlock;
