import {
  getSettingsAnalyticsPreset,
  saveSettingsAnalyticsPreset,
} from '@app/api/analytics-api/analytics-presets.api';
import { FilterRange, Filter, GetFilterParams } from '@app/interfaces/filter';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const getAnalyticPresetThunk = {
  getPreset: createAsyncThunk<{ preset: (FilterRange & Filter) | null }, { project_id: string }>(
    'analyticPresetSlice/getPreset',
    async (params, { rejectWithValue }) => {
      return getSettingsAnalyticsPreset(params)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  savePreset: createAsyncThunk<void, { project_id: string; rules: GetFilterParams['filter'] }>(
    'analyticPresetSlice/savePreset',
    async (params, { rejectWithValue }) => {
      return saveSettingsAnalyticsPreset(params)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
};

const analyticPresetSlice = createSlice({
  name: 'analyticPresetSlice',
  initialState,
  reducers: {},
});

// export const {  } = analyticPresetSlice.actions;

export default analyticPresetSlice.reducer;
