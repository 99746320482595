import { Filter, FilterRange } from '@app/interfaces/filter';

export function getEmptyFilter(): Filter & FilterRange {
  return {
    range: {
      type: 'n',
      parameters: [],
    },
    filter: [],
  };
}
