import React from 'react';
import { useParams } from 'react-router-dom';

import { createMetric, editMetric } from '@app/api/analytics-api/analytics-metric.api';
import { Button, Input, Loading, Select } from '@ui';
import {
  AllChanelsArrType,
  MetricObjType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { getMetricThunks } from '@app/store/reducers/metric-slice';
import { useAppDispatch, useAppSelector } from '@app/store/store';

import { AutoFocusHook } from '@app/utils/helpers';

import { createMetricTemplate, editMetricTemplate } from '@app/api/metric-templates.api';

import { getMetricTemplatesThunks } from '@app/store/reducers/metric-templates.slice';

import { AllStatusProject } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import { Controller, useForm } from 'react-hook-form';

import { toast } from 'react-toastify';

import { VadModalsChildrenType } from '../constantsForModals';
import { AddToTemplateModal } from '../add-to-template-modal/add-to-template-modal';
type FormType = {
  unit: string;
  threshold: number;
  name: string;
  channel: string;
  exclude_begin_sec: number;
  exclude_end_sec: number;
  select: string;
};
export const VadModals: React.FC<{
  unitVal: string;
  copyModal?: boolean;
  modalsSetState: (type: string) => void;
  dataFields: VadModalsChildrenType[];
  metric: MetricTypesArrType;
  allChanels?: AllChanelsArrType[];
  editModal?: boolean;
  metricTemplate?: boolean;
  createFromTemplate?: boolean;
  status?: AllStatusProject | undefined;
  page?: string;
  viewOnly?: boolean;
}> = ({
  dataFields,
  page,
  metric,
  allChanels,
  editModal,
  modalsSetState,
  metricTemplate = false,
  createFromTemplate,
  status,
  copyModal,
  viewOnly,
}) => {
  const { id } = useParams();
  const { metricSettings, isPending } = useAppSelector((state) => state.metric);
  const { metricTemplates, metricTemplateSettings } = useAppSelector(
    (state) => state.metricTemplates,
  );
  const { t: tf } = useTranslation('common.forms');

  const dispatch = useAppDispatch();
  const [createTemplateModal, setCreateTemplateModal] = React.useState(false);
  const defaultValue = (): FormType => {
    if (editModal) {
      if (metricTemplate) {
        return {
          unit: metricTemplateSettings?.settings.unit || '',
          threshold: metricTemplateSettings?.settings.threshold || 0,
          name: metricTemplateSettings?.name || '',
          channel: metricTemplateSettings?.settings.channel || '',
          exclude_begin_sec: metricTemplateSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricTemplateSettings?.settings.exclude_end_sec || 0,
          select: metricTemplateSettings?.metric_templates_group_id || '',
        };
      } else {
        return {
          unit: metricSettings?.settings.unit || '',
          threshold: metricSettings?.settings.threshold || 0,
          name: metricSettings?.name || '',
          channel: metricSettings?.settings.channel || '',
          exclude_begin_sec: metricSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricSettings?.settings.exclude_end_sec || 0,
          select: '',
        };
      }
    } else {
      if (createFromTemplate) {
        return {
          unit: metricTemplateSettings?.settings.unit || '',
          threshold: metricTemplateSettings?.settings.threshold || 0,
          name: metricTemplateSettings?.name || '',
          channel: metricTemplateSettings?.settings.channel || '',
          exclude_begin_sec: metricTemplateSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricTemplateSettings?.settings.exclude_end_sec || 0,
          select: metricTemplateSettings?.metric_templates_group_id || '',
        };
      } else
        return {
          unit: '',
          threshold: 0,
          name: '',
          channel: '',
          exclude_begin_sec: 0,
          exclude_end_sec: 0,
          select: 'default',
        };
    }
  };
  const { t } = useTranslation('pages.metricTemplates');
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    control,
    clearErrors,
  } = useForm<FormType>({
    defaultValues: defaultValue(),
  });
  const disableFunc = () => {
    if (status === AllStatusProject.PAUSE) {
      return false;
    }
    return status !== AllStatusProject.CREATED;
  };

  const resetAsyncForm = () => {
    modalsSetState(metric.type);
  };
  const onSubmit = (data) => {
    const obj: MetricObjType = {
      name: data.name,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        channel: data.channel,
        unit: data.unit,
        threshold: data.threshold,
        exclude_begin_sec: data.exclude_begin_sec,
        exclude_end_sec: data.exclude_end_sec,
      },
    };
    if (!editModal) {
      if (metricTemplate) {
        createMetricTemplate(watch('select'), obj).finally(() => {
          dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
        });
      } else {
        id &&
          createMetric(id, obj).finally(() => {
            dispatch(getMetricThunks.getMetricList(id));
          });
      }
    } else {
      if (metricSettings?.metric_id) {
        id &&
          editMetric(id, metricSettings.metric_id, obj).finally(() => {
            dispatch(getMetricThunks.getMetricList(id));
          });
      } else if (metricTemplate) {
        if (copyModal) {
          createMetricTemplate(watch('select'), obj).finally(() => {
            dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
          });
        } else {
          editMetricTemplate(
            metricTemplateSettings?.metric_template_id as string,
            watch('select'),
            obj,
          ).finally(() => {
            dispatch(getMetricTemplatesThunks.getAllMetricTemplatesThunk());
          });
        }
      }
    }
    resetAsyncForm();
  };
  const getDefaultTemplate = () => {
    return metricTemplates.find((e) => e.is_default === true)?.metric_templates_group_id;
  };
  const createMetricTemplateApplyBtn = () => {
    const obj: MetricObjType = {
      name: watch('name'),
      description: '',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        channel: watch('channel'),
        unit: watch('unit'),
        threshold: watch('threshold'),
        exclude_begin_sec: watch('exclude_begin_sec'),
        exclude_end_sec: watch('exclude_end_sec'),
      },
    };
    createMetricTemplate(getDefaultTemplate() as string, obj)
      .then(() => {
        resetAsyncForm();
        setCreateTemplateModal(false);
      })
      .catch(() => {
        toast.error(t('fill_all'));
      });
  };

  const inputFocusRef = AutoFocusHook();

  const getSelectItems = () => {
    return metricTemplates.map((e) => {
      return { title: e.name, value: e.metric_templates_group_id };
    });
  };
  if (isPending) return <Loading />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-[550px]">
        <div className="flex flex-col gap-[20px]">
          <div className="flex items-center justify-between">
            <span className="max-w-[218px] text-0color text-[15px]">{t('metric_group')}</span>
            <span className="mr-[177px]">
              <b>
                {metric.group.toUpperCase()} {t('one_metric')}
              </b>
            </span>
          </div>
          <div>
            {dataFields.map((e) => {
              return e.inputText.map((e) => {
                return (
                  <div key={e.id} className="flex items-center justify-between">
                    <span className="text-0color text-[15px]">{e.labelTitle}</span>
                    <div className="w-[280px]">
                      <Input
                        {...inputFocusRef}
                        {...register('name', {
                          required: { value: true, message: tf('required') },
                        })}
                        name="name"
                        placeholder={e.placeholder}
                        error={errors.name?.message}
                      />
                    </div>
                  </div>
                );
              });
            })}
          </div>
          {metricTemplate && (
            <div className="flex items-center justify-between">
              <span className="text-0color text-[15px]">{t('metric_templates_group')}</span>
              <div className="w-[280px]">
                <Controller
                  render={() => (
                    <Select
                      error={errors.select?.message}
                      defaultValue={watch('select')}
                      options={[
                        ...getSelectItems(),
                        {
                          title: t('choose') + '...',
                          value: 'default',
                          disabled: true,
                          nullable: true,
                        },
                      ]}
                      onChange={(value) => {
                        setValue('select', value as string);
                        clearErrors('select');
                      }}
                    />
                  )}
                  name={'select'}
                  rules={{ validate: (value) => value !== 'default' || tf('required') }}
                  control={control}
                />
              </div>
            </div>
          )}
          <div className="flex items-center justify-between mr-[140px] relative">
            <span className="max-w-[218px] text-0color text-[15px]">{t('channel_search')}</span>
            <Controller
              render={() => (
                <div className="flex flex-col min-w-[130px]">
                  <div className="flex items-center gap-[10px]">
                    <input
                      name="channel"
                      onChange={(e) => {
                        setValue('channel', e.target.value);
                        clearErrors('channel');
                      }}
                      type="checkbox"
                      value={'all'}
                      checked={watch('channel') === 'all'}
                      className="focus:ring-offset-0 focus:ring-0 rounded-full"
                    />
                    <label className="text-[14px] text-0color">{t('all')}</label>
                  </div>
                  {allChanels?.map((chanel) => {
                    return (
                      <div
                        className="flex items-center gap-[10px]"
                        key={chanel.settings_channels_id}
                      >
                        <input
                          name="channel"
                          onChange={(e) => {
                            setValue('channel', e.target.value);
                            clearErrors('channel');
                          }}
                          type="checkbox"
                          checked={watch('channel') === chanel.settings_channels_id}
                          value={chanel.settings_channels_id}
                          className="focus:ring-offset-0 focus:ring-0 rounded-full"
                        />
                        <label className="text-[14px] text-0color">{chanel.name}</label>
                      </div>
                    );
                  })}
                </div>
              )}
              name={'channel'}
              control={control}
              rules={{ validate: (value) => !!value || tf('required') }}
            />
            {errors.channel?.message && (
              <div
                className={
                  'absolute bottom-[-17px] right-[20px] text-sm text-red-600 dark:text-red-500'
                }
              >
                {errors.channel?.message}
              </div>
            )}
          </div>
          {(metric.type === 'summ-speech-duration' || metric.type === 'summ-silent-duration') &&
            dataFields.map((e) => {
              return e.radioButtons.map((e, index) => {
                return (
                  <div
                    key={index}
                    className="flex relative items-center justify-between mr-[140px]"
                  >
                    <span className="max-w-[218px] text-[14px] text-0color">
                      {metric.type === 'summ-silent-duration' ? t('summ_silent') : e.labelTitle}
                    </span>
                    <div className="flex flex-col min-w-[130px]">
                      {e.radioBut?.map((e, index) => {
                        return (
                          <div className="flex items-center gap-[10px]" key={index}>
                            <Controller
                              render={() => (
                                <input
                                  name="unit"
                                  checked={watch('unit') === e.value}
                                  onChange={(e) => {
                                    setValue('unit', e.target.value);
                                    clearErrors('unit');
                                  }}
                                  type="checkbox"
                                  value={e.value}
                                  className="focus:ring-offset-0 focus:ring-0 rounded-full"
                                />
                              )}
                              control={control}
                              name={'unit'}
                              rules={{ validate: (value) => !!value || tf('required') }}
                            />
                            <label className="text-[14px] text-0color">{e.label}</label>
                          </div>
                        );
                      })}
                    </div>
                    {errors.unit?.message && (
                      <div
                        className={
                          'absolute bottom-[-17px] right-[20px] text-sm text-red-600 dark:text-red-500'
                        }
                      >
                        {errors.unit?.message}
                      </div>
                    )}
                  </div>
                );
              });
            })}
          {metric.type === 'pause-exceed' && (
            <div className="flex items-center justify-between">
              <span className="text-0color max-w-[222px] text-[15px]">{t('threshold')}</span>
              <div className="w-[91px] mr-[190px]">
                <Input
                  name="threshold"
                  defaultValue={watch('threshold') || '0'}
                  onChange={(e) => setValue('threshold', Number(e.target.value))}
                  type={'number'}
                  icon={'ClockIcon'}
                />
              </div>
            </div>
          )}
          {dataFields.map((e) => {
            return e.inputNumber.map((e, index) => {
              return (
                <div key={e.id} className="flex items-center justify-between">
                  <span className="text-0color max-w-[200px] text-[15px]">{e.labelTitle}</span>
                  <div className="w-[91px] mr-[190px]">
                    <Input
                      {...register(e.id as 'exclude_begin_sec' | 'exclude_end_sec')}
                      max={1000000}
                      min={0}
                      name={e.id}
                      key={index}
                      type={'number'}
                      icon="ClockIcon"
                    />
                  </div>
                </div>
              );
            });
          })}
          <div className="sticky bottom-0">
            <div className="bg-[#fff]">
              <div className="flex items-center justify-between py-4">
                <div className="gap-[15px] flex">
                  <Button
                    disabled={(page !== 'metricTemplate' && disableFunc()) || viewOnly}
                    type="submit"
                    label={t('save')}
                  />
                  <Button onClick={() => resetAsyncForm()} fill="linked" label={t('cancel')} />
                </div>
                <div>
                  {page !== 'metricTemplate' && !createFromTemplate && (
                    <Button
                      onClick={() => setCreateTemplateModal(true)}
                      fill="linked"
                      icon="FolderConfigIcon"
                      label={t('save_as_template')}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <AddToTemplateModal
        show={createTemplateModal}
        name={watch('name')}
        applyBtn={createMetricTemplateApplyBtn}
        onClose={setCreateTemplateModal}
      />
    </>
  );
};
