import {
  CHANGE_GROUP,
  CHANGE_GROUP_PROJECTS,
  SET_GROUP_PROJECTS,
  SET_GROUPS,
} from '@app/store/reducers/types';

export type AnalyticsReducerActionType =
  | typeof SET_GROUPS
  | typeof SET_GROUP_PROJECTS
  | typeof CHANGE_GROUP_PROJECTS
  | typeof CHANGE_GROUP;

export interface AnalyticsReducerState {
  groups: GroupsListObject['projects_groups'];
  group_projects: Projects['projects'];
  asrModalsExt: any[];
  projectsList: ProjectItem[];
  asrModalsList: any[];
  project: ProjectItem | null;
  loading: boolean;
  allProjects: Array<ProjectItem>;
  error: string | null;
}

export interface GroupsStatuses {
  projects_groups: (Pick<GroupsListObjectItem, 'project_group_id'> & {
    projects: Pick<ProjectItem, 'project_id' | 'status'>[];
  })[];
}

export interface GroupsListObject {
  projects_groups: GroupsListObjectItem[];
}

export type GroupsListObjectItem = {
  project_group_id: string;
  name: string;
  is_default?: boolean;
} & Projects;

export interface Projects {
  projects: ProjectItem[];
}

export interface ProjectItem {
  project_id: string;
  project_group_id: string;
  name: string;
  description: string;
  owner: boolean;
  shared: GroupSharedStatus;
  asr_model_id: string;
  status: AllStatusProject;
}

export enum GroupProjectsStatus {
  ACTIVE = 'ACTIVE',
  REMOVING = 'REMOVING',
  CREATED = 'CREATED',
}
export enum GroupSharedStatus {
  PRIVATE = 'PRIVATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
}

export enum actionsIds {
  ACTIONS_MOVE_TO_GROUP = 'actions_move_to_group',
  ACTIONS_DELETE_SELECTED = 'actions_delete_selected',
}

export interface ProjectAnalyticsParams {
  id?: string; // backend
  name: string;
  description: string;
  project_group_id: string;
  project_id?: string;
  asr_model_id: string;
  status?: AllStatusProject;
}
export interface CreateProjectAnalyticsParams {
  id?: string;
  name: string;
  project_group_id: string;
  description: string;
  asr_model_id: string;
  shared: GroupSharedStatus;
}
export enum AllStatusProject {
  CREATED = 'CREATED',
  FILLING = 'FILLING',
  ACTIVE = 'ACTIVE',
  STOPPING = 'STOPPING',
  PAUSE = 'PAUSE',
  ACTUALIZATION = 'ACTUALIZATION',
  STARTING = 'STARTING',
  REMOVING = 'REMOVING',
  ARCHIVE = 'ARCHIVE',
}
export interface EditStatusProjectAnalyticsParams {
  id: string;
  status: AllStatusProject;
}

export interface GroupAnalyticsParams {
  id: string;
}
