import React, { FC } from 'react';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import cn from 'classnames';
import Icon from '@app/components/ui/icons';
import { useNavigate } from 'react-router-dom';

import { v4 } from 'uuid';

import TooltipWrapper from '../tooltip-wrapper';

type CircleButtonProps = {
  icon: IconNameType;
  link?: string;
  size?: number;
  onClick?: () => void;
  className?: string;
  hintTitle?: string;
  hintPlace?: 'top' | 'bottom' | 'left' | 'right' | undefined;
  disable?: boolean;
  stopPropagation?: boolean;
  stateLink?: string;
};

const CircleButton: FC<CircleButtonProps> = (props) => {
  const {
    icon,
    size = 13,
    link,
    onClick,
    className,
    stopPropagation = false,
    hintTitle,
    hintPlace = 'bottom',
    disable = false,
    stateLink,
  } = props;
  const navigate = useNavigate();
  function handleOnCLickIcon(e) {
    if (!disable) {
      stopPropagation && e.stopPropagation();
      link && navigate(link, { state: stateLink });
      onClick?.();
    }
  }
  const circleClassName = cn(
    'rounded-full',
    'inline-flex',
    'bg-transparent',
    'active:scale-[90%]',
    'cursor-pointer',
    'items-center',
    'justify-center',
    'group',
    'hover:opacity-80',
  );
  const buttonSizeStyle = { width: `${size + 10}px`, height: `${size + 10}px` };
  return (
    <div className={circleClassName} onClick={handleOnCLickIcon} style={buttonSizeStyle}>
      <TooltipWrapper
        className={'flex items-center justify-center'}
        place={hintPlace}
        content={hintTitle}
        id={hintTitle + v4()}
      >
        <Icon
          name={icon}
          className={disable ? className + ' !text-4color' : className}
          size={size}
        />
      </TooltipWrapper>
    </div>
  );
};

export default CircleButton;
