import serverRoutes from '@app/utils/server-routes';
import { DownloadRecordTranscriptionRequestParams } from '@app/interfaces/record-transcription';
import { CompositeRecordParams, RecordTranscriptionResponse } from '@app/interfaces/records';
import {
  MetricListArrType,
  MetricRecordDataResult,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import { recordResponseMutation } from '@app/api/server/record-response.mutation';
import { AudioChannel } from '@app/store/reducers/audio-channels.slice';
import { ProjectItem } from '@app/interfaces/analytics';

import apiInstance from './common.api';

export const getAliasRecordPage = async () => {
  return await apiInstance.get('/api/front/alias/bulk');
};

export const getRecordBulk = async (data) => {
  return await apiInstance.post('/api/front/record/bulk', data);
};

export const getRecord = async (params: { id: string }) => {
  return await apiInstance.get('/api/front/record', { params: { record_id: params.id } });
};

export const getRecordAudio = async (params: { id: string }) => {
  return await apiInstance.get('/api/front/record/audio', {
    params: { record_id: params.id },
    responseType: 'blob',
  });
};

export const deleteRecord = async (data: { items: string[] }) => {
  return await apiInstance.delete('/api/front/record', { data });
};

export const getFileRecordRequest = async ({ params }: { params: { record_id: string } }) => {
  return await apiInstance
    .get<Blob>(`${serverRoutes.audio}?record_id=${params.record_id}`, {
      responseType: 'blob',
    })
    .then((response) => response.data);
};

export const getFileChatRecordRequest = async (
  params: DownloadRecordTranscriptionRequestParams,
) => {
  return await apiInstance
    .get<Blob>(`${serverRoutes.downloadRecordTranscription}`, {
      params,
    })
    .then((response) => response.data);
};

export const compositeRecordRequest = async ({ recordId, projectId }: CompositeRecordParams) => {
  const projectInfo: ProjectItem = projectId
    ? (
        await apiInstance.get(serverRoutes.projectInfo, {
          params: { id: projectId },
        })
      ).data
    : undefined;
  let metricRecordData: MetricRecordDataResult<never> | undefined = undefined;
  let metricProjectData: Array<MetricListArrType> | undefined = undefined;
  const translationParams = projectInfo?.asr_model_id
    ? { record_id: recordId, asr_model_id: projectInfo.asr_model_id }
    : { record_id: recordId };
  const transitionData: RecordTranscriptionResponse | undefined = await apiInstance
    .get<RecordTranscriptionResponse>(`${serverRoutes.transcription}`, {
      params: translationParams,
    })
    .then(({ data }) => data)
    .catch(() => undefined);
  const translationChannelsData: Array<AudioChannel> = await apiInstance
    .get<Array<AudioChannel>>(serverRoutes.audioChannels)
    .then(({ data }) => data);
  if (transitionData) {
    metricRecordData = projectId
      ? await apiInstance
          .get(`${serverRoutes.projectMetricResult}`, {
            params: { record_id: recordId, project_id: projectId },
          })
          .then((response) => response?.data)
      : undefined;
    metricProjectData = projectId
      ? await apiInstance
          .get(`${serverRoutes.metric}`, {
            params: { project_id: projectId },
          })
          .then((response) => response?.data)
      : undefined;
  }
  return recordResponseMutation({
    transitionData,
    translationChannelsData,
    metricRecordData,
    metricProjectData,
  });
};
