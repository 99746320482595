import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import {
  activeClassName,
  chartMessageIconClassName,
} from '@app/components/audio-build/audio-chat/audio-chat.types';
import Icon from '@app/components/ui/icons';
import { Dialog } from '@app/interfaces/records';
import { IconNameType } from '@app/components/ui/icons/icon-name.type';
import { emotionToColorConverter } from '@app/constants/emotions';
import { VoiceEmotion } from '@app/interfaces/record-transcription';
type AudioChannelsChartSettings = { iconName: IconNameType; iconColor: string };

type AudioChatItemProps = {
  toggleEmotions: boolean;
  id: string;
  onClick: () => void;
  phrase: ReactNode;
  emotion: VoiceEmotion;
  active: boolean;
  channelId: string;
  channelConfig: Dialog['channel_config'];
  duration: { from: number; to: number };
};

const AudioChatItem: FC<AudioChatItemProps> = (props) => {
  const {
    id,
    emotion,
    channelId,
    phrase,
    channelConfig,
    active,
    onClick,
    duration,
    toggleEmotions = true,
  } = props;
  const ICONS_LIST: { [x: string]: AudioChannelsChartSettings } = {
    operator: {
      iconName: 'HeadphoneIcon',
      iconColor: 'bg-speech_analitics',
    },
    client: { iconName: 'ProfileIcon', iconColor: 'bg-3color' },
    other: { iconName: 'HelpCircleIcon', iconColor: 'bg-3color' },
  };

  const messageDurationInMinute = () => {
    const roundedMinute = Math.floor(duration.from / 60000);
    const roundedSeconds = `0${Math.floor(Number(duration.from - roundedMinute * 60000) / 1000)}`;
    return `${Math.floor(roundedMinute)}:${roundedSeconds.slice(-2)}`;
  };

  return (
    <li
      id={id}
      className="mb-2 ml-6 hover:bg-speech_analitics_light cursor-pointer hover:rounded-[6px] hover:opacity-90"
      onClick={onClick.bind(null)}
    >
      <span className="inline-flex w-full justify-between">
        <span className="block w-[0px] min-h-[10px]">
          <span
            className="block w-[5px] min-h-[20px] ml-[-90px] h-[100%] rounded-[6px]"
            style={{
              opacity: toggleEmotions ? 1 : 0,
              backgroundColor: emotionToColorConverter(emotion),
            }}
          />
        </span>
        <span className="flex-1 block">
          <span
            className={chartMessageIconClassName(
              ICONS_LIST[channelConfig?.icon]?.iconColor || 'bg-3color text-white',
            )}
          >
            {ICONS_LIST[channelConfig?.icon] ? (
              <Icon
                name={ICONS_LIST[channelConfig?.icon]?.iconName || ICONS_LIST[0]?.iconName}
                className="text-white"
                size={14}
              />
            ) : (
              <>{channelId}</>
            )}
            <div className="absolute text-[11px] font-normal text-3color left-[-30px]">
              {messageDurationInMinute()}
            </div>
          </span>
          <div className={activeClassName({ active })}>
            <div className="mt-[-5px]">
              <h3 className=" flex items-center text-[15px] font-bold text-gray-900">
                {channelConfig?.name}
              </h3>
              <p
                className={classNames('mb-4', 'text-base', 'text-sm', 'font-[500]', 'text-0color')}
              >
                {phrase}
              </p>
            </div>
          </div>
        </span>
      </span>
    </li>
  );
};

export default AudioChatItem;
