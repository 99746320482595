import { createDictionaries, editDictionary } from '@app/api/dictionaries.api';
import { WordsListComponent } from '@app/components/ui/add-words-and-dictionaries-container/wordsListComponent';
import { Box, Button, EditText, Input, Modal, PageHeader } from '@app/components/ui';
import {
  createDictionaryType,
  DictionariesType,
  DictionaryType,
} from '@app/interfaces/slices-types/dictionaries-slice.type';
import { getDictionariesThunks } from '@app/store/reducers/dictionaries-slice';
import { useAppDispatch } from '@app/store/store';
import { AutoFocusHook, getFrontendDate } from '@app/utils/helpers';
import { navigationRoutes } from '@app/utils/navigation-routes';
import React, { useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import useTranslation from '@app/hooks/use-translation';
import { toast } from 'react-toastify';
import { NameAndDescriptionPattern } from '@app/constants/validation/regexp';

type PropsType = {
  edit: boolean;
  copy: boolean;
  newDictionary: boolean;
  dictionaries: DictionariesType[];
  dictionary: DictionaryType | null;
};

export const CreateDictionaries: React.FC<PropsType> = ({
  edit = false,
  copy = false,
  newDictionary = false,
  dictionaries,
  dictionary,
}) => {
  const { id } = useParams();
  const { t } = useTranslation('pages.allPagesHintTitles');
  const { t: ti } = useTranslation('pages.dictionaries');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [wordsListArray, setWordsListArray] = React.useState<string[]>([]);
  const [dictionariesListArray, setDictionariesListArray] = React.useState<string[]>([]);
  const [visibleInput, setVisibleInput] = React.useState(newDictionary);
  const [dictionaryName, setDictionaryName] = React.useState<string>();
  const [description, setDescription] = React.useState<string>();

  const getDictionaryName = React.useCallback(() => {
    if (dictionaries.length >= 9) {
      setDictionaryName(ti('dictionary') + ' 0' + (dictionaries.length + 1));
    } else if (dictionaries.length >= 99) {
      setDictionaryName(ti('dictionary') + ' ' + (dictionaries.length + 1));
    } else {
      setDictionaryName(ti('dictionary') + ' 00' + (dictionaries.length + 1));
    }
  }, [dictionaries.length, ti]);

  React.useEffect(() => {
    setDictionariesListArray([]);
    setWordsListArray([]);
    if (edit) {
      setDescription(dictionary?.description);
      setDictionaryName(dictionary?.name);
      if (dictionary?.items) {
        setWordsListArray(dictionary?.items);
      }
      if (dictionary?.parents) {
        setDictionariesListArray(dictionary?.parents.map((e) => e.dictionary_id));
      }
    } else if (newDictionary) {
      getDictionaryName();
    } else if (copy && dictionary?.name) {
      setDictionaryName(dictionary?.name + ' ' + ti('copy'));
      setDescription(dictionary?.description);
      if (dictionary?.items) {
        setWordsListArray(dictionary?.items);
      }
      if (dictionary?.parents) {
        setDictionariesListArray(dictionary?.parents.map((e) => e.dictionary_id));
      }
    }
  }, [
    copy,
    dictionary?.description,
    dictionary?.items,
    dictionary?.name,
    dictionary?.parents,
    edit,
    getDictionaryName,
    newDictionary,
    ti,
  ]);

  const inputVisibleChange = (e: string) => {
    setVisibleInput(!visibleInput);
    const value = e.replace(NameAndDescriptionPattern, '');
    setDictionaryName(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (dictionaryName && description) {
      const obj: createDictionaryType = {
        name: dictionaryName,
        description: description,
        items: wordsListArray,
        parents: dictionariesListArray,
      };
      if (edit && id) {
        editDictionary(obj, id).then(() => {
          dispatch(getDictionariesThunks.getAllDictionariesThunk());
          setTimeout(() => {
            navigate(`/${navigationRoutes.dictionaries}`);
          }, 0);
        });
      } else {
        createDictionaries(obj).then(() => {
          dispatch(getDictionariesThunks.getAllDictionariesThunk());
          setTimeout(() => {
            navigate(`/${navigationRoutes.dictionaries}`);
          }, 0);
        });
      }
    } else {
      toast.error(ti('fillAllFields'));
    }
  };

  const inputFocusRef = AutoFocusHook(newDictionary);
  const ref = useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (newDictionary) {
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    } else if (visibleInput) {
      setTimeout(() => {
        ref.current?.focus();
      }, 0);
    }
  }, [newDictionary, visibleInput]);
  const onDictionaryClick = (e: string) => {
    navigate(`/${navigationRoutes.dictionaries}/${e}/edit`);
    setDictionariesListArray([]);
    setWordsListArray([]);
  };
  function disableButtonDelete() {
    if (dictionary?.is_system) return true;
    return dictionary?.childs !== null;
  }
  function saveButtonDisable() {
    if (edit) {
      return dictionary?.is_system;
    } else return false;
  }
  function handleClearAllWords() {
    setWordsListArray([]);
    setDictionariesListArray([]);
  }
  return (
    <>
      <PageHeader
        popLink={'/' + navigationRoutes.dictionaries}
        label={
          <div className="flex items-center gap-[18px]">
            {dictionaryName && (
              <EditText
                defaultOpen={newDictionary || copy}
                refCondition={newDictionary}
                editIconSize={18.5}
                text={dictionaryName}
                onEdited={inputVisibleChange}
              />
            )}
          </div>
        }
      ></PageHeader>
      {/* <div className="py-[30px] ">{}</div> */}
      <Box className="p-[40px]">
        <form onSubmit={(e) => e.preventDefault()} className="flex gap-[20px] flex-col w-full">
          <div className="w-[493px] flex items-center justify-between">
            <span className="text-[15px] text-0color">{ti('dict_desc')}</span>
            <div className="w-[281px]">
              <Input
                {...inputFocusRef}
                defaultValue={description || ''}
                onChange={(e) =>
                  setDescription(e.target.value.replace(NameAndDescriptionPattern, ''))
                }
                type="text"
                placeholder={ti('input')}
                name={'description'}
              />
            </div>
          </div>

          <div>
            <WordsListComponent
              currentDictionaryId={edit ? dictionary?.dictionary_id : undefined}
              dictionariesListArray={dictionariesListArray}
              setDictionariesListArray={setDictionariesListArray}
              className="min-h-[322px] "
              wordsListArray={wordsListArray}
              setWordsListArray={setWordsListArray}
              dictionaries={dictionaries}
            />
          </div>
          {!newDictionary && (
            <div className="flex flex-col gap-[30px]">
              <div className="flex items-center justify-between w-[285px]">
                <span className="text-[15px] text-0color">{ti('date_update')}</span>
                <span className="text-[15px] text-0color">
                  {(edit || copy) && dictionary
                    ? getFrontendDate(dictionary.dt_update, 'dd.MM.yyyy')
                    : null}
                </span>
              </div>
              {dictionary?.childs !== null && (
                <div className="flex flex-col gap-[15px]">
                  <span className="text-[15px] text-0color">{ti('dict_used')}</span>
                  <div className="flex gap-[10px] items-center w-full flex-wrap">
                    {dictionary?.childs.map((e) => {
                      return (
                        <span
                          title={t('to_dictionary')}
                          onClick={() => onDictionaryClick(e.dictionary_id)}
                          key={e.dictionary_id}
                          className="cursor-pointer p-[2px_8px_3px] bg-[#FFE2F4] rounded-[4px]"
                        >
                          <span className="text-0color underline">{e.name}</span>
                        </span>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-[12px]">
              <Button
                onClick={(e) => onSubmit(e)}
                disabled={saveButtonDisable()}
                label={ti('save')}
              />
              <Button
                link={'/' + navigationRoutes.dictionaries}
                fill="linked"
                label={ti('cancel')}
              />
              {edit && (
                <Button
                  link={`/${navigationRoutes.dictionaries}/${id}/copy`}
                  icon="CopyIcon"
                  fill="outlined"
                  label={ti('copy_dict')}
                />
              )}
            </div>
            <div className={'flex items-center'}>
              <div>
                <Button
                  onClick={handleClearAllWords}
                  variant="danger"
                  fill={'linked'}
                  label="Стереть все"
                />
              </div>
              {!newDictionary && (
                <Button
                  icon="TrashIcon"
                  label={ti('delete')}
                  variant="danger"
                  onClick={() => setDeleteModal(true)}
                  fill="linked"
                  disabled={disableButtonDelete()}
                />
              )}
            </div>
          </div>
        </form>
      </Box>
      <Modal
        id={'deleteDictionary'}
        variant={'removal'}
        value={deleteModal}
        setValue={setDeleteModal}
        title={ti('warning')}
        size={'xs'}
        onApply={() => {
          id &&
            dispatch(getDictionariesThunks.deleteDictionaryThunk(id))
              .unwrap()
              .then(() => navigate(`/${navigationRoutes.dictionaries}`))
              .catch((err) => {
                if (Number(err) === 403) {
                  toast.dismiss();
                  toast.info(ti('dictDeleteToastError'));
                  setDeleteModal((prev) => !prev);
                }
              });
        }}
      >
        <div className="px-[50px]">{ti('dict_confirm_delete')}</div>
      </Modal>
    </>
  );
};
