module.exports = {
  analytic_title: 'Аналитика',
  templates_title: 'Шаблоны',
  settings_title: 'Настройки',
  main: '',
  dashboard: 'Дашборд',
  all_records: 'Все записи',
  analytic_proj: 'Аналитические проекты',
  proj_records: 'Записи проекта',
  reports: 'Отчеты',
  presets_templates: 'Шаблоны условий отбора',
  metrics_templates: 'Шаблоны метрик',
  dictionaries: 'Словари',
  system_settings: 'Настройки системы',
  audit_logs: 'Логи аудита',
  words_cloud: 'Облако слов',
};
