import { CircleButton, EditText, Modal, TooltipWrapper } from '@app/components/ui';
import Icon from '@app/components/ui/icons';
import useTranslation from '@app/hooks/use-translation';
import React from 'react';
import { v4 } from 'uuid';
import { NameAndDescriptionPattern } from '@app/constants/validation/regexp';
import {
  useEditPresetTemplateGroupMutation,
  useDeletePresetTemplateGroupMutation,
} from '@app/store/api/preset-template.api';

type PropsType = {
  name?: string;
  preset_group_id?: string;
  active?: boolean;
  presetsCount?: number;
  isDefault?: boolean;
  setActiveAccordionItem: React.Dispatch<React.SetStateAction<(string | undefined)[]>>;
};
export const AccordionTitle: React.FC<PropsType> = ({
  name,
  active,
  presetsCount,
  preset_group_id,
  isDefault,
  setActiveAccordionItem,
}) => {
  const { t } = useTranslation('pages.allPagesHintTitles');
  const { t: ti } = useTranslation('pages.presets');
  const [edit, setEdit] = React.useState(false);
  const [modalDelete, setModalDelete] = React.useState(false);
  const [id, setId] = React.useState('');
  //api
  const [editPresetGroup] = useEditPresetTemplateGroupMutation();
  const [deletePresetGroup] = useDeletePresetTemplateGroupMutation();
  const deleteName = () => {
    if (preset_group_id) {
      setId(preset_group_id);
      setModalDelete(true);
    }
  };
  const deletePresetsGroup = () => {
    deletePresetGroup({ id }).then(() => {
      const accordionListFromStorage = localStorage.getItem('preset_accordion')?.split(',');
      const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
        (item) => item !== id,
      ) as string[];
      localStorage.setItem('preset_accordion', filteredAccordionListFromStorage.join(','));
      setActiveAccordionItem(filteredAccordionListFromStorage);
    });
    setModalDelete(false);
  };
  const editGroupName = (groupName: string) => {
    if (preset_group_id && groupName) {
      const name = groupName.replace(NameAndDescriptionPattern, '');
      editPresetGroup({ params: { id: preset_group_id }, body: { name } }).then(() =>
        setEdit(false),
      );
    }
  };
  React.useMemo(() => {
    if (!active) {
      setEdit(false);
    }
  }, [active]);

  return (
    <>
      <Modal
        value={modalDelete}
        setValue={setModalDelete}
        onApply={() => deletePresetsGroup()}
        id={'deletePresetsGroup'}
        title={ti('remove_presets_group_title')}
        size="xs"
        variant="removal"
      >
        {ti('confirm_remove_presets_group')}
      </Modal>
      <div className="flex items-center gap-[15px]">
        <EditText
          textStyle="font-[700] text-[16px] text-2color leading-[110%] tracking-tight"
          visibleInput={isDefault ? false : active}
          editIconSize={14}
          text={name || ''}
          onEdited={editGroupName}
        />
        {!isDefault && active && !edit && (
          <CircleButton
            hintTitle={t('delete_icon')}
            size={14}
            onClick={presetsCount === 0 ? () => deleteName() : undefined}
            className={`${presetsCount === 0 ? 'text-dangerBg' : 'text-3color'}`}
            icon={'TrashIcon'}
            stopPropagation
          />
        )}
      </div>

      {!active && (
        <div className="flex justify-center items-center text-xs w-[17px] h-[17px] rounded-full ml-[13px] text-[11px] bg-defaultBgBtn !text-white">
          <TooltipWrapper
            content={t('templates.templates_list_count')}
            id={t('templates.templates_list_count') + v4()}
            className="text-white"
          >
            {presetsCount}
          </TooltipWrapper>
        </div>
      )}
      {isDefault && (
        <div className="flex items-center justify ml-[10px]">
          <Icon name={'LockCircle'} className="text-2color ml-[px]" size={15} />
        </div>
      )}
    </>
  );
};
