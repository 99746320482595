export const serverPrefix = '/api/front';

const serverRoutes = {
  recordMetadata: `${serverPrefix}/record/metadata`,
  tenantAliases: `${serverPrefix}/alias/bulk`,
  aliases: `${serverPrefix}/cus/aliases`,
  preset: `${serverPrefix}/preset`,
  projectInfo: `${serverPrefix}/project`,
  projectMetricResult: `${serverPrefix}/project/metric_result`,
  projectMetric: `${serverPrefix}/project/metric`,
  metrics: `${serverPrefix}/project/metric_result/bulk`,
  recordList: `${serverPrefix}/record/bulk`,
  record: `${serverPrefix}/record`,
  downloadRecordTranscription: `${serverPrefix}/record/transcription/export`,
  audio: `${serverPrefix}/record/audio`,
  reports: `${serverPrefix}/project/report/bulk`,
  changeStatus: `${serverPrefix}/project/report/status`,
  report: `${serverPrefix}/project/report`,
  live_reports: `${serverPrefix}/live_report/bulk`,
  live_report: `${serverPrefix}/live_report`,
  live_report_records: `${serverPrefix}/live_report/record/bulk`,
  projects: `${serverPrefix}/project/bulk`,
  charts: `${serverPrefix}/live_report/graph/bulk`,
  chartData: `${serverPrefix}/live_report/graph/data`,
  chart: `${serverPrefix}/live_report/graph`,
  metric: `${serverPrefix}/project/metric/bulk`,
  metricProject: `${serverPrefix}/project/metric`,
  transcription: `${serverPrefix}/record/transcription`,
  audioChannel: `${serverPrefix}/settings/channel`,
  audioChannels: `${serverPrefix}/settings/channel/bulk`,
  userInfo: `${serverPrefix}/me`,
  wordsCloud: `${serverPrefix}/wordcloud`,
  audit_logs: `${serverPrefix}/audit_log/bulk`,
  audit_log: `${serverPrefix}/audit_log`,
  commonSettingsChannels: `${serverPrefix}/settings/channel/bulk`,
  commonSettingChannel: `${serverPrefix}/settings/channel`,
  aliasSettings: `${serverPrefix}/alias/bulk`,
  aliasSetting: `${serverPrefix}/alias`,
  recognition_rule_default: `${serverPrefix}/recog_rule/default`,
  recognition_rule: `${serverPrefix}/recog_rule`,
  recognition_rule_do: `${serverPrefix}/recog_rule/do`,
  recognition_rule_count: `${serverPrefix}/recog_queue/count`,
  recognition_rules: `${serverPrefix}/recog_rule/bulk`,
  asr_models: `${serverPrefix}/usr/model/bulk`,
  asr_ext_models: `${serverPrefix}/ext_asr_model/bulk`,
  asr_model: `${serverPrefix}/usr/model`,
  dashboards: `${serverPrefix}/dashboard/bulk`,
  dashboard: `${serverPrefix}/dashboard`,
  dashboardGraphs: `${serverPrefix}/dashboard/graph/bulk`,
  dashboardGraph: `${serverPrefix}/dashboard/graph`,
  dashboardLayout: `${serverPrefix}/dashboard/graph/layout`,
  analyticsProjectsGroups: `${serverPrefix}/project/group/bulk`,
  analyticsProjectsGroup: `${serverPrefix}/project/group`,
  analyticsProjectsGroupsStatus: `${serverPrefix}/project/status/bulk`,
  analyticsProjectStatus: `${serverPrefix}/project/status`,
  analyticsProject: `${serverPrefix}/project`,
  analyticsProjectStatusChange: `${serverPrefix}/project/status/change`,
  presetGroups: `${serverPrefix}/preset_group/bulk`,
  presetGroup: `${serverPrefix}/preset_group`,
};
export default serverRoutes;
