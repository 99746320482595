import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';
import { PresetState } from '@app/interfaces/presets';
import { PresetsDataType } from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import { getEmptyPreset } from '@app/utils/presets';
import serverRoutes from '@app/utils/server-routes';

const initialState: PresetState = {
  preset: getEmptyPreset(),
  isPending: false,
  error: null,
};

const presetSlice = createSlice({
  name: 'presetSlice',
  initialState,
  reducers: {
    setPreset: (state, { payload }: PayloadAction<PresetsDataType>) => {
      state.preset = payload;
    },
    setPending: (state, { payload }) => {
      state.isPending = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const { setPreset, setPending, setError } = presetSlice.actions;
export default presetSlice.reducer;

export const requestPreset = (preset_id: string) =>
  httpRequest({
    url: serverRoutes.preset,
    method: 'GET',
    params: { id: preset_id },
    onSuccess: setPreset.type,
    onLoading: setPending.type,
    onError: setError.type,
  });
