export const METAdatetimeCalendar = [
  {
    name: 'Текущий день',
    id: 'cd',
  },
  {
    name: 'Текущая неделя',
    id: 'cw',
  },
  {
    name: 'Текущий месяц',
    id: 'cm',
  },
];

export const METAdatetimeCalendarId = METAdatetimeCalendar.reduce(
  (total, curr) => total + curr.id,
  '',
);

export const METAdatetime = [
  {
    name: 'Без указания диапазона дат',
    id: 'n',
  },
  {
    name: 'Дата и время : Диапазон',
    id: 'r',
  },
  {
    name: 'Дата и время : N дней',
    id: 'l',
  },
  {
    name: 'Дата и время : Календарная привязка',
    id: METAdatetimeCalendarId,
  },
];
/* Лимит страниц для записей */
export const ALL_RECORDS_PAGE_LIMIT = 'ALL_RECORDS_PAGE_LIMIT';
export const PRESET_SETTINGS_RECORDS_PAGE_LIMIT = 'PRESET_SETTINGS_RECORDS_PAGE_LIMIT';
