import React, { FC, useState } from 'react';
import cn from 'classnames';

import { Checkbox, EasyModal, Paginator, PermissionAccessWrapper, Skeleton, Table } from '@ui';
import { ALL_RECORDS_PAGE_LIMIT } from '@app/utils/constants';
import useTranslation from '@app/hooks/use-translation';
import { useDeleteRecordsMutation, useGetRecordsQuery } from '@app/store/api/records.api';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { FilterTableData, TableItem, TableTitle } from '@app/components/ui/table/table.type';
import { Link } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { DownloadCloudIcon, Oscilogram, TrashIcon } from '@icons';
import { userPermissions } from '@app/utils/user-permissions';

import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';

import { getRecordAudio } from '@app/api/record.api';
import fileDownload from 'js-file-download';

import { useGetAliasesListQuery } from '@app/store/reducers/alias-settings-page.api';

import { tableUtilIconsClassName } from './records-table.styles';

type RecordsTableBlockPropsType = {
  recordsParams: GetFilterParamsNew;
  getRecordsParams: React.Dispatch<React.SetStateAction<GetFilterParamsNew>>;
  changeSelectedRecords?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedRecords?: string[];
  columnSize?: number;
};
const RecordsTableBlock: FC<RecordsTableBlockPropsType> = (props) => {
  const { recordsParams, getRecordsParams, selectedRecords, changeSelectedRecords, columnSize } =
    props;
  const { t } = useTranslation('pages.records');
  const pageOptionsData = [
    { title: '10 ' + t('calls'), value: 10 },
    { title: '15 ' + t('calls'), value: 15 },
    { title: '25 ' + t('calls'), value: 25 },
    { title: '50 ' + t('calls'), value: 50 },
  ];
  const [deleteRecordIds, changeDeleteRecordsIds] = useState<Array<string>>([]);
  const { data: recordsList, isLoading } = useGetRecordsQuery(recordsParams);
  const [deleteRecordRequest] = useDeleteRecordsMutation();
  const { data } = useGetAliasesListQuery();

  const checkingAliasForSorting = (alias_id: string) => {
    const alias = data?.aliases.find((alias) => alias.alias_id === alias_id);
    if (alias) {
      return alias.type_meta !== 'channel';
    }
    return false;
  };

  const tableTitles: TableTitle[] = recordsList?.headers.length
    ? [
        {
          index: 'duration_records_items',
          title: (
            <div className="flex items-center">
              <>
                <div className="mr-[10px]">
                  <Checkbox
                    touched={(selectedRecords?.length as number) >= 1}
                    checked={selectedRecords?.length === recordsParams.limit}
                    onChange={() => onSelectAllRecords()}
                  />
                </div>
                <span>{t('duration')}</span>
              </>
            </div>
          ),
        },
        ...recordsList.headers.map((column) => ({
          index: column.alias_id,
          title: column.name,
          size: columnSize,
          filter: checkingAliasForSorting(column.alias_id),
        })),
        {
          index: 'table_record_details',
          title: t('actions'),
        },
      ]
    : [];
  const tableData: TableItem[] =
    recordsList?.records.reduce<TableItem[]>((dataTable, record) => {
      const cell: TableItem = {};
      cell['duration_records_items'] = (
        <div className="truncate w-full flex items-center">
          <div className="!mr-[10px]">
            <Checkbox
              checked={selectedRecords?.includes(record.record_id)}
              onChange={() => onSelectRecord(record.record_id)}
            />
          </div>
          <div className="flex cursor-pointer">
            <Link
              target="_blank"
              className="inline-flex items-center"
              to={`/${navigationRoutes.records}/${record.record_id}`}
            >
              <TimeDuration duration={record.duration} />
            </Link>
          </div>
        </div>
      );
      cell['table_record_details'] = (
        <div className="flex items-center w-[110px] gap-[15px] justify-center">
          <DownloadCloudIcon
            hintTitle={t('download_record')}
            size={18}
            className={tableUtilIconsClassName}
            onClick={() => downloadAudioHandler({ record_id: record.record_id })}
          />
          <Oscilogram
            hintTitle={t('to_record_title')}
            size={22}
            className={cn(tableUtilIconsClassName, 'text-bold')}
            onClick={() =>
              window.open(`/${navigationRoutes.records}/${record.record_id}`, '_blank')
            }
          />
          <PermissionAccessWrapper
            permissions={'action'}
            tag={userPermissions.action.actionDeleteRecord}
          >
            <TrashIcon
              hintTitle={t('delete_icon')}
              size={18}
              className={cn(tableUtilIconsClassName, '!text-basic_red')}
              onClick={() =>
                changeDeleteRecordsIds((prevState) => [...prevState, record.record_id])
              }
            />
          </PermissionAccessWrapper>
        </div>
      );
      for (const tableCell of record.data) {
        cell[tableCell.alias_id] = tableCell.value;
      }
      dataTable.push(cell);
      return dataTable;
    }, []) || [];

  function onSelectAllRecords() {
    if (!recordsList) return;
    const notImportantArray: string[] = [];
    if (selectedRecords?.length !== recordsParams.limit) {
      for (const record of recordsList.records) {
        notImportantArray.push(record.record_id);
      }
    }
    changeSelectedRecords?.(notImportantArray);
  }
  function onSelectRecord(record_id: string) {
    changeSelectedRecords?.((prev) =>
      prev.includes(record_id) ? prev.filter((id) => id !== record_id) : [...prev, record_id],
    );
  }

  function paginatorChangePageHandler(paginationData: PaginationResponse) {
    getRecordsParams((prevState) => ({ ...prevState, ...paginationData }));
    localStorage.setItem(ALL_RECORDS_PAGE_LIMIT, String(paginationData.limit));
  }
  function tableChangeFilterHandler(tableFilter: FilterTableData) {
    // console.log(tableFilter);
    getRecordsParams((prevState) => ({ ...prevState, ...tableFilter }));
  }

  async function downloadAudioHandler(params: { record_id: string }) {
    return await getRecordAudio({ id: params.record_id }).then((res) =>
      fileDownload(
        res.data,
        res.headers['content-disposition'].replace(/"/gim, '').replace('attachment; filename=', ''),
      ),
    );
  }

  function removeRecords() {
    changeDeleteRecordsIds((prevState) => {
      deleteRecordRequest({ items: prevState });
      return [];
    });
  }
  const LoadingSkeleton = (
    <div className={'flex flex-col gap-[10px] pb-2'}>
      <Skeleton height={400} className={'mt-6'} />
      <Skeleton height={50} />
    </div>
  );
  if (isLoading) return LoadingSkeleton;

  return (
    <div className="relative">
      <div className="flex flex-col gap-[10px] pb-2">
        <Table
          columns={tableTitles}
          dataSource={tableData}
          isPending={isLoading}
          onFilter={tableChangeFilterHandler}
        />
        <Paginator
          limit={recordsParams.limit}
          page={Number(recordsParams.offset)}
          count={Number(recordsList?.total)}
          onChange={paginatorChangePageHandler}
          pageOptions={pageOptionsData}
        />
      </div>
      <EasyModal
        show={Boolean(deleteRecordIds.length)}
        onClose={changeDeleteRecordsIds.bind(null, [])}
        label={
          deleteRecordIds.length > 1 ? (
            <>
              {t('popover.delete_selected')}
              <span className="text-basic_red">{changeDeleteRecordsIds.length}</span>
            </>
          ) : (
            <>
              {t('delete_record')} <span className="text-basic_red">{}</span>
            </>
          )
        }
        onSuccess={removeRecords}
        variant="removal"
      >
        {deleteRecordIds.length > 1 ? t('delete_all_records') : t('record_wb_deleted')}
      </EasyModal>
    </div>
  );
};

export default RecordsTableBlock;
