import React, { useState, FC, ChangeEvent, useRef, KeyboardEvent, useEffect } from 'react';
import { v4 } from 'uuid';
import cn from 'classnames';

import { Input, TooltipWrapper } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import useOnclickOutside from '@app/hooks/use-onclick-outside';
import { AutoFocusHook } from '@app/utils/helpers';

import { EditIcon } from '../icons/icons-list';

type EditTextProps = {
  textStyle?: string;
  text: string;
  onEdited: (text: string) => void;
  visibleInput?: boolean;
  defaultOpen?: boolean;
  editIconSize?: number;
  refCondition?: boolean;
  showEdit?: boolean;
  editIconClassNames?: string;
  disableEdit?: boolean;
};

const EditText: FC<EditTextProps> = (props) => {
  const { t } = useTranslation('pages.allPagesHintTitles');
  const {
    textStyle = 'font-[700] text-[18px] text-1color',
    text,
    onEdited,
    visibleInput = true,
    editIconSize = 22,
    refCondition,
    defaultOpen = false,
    showEdit = false,
    disableEdit = false,
    editIconClassNames,
  } = props;
  const editBlockRef = useRef<HTMLDivElement>(null);
  // const inputRef = useRef<HTMLInputElement>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [backUpText, updateBackupText] = useState<string>(text);
  const [editText, setEditText] = useState<string>(text);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const inlineEditBlockClassName = 'flex items-center justify-between w-full max-w-[500px]';
  function handlerIsEditChanged(status: boolean) {
    setIsEditing(status);
    !status && onEdited?.(editText);
    updateBackupText(editText);
  }
  function handlerRollBackText() {
    setEditText(backUpText);
    setIsEditing(false);
  }
  useOnclickOutside(editBlockRef, () => {
    handlerRollBackText();
    setIsEditing(false);
  });
  useEffect(() => {
    if (defaultOpen) {
      setIsEditing(true);
    }
  }, [defaultOpen]);

  function handlerOnKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') handlerIsEditChanged(false);
    if (event.key === 'Esc' || event.key === 'Escape') {
      handlerRollBackText();
      setIsEditing(false);
    }
  }
  const inputFocusRef = AutoFocusHook(refCondition ? refCondition : isEditing);
  return (
    <div ref={editBlockRef}>
      <div className={cn(inlineEditBlockClassName, isEditing ? 'block' : 'hidden h-[0px]')}>
        {visibleInput && (
          <Input
            {...inputFocusRef}
            stopPropagation
            onKeyDown={handlerOnKeyDown}
            name={v4()}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setEditText(event.target.value)}
            defaultValue={editText}
            onClick={handlerRollBackText}
            secondOnClick={() => handlerIsEditChanged(false)}
            icon="CancelIcon"
            secondIcon={'CheckIcon'}
            iconColor="primary"
          />
        )}
      </div>
      <div className={cn(inlineEditBlockClassName, isEditing ? 'invisible h-0' : 'visible')}>
        <div
          className={cn(
            textStyle,
            'truncate',
            'max-w-[500px]',
            isEditing ? 'invisible h-[0px]' : 'visible',
          )}
          ref={textContainerRef}
        >
          {editText}
        </div>
        {!showEdit && visibleInput && (
          <div
            className={`text-action ml-[22px] ${editIconClassNames}`}
            onClick={(e) => {
              e.stopPropagation();
              !disableEdit && handlerIsEditChanged(true);
            }}
          >
            <TooltipWrapper content={t('edit_icon')} id={v4()}>
              <EditIcon
                className={`${disableEdit ? 'text-4color' : 'text-action'} cursor-pointer`}
                size={editIconSize}
              />
            </TooltipWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditText;
