import { StatusVariant } from '@app/components/status-label/status-label';

export const reportStatusPropertiesData: Partial<Record<StatusVariant, StatusVariant[]>> = {
  CREATED: ['UPDATING', 'ARCHIVE', 'DELETING', 'PAUSE'],
  UPDATING: ['ARCHIVE', 'DELETING', 'PAUSE'],
  DONE: ['UPDATING', 'ARCHIVE', 'DELETING', 'PAUSE', 'DONE'],
  ARCHIVE: ['UPDATING', 'DELETING', 'PAUSE'],
  PAUSE: ['UPDATING', 'ARCHIVE', 'DELETING'],
  ERROR: ['UPDATING', 'ARCHIVE', 'DELETING', 'PAUSE'],
};
