import React, { FC } from 'react';
import cn from 'classnames';

type SkeletonProps = {
  width?: number | string;
  height?: number | string;
  count?: number;
  className?: string;
  label?: string;
};
const Skeleton: FC<SkeletonProps> = (props) => {
  const { width = '100%', height = 32, count = 1, className, label } = props;
  const skeletonStyle = { width: checkSizeForStyles(width), height: checkSizeForStyles(height) };

  function checkSizeForStyles(size: string | number) {
    return typeof size === 'number' ? `${size}px` : size;
  }

  return (
    <>
      {Array(count)
        .fill('_')
        .map((_, index) => (
          <div
            key={index}
            className={cn(
              'bg-b_dark/80 flex items-center justify-center shadow-medium animate-pulse rounded-defaultR',
              className,
            )}
            style={skeletonStyle}
          >
            <div className="text-action text-[14px]">{label}</div>
          </div>
        ))}
    </>
  );
};

export default Skeleton;
