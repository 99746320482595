import { PresetsDataType } from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import React from 'react';

import { TableChildren } from './tableChildren';

type PropsType = {
  group: PresetsDataType[];
};
export const Container: React.FC<PropsType> = ({ group }) => {
  return <TableChildren presetGroup={group} />;
};
