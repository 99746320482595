import { createSlice } from '@reduxjs/toolkit';
import { GraphSettingsDate } from '@app/interfaces/report.type';
import { httpRequest } from '@app/store/middleware/rest';
import serverRoutes from '@app/utils/server-routes';

export type ChartSettingsInitialState = {
  setup: GraphSettingsDate | null;
  isPending: boolean;
  error: string | null;
};

const chartSettingsInitialState: ChartSettingsInitialState = {
  setup: null,
  isPending: false,
  error: null,
};

const chartSettingsSlice = createSlice({
  name: 'ChartSettingsSlice',
  initialState: chartSettingsInitialState,
  reducers: {
    chartSettingsResponse: (state, { payload }) => {
      state.setup = payload;
    },
    chartChangeSettingsResponse: (state, { payload }) => {
      state.setup = payload;
    },
    chartSettingsPending: (state, { payload }) => {
      state.isPending = payload;
    },
    chartSettingsError: (state, { payload }) => {
      state.error = payload;
    },
    chartSettingsReset: (state) => {
      state.setup = null;
    },
  },
});

export default chartSettingsSlice.reducer;
export const {
  chartSettingsResponse,
  chartSettingsPending,
  chartSettingsError,
  chartSettingsReset,
  chartChangeSettingsResponse,
} = chartSettingsSlice.actions;

export const editChartLiveReportRequest = ({
  params,
  data,
}: {
  params: { id };
  data: { name: string; settings: GraphSettingsDate };
}) =>
  httpRequest({
    url: serverRoutes.chart,
    method: 'PUT',
    params,
    data,
    onStatus: chartChangeSettingsResponse.type,
    onError: chartSettingsError.type,
  });

export const chartSettingsRequest = (params: { id: string }) =>
  httpRequest({
    url: serverRoutes.chart,
    params,
    method: 'GET',
    onSuccess: chartSettingsResponse.type,
    onError: chartSettingsError.type,
    onLoading: chartSettingsPending.type,
  });
