import React, { FC } from 'react';
import { EmptyIcon } from '@app/components/ui/icons/icons-list';
import { useTranslation } from '@app/hooks/use-translation';

const Empty: FC<{ title?: string }> = (props) => {
  const { title } = props;
  const { t } = useTranslation('components.empty');
  return (
    <div className="inline-flex w-full flex-col items-center justify-center">
      <EmptyIcon size={30} className="text-0color" />
      <div className="text-0color">{title || t('title')}</div>
    </div>
  );
};

export default Empty;
