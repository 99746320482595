import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

// import { Mutex } from 'async-mutex';

import { baseQuery } from '../baseQuery';

type UserPermissions = {
  permissions: { display: { [permission_key: string]: boolean } };
  userinfo: { name: string };
};
// const mutex = new Mutex();
export const userInfoApi = createApi({
  reducerPath: 'userInfoApiQuery',
  tagTypes: ['userInfo'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getUserInfo: build.query<UserPermissions, void>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const userInfo = (await fetchWithBQ({
          url: serverRoutes.userInfo,
        })) as unknown as { data: UserPermissions };
        return userInfo
          ? { data: userInfo.data }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
    }),
  }),
});

export const { useGetUserInfoQuery } = userInfoApi;
