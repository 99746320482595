import React, { useEffect, useState, FC, useCallback } from 'react';

import { Button, PageHeader, Paginator, Select, Table } from '@app/components/ui';
import { useAppDispatch, useAppSelector } from '@app/store/store';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { GetFilterParams } from '@app/interfaces/filter';
import { getMetricThunks } from '@app/store/reducers/metric-slice';
import connector, { PropsFromRedux } from '@app/utils/store';
import cloneFilterWithValues from '@app/hooks/use-universal-filter-values/cloneFilterWithValues';
import { getAnalyticsThunk } from '@app/store/reducers/analyticsSlice';
import { navigationRoutes } from '@app/utils/navigation-routes';

import { Link, useNavigate } from 'react-router-dom';

import { DownloadCloudIcon, Oscilogram } from '@app/components/ui/icons/icons-list';
import classNames from 'classnames';
import { getRecordsThunk } from '@app/store/reducers/recordsSlice';

import { LiveReport } from '@app/interfaces/report.type';

import useTranslation from '@app/hooks/use-translation';

import { MetricMetricItemData } from '@app/interfaces/metric.type';

import { GroupSharedStatus } from '@app/interfaces/analytics';

import ViewFilter from '@app/components/UniversalFilter/UniversalFilterView';

import { useGetMetricRecordsQuery } from '@app/store/reducers/metric-records.api';

import { Loading } from '@ui';

import { getMetricScoreStyles, getMetricScoreStyles100 } from './styles/styles';

import TimeDuration from './blocks/time-duration';
const localStorageLimit = 'LAST_PROJECT_RECORDS_PAGE_LIMIT';
const DEFAULT_RECORDS_LIMIT = 10;
const DEFAULT_RECORDS_OFFSET = 0;
const LastProjectRecordsPage: FC<PropsFromRedux> = (props) => {
  const { createLiveReportRequest } = props;
  const { t } = useTranslation('pages.records');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [project_id, changeProjectId] = useState(localStorage.getItem('analytic_project_id') || '');
  const { project, groups, loading } = useAppSelector((state) => state.analytics);
  const { filter, range } = useAppSelector((state) => state.filter);
  const { projectsList } = useAppSelector((state) => state.analytics);
  const tableUtilIconsClassName = classNames(
    'text-3color transition hover:text-action cursor-pointer transition cursor-pointer',
  );
  const [filterParams, changeFilterParams] = useState<GetFilterParams>({
    offset: DEFAULT_RECORDS_OFFSET,
    limit: Number(localStorage.getItem(localStorageLimit)) || DEFAULT_RECORDS_LIMIT,
    filter: {
      range: {
        type: 'n',
        parameters: [],
      },
      filter: [],
    },
  });
  const { data, isFetching: isLoading } = useGetMetricRecordsQuery({
    ...filterParams,
    project_id: project_id,
  });
  const sortByOrder = (data) => {
    const value = Object.entries(data)[0];
    const params = {
      sortBy: value?.[0] || undefined,
      sortDesc: value?.[1] === 1,
    };
    changeFilterParams((prev) => ({ ...prev, sortBy: params.sortBy, sortDesc: params.sortDesc }));
  };

  const checkingAliasForSorting = useCallback(
    (index) => {
      if (index === 0) {
        return false;
      } else if (index === (data?.headers.length as number) + 1) {
        return false;
      }
      return true;
    },
    [data?.headers.length],
  );

  function displayItemVisualization(item: MetricMetricItemData) {
    if (item.visualization) {
      if (item.visualization === 'score5') {
        if (Number(item.value) > 5 || Number(item.value) === 0) {
          return item.value;
        } else {
          return (
            <div
              className={`${getMetricScoreStyles(
                Number(item.value),
              )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
            >
              {item.value}
            </div>
          );
        }
      } else if (item.visualization === 'score100') {
        if (Number(item.value) > 100 || Number(item.value) === 0) {
          return item.value;
        } else {
          return (
            <div
              style={{ width: `${22 + Number(item.value) * 1.5}px` }}
              className={`${getMetricScoreStyles100(
                Number(item.value),
              )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
            >
              {item.value}
            </div>
          );
        }
      } else return item.value;
    } else return item.value;
  }
  const tableColumns = [
    {
      index: 'duration_project_records_items',
      title: t('duration'),
      size: 160,
      hintTitle: t('to_record_title'),
    },
    ...(data?.headers?.map((header, index) => ({
      title: header.name,
      index: header.metric_id,
      size: 190,
      truncate: true,
      filter: checkingAliasForSorting(index),
    })) || []),
    { index: 'actions_project_records_items', title: t('actions'), size: 110 },
  ];

  const tableData = data?.metrics.length
    ? data?.metrics?.map((item) => ({
        ...item,
        duration_project_records_items: (
          <div className="flex cursor-pointer">
            <Link
              target="_blank"
              className="inline-flex items-center"
              to={`/${navigationRoutes.projectRecords}/${project_id}/${item.record_id}`}
            >
              <TimeDuration duration={item.duration} />
            </Link>
          </div>
        ),
        actions_project_records_items: (
          <div className="flex items-center pl-[19px]">
            <DownloadCloudIcon
              hintTitle={t('download_record')}
              size={18}
              className={`${tableUtilIconsClassName}, mr-[18px]`}
              onClick={() => getRecordsAudio([item.record_id])}
            />
            <Link
              target="_blank"
              to={`/${navigationRoutes.projectRecords}/${project_id}/${item.record_id}`}
            >
              <Oscilogram
                hintTitle={t('to_record_title')}
                size={22}
                className={tableUtilIconsClassName}
              />
            </Link>
          </div>
        ),
        ...Object.fromEntries(
          item.data.map((metrics) => [[metrics.metric_id], displayItemVisualization(metrics)]),
        ),
      }))
    : [];
  const getRecordsAudio = (recordsIds: string[]) => {
    for (const id of recordsIds) {
      dispatch(getRecordsThunk.getRecordAudioThunk({ id }));
    }
  };

  useEffect(() => {
    dispatch(getAnalyticsThunk.getGroupsThunk());
    dispatch(getAnalyticsThunk.getGroupsToListLastProjects());
  }, [dispatch]);

  useEffect(() => {
    const localStorageId = localStorage.getItem('analytic_project_id');
    if (!loading && projectsList.length) {
      const condition = projectsList.find((item) => item.project_id === localStorageId)?.name;
      if (localStorageId !== null && condition) {
        localStorageId && changeProjectId(localStorageId);
      } else {
        const project_id_default = groups.find((i) => i.is_default)?.projects[0].project_id;
        project_id_default && localStorage.setItem('analytic_project_id', project_id_default);
        project_id_default && changeProjectId(project_id_default);
        changeProjectId(project_id_default as string);
      }
    }
  }, [groups, loading, projectsList]);
  useEffect(() => {
    project_id && dispatch(getAnalyticsThunk.getProjectThunk({ id: project_id }));
  }, [dispatch, project_id]);
  function changePage({ offset, limit }: PaginationResponse) {
    changeFilterParams((prev) => ({ ...prev, offset: offset, limit: limit }));
    localStorage.setItem(localStorageLimit, String(limit));
  }

  function getAliases() {
    return dispatch(getMetricThunks.getMetricList(project_id)).unwrap();
  }

  async function createLiveReport() {
    if (!project) return;
    const result: LiveReport = await createLiveReportRequest({
      name: `${project.name}`,
      description: project.description,
      filter: {
        filter: cloneFilterWithValues(filter),
        range,
      },
      project_id: project.project_id,
    });
    navigate(`/${navigationRoutes.reports}/${result.live_report_id}/${navigationRoutes.settings}`, {
      state: navigationRoutes.reports,
    });
  }

  function changeProjectSelect(value: string) {
    localStorage.setItem('analytic_project_id', value);
    changeProjectId(value);
  }

  const getTitleFilter = () => {
    return <h2 className="text-1color font-bold">{t('filter_records')}</h2>;
  };

  const getFooterFilter = () => (
    <Button
      label={process.env.REACT_APP_THEME === 'rtk' ? t('create_report_rtk') : t('create_report')}
      onClick={() => createLiveReport()}
      disabled={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
    />
  );

  const getOptionsSelect = () => {
    if (!projectsList.length) return [];
    return projectsList.map(({ name, project_id }) => ({
      title: name,
      value: project_id,
    }));
  };
  return (
    <div className="pb-[25px]">
      {isLoading && <Loading />}
      <PageHeader icon="FolderMediaIcon" label={project?.name || <span>&mdash;</span>}>
        <div className="inline-flex items-center">
          {t('analytic_proj')}:
          <div className="ml-[11px]">
            <Select
              defaultValue={project?.project_id}
              options={getOptionsSelect()}
              placeholder={t('write_analytic_proj')}
              size={300}
              dropHeight={250}
              dropWidth={296}
              onChange={(value) => changeProjectSelect(value as string)}
            />
          </div>
        </div>
      </PageHeader>
      {/*<RecordsTableWithFilter*/}
      {/*  titleFilter={getTitleFilter()}*/}
      {/*  footerFilter={getFooterFilter()}*/}
      {/*  tableColumns={tableColumns}*/}
      {/*  tableData={tableData}*/}
      {/*  getRecords={getRecords}*/}
      {/*  getParams={getParams}*/}
      {/*  getAliases={getAliases}*/}
      {/*  metricMode={true}*/}
      {/*  changePage={changePage}*/}
      {/*  changeSortTable={sortByOrder}*/}
      {/*  totalItems={total}*/}
      {/*  disabledChangeButton={!project?.project_id}*/}
      {/*  changeReportsTableLimit={changeReportsTableLimit}*/}
      {/*  reportsTableLimit={reportsTableLimit}*/}
      {/*  localStorageLimit={localStorageLimit}*/}
      {/*/>*/}
      <div>
        <ViewFilter
          title={getTitleFilter()}
          footer={getFooterFilter()}
          metricMode={true}
          getAliases={getAliases}
          disabledChangeButton={!project?.project_id}
          saveFilter={async (filter) =>
            changeFilterParams((prev) => ({
              ...prev,
              filter: { ...filter, filter: cloneFilterWithValues(filter.filter) },
            }))
          }
        />
        <Table columns={tableColumns} dataSource={tableData} onFilter={sortByOrder} />
        <div className="my-[20px]">
          <Paginator
            page={Math.ceil(Number(filterParams.offset) / filterParams.limit) + 1}
            count={data?.total || 0}
            limit={filterParams.limit}
            onChange={changePage}
          />
        </div>
      </div>
    </div>
  );
};

export default connector(LastProjectRecordsPage);
