export const dataForFormSelectMock = [
  {
    id: 1,
    name: 'первый',
  },
  {
    id: 2,
    name: 'второй',
  },
  {
    id: 3,
    name: 'третий',
  },
  {
    id: 4,
    name: 'четвертый',
  },
  {
    id: 5,
    name: 'пятый',
  },
  {
    id: 6,
    name: 'шестой',
  },
];
export const conditions = [
  {
    id: '>',
    name: '>',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
  },
  {
    id: '<',
    name: '<',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
  },
  {
    id: '>=',
    name: '>=',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
  },
  {
    id: '<=',
    name: '<=',
    values: ['num'],
    type_meta: ['record', 'base', 'system'],
  },
  {
    id: '=',
    name: '=',
    values: ['num', 'str', 'datetime'],
    type_meta: ['channel', 'record', 'base', 'system'],
  },
  {
    id: '!=',
    name: '≠',
    values: ['num', 'str', 'datetime'],
    type_meta: ['channel', 'record', 'base', 'system'],
  },
  {
    id: 'exists',
    name: 'Существует',
    values: ['num', 'str'],
    type_meta: ['record', 'base'],
  },
];
export const MetaDateTimeCalendar = [
  {
    name: 'Текущий день',
    id: 'cd',
  },
  {
    name: 'Текущая неделя',
    id: 'cw',
  },
  {
    name: 'Текущий месяц',
    id: 'cm',
  },
];

export const MetaDateTimeCalendarId = MetaDateTimeCalendar.reduce(
  (total, curr) => total + curr.id,
  '',
);

export const MetaDateTime = [
  {
    name: 'Без указания диапазона дат',
    id: 'n',
  },
  {
    name: 'Дата и время : Диапазон',
    id: 'r',
  },
  {
    name: 'Дата и время : N дней',
    id: 'l',
  },
  {
    name: 'Дата и время : Календарная привязка',
    id: MetaDateTimeCalendarId,
  },
];
export const MetaDateTimeForRangeShow = {
  r: 'Дата и время : Диапазон',
  l: 'Дата и время : Последние',
  [MetaDateTimeCalendarId]: 'Дата и время : Календарная привязка',
};

export const MetaDateTimeCalendarForRangeShow = {
  cd: 'Текущий день',
  cw: 'Текущая неделя',
  cm: 'Текущий месяц',
};
