import apiInstance from './common.api';

export const downloadCsvReport = async (id: string) => {
  return await apiInstance.get('/api/front/live_report/record/export', {
    params: {
      id: id,
    },
    responseType: 'blob',
  });
};
