import React, { useState } from 'react';

import { RecordActionsDropdown } from '@app/interfaces/records';

import useTranslation from '@app/hooks/use-translation';
import { Button, EasyFilterView, PageHeader, Popover } from '@ui';
import { PopoverMenuItem } from '@app/components/ui/popover/popover.type';
import { userPermissions } from '@app/utils/user-permissions';

import RecordsTableBlock from '@app/pages/records/blocks/records-table/records-table.block';
import { useGetAliasesListQuery } from '@app/store/reducers/alias-settings-page.api';
import { GetFilterParamsNew, Filter, Range } from '@app/components/ui/easy-filter/types';
import { ALL_RECORDS_PAGE_LIMIT } from '@app/utils/constants';
import {
  PresetsCreateDataType,
  PresetsDataType,
} from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import {
  useCreateSinglePresetMutation,
  useGetPresetTemplateGroupsListQuery,
} from '@app/store/api/preset-template.api';
import { useNavigate } from 'react-router-dom';
import { getRecordAudio } from '@app/api/record.api';
import fileDownload from 'js-file-download';
import { useDeleteRecordsMutation } from '@app/store/api/records.api';
import { ChangeColumnDisplayModalBlock } from '@app/pages/records/blocks/change-column-display-modal.block';
import { ChangeColumnSizeModal } from '@app/pages/records/blocks/change-column-size-modal';

const DEFAULT_TABLE_LIMIT = 10;

const RecordsPage: React.FC = () => {
  const primaryPageLimit = Number(
    localStorage.getItem(ALL_RECORDS_PAGE_LIMIT) || DEFAULT_TABLE_LIMIT,
  );
  const navigate = useNavigate();
  const { t } = useTranslation('pages.records');
  const { data, isLoading } = useGetAliasesListQuery();
  const [recordsParams, getRecordsParams] = useState<GetFilterParamsNew>({
    offset: 0,
    limit: primaryPageLimit,
    preset_mode: false,
    filter: {
      range: {
        type: 'n',
        parameters: [],
      },
      filter: [],
    },
  });
  const [selectedRecords, changeSelectedRecords] = useState<string[]>([]);
  const [columnDisplayFormModalValue, setColumnDisplayFormModalValue] = useState(false);
  const [columnSize, changeColumnSize] = useState<number>();
  const [columnSizeModalShowState, changeColumnSizeModalShowState] = useState(false);
  //api
  const { data: presetTemplates } = useGetPresetTemplateGroupsListQuery();
  const [createPreset] = useCreateSinglePresetMutation();
  const [deleteRecordRequest] = useDeleteRecordsMutation();

  const actionsDropdown: PopoverMenuItem[] = [
    {
      title: t('popover.download_records'),
      key: RecordActionsDropdown.DOWNLOAD_RECORDS,
      icon: 'DownloadCloudIcon',
      disabled: !selectedRecords.length,
    },
    {
      title: t('popover.delete_selected'),
      key: RecordActionsDropdown.DELETE_SELECTED,
      icon: 'TrashIcon',
      color: 'danger',
      disabled: !selectedRecords.length,
      permission: { tag: userPermissions.action.actionDeleteRecords, permissions: 'action' },
    },
  ];

  const clickActionsDropdown = (key: string) => {
    console.log(key);
    if (key === RecordActionsDropdown.DOWNLOAD_RECORDS) {
      for (const record_id of selectedRecords) {
        downloadAudioHandler(record_id);
      }
    } else if (key === RecordActionsDropdown.DELETE_SELECTED) {
      deleteRecordRequest({ items: selectedRecords });
    }
  };

  const changeColumnDisplayFormModalState = () => {
    setColumnDisplayFormModalValue(true);
  };
  async function downloadAudioHandler(record_id: string) {
    return await getRecordAudio({ id: record_id }).then((res) =>
      fileDownload(
        res.data,
        res.headers['content-disposition'].replace(/"/gim, '').replace('attachment; filename=', ''),
      ),
    );
  }
  function saveAsTemplatePreset() {
    if (!presetTemplates) return;
    const newPreset: PresetsCreateDataType = {
      name: t('template_name'),
      description: '',
      preset_group_id:
        presetTemplates?.find((group) => group.is_default)?.preset_group_id ||
        presetTemplates?.[0]?.preset_group_id,
      shared: false,
      rules: recordsParams?.filter as Filter & Range,
    };
    createPreset(newPreset).then((data) => {
      const { data: presetData } = data as unknown as { data: PresetsDataType };
      navigate(`/${navigationRoutes.presets}/${presetData.preset_id}`);
    });
  }

  const getFooterFilter = (
    <div className="flex">
      <div className="grow">
        <Button
          fill="linked"
          label={t('save_as_template')}
          className="mr-[-22px]"
          onClick={() => saveAsTemplatePreset()}
          disabled={!recordsParams.filter.filter.length}
        />
      </div>
    </div>
  );

  function getFilteringKeys() {
    return data?.aliases
      .filter((item) => !item.forbidden_rules)
      .map((alias) => ({
        id: alias.alias_id,
        title: alias.name,
        type_meta: alias.type_meta,
        type_value: alias.type_value,
      }));
  }
  function onChangeFilterHandler(data: Filter & Range) {
    getRecordsParams((prev) => ({ ...prev, filter: data }));
  }

  return (
    <div>
      <PageHeader label={t('records')} icon="PlayCircleIcon">
        <div className="flex items-center gap-[15px]">
          <Popover
            size={270}
            label={t('actions')}
            menu={actionsDropdown}
            position="end"
            onClick={(key) => clickActionsDropdown(key)}
          />
          <Button
            hintTitlePosition="left"
            hintTitle={t('all_record')}
            icon="ColumnDisplayIcon"
            onClick={() => changeColumnDisplayFormModalState()}
          />
          <Button
            onClick={changeColumnSizeModalShowState.bind(null, true)}
            hintTitlePosition="left"
            hintTitle={t('cols_size_change')}
            icon="SettingIcon"
          />
        </div>
      </PageHeader>
      <EasyFilterView
        isLoading={isLoading}
        footerMarkup={getFooterFilter}
        onChangeHandler={onChangeFilterHandler}
        keysForFiltering={getFilteringKeys() || []}
        fillFromTemplate
      />
      <RecordsTableBlock
        changeSelectedRecords={changeSelectedRecords}
        selectedRecords={selectedRecords}
        recordsParams={recordsParams}
        getRecordsParams={getRecordsParams}
        columnSize={columnSize}
      />
      <ChangeColumnDisplayModalBlock
        value={columnDisplayFormModalValue}
        recordsParams={recordsParams}
        setValue={setColumnDisplayFormModalValue}
      />
      <ChangeColumnSizeModal
        show={columnSizeModalShowState}
        onClose={changeColumnSizeModalShowState}
        changeColumnSize={changeColumnSize}
      />
    </div>
  );
};

export default RecordsPage;
