import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { Box, NavigationTabs } from '@app/components/ui';
import { navigationData } from '@app/pages/analytics/blocks/navigation.data';
// import { AllStatusProject } from '@app/interfaces/analytics';
// import { editStatusProjectAnalytics } from '@app/api/analytics.api';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';
import useTranslation from '@app/hooks/use-translation';
// import { navigationRoutes } from '@app/utils/navigation-routes';
// import { useNavigate } from 'react-router';
import MainSettings from '@app/pages/analytics/project-settings/analytics-main-settings/blocks/main-settings';

const ProjectSettingsPage = () => {
  const { project } = useOutletContext<AnalyticPageOutlet>();
  // const navigate = useNavigate();
  // const [, ] = React.useState(false);
  const { t } = useTranslation('components.navigateTabs');
  const data: NavigationTabsItem[] = navigationData(project?.project_id).map((item) => ({
    ...item,
    label: t(item.label),
  }));

  // const changeStatusFunctions = {
  //   REMOVING: () => {
  //     if (project?.project_id) {
  //       editStatusProjectAnalytics({
  //         id: project?.project_id,
  //         status: AllStatusProject.REMOVING,
  //       }).then(() => {
  //         navigate('/' + navigationRoutes.analytics);
  //       });
  //     }
  //   },
  // };
  // 'success' active primary

  return (
    <div>
      <NavigationTabs items={data} />
      <Box>
        <MainSettings />
      </Box>
    </div>
  );
};

export default ProjectSettingsPage;
