import React, { FC } from 'react';
import { CircleButton, RubberTable } from '@ui';
import { FilterItemNotOnlyArray } from '@app/interfaces/filter';
import { getFrontendDate } from '@app/utils/helpers';
import { TableColumn, TableItem } from '@app/components/ui/rubber-table/table.types';
import { AliasSettingsObj, RecognitionRulesSettingsObj } from '@app/interfaces/settings.type';
import { AsrModerType } from '@app/interfaces/asr-moder.type';
import RecordChannelIcon from '@app/components/ui/icons/icons-list/record-channel.icon';

type RecognitionRulesTablePropsType = {
  data: RecognitionRulesSettingsObj[];
  columns: TableColumn[];
  aliases: AliasSettingsObj[] | undefined;
  asr_model: AsrModerType[] | undefined;
  editHandleClick(id: string): void;
  deleteHandleClick(id: string): void;
  recognitionHandleClick(id: string, name: string): void;
};

const RecognitionRulesTable: FC<RecognitionRulesTablePropsType> = (props) => {
  const {
    data,
    columns,
    aliases,
    asr_model,
    editHandleClick,
    deleteHandleClick,
    recognitionHandleClick,
  } = props;
  const getAliasesNameById = (id: string) => {
    return aliases?.find((alias) => alias.alias_id === id)?.name || 'N/A';
  };
  const getAsrModelNameById = (id: string) => {
    return asr_model?.find((model) => model.model_id === id)?.name || id;
  };
  const getAliasesTypeById = (id: string) => {
    return aliases?.find((alias) => alias.alias_id === id)?.type_value || id;
  };
  const getFilterValues = (filter: FilterItemNotOnlyArray) => {
    if (Array.isArray(filter.value)) {
      return filter?.value.map((val, index) => {
        return (
          <span className="mr-[6px] last:mr-[0px]" key={index}>
            {val}
          </span>
        );
      });
    } else if (getAliasesTypeById(filter.id) === 'datetime') {
      return getFrontendDate(filter.value as string, 'dd.MM.yyyy HH:mm');
    } else {
      return filter.value;
    }
  };
  function getTableData() {
    return data?.map((item) => ({
      ...item,
      name: (
        <div className={'flex items-center gap-[10px]'}>
          <RecordChannelIcon size={18} className={'text-action'} />
          <span className={'text-0color font-[700] tracking-tight text-[14px] leading-[110%]'}>
            {item.name}
          </span>
        </div>
      ),
      rules: (
        <div className="max-h-[31px]">
          <div className="text-0color font-[400] tracking-tight text-[14px] py-2 leading-[110%]">
            {item.rules.filter.map((filter, index) => {
              return (
                <span
                  key={filter.id + index}
                  className="mr-[16px] py-2 last:mr-[0px] w-full truncate"
                >
                  <span>{getAliasesNameById(filter.id)}</span>
                  <span className="mr-[6px] ml-[6px] w-[19px] h-full border-[1px] border-solid border-[#A6ABBA] rounded-[50%] p-[0px_4px_1px_5px]">
                    {filter.condition}
                  </span>

                  {filter.value[0] !== '' && (
                    <span className="bg-basic_app_bg p-[2px_8px_3px]  rounded-[4px] text-2color w-full">
                      {getFilterValues(filter)}
                    </span>
                  )}
                </span>
              );
            })}
          </div>
        </div>
      ),
      actions: (
        <div className="w-full flex items-center gap-[10px]">
          <div className={'mt-[-2px]'}>
            <CircleButton
              onClick={() => recognitionHandleClick(item.recognize_rule_id, item.name)}
              className={'text-3color '}
              size={24}
              icon={'RecognitionIcon'}
            />
          </div>
          <div>
            <CircleButton
              onClick={() => editHandleClick(item.recognize_rule_id)}
              className={'text-3color'}
              size={18}
              icon={'EditIcon'}
            />
          </div>
          <div>
            <CircleButton
              onClick={() => deleteHandleClick(item.recognize_rule_id)}
              className={'text-basic_red'}
              size={18}
              icon={'TrashIcon'}
            />
          </div>
        </div>
      ),
      asr_model_id: (
        <span className={'text-[14px] text-0color font-[400] leading-[30px] tracking-tight'}>
          {getAsrModelNameById(item.asr_model_id)}
        </span>
      ),
    })) as unknown as TableItem[];
  }
  return <RubberTable columns={columns} dataSource={getTableData() || []} />;
};

export default RecognitionRulesTable;
