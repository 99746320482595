import React, { FC, useEffect, useState } from 'react';
import { Box, Button, CustomDatePicker, EasyModal, Input, Select, Skeleton } from '@ui';

import { FormProvider, useForm } from 'react-hook-form';

import cn from 'classnames';

import useTranslation from '@app/hooks/use-translation';

import { OptionItem } from '@app/components/ui/select/select.type';

import { onFilterInput, onFilterSend } from '@app/components/ui/easy-filter/helpers';

import moment from 'moment/moment';

import { AddFromTemplateModal } from '@app/components/ui/easy-filter/blocks/addFromTemplateModal';

import { useGetPresetTemplateGroupsListQuery } from '@app/store/api/preset-template.api';

import { MetaDateTime, MetaDateTimeCalendar, MetaDateTimeCalendarId } from './data';

import {
  Filter,
  FilterRangeParametersItemForRType,
  FilterRangeType,
  KeysForFilteringType,
  Range,
} from './types';

import { Field } from './blocks';
type EasyFilterProps = {
  data: Filter & Range;
  // changeFilterStateHandle: React.Dispatch<React.SetStateAction<Filter & Range>>;
  onChange?: (data: Filter & Range) => void;
  changeFilterOpenState: React.Dispatch<React.SetStateAction<boolean>>;
  calendarBindValue: React.Dispatch<React.SetStateAction<string>>;
  keysForFiltering: KeysForFilteringType[];
  fillFromTemplate?: boolean;
  fillFromTemplateHandler?: (data: Filter & Range) => void;
  isLoading?: boolean;
};
const DEFAULT_TIME_START = '00:00';
const DEFAULT_TIME_END = '23:59';
const EasyFilter: FC<EasyFilterProps> = (props) => {
  const {
    data: filterData,
    // changeFilterStateHandle,
    keysForFiltering,
    calendarBindValue,
    changeFilterOpenState,
    onChange,
    fillFromTemplate,
    fillFromTemplateHandler,
    isLoading,
  } = props;
  const textStyle = cn('font-[500]', 'text-[14px]', 'leading-[16px]', 'text-3color');
  const { t } = useTranslation('components.filter');
  //api
  const { data: presetTemplates } = useGetPresetTemplateGroupsListQuery();
  const [addFromTemplateModalState, setAddFromTemplateModalState] = useState(false);
  const [metaDateTimeCalendarValue, changeMetaDateTimeCalendarValue] = useState(
    getMetaDateTimeCalendar()[0].value,
  );
  //useState
  const [data, changeFilterData] = useState(filterData);
  const [rangeDatesValue, changeRangeDatesValue] = useState({
    startDate:
      (data.range.parameters[0] as FilterRangeParametersItemForRType)?.value ||
      moment().format('yyyy.MM.DD'),
    startTime:
      moment((data.range.parameters[0] as FilterRangeParametersItemForRType)?.value).format(
        'hh:mm',
      ) || DEFAULT_TIME_START,
    endDate:
      (data.range.parameters[1] as FilterRangeParametersItemForRType)?.value ||
      moment().format('yyyy.MM.DD'),
    endTime:
      moment((data.range.parameters[1] as FilterRangeParametersItemForRType)?.value).format(
        'hh:mm',
      ) || DEFAULT_TIME_END,
  });
  const methods = useForm<Filter & Range>({ defaultValues: data });
  const { handleSubmit, setValue, watch, register } = methods;
  function getRangeSelectOptions(): OptionItem[] {
    return MetaDateTime.map((item) => ({ value: item.id, title: item.name }));
  }
  function getMetaDateTimeCalendar(): OptionItem[] {
    return MetaDateTimeCalendar.map((item) => ({ value: item.id, title: item.name }));
  }

  // useEffect
  useEffect(() => {
    changeFilterData(filterData);
  }, [filterData]);

  useEffect(() => {
    if (watch(`range.parameters.${0}.num`)) return;
    setValue(`range.parameters.${0}.num`, 1);
  }, [setValue, watch]);

  const renderRangeCondition = {
    n: <div></div>,
    r: (
      <div className={'flex flex-col items-start gap-[35px]'}>
        <div>{t('from')}</div>
        <div>{t('before')}</div>
      </div>
    ),
    l: <div></div>,
    [MetaDateTimeCalendarId]: <div>=</div>,
  };
  const renderRangeValue = {
    n: <div></div>,
    r: (
      <div className={'flex flex-col gap-[15px]'}>
        <div className={'flex items-center gap-[11px]'}>
          <CustomDatePicker
            selected={moment(rangeDatesValue.startDate).toDate()}
            onChange={(date) => changeRangeDatesValue((prev) => ({ ...prev, startDate: date }))}
          />
          <Input
            mask="99:99"
            defaultValue={rangeDatesValue.startTime}
            onChange={(time) =>
              changeRangeDatesValue((prev) => ({ ...prev, startTime: time.target.value }))
            }
          />
        </div>
        <div className={'flex items-center gap-[11px]'}>
          <CustomDatePicker
            selected={moment(rangeDatesValue.endDate, 'yyyy.MM.DD').toDate()}
            onChange={(date) => changeRangeDatesValue((prev) => ({ ...prev, endDate: date }))}
          />
          <Input
            defaultValue={rangeDatesValue.endTime}
            mask="99:99"
            onChange={(time) =>
              changeRangeDatesValue((prev) => ({ ...prev, endTime: time.target.value }))
            }
            error={rangeDatesValue.endTime.replace(/\D/gm, '').length !== 4 ? 'err' : undefined}
          />
        </div>
      </div>
    ),
    l: (
      <div className={'w-full flex items-center gap-[15px]'}>
        <div className={textStyle}>{t('last_from_capital')}</div>
        <Input
          {...register(`range.parameters.${0}.num`, {
            valueAsNumber: true,
            max: 90,
            min: 1,
          })}
          error={
            Number(watch(`range.parameters.${0}.num`)) > 90 ? 'Максимальное значение 90 дней' : ''
          }
          min={1}
        />
        <div className={textStyle}>{t('days')}</div>
      </div>
    ),
    [MetaDateTimeCalendarId]: (
      <div>
        <Select
          defaultValue={metaDateTimeCalendarValue}
          onChange={(value) => {
            changeMetaDateTimeCalendarValue(value);
            calendarBindValue(value as string);
          }}
          options={getMetaDateTimeCalendar()}
        />
      </div>
    ),
  };
  const onSubmit = (value: Filter & Range) => {
    let values = value;
    if (values.range.type === 'n') {
      values = { ...values, range: { ...values.range, parameters: [] } };
    } else if (values.range.type === 'r') {
      // console.log(
      //   moment(`${rangeDatesValue.startDate} ${rangeDatesValue.startTime}`, 'yyyy.MM.DD hh:mm')
      //     .toDate()
      //     .toISOString(),
      // );
      const startTime = {
        value: `${moment(rangeDatesValue.startDate).format('YYYY-MM-DD')} ${
          rangeDatesValue.startTime
        }`,
        condition: '>=',
      };
      const endTime = {
        value: `${moment(rangeDatesValue.endDate).format('YYYY-MM-DD')} ${rangeDatesValue.endTime}`,
        condition: '<=',
      };
      values = { ...values, range: { ...values.range, parameters: [startTime, endTime] } };
    }
    const data = onFilterSend(values);
    // changeFilterStateHandle(data);
    onChangeFilter(data);
  };
  function onChangeFilter(data: Filter & Range) {
    let newData = {
      ...data,
      filter: data.filter.filter((_, index) => index != data.filter.length - 1),
    };
    if (newData.range.type === MetaDateTimeCalendarId) {
      newData = { ...newData, range: { ...newData.range, parameters: [] } };
      newData = {
        ...newData,
        range: { ...newData.range, type: metaDateTimeCalendarValue as FilterRangeType },
      };
    }
    onChange?.(newData);
    changeFilterOpenState(false);
  }
  function addFilterFromTemplateHandler(data: Filter & Range) {
    const convertedData = onFilterInput(data);
    const startDate = moment(
      (convertedData.range.parameters as unknown as FilterRangeParametersItemForRType)[0].value,
    ).format('YYYY.MM.DD');
    const startTime = moment(
      (convertedData.range.parameters as unknown as FilterRangeParametersItemForRType)[0].value,
    ).format('hh:mm');
    const endDate = moment(
      (convertedData.range.parameters as unknown as FilterRangeParametersItemForRType)[1].value,
    ).format('YYYY.MM.DD');
    const endTime = moment(
      (convertedData.range.parameters as unknown as FilterRangeParametersItemForRType)[1].value,
    ).format('hh:mm');
    changeFilterData(data);
    methods.setValue('filter', convertedData.filter);
    methods.setValue('range', convertedData.range);
    changeRangeDatesValue({
      startDate: startDate,
      startTime: startTime,
      endTime: endTime,
      endDate: endDate,
    });
    fillFromTemplateHandler?.(data);
    setAddFromTemplateModalState(false);
  }
  const SkeletonLoading = (
    <div className={'pb-6'}>
      <div className={'flex w-full justify-around items-center pb-6'}>
        <Skeleton width={160} height={15} />
        <Skeleton width={160} height={15} />
        <Skeleton width={160} height={15} />
      </div>
      <div className={'flex flex-col justify-center w-full items-center gap-[15px]'}>
        <Skeleton width={987} height={66} />
        <Skeleton width={987} height={66} />
      </div>
    </div>
  );
  if (isLoading) return SkeletonLoading;
  return (
    <Box className="min-h">
      <div className={'w-full flex justify-center items-center'}>
        <FormProvider {...methods}>
          <form className={'relative w-full px-[70px]'} onSubmit={handleSubmit(onSubmit)}>
            <div
              className={
                'text-[12px] leading-[13px] flex pb-[13px] font-semibold uppercase items-center justify-around'
              }
            >
              <div>{t('alias')}</div>
              <div>{t('condition')}</div>
              <div>{t('values')}</div>
            </div>
            <div className={'w-full'}>
              <div
                className={
                  'flex items-center py-4 relative bg-speech_analitics_filter rounded-[12px] border-solid border-l-[2px] border-speech_analitics px-[24px]'
                }
              >
                <div className={'flex items-start gap-[16px]'}>
                  <div className={'w-[360px]'}>
                    <Select
                      defaultValue={watch('range.type')}
                      onChange={(value) => setValue('range.type', value as FilterRangeType)}
                      options={getRangeSelectOptions()}
                    />
                  </div>
                  <div className={`w-[100px] flex self-center justify-center ${textStyle}`}>
                    {renderRangeCondition[watch('range.type')]}
                  </div>
                  <div className={'w-[330px] flex self-center'}>
                    {renderRangeValue[watch('range.type')]}
                  </div>
                </div>
              </div>
            </div>
            <Field keysForFiltering={keysForFiltering} />
            <div className={'py-12 flex items-center justify-between'}>
              <div className={'flex items-center gap-[10px]'}>
                <Button fill={'filled'} label={t('confirm')} type={'submit'} />
                <Button
                  fill={'linked'}
                  label={t('cancel')}
                  onClick={changeFilterOpenState.bind(null, false)}
                />
              </div>
              {fillFromTemplate && (
                <div>
                  <Button
                    onClick={() => setAddFromTemplateModalState(true)}
                    fill={'linked'}
                    icon={'FolderConfigIcon'}
                    label={t('fillFromTemplate')}
                  />
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      {/*modals*/}
      <EasyModal
        variant="mediumW650"
        withoutFooter
        show={addFromTemplateModalState}
        onClose={setAddFromTemplateModalState.bind(null, false)}
        label={
          <div className="text-0color text-[24px] font-bold mb-[25px]">
            {t('add_preset_from_template_title')}
          </div>
        }
      >
        {addFromTemplateModalState && (
          <AddFromTemplateModal
            addFilterFromTemplateHandler={addFilterFromTemplateHandler}
            groups={presetTemplates || []}
          />
        )}
      </EasyModal>
      {/*modals*/}
    </Box>
  );
};

export default EasyFilter;
