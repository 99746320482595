import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import chartSettingsSlice from '@app/store/reducers/chart-settings.slice';
import metaSlice from '@app/store/reducers/meta.slice';
import { recognitionSettingsApi } from '@app/store/reducers/recognition-rules-settings-page.api';
import { asrModelApi } from '@app/store/reducers/asr_model.api';
import { dashboardApi } from '@app/store/reducers/dashboard-page.api';
import { dashboardChartsApi } from '@app/store/reducers/dashboard-charts.api';
import { chartsGraphDataApi } from '@app/store/reducers/graph-data.api';
import { metricRecordsApi } from '@app/store/reducers/metric-records.api';
import { analyticsProjectApi } from '@app/store/reducers/analytics-projects.api';

import { presetTemplateApi } from '@app/store/api/preset-template.api';

import { customAliasesApi } from '@app/store/api/custom-aliases.api';

import recordsApi from './api/records.api';
import restMiddleware from './middleware/rest';
import analyticSlice from './reducers/analyticSlice';
import filterSlice from './reducers/filterSlice';
import recordsSlice from './reducers/recordsSlice';
import analyticsSlice from './reducers/analyticsSlice';
import { RootState } from './../interfaces/common';
import presetsSlice from './reducers/presets-slice';
import recognitionModelsSlice from './reducers/recognitionModelsSlice';
import aliasesSlice from './reducers/aliases-slice';
import metricSlice from './reducers/metric-slice';
import reportsSlice from './reducers/reports.slice';
import dictionariesSlice from './reducers/dictionaries-slice';
import presetSlice from './reducers/presetSlice';
import chartsSlice from './reducers/charts.slice';
import metricTemplatesSlice from './reducers/metric-templates.slice';
import audioChannelsSlice from './reducers/audio-channels.slice';
import { userInfoApi } from './reducers/get-user-info.api';
import { channelsInfoApi } from './reducers/get-channels-info.api';
import { wordsCloudApi } from './reducers/get-words-cloud.api';
import { auditLogsApi } from './reducers/audit-logs.api';
import { commonSettingsApi } from './reducers/common-settings-page.api';
import { aliasSettingsApi } from './reducers/alias-settings-page.api';

const store = configureStore({
  reducer: {
    filter: filterSlice,
    analytics: analyticsSlice,
    analytic: analyticSlice,
    recognitionModels: recognitionModelsSlice,
    records: recordsSlice,
    reports: reportsSlice,
    presets: presetsSlice,
    preset: presetSlice,
    metric: metricSlice,
    aliases: aliasesSlice,
    dictionaris: dictionariesSlice,
    charts: chartsSlice,
    metricTemplates: metricTemplatesSlice,
    chartSettings: chartSettingsSlice,
    meta: metaSlice,
    audioChannels: audioChannelsSlice,
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [channelsInfoApi.reducerPath]: channelsInfoApi.reducer,
    [wordsCloudApi.reducerPath]: wordsCloudApi.reducer,
    [auditLogsApi.reducerPath]: auditLogsApi.reducer,
    [commonSettingsApi.reducerPath]: commonSettingsApi.reducer,
    [aliasSettingsApi.reducerPath]: aliasSettingsApi.reducer,
    [asrModelApi.reducerPath]: asrModelApi.reducer,
    [recognitionSettingsApi.reducerPath]: recognitionSettingsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [dashboardChartsApi.reducerPath]: dashboardChartsApi.reducer,
    [chartsGraphDataApi.reducerPath]: chartsGraphDataApi.reducer,
    [metricRecordsApi.reducerPath]: metricRecordsApi.reducer,
    [analyticsProjectApi.reducerPath]: analyticsProjectApi.reducer,
    [recordsApi.reducerPath]: recordsApi.reducer,
    [presetTemplateApi.reducerPath]: presetTemplateApi.reducer,
    [customAliasesApi.reducerPath]: customAliasesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userInfoApi.middleware,
      recognitionSettingsApi.middleware,
      commonSettingsApi.middleware,
      dashboardApi.middleware,
      aliasSettingsApi.middleware,
      wordsCloudApi.middleware,
      channelsInfoApi.middleware,
      auditLogsApi.middleware,
      metricRecordsApi.middleware,
      dashboardChartsApi.middleware,
      chartsGraphDataApi.middleware,
      asrModelApi.middleware,
      analyticsProjectApi.middleware,
      recordsApi.middleware,
      presetTemplateApi.middleware,
      customAliasesApi.middleware,
      restMiddleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
