import { Filter, FilterRange, FilterRangeItem } from '@app/interfaces/filter';
import {
  setFilter,
  setCloneFilter,
  setRange,
  setCloneRange,
} from '@app/store/reducers/filterSlice';
import { useAppDispatch } from '@app/store/store';
import { useCallback } from 'react';

const useGetUniversalFilter = () => {
  // const { param = false } = props;
  // const { id } = useParams();
  // console.log(id);
  const dispatch = useAppDispatch();

  const getFilter = useCallback(
    function (params?: Filter & FilterRange) {
      const clearRange: FilterRangeItem = {
        type: 'n',
        parameters: [],
      };

      dispatch(setFilter(params?.filter || []));
      dispatch(setCloneFilter(params?.filter || []));
      dispatch(setRange(params?.range || clearRange));
      dispatch(setCloneRange(params?.range || clearRange));
    },
    [dispatch],
  );

  return getFilter;
};

export default useGetUniversalFilter;
