import React from 'react';
import { NavigationTabs } from '@ui';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';
import useTranslation from '@app/hooks/use-translation';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useOutletContext } from 'react-router-dom';
import { ReportOutletContext } from '@app/pages/reports/report.outlet';

const ReportNavigatorBlock = () => {
  const { report, changeIsOpenChartModal } = useOutletContext<ReportOutletContext>();
  const { t } = useTranslation('pages.reports.navigation');
  const navigationIndexes = {
    chart: 'chart',
    settings: 'settings',
    records: 'records',
  };
  const navigatorData: Array<NavigationTabsItem> = [
    {
      label: t('charts'),
      icon: 'ChartIcon',
      index: navigationIndexes.chart,
      link: `/${navigationRoutes.reports}/${report.live_report_id}`,
    },
    {
      icon: 'SettingIcon',
      label: t('settings'),
      index: navigationIndexes.settings,
      link: `/${navigationRoutes.reports}/${report.live_report_id}/${navigationRoutes.settings}`,
    },
    {
      icon: 'TableIcon',
      label: t('records'),
      index: navigationIndexes.records,
      link: `/${navigationRoutes.reports}/${report.live_report_id}/${navigationRoutes.reportRecords}`,
    },
  ];

  function handlerOnChange(clickedTab: NavigationTabsItem) {
    clickedTab.index === navigationIndexes.settings && changeIsOpenChartModal(true);
  }

  return <NavigationTabs onChange={handlerOnChange} items={navigatorData} />;
};

export default ReportNavigatorBlock;
