module.exports = {
  move_group: 'Переместить в группу',
  refresh: 'Обновить',
  remove_choosen: 'Удалить выбранные',
  name: 'Название',
  status: 'Статус',
  open: 'Открыть',
  actions: 'Действия',
  records: 'Записи',
  reports: 'Отчеты',
  group_name_not_selected: 'Название группы не было указано',
  status_will_be: 'Статус проекта будет',
  status_will_be_changed: 'Статус проекта будет изменен с',
  to: 'на',
  cant_change_status: 'Не удалось изменить статус проекта',
  write_name: 'Укажите наименование',
  analytic_proj: 'Аналитические проекты',
  create_proj: 'Создать проект',
  not_projects: 'Нет проектов',
  not_groups: 'Нет созданных групп',
  new_group: 'Новая группа',
  delete_group: 'Удалить группу',
  delete_proj: 'Удалить проект',
  delete_selected_proj: 'Удалить выбранные проекты',
  delete_group_confirm: 'Вы действительно хотите удалить данную группу?',
  delete_proj_confirm: 'Все данные аналитического проекта, в том числе отчёты, будут удалены.',
  delete_proj_active: 'Активный аналитический проект не может быть удален. Требуется остановить проект.',
  delete_projects_confirm: 'Все данные аналитических проектов, в том числе отчёты, будут удалены.',
  settings: {
    proj_is_created: 'Проект успешно создан',
    proj_is_changed: 'Проект успешно изменён',
    error: 'Необходимо заполнить все поля',
    model_error: 'Модель нельзя применить для данного проекта',
    model_list: 'Список доступных моделей распознавания',
    proj_shared: 'Доступ к проекту',
    choose: 'Выберите',
    main_settings: 'Основные настройки',
    proj_name: 'Название проекта',
    group_proj: 'Группа проектов',
    model: 'Модель распознавания',
    create: 'Создать',
    save: 'Сохранить',
    delete: 'Удалить проект',
  },
  preset_fill_error: 'Проект не может быть запущен. Отсутствуют необходимые настройки проекта',
};
